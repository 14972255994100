import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import jQuery from 'jquery';
import { get } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserActions } from '../../actions/user.actions';
import { generateLink } from '../../libs/generate-link';
import { routes } from '../../routes';
import { LanguageSelector } from '../language-selector/language-selector';
import './menu.scss';

interface MenuProps {
    data: any;
    goTo?: any;
    header?: any;
}

const mapStateProps = (store: any) => ({
    header: store.header,
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: (action: any) => any) => ({
    checkWhoAmI: () => dispatch(UserActions.checkWhoAmI())
});

@connect(mapStateProps, mapDispatchProps)
export class Menu extends React.Component<MenuProps, any> {
    public listeners: any[] = [];

    public state: any = {
        subData: null,
        opened: false,
        error: false,
        loading: false,
        affixed: false
    };

    public render(): React.ReactNode {
        return <div className="display-flex MenuComponent">
            {this.renderToggle()}
            {this.state.opened && this.renderBackdrop()}
            {this.renderMenu()}
        </div>;
    }

    public renderToggle() {
        return <div className={`MenuButton display-flex flex-row justify-content-space-between align-items-center cursor-pointer ${this.state.affixed ? 'affixed' : ''}`} onClick={() => this.toggle()}>
            <span className="palette--c-neutral-6 material-icons mb-0">menu</span>
            <span className="ml-4 fs-large fw-bold palette--c-neutral-6  mb-0 line-height-1 display-md-block display-none">{trans('global.menu.title')}</span>
        </div>;
    }

    public renderBackdrop() {
        return <div className="">
            <div className="MenuBackdrop" onClick={() => this.toggle()}/>
        </div>;
    }

    public renderMenu() {
        jQuery(window).on('scroll', () => this.getOffset());
        const primaryMenuClasses = arrayToClass([
            'MenuContent w-100 palette--bgc-neutral-1 p-box'
        ]);

        return <div className={`MenuMenu elevation-menu overflow-hidden palette--bgc-neutral-1 ${this.state.opened ? 'opened' : ''}`}>
            <div className="MenuContentWrapper display-flex flex-column flex-fill mb-8">
                <div className={primaryMenuClasses}>
                    {!this.state.loading && !this.state.error && this.props.data.map((item: any, index: number) => this.renderTopLevelItem(item, index))}
                </div>

                <div className={'display-md-none justify-content-center align-items-center mb-6 display-flex'}>
                    <LanguageSelector openBottom={true}/>
                </div>

                {!get(this.props, 'user', null) && <div className={'display-flex display-md-none flex-column justify-content-end align-items-center py-1 mb-6'}>
                    <div className={'mr-4 mb-7'}>
                        <Link to={generateLink(routes.login)} className={'text-decoration-none'}>
                            <Button onClick={() => null} title={trans('global.signin')} className={'variant-outline hover-opacity palette--c-neutral-6 palette--bc-neutral-6 fw-bold size-large'}/>
                        </Link>
                    </div>
                    <div>
                        <Link to={generateLink(routes.registration)} className={'text-decoration-none'}>
                            <Button onClick={() => null} title={trans('global.signup')} className={'variant-tryout size-large'}/>
                        </Link>
                    </div>
                </div>}

                <div onClick={() => this.toggle()} className={'CloseButton cursor-pointer mb-4 display-flex justify-content-center align-items-center'}>
                    <span className={'material-icons mr-4'}>close</span>
                    <span>{trans('global.menu.close')}</span>
                </div>
            </div>
        </div>;
    }

    public getOffset() {
        const offset = jQuery(window)[0].pageYOffset;
        if (offset >= 60 && !this.state.affixed) {
            this.setState({ affixed: true });
        }

        if (offset <= 60 && this.state.affixed) {
            this.setState({ affixed: false });
        }
    }

    public renderTopLevelItem(item: any, index: number): React.ReactNode {

        const classes = arrayToClass([
            'w-100 display-flex mb-4 cursor-pointer',
        ]);

        if (item.subs) {
            return <div key={index} className={classes}>
                <div className="display-flex flex-column">
                    <div className={'display-flex flex-row justify-content-left align-items-center'}>
                        {!!item.icon && <div className="palette--c-neutral-5 material-icons mr-0 mr-sm-row">{item.icon}</div>}
                        <div className="flex-fill HideWhenOpen">
                            <span className="palette--c-neutral-5 cursor-default">{trans(item.title)}</span>
                        </div>
                    </div>
                    {item.subs.map((sub: any, i: number) => this.renderSubs(sub, i))}
                </div>
            </div>;
        }

        return <Link className={classes} key={index} to={this.getMenuLink(item.link)} onClick={() => this.toggle()}>
            <div className={'display-flex flex-row justify-content-left align-items-center'}>
                {!!item.icon && <div className="palette--c-neutral-5 material-icons mr-0 mr-sm-row">{item.icon}</div>}
                <div className="flex-fill HideWhenOpen">
                    <span className="palette--c-neutral-6 size-large fw-bold">{trans(item.title)}</span>
                </div>
            </div>
        </Link>;
    }

    public getMenuLink(link: string) {
        if (link !== generateLink(routes.starSign)) {
            return link;
        }

        return get(this.props, 'user', null) ? generateLink(routes.compatibilityTest) : generateLink(routes.starSign);
    }

    public renderSubs(item: any, index: number) {
        const classes = arrayToClass([
            'w-100 display-flex mb-4 cursor-pointer SubMenu',
        ]);

        if (item.link.includes("https")) {
            return <a className={classes} key={index} href={item.link}>
                <div className={'display-flex flex-row justify-content-left align-items-center'}>
                    {!!item.image && <div className={'Image'}><img src={item.image} alt={'menu image'}/></div>}
                    <div className="flex-fill HideWhenOpen">
                        <span className="palette--c-neutral-6 size-large fw-bold">{trans(item.title)}</span>
                    </div>
                </div>
            </a>;
        }

        return <Link className={classes} key={index} to={this.getMenuLink(item.link)} onClick={() => this.toggle()}>
            <div className={'display-flex flex-row justify-content-left align-items-center'}>
                {!!item.image && <div className={'Image'}><img src={item.image} alt={'menu image'}/></div>}
                <div className="flex-fill HideWhenOpen">
                    <span className="palette--c-neutral-6 size-large fw-bold ">{trans(item.title)}</span>
                </div>
            </div>
        </Link>;
    }

    private toggle() {
        this.setState({
            opened: !this.state.opened,
        });
    }

}
