import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as queryString from 'querystring';
import * as React from 'react';
import { Spinner } from '../../components/spinner';
import { Subscribable } from '../../libs/subscribable';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class EmailVerificationScreen extends Subscribable<any, any> {
    public state: any = {
        isLoading: false,
        isError: false,
        isSuccess: false
    };

    public componentDidMount(): void {
        this.setState({ isLoading: true }, () => this.validateEmail());
    }

    public render(): React.ReactNode {
        return <div className={'EmailVerificationValidate'}>
            <div className="container">
                <div className="display-flex flex-column justify-content-center align-items-center p-box">
                    <h1 className={'mb-8'}>{trans('verifyemail.status.title')}</h1>
                    {this.state.isLoading && <div className={'display-flex justify-content-center align-items-center flex-column'}>
                        <div className={'mb-16'}><Spinner size={'large'} theme={'dark'}/></div>
                    </div>}
                    {!this.state.isLoading && this.state.isSuccess && <div className={'display-flex justify-content-center align-items-center flex-column'}>
                        <span className={'fs-large mb-16'}>{trans('verifyemail.status.description.success')}</span>
                        <div><span className={'CheckIcon material-icons'}>check_circle</span></div>
                    </div>}
                    {!this.state.isLoading && this.state.isError && <div className={'display-flex justify-content-center align-items-center flex-column'}>
                        <span className={'fs-large mb-16'}>{trans('verifyemail.status.description.error')}</span>
                        <div><span className={'ErrorIcon material-icons'}>error</span></div>
                    </div>}
                </div>
            </div>
        </div>;
    }

    private async validateEmail() {
        try {
            const query = queryString.parse(this.props.location.search);
            const token = query['?token'];
            const user = query.user;

            await Repository.post('/auth/verification/validate', { user: user, token: token });
            this.setState({ isLoading: false, isSuccess: true, isError: false });
            // todo -> worst case scenario put here an extra input to add the credit...
        } catch (err) {
            this.setState({ isLoading: false, isError: true, isSuccess: false });

        }
    }

}
