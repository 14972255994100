import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getLanguage } from '../libs/get-language';

class RedirectHandler extends Component<any, any> {
    public componentDidMount() {
        // Redirect to another domain when the component mounts
        const { history } = this.props;
        const lang = getLanguage();
        if (lang === 'en') {
            history.push('https://selectivita.com');
        } else {
            history.push(`https://selectivita.com/${lang}`);
        }
    }

    public render() {
        return (
            <div>
                {/* This component doesn't need any UI since it redirects in componentDidMount */}
            </div>
        );
    }
}

export default withRouter(RedirectHandler);
