import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import * as React from 'react';
import { Footer } from '../../components/footer/footer';
import { Header } from '../../components/header/header';
import { LanguageLayout } from '../language.layout';
import './simple.layout.scss';

export class SimpleLayout extends LanguageLayout<any, any> {
    public static noBackground() {
        return (props) => React.createElement(SimpleLayout, { ...props, noBackground: true });
    }

    public render(): React.ReactNode {
        const classes = arrayToClass([
            'SimpleLayout position-relative display-flex flex-column Layout',
            this.props.noBackground ? 'noBackground' : ''
        ]);

        return <div className={classes}>
            <Header/>
            <div className="w-100 flex-fill display-flex flex-column position-relative">
                {this.props.children}
            </div>
            <Footer/>
        </div>;
    }
}
