import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { QuestionnaireProps } from '../../screens/questionnaires/questionnaires';
import { QuestionnaireBox } from '../questionnaires/questionnaire-box';
import { StorageHelper } from '../share/helpers/storage.helper';

interface QuestionnaireFormProps {
    questionnaire: QuestionnaireProps;
    current: number;
    onPick: (hasNotPickedYet: boolean) => void;
    questionnaireForm: StorageHelper;
}

export class QuestionnnaireFrom extends Subscribable<QuestionnaireFormProps, any> {
    public state = { question: '', value: NaN, identifier: '', reverse: false };

    public behaviouralOptions = [
        { display: 'test.option.totally.disagree', colour: 'questionnaire-button--red-3', value: 0 },
        { display: 'test.option.disagree', colour: 'questionnaire-button--red-2', value: 1 },
        { display: 'test.option.slightly.disagree', colour: 'questionnaire-button--red-1', value: 2 },
        { display: 'test.option.neutral', colour: 'questionnaire-button--white', value: 3 },
        { display: 'test.option.slightly.agree', colour: 'questionnaire-button--green-1', value: 4 },
        { display: 'test.option.agree', colour: 'questionnaire-button--green-2', value: 5 },
        { display: 'test.option.totally.agree', colour: 'questionnaire-button--green-3', value: 6 },
    ];

    public reverseOptions = [
        { display: 'test.option.totally.disagree', colour: 'questionnaire-button--red-3', value: 7 },
        { display: 'test.option.disagree', colour: 'questionnaire-button--red-2', value: 6 },
        { display: 'test.option.slightly.disagree', colour: 'questionnaire-button--red-1', value: 5 },
        { display: 'test.option.neutral', colour: 'questionnaire-button--white', value: 4 },
        { display: 'test.option.slightly.agree', colour: 'questionnaire-button--green-1', value: 3 },
        { display: 'test.option.agree', colour: 'questionnaire-button--green-2', value: 2 },
        { display: 'test.option.totally.agree', colour: 'questionnaire-button--green-3', value: 1 },
    ];
    public normalOptions = [
        { display: 'test.option.totally.disagree', colour: 'questionnaire-button--red-3', value: 1 },
        { display: 'test.option.disagree', colour: 'questionnaire-button--red-2', value: 2 },
        { display: 'test.option.slightly.disagree', colour: 'questionnaire-button--red-1', value: 3 },
        { display: 'test.option.neutral', colour: 'questionnaire-button--white', value: 4 },
        { display: 'test.option.slightly.agree', colour: 'questionnaire-button--green-1', value: 5 },
        { display: 'test.option.agree', colour: 'questionnaire-button--green-2', value: 6 },
        { display: 'test.option.totally.agree', colour: 'questionnaire-button--green-3', value: 7 },
    ];

    public componentDidMount() {
        if (this.props.questionnaire?.items) {
            this.setQuestionContext();
        }
    }

    public setQuestionContext() {
        const questionnaire = this.props.questionnaire.items[this.props.current - 1];
        const { text, identifier, category } = questionnaire;
        const value = this.getCurrentValue(identifier);
        this.setState({ identifier, category, value, reverse: !!questionnaire?.reverse, question: text[TranslationLib.getLang() || 'en'] });

        return value === '';
    }

    public getCurrentValue(identifier: string) {
        const questionnaireForm = this.props.questionnaireForm.get();
        const value = questionnaireForm?.items?.[identifier]?.value;

        return value !== 0 && !value ? '' : value;
    }

    public componentDidUpdate(prevProps: Readonly<QuestionnaireFormProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.current !== this.props.current || prevProps.questionnaire !== this.props.questionnaire) {
            if (this.props.questionnaire?.items?.[this.props.current - 1]) {
                if (this.setQuestionContext()) {
                    this.props.onPick(true);
                }
            }
        }
    }

    public renderTopSection() {
        const all = this.props.questionnaire?.items?.length;
        const { title } = this.props.questionnaire.meta;
        return <div className={'row'}>
            <div className={'col-12 display-flex justify-content-start'}>
                <h6 className={'fw-bold text--small'}>{trans('account.data.fill.out')} {trans(title)} {trans('account.data.test')}</h6>
            </div>
            <div className={'col-12 display-flex justify-content-end'}>
                <span className={'text--thin'}>{this.props.current}/{all}</span>
            </div>
        </div>;
    }

    public renderQuestion() {
        return <h3 className={'text--h3-black py-5'}>{this.state.question}</h3>;
    }

    public handlePick(value) {
        this.setState({ value: value });
        this.props.onPick(false);
        const questionnaireForm = this.props.questionnaireForm.get();
        questionnaireForm.items[this.state.identifier].value = value;
        this.props.questionnaireForm.set(questionnaireForm);
    }

    public formatButtonsAccordingToQuestionnaire() {
        const identifier = this.props.questionnaire?.meta?.identifier;
        switch (identifier) {
            case 'personality':
                return this.state.reverse ? this.reverseOptions : this.normalOptions;
            case 'behavioural':
                return this.behaviouralOptions;
            case 'love-style':
                return this.normalOptions;
            default:
                return this.behaviouralOptions;
        }
    }

    public renderSelectors() {
        const buttons = this.formatButtonsAccordingToQuestionnaire();
        const desktopButtons = <div className={'position-center py-7 w-100'}>
            {buttons.map(button => {
                const colourClasses = this.state.value === button.value ? 'variant-primary' : button.colour;

                return <Button
                    key={button.value}
                    className={`${colourClasses} size-medium ml-px-24 hover-scale h-px-32 appear-smoothly`}
                    title={trans(button.display)}
                    onClick={() => this.handlePick(button.value)}
                />;
            })}
        </div>;

        const mobileButtons = <div className={'row'}>
            {buttons.map(button => {
                const colourClasses = this.state.value === button.value ? 'variant-primary' : button.colour;

                return <div
                    className={'col-24 mb-3'}
                    key={button.value}
                >
                    <Button
                        className={`${colourClasses} size-medium mt-6 hover-scale h-px-32 appear-smoothly w-100`}
                        title={trans(button.display)}
                        onClick={() => this.handlePick(button.value)}
                    />
                </div>;
            })}
        </div>;

        return <div className={'w-100'}>
            <div className={'display-md-none display-flex'}>
                {mobileButtons}
            </div>
            <div className={'display-none display-md-flex'}>
                {desktopButtons}
            </div>
        </div>;
    }

    public render() {
        return <QuestionnaireBox>
            {this.renderTopSection()}
            {this.renderQuestion()}
            {this.renderSelectors()}
        </QuestionnaireBox>;
    }
}
