import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import moment from 'moment';
import * as React from 'react';
import { Subscribable } from '../../../../libs/subscribable';

export interface CollabsibleTextProps {
    description?: string;
}

export class CollapsibleText extends Subscribable<CollabsibleTextProps, any> {
    public state = { showAll: false, mouseDown: 0 };

    public handleOnClick() {
        const mouseDownTime = moment().valueOf() - this.state.mouseDown;

        if (mouseDownTime < 140) {
            this.setState({ showAll: !this.state.showAll });
        }
    }

    public render() {
        if (!this.props.description) {
            return <span className={'text--span'}>-</span>;
        }

        if (this.props.description.length < 400) {
            return <div className={'w-100'}>
                <div
                    onClick={() => this.setState({ showAll: !this.state.showAll })}
                    className="text--span"
                    dangerouslySetInnerHTML={{ __html: `${this.props.description}` }}
                />
            </div>;
        }

        const title = this.state.showAll ? trans('read.less') : trans('read.more');
        const textToDisplay = this.state.showAll ? this.props.description : `${this.props.description.replace('</br>', '').slice(0, 400)}...`;
        const controller = `<span class="hover-opacity text--span color--primary" >${title}</span>`;

        return <div className={'w-100'}>
            <div
                onMouseDown={() => this.setState({ mouseDown: moment().valueOf() })}
                onMouseUp={() => this.handleOnClick()}
                className="text--span"
                dangerouslySetInnerHTML={{ __html: `${textToDisplay} ${controller}` }}
            />
        </div>;
    }
}
