import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { GaugeWrapper } from '../gauge-chart/gauge-wrapper';
import { DeleteNotificationModal } from '../share/delete-notification.modal';
import { getImageSrc, handleNotEnoughBalance } from '../share/helpers/util';
import { ErrorModal } from '../share/ui-components/error.modal';
import { Hr } from '../share/ui-components/hr';
import { Spinner } from '../spinner';
import { AnalysisModal } from './analysis-modal';

interface MatchStripProps {
    match: any;
    index: number;
    profiles: any[];
    isMobile: boolean;
}

export class AssessedStrip extends React.Component<MatchStripProps, any> {
    public $errorModal: ErrorModal;
    public $modal: AnalysisModal;
    public state = {
        isLoading: false,
        error: '',
        shouldAnimate: false,
        isPurchased: false,
        matchValue: 0
    };

    public componentDidMount() {
        this.setState({
            isPurchased: this.props.match.isAnalysisPurchased,
            matchValue: Math.round(this.props.match.value) / 100
        });
    }

    public componentDidUpdate(prevProps: Readonly<MatchStripProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.match.isAnalysisPurchased !== this.props.match.isAnalysisPurchased) {
            this.setState({ isPurchased: this.props.match.isAnalysisPurchased });
        }
    }

    public renderButtonText(text: string, credit: number) {
        const creditText = trans(credit > 1 ? 'global.label.credits' : 'global.label.credit');

        return <span>{trans(text)} <span className={'text-italic'}>({credit} {creditText})</span></span>;
    }

    public renderCreditButtonContent(icon: string, title: string) {
        return <div className={'display-flex'}>
            <span>{this.state.isLoading ? <div className={'pl-4'}><Spinner/></div> : this.renderButtonText(title, 10)}</span>
        </div>;
    }

    public renderButtonContent(icon: string, title: string) {
        return <div className={'display-flex'}>
            <span>{trans(title)}</span>
        </div>;
    }

    public renderMatchData(data: any, extraClasses?: string) {
        return <div className={'display-flex flex-column'}>
            <h4 className={`text--h3-black ${extraClasses}`}>{data.name}</h4>
        </div>;
    }

    public renderProfilePic(data: any, size: number) {
        return <div
            className={`img-wrapper rounded w-px-${size} h-px-${size}`}
            style={{ backgroundImage: `url(${getImageSrc(data.photo, data.gender)})` }}
        />;
    }

    public renderItemDesktop(data: any, index: number) {
        return <div className={'row w-100 justify-content-space-around px-6 mx-0 py-px-20 display-md-flex display-none'}>
            <div className={'col-3 position-center'}>
                <GaugeWrapper
                    id={'1'}
                    isAnimated={this.state.shouldAnimate}
                    value={this.state.matchValue}
                    width={104}
                    height={104}
                />
            </div>
            <div className={'col-3 display-flex display-md-none position-center'}>
                <GaugeWrapper
                    id={'2'}
                    isAnimated={this.state.shouldAnimate}
                    value={this.state.matchValue}
                    width={50}
                    height={50}
                />
            </div>
            <div className={'col-lg-3 col-6 position-center'}>{this.renderProfilePic(data, 80)}</div>
            <div className={'col-lg-6 col-5 display-flex justify-content-flex align-items-center'}>{this.renderMatchData(data)}</div>
            <div className={'col-lg-10 col-8 display-flex justify-content-end align-items-center pr-0 flex-column flex-lg-row'}>
                <div className={'row w-100'}>
                    <div className={'col-24 py-lg-0 py-7 display-flex justify-content-end'}>
                        {this.state.isPurchased
                            ? this.renderReadAnalysisButton()
                            : this.renderPurchaseButton()}

                        <DeleteNotificationModal
                            triggerClass={'ml-8'}
                            endpoint={`/couples-assessment/${data.id}`}
                            onSuccess={() => window.location.reload()}
                        />
                    </div>
                </div>
            </div>
            {this.props.profiles.length !== index + 1 && <Hr type={'light'} className={'col-24 pt-px-16'}/>}
        </div>;
    }

    public renderItemMobile(data: any, index: number) {
        return <div className={'row w-100 position-center px-6 mx-0 pt-px-10 display-flex display-md-none'}>
            <div className={'col-12 position-center'}>
                <GaugeWrapper
                    id={'3'}
                    isAnimated={this.state.shouldAnimate}
                    value={this.state.matchValue}
                    width={90}
                    height={90}
                />
            </div>
            <div className={'col-12 position-center'}>{this.renderProfilePic(data, 80)}</div>
            <div className={'col-24 position-center py-4'}>{this.renderMatchData(data, 'fs-19')}</div>
            <div className={'col-24'}>
                <div className={'row w-100'}>
                    <div className={'col-24 position-center pb-7'}>
                        {this.state.isPurchased
                            ? this.renderReadAnalysisButton()
                            : this.renderPurchaseButton()}
                    </div>
                </div>
            </div>
            <div className={'col-24 postion-center py-4'}>
                <DeleteNotificationModal
                    endpoint={`/couples-assessment/${data.id}`}
                    onSuccess={() => window.location.reload()}
                />
            </div>
            {this.props.profiles.length !== index + 1 && <Hr type={'light'} className={'col-24 pt-px-16'}/>}
        </div>;
    }

    public renderPurchaseButton() {
        return <Button
            onClick={() => this.manageAnalysisUpdate()}
            title={this.renderCreditButtonContent('book', 'account.data.buy.analysis')}
            className={'variant-primary size-large'}
        />;
    }

    public renderReadAnalysisButton() {
        return <Button
            onClick={() => this.$modal.open()}
            title={this.renderButtonContent('book', 'account.data.read.analysis')}
            className={'variant-primary size-large'}
        />;
    }

    public async manageAnalysisUpdate() {
        try {
            this.setState({ isLoading: true });

            const response = await Repository.put('/couples-assessment/buy-analysis', { matchId: this.props.match.id });

            const matchValue = response.updatedMatch?.matchScore;

            if (!matchValue) {
                throw new Error(trans('account.data.error.something.went.wrong'));
            }

            this.setState({ isLoading: false, shouldAnimate: true, isPurchased: true, matchValue: Math.round(matchValue) / 100 });
        } catch (error) {
            this.setState({ isLoading: false, error: error?.message || trans('account.data.error.something.went.wrong') });

            handleNotEnoughBalance(error);
        }
    }

    public renderModals() {
        return <>
            <ErrorModal ref={(ref: any) => this.$errorModal = ref} errorText={this.state.error}/>
            <AnalysisModal {...this.props} shouldFetch={this.state.isPurchased} ref={(ref: any) => this.$modal = ref}/>
        </>;
    }

    public render() {
        return <div key={this.props.match.name}>
            {
                this.props.isMobile
                    ? this.renderItemMobile(this.props.match, this.props.index)
                    : this.renderItemDesktop(this.props.match, this.props.index)
            }
            {this.renderModals()}
        </div>;
    }
}
