import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';

export interface NotificationStripProps {
    content: JSX.Element;
    backgroundColour: 'blue' | 'orange';
}

export class NotificationStrip extends Subscribable<NotificationStripProps, any> {
    public getBackgroundColour() {
        switch (this.props.backgroundColour) {
            case 'blue':
                return 'background-blue';
            case 'orange':
                return 'background-orange';
            default:
                return '';
        }
    }

    public render() {
        return <div className={`w-100 p-4 position-center ${this.getBackgroundColour()}`}>
            {this.props.content}
        </div>;
    }
}
