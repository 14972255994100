import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { ColourInterface } from './chart';

export interface ChartData {
    range: { start: number, finish: number };
    average: number;
    userValue: number;
    unit: number;
}

export interface IndicatorProps {
    value: number;
    label: string;
    color: ColourInterface;
    isOverlapping: boolean;
    chartData: ChartData;
    size?: 'sm' | 'md' | 'lg';
}

export class Indicator extends Subscribable<IndicatorProps, any> {
    public state = { active: false };

    public getValueRange() {
        switch (this.props.size) {
            case 'sm':
                return { min: -5, max: 5 };
            case 'md':
                return { min: 0, max: 10 };
            case 'lg':
                return { min: 0, max: 25 };
            default:
                return { min: 5, max: 35 };
        }
    }

    public render() {
        const { min, max } = this.getValueRange();
        const { value, label, color: { borderColor, backgroundColor } } = this.props;
        const zIndex = this.state.active ? 'z-100' : '';
        const bottom = this.props.isOverlapping ? 131 : 72;
        const { unit, range: { start } } = this.props.chartData;
        const val = value <= min ? min : value >= max ? max : value;
        const left = ((val - start) * unit) - (val === max ? 2 : 1);
        const langSpecificPadding = TranslationLib.getLang() === 'hu' ? 'px-1' : '';

        return [<div
            key={1}
            onMouseOver={() => this.setState({ active: true })}
            onMouseLeave={() => this.setState({ active: false })}
            style={{ left: `${left - 18}%`, top: `-${bottom}%` }}
            className={`${zIndex} display-flex flex-column justify-content-center align-items-center position-absolute fix-width-85`}
        >
            <div style={{ borderColor: borderColor, backgroundColor: backgroundColor }} className={`${langSpecificPadding} value-indicator background-white position-center mb-3`}>
                <span style={{ color: borderColor }} className={'fs-12'}>{value}% {label}</span>
            </div>
        </div>,
            <div
                key={2}
                style={{ left: `${left}%`, top: `-${1}%`, backgroundColor: borderColor }}
                className={` position-absolute colored-dot ${this.props.isOverlapping ? 'move-dot-bottom' : ''}`}
            />];
    }
}
