import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import * as React from 'react';
import { HandleToasts } from '../../../handle.toasts';
import { Subscribable } from '../../../libs/subscribable';
import { BirthDateInput } from '../../birth-date-picker/birth-date-input';
import { EditModal } from '../shared/edit.modal';

export class DatePickerModal extends Subscribable<any, any> {
    public modal$: EditModal | null;
    public form = new Form({
        birthDate: new Field({
            label: '',
            value: '',
            isDate: true,
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        })
    });

    public componentDidMount() {
        if (this.props.user && !this.props.birthDate) {
            this.modal$?.openModal();
        }
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.birthDate !== this.props.birthDate && !this.props.birthDate && this.props.user) {
            this.modal$?.openModal();
        }
    }

    public async handleSubmit() {
        try {
            const data = this.form.toJSON();

            const response = await Repository.put('/user', data);

            if (!response) {
                throw new Error('something went wrong');
            }

            this.modal$?.closeModal();

            window.location.reload();
        } catch (err) {
            this.modal$?.closeModal();
            HandleToasts.error(err, trans(err?.response?.data?.message || err?.message));
        }
    }

    public renderDatePicker() {
        return <div className={'p-box'}>
            <h2 className={'palette--c-neutral-5 fs-large text-uppercase fs-21'}>{trans('account.edit.title')}</h2>
            <span className={'palette--c-neutral-5 fs-large'}>{trans('account.data.please.provide.your.date.of.birth')}</span>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <div className={'row pb-7'}>
                    <div className={'col-24 position-center'}>
                        <BirthDateInput field={this.form.field('birthDate')}/>
                    </div>
                    <div className={'col-24 display-flex justify-content-end pt-7'}>
                        <Button type={'submit'} className="variant-primary size-medium">
                            <span className="material-icons mr-4">check</span>
                            <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                        </Button>
                    </div>
                </div>
            </AbstractForm>
        </div>;
    }

    public renderModal(): null | JSX.Element {
        return <EditModal
            className={'max-width-448'}
            content={this.renderDatePicker()}
            ref={ref => this.modal$ = ref}
        />;
    }

    public render() {
        return this.renderModal();
    }
}
