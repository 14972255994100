export interface ScreenContentProps {
    title?: string;
    description?: string;
    showButton?: boolean;
    buttonTitle?: string;
    buttonIcon?: string;
    buttonOnClick?: () => void;
}

export abstract class HeaderActions {
    public static readonly SET_SCREEN_CONTENT = 'UrlActions(SET_SCREEN_CONTENT)';

    public static setScreenContent(content: ScreenContentProps) {
        return {
            type: HeaderActions.SET_SCREEN_CONTENT,
            payload: content
        };
    }

}
