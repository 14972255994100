import * as React from 'react';
import { EmailVerificationMessage } from '../../components/authentication/email-verification/email-verification-message';
import { CompatibilityResults } from '../../components/compatibility-test/compatibility-results/compatibility-results';
import { NewCompatibilityTest } from '../../components/compatibility-test/new-test/new-compatibility-test';
import { Subscribable } from '../../libs/subscribable';
import './start-sign-compatibility-test.screen.scss';

export class StartSignCompatibilityTestScreen extends Subscribable<any, any> {
    public resultListRef$: CompatibilityResults | null;

    public render(): React.ReactNode {
        return <div className={'CompatibilityTestScreen py-7 container'}>
            <div className="BackgroundWrapper"/>
            <div className="row display-flex justify-content-center">
                <div className="col-lg-8 col-24 mb-7">
                    <EmailVerificationMessage/>
                </div>
                <div className="col-lg-18 col-24 mb-7">
                    <NewCompatibilityTest onNewCompatibility={() => (this.resultListRef$ as any).reload()}/>
                </div>
                <div className="col-lg-18 col-24 mb-7">
                    <CompatibilityResults ref={(ref) => this.resultListRef$ = ref}/>
                </div>
            </div>
        </div>;
    }

}
