import React from 'react';

export class Dropdown extends React.Component<any, any> {
    public state = { show: false };

    public componentDidMount() {
        if (this.props.showByDefault) {
            this.setState({ show: this.props.showByDefault });
        }
    }

    public renderTrigger() {
        return <div className={'display-flex justify-content-space-between align-items-center no-clear-fix'}>
            {this.props.trigger}
            <span className={'material-icons'}>{this.state.show ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
        </div>;
    }

    public render() {
        return <div className={'dropdown-wrapper'}>
            <div
                onClick={() => this.setState({ show: !this.state.show })}
                className={'dropdown-trigger hover-opacity'}
            >
                {this.renderTrigger()}
            </div>
            <div className={`dropdown-content transition--smooth dropdown--${this.state.show ? 'show' : 'hide'}`}>
                {this.props.children}
            </div>
        </div>;
    }
}
