import { connect } from '@codebuild/cookie-jar/libs/connect';
import { push } from 'connected-react-router';
import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { UserActions } from '../actions/user.actions';
import { Spinner } from '../components/spinner';
import { Subscribable } from './subscribable';

interface UnauthorizedRouteProps extends RouteProps {
    /**
     * Current screen layout
     */
    layout: any;

    /**
     * If we cannot authenticate the user, it is possible to define behavior of navigation
     * - true -> Redirect user to a route as soon as possible
     * - false -> do nothing
     */
    redirectOnError?: boolean;

    /**
     * If redirectOnError is true
     * Target to redirect
     */
    redirectOnErrorTarget?: string;

    /**
     * Specify how to render loading
     */
    renderLoading?: () => React.ReactNode;

    showLayoutWhileLoading?: boolean;

    /**
     * Specify how to render error
     */
    renderError?: () => React.ReactNode;

    showLayoutWhileError?: boolean;

    // -.-
    whoAmIRequest?: any;
    checkWhoAmI?: any;
    redirect?: any;
    location?: any;
    user?: any;
    routeSource?: any;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    production: store.authentication.production,
    whoAmIRequest: store.authentication.whoAmIRequest
});

const mapDispatchProps = (dispatch: any) => ({
    checkWhoAmI: () => dispatch(UserActions.checkWhoAmI()),
    redirect: (target: string) => dispatch(push(target || '/login')),
});

@connect(mapStateProps, mapDispatchProps)
export class AuthorizedRoute extends Subscribable<UnauthorizedRouteProps, any> {
    public componentDidMount(): void {
        this.props.checkWhoAmI();

        (window as any).ROUTE_SOURCE = this.props.routeSource;
    }

    public componentDidUpdate(prevProps: Readonly<UnauthorizedRouteProps>, prevState: Readonly<any>, snapshot?: any): void {

        if ((prevProps.whoAmIRequest.error !== this.props.whoAmIRequest.error) && this.props.whoAmIRequest.error) {
            if (this.getRedirectOnError()) {
                this.props.redirect(this.props.redirectOnErrorTarget);
            }
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.checkWhoAmI();
        }

        (window as any).ROUTE_SOURCE = this.props.routeSource;
    }

    public renderError(): React.ReactNode {
        if (this.props.renderError) {
            return this.props.renderError();
        }

        return <h3>Error</h3>;
    }

    public renderLoading(): React.ReactNode {
        if (this.props.renderLoading) {
            return this.props.renderLoading();
        }

        return <div className="position absolute absolute-center">
            <Spinner theme="dark" size="large"/>
        </div>;
    }

    public render(): React.ReactNode {
        if (this.props.whoAmIRequest.error) {
            if (!this.getShowLayoutWhileError()) {
                return this.renderError();
            }

            return React.createElement(
                this.props.layout,
                this.props,
                React.createElement(Route, this.props, this.renderError())
            );
        }

        if (!!this.props.whoAmIRequest.loading || !this.props.user) {
            if (!this.getShowLayoutWhileLoading()) {
                return this.renderLoading();
            }

            return React.createElement(
                this.props.layout,
                this.props,
                React.createElement(Route, this.props, this.renderLoading())
            );
        }

        return React.createElement(
            this.props.layout,
            this.props,
            React.createElement(Route, this.props, this.props.children)
        );
    }

    private getRedirectOnError(): boolean {
        if (this.props.redirectOnError === undefined) {
            return true;
        }

        return this.props.redirectOnError;
    }

    private getShowLayoutWhileLoading(): boolean {
        if (this.props.showLayoutWhileLoading === undefined) {
            return false;
        }

        return this.props.showLayoutWhileLoading;
    }

    private getShowLayoutWhileError(): boolean {
        if (this.props.showLayoutWhileError === undefined) {
            return false;
        }

        return this.props.showLayoutWhileError;
    }
}
