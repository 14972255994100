import * as React from 'react';
import { Subscribable } from '../../../../libs/subscribable';
import { Chart } from '../../../questionnaires/chart';
import { ChartsWithDescriptionProps } from './charts-with-description';
import { CollapsibleText } from './collapsible-text';

export interface DoubleChartsWithDescriptionProps extends Omit<ChartsWithDescriptionProps, 'datasets'> {
    differenceDataset: { [key: string]: number };
    user1Dataset: { [key: string]: number };
    user2Dataset: { [key: string]: number };
    evaluatedData: any;
    differenceTitle: string;
    comparisonTitle: string;
}

export const GENDER_COLOURS = {
    female: { borderColor: ['#EF6BA4'], backgroundColor: ['#EF6BA4'] },
    male: { borderColor: ['#2995F8'], backgroundColor: ['#2995F8'] }
};

export class DoubleChartsWithDescription extends Subscribable<DoubleChartsWithDescriptionProps, any> {
    public renderDifferenceChart() {
        const datasets = Object.values(this.props.differenceDataset).map(v => Math.abs(v));
        const labels = Object.keys(this.props.differenceDataset);

        return <Chart
            title={this.props.differenceTitle}
            chartWrapper={this.props.chartWrapper}
            customBarColours={this.props.customColors}
            user={this.props.user?.meta as any}
            colorIndex={this.props.datasetsColor}
            colorIndex2={this.props.datasetsColor2}
            chartName={this.props.chartName}
            data={datasets}
            labels={labels}
            primaryOptionLabel={this.props.primaryOptionLabel}
            secondaryOptionLabel={this.props.secondaryOptionLabel}
            doNotDisplayLegend={this.props.doNotDisplayLegend}
        />;
    }

    public getColourByGender() {
        switch (this.props.user?.meta?.gender) {
            case 'account.edit.gender.female':
                return GENDER_COLOURS.female;
            case 'account.edit.gender.male':
                return GENDER_COLOURS.male;
            default:
                return GENDER_COLOURS.female;
        }
    }

    public getColourIndexByGender() {
        switch (this.props.evaluatedData?.rightGender) {
            case 'account.edit.gender.female':
                return 0;
            case 'account.edit.gender.male':
                return 1;
            default:
                return 0;
        }
    }

    public renderComparisonChart() {
        const user1Dataset = Object.values(this.props.user1Dataset);
        const labels = Object.keys(this.props.user1Dataset);
        const user2Dataset = Object.values(this.props.user2Dataset);

        return <Chart
            title={this.props.comparisonTitle}
            chartWrapper={this.props.chartWrapper}
            customBarColours={this.getColourByGender()}
            user={this.props.user?.meta as any}
            colorIndex={this.props.datasetsColor}
            colorIndex2={this.getColourIndexByGender()}
            chartName={this.props.chartName}
            data={user1Dataset}
            data2={user2Dataset}
            labels={labels}
            primaryOptionLabel={this.props.primaryOptionLabel}
            secondaryOptionLabel={this.props.secondaryOptionLabel}
            doNotDisplayLegend={this.props.doNotDisplayLegend}
        />;
    }

    public render() {
        const fullSized = this.props.fullSized ? 24 : 12;
        const classes = `col-md-${fullSized} col-24 ${this.props.classNames}`;

        return <div className={'row'}>
            <div className={classes}>
                <CollapsibleText description={this.props.description}/>
            </div>
            <div className={'col-24 col-md-12'}>
                {this.renderComparisonChart()}
            </div>
            <div className={'col-24 col-md-12'}>
                {this.renderDifferenceChart()}
            </div>
        </div>;
    }

}
