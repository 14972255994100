import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import { get } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserActions } from '../../actions/user.actions';
import { generateLink } from '../../libs/generate-link';
import { getLanguage } from '../../libs/get-language';
import { Subscribable } from '../../libs/subscribable';
import { routes } from '../../routes';
import { LanguageSelector } from '../language-selector/language-selector';
import { Menu } from '../menu/menu';
import { ProfileMenu } from '../menu/profile-menu/profile-menu';
import './header.scss';

const logo = require('../../assets/images/selectivita-logo-symbol.svg');

const mapStateProps = (store: any) => ({
    header: store.header,
    user: store.authentication.user,
    whoAmIRequest: store.authentication.whoAmIRequest
});

const mapDispatchProps = (dispatch: any) => ({
    checkWhoAmI: () => dispatch(UserActions.checkWhoAmI()),
});

@connect(mapStateProps, mapDispatchProps)
export class Header extends Subscribable<any, any> {
    public menuSource = require('../menu/menu-source.ts');

    public render(): React.ReactNode {
        const classes = arrayToClass([
            'palette--bgc-neutral-1',
            'palette--bgc-neutral-1',
            'justify-content-between',
            'elevation-1',
            'Header display-flex px-box py-4'
        ]);

        return <div className={classes}>
            <div className={'display-flex justify-content-start align-items-center flex-fill'}>
                <div className={' py-4 pr-7 align-items-center'}>
                    <Menu data={this.menuSource}/>
                </div>
                <span className={'Separator'}/>
                <div className={'px-7'}>
                    <a className={'text-decoration-none cursor-pointer'} key={1} href={this.getLink()}>
                        <div className={'justify-content-start align-items-center display-flex flex-row'}>
                            <img src={logo} className={'Logo'} alt="logo"/>
                            <h3 className={'mb-0 ml-4 display-block'}>Selectivita</h3>
                        </div>
                    </a>
                </div>
            </div>

            <div className={'display-flex justify-content-end align-items-center flex-fill'}>
                <div className={'display-md-flex align-items-center display-none mr-8'}>
                    <LanguageSelector showArrow={true} openBottom={true}/>
                </div>

                {get(this.props, 'user', null) && <ProfileMenu/>}
            </div>

            {!get(this.props, 'user', null) && <div className={'display-none display-md-flex justify-content-end align-items-center py-1'}>
                <div className={'mr-4'}>
                    <Link to={generateLink(routes.login)} className={'text-decoration-none'}>
                        <Button onClick={() => null} title={trans('global.signin')} className={'variant-outline hover-opacity palette--c-neutral-6 palette--bc-neutral-6 fw-bold size-large'}/>
                    </Link>
                </div>
                <div>
                    <Link to={generateLink(routes.registration)} className={'text-decoration-none'}>
                        <Button onClick={() => null} title={trans('global.signup')} className={'variant-tryout size-large'}/>
                    </Link>
                </div>
            </div>}
        </div>;
    }

    private getLink() {
        const lang = getLanguage();
        const links = routes.profile;
        const routeWithLang = links.languages.find(route => route.lang === lang);

        return routeWithLang?.path || '';
    }
}
