import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { handleError } from '../../share/helpers/util';
import { OverlaySpinner } from '../../share/ui-components/overlay-spinner';
import { BoxWrapper } from '../shared/box-wrapper';
import { UserBase } from '../shared/user.base';
import { EditPhotos } from './edit.photos';
import { Lightbox } from './lightbox';
import { UploaderMulti } from './uploader/uploader-multi';

export class PhotoBox extends UserBase {
    public state = {
        ...this.state,
        isLoading: false,
        shouldClose: false,
    };

    public editSize = {
        cut: 2,
        slice: 3
    };
    public viewSize = {
        cut: 3,
        slice: 4,
    };

    public renderImage(photo: string, isOverlay = false) {
        const trigger = !isOverlay
            ? <div className={'img-wrapper img-wrapper--rounded hover-opacity'} style={{ backgroundImage: `url(${photo})` }}/>
            : <div className={'img-wrapper img-wrapper--rounded hover-opacity'} style={{ backgroundImage: `url(${photo})` }}>
                {this.renderLastImage()}
            </div>;

        return <Lightbox
            photos={this.state.meta.photos || []}
            trigger={trigger}
        />;
    }

    public renderLastImage() {
        const photosLength = (this.state.meta?.photos || []).length;
        const maxNumberOfImages = this.props.enableEdit ? this.editSize.cut : this.viewSize.cut;

        if (photosLength > maxNumberOfImages) {
            return <div className={'img-overlay'}>
                <span>+{photosLength - maxNumberOfImages}</span>
            </div>;
        }

        return null;
    }

    public async updateUserInfo(newPhotos: string[]) {
        try {
            this.setState({ isLoading: true });
            const photos = [...(this.state.meta?.photos || []), ...newPhotos];
            const response = await Repository.put('/user', { photos });

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            this.setState({ isLoading: false });
            handleError(err);
        }
    }

    public addMore() {
        return <UploaderMulti
            onUploadSuccess={(attachments) => this.updateUserInfo(attachments)}
            trigger={<div className={'img-wrapper img-wrapper--rounded hover-opacity background-dark position-center mb-6 mx-4'}>
                <span className={'material-icons color--light'}>add_a_photo</span>
            </div>}
        />;
    }

    public renderImages() {
        const maxNumberOfImages = this.props.enableEdit ? this.editSize.cut : this.viewSize.cut;
        const maxSliceSize = this.props.enableEdit ? this.editSize.slice : this.viewSize.slice;

        if (this.state.meta?.photos && this.state.meta?.photos.length) {
            return <div className={'display-flex flex-wrap'}>
                {this.props.enableEdit && this.addMore()}
                {(this.state.meta.photos
                    .slice(0, maxSliceSize))
                    .map((photo, index) => <div className={'mb-6 mx-4'} key={`${photo}-${index}`}>{this.renderImage(photo, index === maxNumberOfImages)}</div>)}
            </div>;
        }

        return this.props.enableEdit ? this.addMore() : <div className={'w-100 position-center'}><span>{trans('account.data.empty')}</span></div>;
    }

    public render() {
        return <BoxWrapper
            hideClose={true}
            shouldClose={this.state.shouldClose}
            content={<EditPhotos
                onCancel={() => this.setState({ shouldClose: !this.state.shouldClose })}
                meta={this.state.meta}
            />}
            enableEdit={this.props.enableEdit}
            customPadding={'p-4'}
            title={<h6 className={'fw-bold text--small'}>{trans('account.data.more.photos')}</h6>}
        >
            <OverlaySpinner strokeColor={'black'} isLoading={this.state.isLoading}/>
            {this.renderImages()}
        </BoxWrapper>;
    }
}
