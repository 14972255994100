import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserActions } from '../../../actions/user.actions';
import { HandleToasts } from '../../../handle.toasts';
import { FacebookAuth } from '../../../libs/facebook.auth';
import { generateLink } from '../../../libs/generate-link';
import { getLanguage } from '../../../libs/get-language';
import { Subscribable } from '../../../libs/subscribable';
import { routes } from '../../../routes';
import { LanguageSelector } from '../../language-selector/language-selector';
import { MessageBox } from '../../message-box/message-box';
import { Spinner } from '../../spinner';
import './login.component.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    loginRequest: store.authentication.loginRequest,
    whoAmIRequest: store.authentication.whoAmIRequest,
});

const mapDispatchProps = (dispatch: any) => ({
    login: (credentials: any) => dispatch(UserActions.login(credentials, true, '/profile')),
    facebookLogin: (credentials: any) => dispatch(UserActions.facebookLogin(credentials, true, '/profile'))
});

@connect(mapStateProps, mapDispatchProps)
export class LoginComponent extends Subscribable<any, any> {
    public form = new Form({
        email: new Field({
            label: trans('login.email'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        password: new Field({
            label: <div className={'display-flex flex-row justify-content-space-between'}>
                <span className={'flex-fill input-label fs-small fw-bold palette--c-neutral-5 text-uppercase'}>{trans('login.password')}</span>
                <Link className={'palette--c-blue-2 text-decoration-none text-transform-none fs-small fw-medium'} to={generateLink(routes.forgotPasswordRequest)}>{trans('login.forgetpassword')}</Link>
            </div>,
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        })
    });

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (!isEqual(prevProps.loginRequest.error, this.props.loginRequest.error) && this.props.loginRequest.error) {
            this.setState({ error: get(this.props, 'loginRequest.error.response.data') });
        }

        if ((prevProps.whoAmIRequest.error !== this.props.whoAmIRequest.error) && this.props.whoAmIRequest.error) {
            HandleToasts.error(this.props.whoAmIRequest.error);
        }
    }

    public isDisabled(): boolean {
        return this.props.loginRequest.loading;
    }

    public handleSubmit() {
        if (this.props.loginRequest.loading) {
            return;
        }

        const lang = getLanguage();

        this.props.login({ lang, type: 'password', credentials: { ...this.form.toJSON() } });
    }

    public render(): React.ReactNode {
        return <div className="display-flex flex-column flex-fill align-items-center LoginComponent">
            {this.renderForm()}
            <div className="flex-fill">
                {this.renderCheckingWhoAmI()}
            </div>
            {this.renderFooter()}
        </div>;
    }

    private renderForm(): React.ReactNode {
        if (this.props.whoAmIRequest.loading || this.props.whoAmIRequest.response) {
            return null;
        }

        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row justify-content-start">
                <div className="w-100 pt-box px-box">
                    <h1>{trans('login.title')}</h1>
                    <span className={'palette--c-neutral-5 fs-large'}>{trans('login.description')}</span>
                </div>
            </div>
            <div className="row mb-7">
                <div className="w-100 col-24 p-box">
                    {this.renderFacebookLoginButton()}
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 p-box">
                    <span className={'palette--c-neutral-5 fs-large mb-7'}>{trans('login.description2')}</span>

                    <Input field={this.form.field('email')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="email" disabled={this.isDisabled()}/>
                    <Input field={this.form.field('password')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="password" disabled={this.isDisabled()}/>
                </div>
            </div>

            <div className="row">
                <div className="w-100 col-24 display-flex justify-content-start px-box pb-box">
                    <Button className="variant-outline size-medium" title={this.props.loginRequest.loading ? <Spinner theme={'custom'} customStroke={'#637dcd'}/> : trans('login.button')}/>
                </div>
            </div>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <MessageBox type={'error'} message={trans(get(this.state, 'error.message'))}/>
                </div>
            </div>}
        </AbstractForm>;
    }

    private renderFooter() {
        return <div className={'display-flex flex-column justify-content-center align-items-center'}>
            <div className={'mb-6'}>
                <LanguageSelector/>
            </div>
            <div className={''}>
                <span className={'text-center'}>{this.getCopyRightText()}</span>
            </div>
        </div>;
    }

    private getCopyRightText() {
        const start = trans('global.footermenu.copyright.start');
        const end = trans('global.footermenu.copyright.end');
        const currentYear = moment().format('y').toString();

        return `${start}${currentYear} ${end}`;
    }

    private renderFacebookLoginButton() {
        return <Button
            type={'button'}
            className={'variant-submit size-large FacebookButton'}
            onClick={() => this.facebookLogin()}
        >
            <img className={'facebook-icon'} src="/assets/images/icons/facebook-logo-white.svg" alt="facebook logo"/>
            <span>{trans('login.facebookbutton')}</span>
        </Button>;
    }

    private async facebookLogin() {
        this.setState({ error: null });
        const authInstance = new FacebookAuth();

        await authInstance.initialize({
            appId: Environment.get('facebookAppId')
        });

        try {
            const response = await authInstance.signIn(['email']);

            this.props.facebookLogin({ type: 'facebook-v5', credentials: { ...response } });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    private renderCheckingWhoAmI(): React.ReactNode {
        if (!this.props.whoAmIRequest.loading) {
            return null;
        }

        return <div className={''}>
            <div className="w-100 p-box display-flex justify-content-center">
                <Spinner size="large" theme="dark"/>
            </div>
        </div>;
    }
}
