import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';

export interface ErrorModalProps {
    errorText?: string;
}

export class ErrorModal extends Subscribable<ErrorModalProps, any> {
    public modal$: Modal;

    public renderContent() {
        return <div>
            <h2 className={'pb-4 pt-2 palette--c-red-2'}>{trans('account.data.error.header')}</h2>
            <h3>{this.props.errorText}</h3>
        </div>;
    }

    public open() {
        this.modal$.open();
    }

    public close() {
        this.modal$.close();
    }

    public render(): React.ReactNode {
        return <Modal
            ref={(ref: any) => (this.modal$ = ref)}
            content={() => this.renderContent()}
            wrapperClasses="ErrorModal palette--bgc-neutral-1 elevation-2 border-radius-1 p-2"
            footer={() => null}
            onDismissRequest={() => this.close()}
        />;
    }
}
