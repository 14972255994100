import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import * as React from 'react';

// const spinner = require('../assets/oval.svg');

export interface SpinnerProps {
    size?: 'mini' | 'medium' | 'large';
    theme?: 'light' | 'dark' | 'custom';
    className?: any;
    customStroke?: string;
}

export class Spinner extends React.Component<SpinnerProps, {}> {
    public sizes = {
        mini: {
            width: 20
        },
        medium: {
            width: 30
        },
        large: {
            width: 50
        }
    };

    public themes = {
        light: {
            stroke: '#fff'
        },
        dark: {
            stroke: 'grey'
        },
        custom: {
            stroke: this.props.customStroke || '#9b79f7'
        }
    };

    public getSize(): number {
        return this.sizes[this.props.size || 'mini'].width;
    }

    public getStroke() {
        return this.themes[this.props.theme || 'light'].stroke;
    }

    public render(): React.ReactNode {
        const classes = arrayToClass([
            this.props.className,
            'Spinner'
        ]);

        return <div className={classes}>
            <svg width={this.getSize()} height={this.getSize()} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={this.getStroke()}>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                        <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </g>
                </g>
            </svg>
        </div>;
    }

}
