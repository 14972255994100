import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { get, metricLengthToImperial, metricWeightToImperial } from '../../share/helpers/util';
import { PersonalInfosForm } from '../forms/personal-infos-form';
import { BoxWrapper } from '../shared/box-wrapper';
import { UserBase } from '../shared/user.base';

export class PersonalAttributes extends UserBase {

    public getTrans(data: any) {
        return get(trans(data || ''));
    }

    public getOptions(options: string[]) {
        return [(options as any || [])].flat().map(option => <span key={option} className={'text--small display-block'}>{this.getTrans(option)}</span>);
    }

    public getDisplayedWeight(weight?: string) {
        if (!weight) {
            return '-';
        }

        const imperial = metricWeightToImperial(+weight);

        return `${Math.round(+weight)} kg | ${imperial}lbs`;
    }

    public getDisplayedHeight(height?: string) {
        if (!height) {
            return '-';
        }

        const imperial = metricLengthToImperial(+height);

        return `${Math.round(+height)} cm | ${imperial}`;
    }

    public renderFirstColumn() {
        return <div className={'w-100'}>
            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.gender')}</span>
                <span className={'text--small'}>{trans(get(this.state.meta?.gender))}</span>
            </div>
            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.height')}</span>
                <span className={'text--small'}>{this.getDisplayedHeight(this.state.meta?.height)}</span>
            </div>

            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.ethnicity')}</span>
                <span className={'text--small'}>{this.getOptions((this.state.meta?.ethnicity || []))}</span>
            </div>
        </div>;
    }

    public renderSecondColumn() {
        return <div className={'w-100'}>
            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.hair.color')}</span>
                <span className={'text--small'}>{this.getTrans(this.state.meta?.hairColor)}</span>
            </div>
            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.weight')}</span>
                <span className={'text--small'}>{this.getDisplayedWeight(this.state.meta?.weight)}</span>
            </div>
            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.body.type')}</span>
                <span className={'text--small'}>{this.getTrans(this.state.meta?.bodyType)}</span>
            </div>

            <div className={'display-flex flex-column mb-7'}>
                <span className={'text--small-grey'}>{trans('account.data.eye.color')}</span>
                <span className={'text--small'}>{this.getTrans(this.state.meta?.eyeColor)}</span>
            </div>
        </div>;
    }

    public render() {
        return <BoxWrapper
            shouldClose={this.state.shouldClose}
            content={<PersonalInfosForm
                onCancel={() => this.setState({ shouldClose: !this.state.shouldClose })}
                meta={this.state.meta}
            />}
            className={'h-100'}
            enableEdit={this.props.enableEdit}
            title={<h6 className={'fw-bold text--small'}>{trans('account.data.personal.infos')}</h6>}
        >
            <div className={'row'}>
                <div className={'col-12'}>
                    {this.renderFirstColumn()}
                </div>
                <div className={'col-12'}>
                    {this.renderSecondColumn()}
                </div>
            </div>
        </BoxWrapper>;
    }
}
