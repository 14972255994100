import * as React from 'react';
import { Faq } from '../../components/faq/faq';
import { Subscribable } from '../../libs/subscribable';
import './faq.screen.scss';

export class FaqScreen extends Subscribable<any, any> {

    public render(): React.ReactNode {
        return <div className={'FaqScreen p-box'}>
            <div className="container">
                <div className={'display-flex justify-content-center align-items-center p-8'}>
                    <img className={'QuestionIcon mr-12'} src={'/assets/images/icons/image-question.webp'} alt={'question image'} />
                    <h1>Questions? Look here.</h1>
                </div>
                <div className="mb-8">
                    <Faq/>
                </div>
            </div>
        </div>;
    }

}
