import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { GaugeWrapper } from '../../gauge-chart/gauge-wrapper';

export class StarSignTab extends Subscribable<any, any> {
    public renderTopSection() {
        return <div className={'w-100 position-center'}>
            <GaugeWrapper
                width={200}
                height={200}
                isAnimated={false}
                value={Math.round(this.props.evaluatedData.starSignCompatibility) / 100}
            />
        </div>;
    }

    public renderBottomSection() {
        return <div className={'px-7 pb-7'}>
            <h4 className={'text--h3 pb-4'}>{trans('account.data.overview')}</h4>
            <span className={'text--span'}>{this.getTextBlocks(trans('starsign.on.couples.assessment'))}</span>
        </div>;
    }

    public getTextBlocks(text: string) {
        if (!text) {
            return ' - ';
        }

        const items = text?.split('\n');

        return (items || []).map((item, index) => <span key={index} className={'text--span mb-6'}>{item}</span>);
    }

    public render() {
        if (!this.props?.evaluatedData?.starSignCompatibility) {
            return null;
        }
        return <div className={'row'}>
            <div className={'col-24 py-6'}>
                {this.renderTopSection()}
            </div>
            <div className={'col-24 pt-7'}>
                {this.renderBottomSection()}
            </div>
        </div>;
    }
}
