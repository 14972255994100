import { Field } from '@codebuild/cookie-jar/libs/form/field';
import React from 'react';
import { Subscribable } from '../../../../libs/subscribable';

export interface CustomMultiInputProps {
    field: Field;
    existingLinks?: string[];
}

export interface CustomMultiInputState {
    value: string;
    addedLinks: string[];
}

export class CustomMultiInput extends Subscribable<CustomMultiInputProps, CustomMultiInputState> {
    public state = {
        value: '',
        addedLinks: []
    };

    public componentDidMount() {
        if (this.props.existingLinks) {
            this.setState({ addedLinks: this.props.existingLinks });
        }
    }

    public componentDidUpdate(prevProps: Readonly<CustomMultiInputProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevState.addedLinks !== this.state.addedLinks) {
            this.props.field.setValue(this.state.addedLinks);
        }
        if (prevProps.existingLinks !== this.props.existingLinks && this.props.existingLinks) {
            this.setState({ addedLinks: this.props.existingLinks });
        }
    }

    public renderDisplay() {
        if (!this.state.addedLinks.length) {
            return null;
        }

        return this.state.addedLinks.map(link => this.renderLinkItem(link));
    }

    public handleAdd() {
        if (!this.state.value) {
            return;
        }

        this.setState({ addedLinks: [...this.state.addedLinks, this.state.value], value: '' });
    }

    public handleRemove(link: string) {
        this.setState({ addedLinks: this.state.addedLinks.filter(l => l !== link) });
    }

    public renderAddButton() {
        return <div onClick={() => this.handleAdd()} className={'add-button-other hover-opacity position-center'}>
            <span className={'material-icons color--light'}>add</span>
        </div>;
    }

    public renderLinkItem(link: string) {
        return <div key={link} className={'row my-5'}>
            <div className={'col-24 display-flex justify-content-space-between align-items-center'}>
                <span className={'text--small'}>{link}</span>
                <div onClick={() => this.handleRemove(link)} className={'remove-button-other hover-opacity position-center mr-2'}>
                    <span className={'material-icons color--light'}>remove</span>
                </div>
            </div>
        </div>;
    }

    public render() {
        return <div>
            <div>
                <label
                    htmlFor={'custom-input-other'}
                    className={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                >
                    {this.props.field.label}
                </label>
                <div className={'row'}>
                    <div className={'col-24 display-flex justify-content-space-between align-items-center'}>
                        <div className={'custom-input-other w-100 mr-2'}>
                            <input
                                onChange={(e) => this.setState({ value: e.target.value })}
                                value={this.state.value}
                                id={'custom-input-other'}
                                name={'custom-input-other'}
                                className="mb-box variant-primary size-medium w-100"
                                type="text"
                            />
                        </div>
                        {this.renderAddButton()}
                    </div>
                </div>
                {this.renderDisplay()}
            </div>
        </div>;
    }
}
