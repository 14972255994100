import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { handleError } from '../../share/helpers/util';
import { UserInterface } from '../../share/interfaces/user.interface';

interface LikeButtonProps {
    user: UserInterface | any;
}

export class LikeButton extends Subscribable<LikeButtonProps, any> {
    public state = { isLiked: false };

    public async componentDidMount() {
        if (this.props?.user?._id) {
            await this.getIsLiked();
        }
    }

    public async componentDidUpdate(prevProps: Readonly<LikeButtonProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user && this.props?.user?._id) {
            await this.getIsLiked();
        }
    }

    public async getIsLiked() {
        try {
            const response = await Repository.get(`/like/is-liked-by-me/${this.props?.user?._id}`);
            this.setState({ isLiked: response.isLiked });
            if (!response) {
                throw new Error('something went wrong');
            }
        } catch (e) {
            handleError(e);
        }
    }

    public async handleOnLikeButtonClick() {
        try {
            const payload = { profileId: this.props?.user?._id };
            const response = await Repository.post('/like', payload);

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (e) {
            handleError(e);
        }
    }

    public renderLikeButton() {
        const iconClass = this.state.isLiked ? 'color--red' : '';
        const title = !this.state.isLiked
            ? trans('account.data.add.to.like.list')
            : trans('account.data.remove.from.like.list');

        return <div className={'position-center w-100'}>
            <Button
                className={'ui-base uikit-button variant-contact size-medium'}
                onClick={() => this.handleOnLikeButtonClick()}
            >
                <i className={`fa fa-heart pr-2 ${iconClass}`}/>
                <span className={'fs-13'}>{title}</span>
            </Button>
        </div>;
    }

    public render() {
        return <div className={'w-100'}>
            {this.renderLikeButton()}
        </div>;
    }
}
