import { numArray } from '../../share/helpers/util';
import { COUNTRIES } from './countries';
import { countryTranslator } from './country-translator';

export const GENDER_OPTIONS = [
    {
        value: 'account.edit.gender.female',
        title: 'account.edit.gender.female'
    },
    {
        value: 'account.edit.gender.male',
        title: 'account.edit.gender.male'
    }
];

export const ETHNICITY = (() => {
    return numArray(52).map((v, index) => {
        const value = `account.data.ethnicity.${index}`;

        return { value, title: value };
    });
})();

export const RELATIONSHIP_STATUS = (() => {
    return numArray(5).map((v, index) => {
        const value = `account.data.relationship.status.${index + 1}`;

        return { value, title: value };
    });
})();

export const FREQUENCY_OF_WORSHIP = (() => {
    return numArray(6).map((v, index) => {
        const value = `account.data.religious.practices.${v}`;

        return { value, title: value };
    });
})();

export const EYE_COLOR = (() => {
    return numArray(10).map((v, index) => {
        const value = `account.data.eye.color.${v}`;

        return { value, title: value };
    });
})();

export const HAIR_COLOR = (() => {
    return numArray(14).map((v, index) => {
        const value = `account.data.hair.color.${v}`;

        return { value, title: value };
    });
})();

export const EDUCATION = (() => {
    return numArray(11).map((v, index) => {
        const value = `account.data.education.${v}`;

        return { value, title: value };
    });
})();

export const SEXUAL_ORIENTATION = (() => {
    return numArray(4).map((v, index) => {
        const value = `account.data.sexual.orientation.${v}`;

        return { value, title: value };
    });
})();

export const LIBIDO = (() => {
    return numArray(6).map((v, index) => {
        const value = `account.data.libido.${v}`;

        return { value, title: value };
    });
})();

export const ALCOHOL = (() => {
    return numArray(6).map((v, index) => {
        const value = `account.data.alcohol.${v}`;

        return { value, title: value };
    });
})();

export const DRUGS = (() => {
    return numArray(4).map((v, index) => {
        const value = `account.data.drugs.${v}`;

        return { value, title: value };
    });
})();

export const PROFESSIONS = (() => {
    return numArray(28).map((v, index) => {
        const value = `account.data.profession.${v}`;

        return { value, title: value };
    });
})();

export const RELATIONSHIP_TYPE = (() => {
    return numArray(8).map((v, index) => {
        const value = `account.data.relationship.type.${v}`;

        return { value, title: value };
    });
})();

export const LANGUAGES = (() => {
    return numArray(42).map((v, index) => {
        const value = `account.data.language.${v}`;

        return { value, title: value };
    });
})();

export const RELATIONSHIP_HISTORY = (() => {
    return numArray(8).map((v, index) => {
        const value = `account.data.relationship.history.${v}`;

        return { value, title: value };
    });
})();

export const CHILDREN = (() => {
    return numArray(7).map((v, index) => {
        const value = `account.data.children.${v}`;

        return { value, title: value };
    });
})();

export const LIVING_STATUS = (() => {
    return numArray(5).map((v, index) => {
        const value = `account.data.living.status.${v}`;

        return { value, title: value };
    });
})();

export const BODY_TYPE = (() => {
    return numArray(6).map((v, index) => {
        const value = `account.data.body.type.${v}`;

        return { value, title: value };
    });
})();

export const MONEY_MANAGEMENT_HABITS = (() => {
    return numArray(5).map((v, index) => {
        const value = `account.data.money.management.habits.${v}`;

        return { value, title: value };
    });
})();

export const UPBRINGING = (() => {
    return numArray(6).map((v, index) => {
        const value = `account.data.upbringing.${v}`;

        return { value, title: value };
    });
})();

export const SMOKING_HABIT = (() => {
    return numArray(5).map((v, index) => {
        const value = `account.data.smoking.habit.${v}`;

        return { value, title: value };
    });
})();

export const TATTOO = (() => {
    return numArray(7).map((v, index) => {
        const value = `account.data.tattoo.${v}`;

        return { value, title: value };
    });
})();

export const RELIGION = (() => {
    return numArray(27).map((v, index) => {
        const value = `account.data.religion.${v}`;

        return { value, title: value };
    });
})();

export const RELIGIOUS_PRACTICES = (() => {
    return numArray(6).map((v, index) => {
        const value = `account.data.religious.practices.${v}`;

        return { value, title: value };
    });
})();

export const COUNTRY_OPTIONS = (() => {
    return (COUNTRIES || []).map(({ code, name }, index) => {
        const title = countryTranslator(code);

        return { title, value: code };
    });
})();
