import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { LanguageSelector } from '../../language-selector/language-selector';
import { MessageBox } from '../../message-box/message-box';
import { Spinner } from '../../spinner';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class RequestForgotPassword extends Subscribable<any, any> {
    public state: any = {
        isLoading: false,
        requestSent: false
    };
    public form = new Form({
        email: new Field({
            label: trans('forgotpsw.email'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        })
    });

    public isDisabled(): boolean {
        return this.state.isLoading;
    }

    public async handleSubmit() {
        try {
            this.setState({ isLoading: true, error: null });
            await Repository.post('/auth/forgot-password/request', { email: this.form.toJSON().email });

            this.setState({ requestSent: true });

        } catch (err) {
            this.setState({ error: err });
        } finally {
            this.setState({ isLoading: false });

        }
    }

    public render(): React.ReactNode {
        if (this.state.requestSent) {
            return <div className="row display-flex flex-column flex-fill align-items-center">
                <span className={'CheckIcon material-icons'}>check_circle</span>
                <h1 className={'mb-8'}>Check your inbox!</h1>
                <span className={'fs-large'}>We sent an email with the email verification link. Please check your inbox and verify your email.</span>
            </div>;
        }

        return <div className="display-flex flex-column flex-fill align-items-center LoginComponent">
            <div className="flex-fill">
                {this.renderForm()}
            </div>
            {this.renderFooter()}
        </div>;
    }

    private renderFooter() {
        return <div className={'display-flex flex-column justify-content-center align-items-center'}>
            <div className={'mb-6'}>
                <LanguageSelector/>
            </div>
            <div className={''}>
                <span className={'text-center'}>{this.getCopyRightText()}</span>
            </div>
        </div>;
    }

    private getCopyRightText() {
        const start = trans('global.footermenu.copyright.start');
        const end = trans('global.footermenu.copyright.end');
        const currentYear = moment().format('y').toString();

        return `${start}${currentYear} ${end}`;
    }

    private renderForm(): React.ReactNode {

        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row justify-content-start">
                <div className="w-100 pt-box px-box">
                    <h1>{trans('forgotpsw.title')}</h1>
                    <span className={'palette--c-neutral-5 fs-large'}>{trans('forgotpsw.description')}</span>
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 p-box">
                    <Input field={this.form.field('email')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="email" disabled={this.isDisabled()}/>

                </div>
            </div>

            <div className="row">
                <div className="w-100 col-24 display-flex justify-content-start px-box pb-box">

                    <Button className="variant-outline size-medium" title={this.state.isLoading ? <Spinner theme={'custom'} customStroke={'#9b79f7'}/> : trans('forgotpsw.button')}/>

                </div>
            </div>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                </div>
            </div>}
        </AbstractForm>;
    }
}
