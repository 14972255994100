import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { CollapsibleText } from '../couples-assessment/tabs/extras/collapsible-text';
import { getUrlByLang } from '../profile/analysis/libs/get-url-by-lang';
import { EditModal } from '../profile/shared/edit.modal';
import { CategoryDescription } from '../share/category-description';
import { DimensionTabs } from '../share/dimension-tabs';
import { UserInterface } from '../share/interfaces/user.interface';
import { GenderForm } from './forms/gender.form';
import { PersonalityCharts } from './libs/personality-charts';
import { RegularCharts } from './libs/regular-charts';
import { QuestionnaireBox } from './questionnaire-box';

interface QuestionnaireProps {
    boxLabel: string;
    isComplete: boolean;
    questionnaire: 'love-style' | 'personality' | 'behavioural';
    description: string;
    user: UserInterface;
    index?: number;
}

export class Questionnaire extends Subscribable<QuestionnaireProps, any> {
    public modal$;
    public genderModal$;

    public renderHeader() {
        const { isComplete, description, boxLabel } = this.props;
        const descHtml = isComplete ? trans(`${description}.complete`) : trans(`${description}.incomplete`);

        return <div className={'row'}>
            <div className={'col-md-14 col-24 display-flex flex-column'}>
                <h6 className={'fw-bold text--small'}>{boxLabel}</h6>
                <CollapsibleText description={descHtml}/>
            </div>
            <div className={'col-md-10 col-24 display-flex justify-content-md-end justify-content-center align-items-center mt-8 mt-md-0'}>
                <Button
                    onClick={() => this.modal$.openModal()}
                    disabled={!isComplete}
                    className={`ui-base uikit-button variant-${!isComplete ? 'disabled' : 'primary'} size-large`}
                >
                    <span>{trans('account.data.read.my.analysis')}</span>
                </Button>
            </div>
        </div>;
    }

    public renderCharts() {
        switch (this.props.questionnaire) {
            case 'personality':
                return <PersonalityCharts {...this.props} />;
            case 'behavioural':
                return <RegularCharts
                    {...this.props}
                    title={'account.data.temperament.predisposition'}
                />;
            default:
                return <RegularCharts
                    {...this.props}
                    title={'account.data.love.style.dimensions'}
                />;
        }
    }

    public renderGenderAssignmentModal() {
        if (!this.props.user?.meta?.gender) {
            return <a
                className={'text-decoration-none position-center'}
                onClick={(e) => {
                    e.preventDefault();
                    this.genderModal$.openModal();
                }}
            >
                <span>{trans('account.data.fill.out.for.free')}</span>
            </a>;
        }

        const baseHref = getUrlByLang('startQuestionnaire');

        return <a
            className={'text-decoration-none position-center'}
            href={`${baseHref}/${this.props.questionnaire}`}
        >
            <span>{trans('account.data.fill.out.for.free')}</span>
        </a>;
    }

    public renderIncomplete() {
        return <div className={'position-center w-100 h-px-111'}>
            <Button className={'ui-base uikit-button variant-outline size-large border-box-button'}>
                {this.renderGenderAssignmentModal()}
            </Button>
        </div>;
    }

    public renderDescription(questionnaire: any) {
        if (!questionnaire) {
            return null;
        }

        switch (this.props.questionnaire) {
            case 'personality':
                return <DimensionTabs subCategory={questionnaire?.subCategory} category={questionnaire?.category}/>;
            default:
                return <CategoryDescription data={questionnaire} questionnaire={this.props.questionnaire}/>;
        }
    }

    public render() {
        const baseHref = getUrlByLang('startQuestionnaire');

        return <QuestionnaireBox className={'row p-5 w-100'}>
            <div className={'col-24 mb-6 pb-7 pb-md-2'}>
                {this.renderHeader()}
            </div>
            <div className={'col-24'}>
                {this.props.isComplete ? this.renderCharts() : this.renderIncomplete()}
            </div>
            <EditModal
                className={'max-width-900'}
                content={this.renderDescription(this.props.user.meta[`${this.props.questionnaire}Questionnaire`])}
                ref={ref => this.modal$ = ref}
            />
            <EditModal
                ref={ref => this.genderModal$ = ref}
                content={<GenderForm
                    href={`${baseHref}/${this.props.questionnaire}`}
                    onCancel={() => this.genderModal$?.closeModal()}
                />}
            />
        </QuestionnaireBox>;
    }
}
