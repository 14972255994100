import React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { EditModal } from './edit.modal';

interface BoxWrapperProps {
    children: any;
    className?: string;
    enableEdit?: boolean;
    questionnaireBox?: boolean;
    hideClose?: boolean;
    content: any;
    shouldClose?: boolean;
    customPadding?: string;
    renderExtraContent?: JSX.Element | null;
    customTrigger?: JSX.Element | null;
    modalClass?: string;
    title?: JSX.Element;
}

export class BoxWrapper extends Subscribable<BoxWrapperProps, any> {
    public modal$;

    public onClickHandler() {
        this.modal$.openModal();
    }

    public componentDidUpdate(prevProps: Readonly<BoxWrapperProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.shouldClose !== this.props.shouldClose) {
            this.modal$.closeModal();
        }
    }

    public renderEdit() {
        return <div onClick={() => this.onClickHandler()} className={'hover-scale display-flex justify-content-end'}>
            <span className={'material-icons'}>edit</span>
        </div>;
    }

    public renderQuestionnaireBoxes() {
        const containerSize = this.props.enableEdit ? { first: 'col-18', second: 'col-6' } : { first: 'col-10', second: 'col-14' };

        return <div className={'row'}>
            <div className={'row col-24 h-px-64'}>
                <div className={containerSize.first}>
                    {this.props?.title ? this.props.title : null}
                </div>
                <div className={containerSize.second}>
                    {this.props.enableEdit && this.renderEdit()}
                    {this.props.renderExtraContent && <div className={'width-100 display-flex justify-content-end'}>{this.props.renderExtraContent}</div>}
                    {this.props.customTrigger && <div className={'width-100 display-flex justify-content-end'} onClick={() => this.onClickHandler()}>{this.props.customTrigger}</div>}
                </div>
            </div>

            <div className={'col-24 h-px-210 display-flex flex-column justify-content-space-between'}>{this.props.children}</div>

            <EditModal
                hideClose={this.props.hideClose}
                modalClass={this.props.modalClass}
                content={this.props.content}
                ref={ref => this.modal$ = ref}
            />
        </div>;
    }

    public renderRegularBoxes() {
        return <div className={'row'}>
            <div className={'col-18'}>
                {this.props?.title ? this.props.title : null}
            </div>
            <div className={'col-6'}>
                {this.props.enableEdit && this.renderEdit()}
            </div>
            <div className={'col-24'}>
                {this.props.children}
            </div>
            <EditModal
                hideClose={this.props.hideClose}
                modalClass={this.props.modalClass}
                content={this.props.content}
                ref={ref => this.modal$ = ref}
            />
        </div>;
    }

    public render() {
        const padding = this.props.customPadding ? this.props.customPadding : ' p-7';

        return <div className={`border-radius-px-8 ${padding} background-white ${this.props.className}`}>
            {this.props.questionnaireBox
                ? this.renderQuestionnaireBoxes()
                : this.renderRegularBoxes()}
        </div>;
    }
}
