import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { HandleToasts } from '../../../../handle.toasts';
import { Subscribable } from '../../../../libs/subscribable';
import { analysisTabs, AnalysisTabsProps } from '../../../couples-assessment/analysis-tabs';

import { handleError, handleNotEnoughBalance, PurchasedInfoTypes } from '../../../share/helpers/util';
import { UserInterface } from '../../../share/interfaces/user.interface';
import { Spinner } from '../../../spinner';
import { BoxWrapper } from '../../shared/box-wrapper';
import { BehaviourContent, UserAnalysisData } from './modal-content/behaviour-content';
import { LoveStyleContent } from './modal-content/love-style-content';
import { PersonalityContent } from './modal-content/personality-content';

export type AnalysisType = 'behaviouralQuestionnaire' | 'personalityQuestionnaire' | 'love-styleQuestionnaire' | 'starSignCompatibilityDetails' | 'starSignCharts';

export interface CompatibilityBoxProps {
    isPurchased?: boolean;
    tabName: AnalysisTabsProps['fixedTab'];
    boxTitle: string;
    userId: string;
    user?: UserInterface;
    viewedUser?: UserInterface;
    type: PurchasedInfoTypes;
    calcType?: PurchasedInfoTypes;
    analysisType: AnalysisType;
    renderAlternativeContent?: () => JSX.Element;
    renderGaugeContent?: (compatibility: number, isAnimated: boolean) => JSX.Element;
    isCalculated?: boolean;
}

export interface CompatibilityBoxState {
    analysisData: UserAnalysisData | null;
    isPurchased?: boolean;
    isAnimated: boolean;
    isCalculated: boolean;
    showTooltip: string;
    showTooltip2: string;
    loading: boolean;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class CompatibilityBox extends Subscribable<CompatibilityBoxProps, any> {
    public state: CompatibilityBoxState = {
        analysisData: null,
        isPurchased: this.props.isPurchased,
        isCalculated: !!this.props.isCalculated,
        showTooltip: '',
        showTooltip2: '',
        isAnimated: false,
        loading: false,
    };

    public async componentDidMount() {
        if (!!this.props.isCalculated) {
            await this.getAnalysisData();
        }
    }

    public async componentDidUpdate(prevProps: Readonly<CompatibilityBoxProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.isPurchased !== this.props.isPurchased) {
            this.setState({ isPurchased: this.props.isPurchased });
        }
        if (prevProps.isCalculated !== this.props.isCalculated) {
            this.setState({ isCalculated: this.props.isCalculated });
        }
        if (prevState.isCalculated !== this.state.isCalculated) {
            await this.getAnalysisData();
        }
        if (prevState.isPurchased !== this.state.isPurchased) {
            await this.getAnalysisData();
        }
    }

    public async getAnalysisData(): Promise<any> {
        try {
            const profileId = this.props?.userId || '';

            if (!profileId || !this.props.analysisType) {
                return null;
            }

            const analysisData = await Repository.get('/analysis-purchase/get-user-analysis', {
                id: profileId,
                type: this.props.analysisType
            });

            if (!analysisData) {
                throw new Error('something went wrong');
            }

            this.setState({ analysisData });
        } catch (e) {
            handleError(e);
        }
    }

    public async handleCalculateCompatibility() {
        try {
            if (this.state.loading) {
                return;
            }

            this.setState({ loading: true });

            const profileId = this.props.userId;

            const response = await Repository.put('/analysis-purchase/purchase-profile-infos', { profileId, type: this.props.type });

            if (!response) {
                throw new Error('something went wrong');
            }

            await this.getAnalysisData();

            this.setState({ isAnimated: true, isCalculated: true });
        } catch (e) {
            HandleToasts.error(e, trans(e?.response?.data?.message || e?.message || 'account.data.error.something.went.wrong'));

            handleNotEnoughBalance(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    public async handleViewDataPurchase(type: string) {
        try {
            if (this.state.loading) {
                return;
            }

            this.setState({ loading: true });

            const profileId = this.props.userId;

            const response = await Repository.put('/analysis-purchase/purchase-profile-infos', { profileId, type });

            if (!response) {
                throw new Error('something went wrong');
            }

            await this.getAnalysisData();

            window.location.reload();
        } catch (e) {
            HandleToasts.error(e, trans(e?.response?.data?.message || e?.message || 'account.data.error.something.went.wrong'));

            handleNotEnoughBalance(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    public renderPopOver(tooltip: string) {
        return <div className={'popup min-width-max-content'}>
            <span className={'fs-12 py-2 px-7'}>{trans(tooltip)}</span>
        </div>;
    }

    public renderButtonContent(text: string, credit: number) {
        const creditText = trans(credit > 1 ? 'global.label.credits' : 'global.label.credit');

        return <span className={'fs-13'}>{trans(text)} <span className={'text-italic fs-13'}>({credit} {creditText})</span></span>;
    }

    public renderDisabledTooltipButton(tooltip: string) {
        return <div>
            <Button
                onMouseOver={() => this.setState({ showTooltip: true })}
                onMouseOut={() => this.setState({ showTooltip: false })}
                className={'ui-base uikit-button variant-disabled size-medium'}
            >
                {this.renderButtonContent('account.data.buy.read', 1)}
            </Button>
            {this.state.showTooltip && this.renderPopOver(tooltip)}
        </div>;
    }

    public renderActiveTab() {
        switch (this.props.tabName) {
            case analysisTabs.behaviour:
                return <BehaviourContent user={this.props.user} userAnalysisData={this.state.analysisData}/>;
            case analysisTabs.personality:
                return <PersonalityContent user={this.props.user} userAnalysisData={this.state.analysisData}/>;
            case analysisTabs.loveStyle:
                return <LoveStyleContent user={this.props.user} userAnalysisData={this.state.analysisData}/>;
            default:
                return null;
        }
    }

    public manageGauge() {
        if (this.props.renderAlternativeContent) {
            return this.props.renderAlternativeContent();
        }

        const compatibility = this.state.isCalculated ? (this.state?.analysisData?.compatibilityRate || 0) / 100 : 0;

        return this.props?.renderGaugeContent?.(compatibility, this.state.isAnimated);
    }

    public renderButton() {
        if (this.state.loading) {
            return <Button
                className={'ui-base uikit-button variant-primary size-medium'}
            >
                <Spinner/>
            </Button>;
        }

        const questionnaire = this.props.user?.meta?.[this.props.analysisType];
        const viewedUserQuestionnaire = this.props.viewedUser?.meta?.[this.props.analysisType];

        if (!questionnaire || !viewedUserQuestionnaire) {
            const tooltipText = !questionnaire
                ? 'account.data.questionnaire.is.not.completed.yet'
                : !viewedUserQuestionnaire ? 'account.data.questionnaire.is.not.completed.yet.by.user' : '';

            return this.renderDisabledTooltipButton(tooltipText);
        }

        if (this.state.isPurchased) {
            return null;
        }

        return <Button
            className={'ui-base uikit-button variant-primary size-medium'}
            onClick={() => this.handleViewDataPurchase(this.props.calcType ?? this.props.type)}
        >
            {this.renderButtonContent('account.data.buy.data', 1)}
        </Button>;
    }

    public renderReadAnalysisButton() {
        if (!this.state.isPurchased) {
            return null;
        }

        return <Button className={'ui-base uikit-button ui-base uikit-button ui-base uikit-button variant-primary size-medium w-px-140'}>
            <span className={'fs-13'}>{trans('account.data.view.data')}</span>
        </Button>;
    }

    public renderCalculateButton() {
        if (this.state.loading) {
            return <div className={'position-relative'}>
                <Button
                    className={'ui-base uikit-button variant-disabled size-medium'}
                >
                    <Spinner/>
                </Button>
            </div>;
        }

        if (!this.props.calcType || this.state.isCalculated) {
            return null;
        }

        const questionnaire = this.props.user?.meta?.[this.props.analysisType];
        const viewedUserQuestionnaire = this.props.viewedUser?.meta?.[this.props.analysisType];

        if (!questionnaire || !viewedUserQuestionnaire) {
            const tooltipText = !questionnaire
                ? 'account.data.questionnaire.is.not.completed.yet'
                : !viewedUserQuestionnaire ? 'account.data.questionnaire.is.not.completed.yet.by.user' : '';

            return <div className={'position-relative'}>
                <Button
                    onMouseOver={() => this.setState({ showTooltip2: true })}
                    onMouseOut={() => this.setState({ showTooltip2: false })}
                    className={'ui-base uikit-button variant-disabled size-medium'}
                >
                    {this.renderButtonContent('account.data.calculate.compatibility', 1)}
                </Button>
                {this.state.showTooltip2 && this.renderPopOver(tooltipText)}
            </div>;
        }

        return <Button
            className={'ui-base uikit-button variant-primary size-medium'}
            onClick={() => this.handleCalculateCompatibility()}
        >
            {this.renderButtonContent('account.data.calculate.compatibility', 1)}
        </Button>;
    }

    public render() {
        return <BoxWrapper
            questionnaireBox={true}
            modalClass={'max-width-800'}
            renderExtraContent={this.renderButton()}
            content={this.renderActiveTab()}
            customTrigger={this.renderReadAnalysisButton()}
            className={'fix-height-310'}
            title={<h6 className={'fw-bold text--small'}>{this.props.boxTitle}</h6>}
        >
            {this.manageGauge()}
            <div className={'w-100 position-center'}>
                {this.renderCalculateButton()}
            </div>
        </BoxWrapper>;
    }
}
