import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import { get, range } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './birth-date-picker.scss';

export class BirthDatePicker extends Subscribable<any, any> {
    public modal$: Modal;

    public state: any = {
        year: null,
        month: null,
        day: null
    };

    public render(): React.ReactNode {
        return <Modal
            ref={(ref: any) => (this.modal$ = ref)}
            content={() => this.renderContent()}
            wrapperClasses="BirthDateModal palette--bgc-neutral-1 elevation-2 border-radius-1 p-2"
            footer={() => null}
        />;
    }

    public renderContent() {

        return <div>
            {this.renderCurrentDate()}
            <div className={'w-100 p-box SelectorBlock'}>
                <div className={'BirthDatePicker row'}>
                    <div className={'col-8 SelectorColumn'}>
                        {this.renderYearSelector()}
                    </div>
                    <div className={'col-8 SelectorColumn middle'}>
                        {this.renderMonthSelector()}
                    </div>
                    <div className={'col-8 SelectorColumn'}>
                        {this.renderDaySelector()}
                    </div>
                </div>
            </div>

            {this.renderFooter()}
        </div>;
    }

    public renderYearSelector() {
        return <div className={'display-flex flex-column align-items-start'}>
            {range(1935, moment(Date.now()).year() - 17, 1).map((item, index) => {
                return <h3 onClick={() => this.setYear(item)} className={`palette--c-neutral-5 fw-bold cursor-pointer ${this.state.year === item ? 'selected' : ''}`} key={item}>{item}</h3>;
            })}
        </div>;
    }

    public renderMonthSelector() {
        return <div className={'display-flex flex-column align-items-start'}>
            {range(1, 13, 1).map((item, index) => {
                return <h3 onClick={() => this.setMonth(item)} className={`palette--c-neutral-5 fw-bold cursor-pointer ${this.state.month === item ? 'selected' : ''}`} key={item}>{(`0${item}`).slice(-2)}</h3>;
            })}
        </div>;
    }

    public renderDaySelector() {
        return <div className={'display-flex flex-column align-items-start'}>
            {range(1, moment(`${get(this.state, 'year') || 1999}-${get(this.state, 'month') || 1}`, 'YYYY-MM').daysInMonth() + 1, 1).map((item, index) => {
                return <h3 onClick={() => this.setDay(item)} className={`palette--c-neutral-5 fw-bold cursor-pointer ${this.state.day === item ? 'selected' : ''}`} key={item}>{(`0${item}`).slice(-2)}</h3>;
            })}
        </div>;
    }

    public renderCurrentDate() {
        return <div className={'p-box'}>
            <h2>{get(this.state, 'year', null) || '_____'}-{(`0${get(this.state, 'month', null) || '__'}`).slice(-2)}-{(`0${get(this.state, 'day', null) || '__'}`).slice(-2)}</h2>
        </div>;
    }

    public renderFooter() {
        return <div className={'p-box display-flex flex-row justify-content-end'}>
            <Button type={'button'} className={'variant-link size-medium'} onClick={() => this.cancel()}>
                <span>{trans('global.buttons.cancel')}</span>
            </Button>
            <Button type={'button'} className={'variant-outline size-medium'} onClick={() => this.submitDate()}>
                <span>{trans('global.datepicker.submit')}</span>
            </Button>
        </div>;
    }

    public open() {
        this.modal$.open();
    }

    public close() {
        this.modal$.close();
    }

    public setYear(year: number) {
        this.setState({ year: year });
    }

    public setMonth(month: number) {
        if (this.state.day > moment(`${get(this.state, 'year') || 1999}-${month}`).daysInMonth()) {
            this.setDay(moment(`${get(this.state, 'year') || 1999}-${month}`).daysInMonth());
        }
        this.setState({ month: month });
    }

    public setDay(day: number) {

        this.setState({ day: day });
    }

    private submitDate() {
        if (moment(`${get(this.state, 'year')}-${get(this.state, 'month')}-${get(this.state, 'day')}`, 'YYYY-MM-DD').isValid()) {
            this.props.onSubmit(moment(`${get(this.state, 'year')}-${get(this.state, 'month')}-${get(this.state, 'day')}`, 'YYYY-MM-DD').toDate());
            this.close();
        }
    }

    private cancel() {
        this.setState({
            year: null,
            month: null,
            day: null
        }, () => this.close());
    }
}
