import { getLanguage } from '../../../libs/get-language';
import { Lang } from '../../share/interfaces/user.interface';

export const countryTranslator = (countryCode?: string) => {
    if (!countryCode) {
        return countryCode;
    }

    const lang: Lang = getLanguage();
    const translator = new (Intl as any).DisplayNames([lang], { type: 'region' });

    return translator.of(countryCode);
};
