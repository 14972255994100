import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { calculateAge, getImageSrc, parseDate } from '../../share/helpers/util';
import { Hr } from '../../share/ui-components/hr';
import { ProfileBoxForm } from '../forms/profile-box-form';
import { PhotoBox } from '../photo-box/photo-box';
import { BoxWrapper } from '../shared/box-wrapper';
import { UserBase } from '../shared/user.base';
import { LikeButton } from './like-button';
import './profile-box.scss';

export class ProfileBox extends UserBase {
    public renderImg() {
        return <div className={'display-flex flex-column align-items-center justify-content-center'}>
            <div className={'img-wrapper img-wrapper--round'} style={{ backgroundImage: `url(${getImageSrc(this.state.meta?.photo, this.state.meta?.gender)})` }}/>
            <h4 className={'text--h3 mt-4'}>{this.state.meta.firstName}</h4>
        </div>;
    }

    public renderDetails() {
        return <div>
            {this.renderAge()}
            {this.props.profileUser && <LikeButton user={this.state.user}/>}
            {!this.props.enableEdit && <div className={'position-center flex-column'}/>}
        </div>;
    }

    public renderAge() {
        if (this.props.profileUser) {
            return null;
        }

        return <>
            <div className={'display-flex justify-content-space-between'}>
                <span className={'text--small-grey'}>{trans('birthday')}</span>
                <span className={'text--small-grey'}>{trans('age')}</span>
            </div>
            <div className={'display-flex justify-content-space-between'}>
                <span className={'text--small'}>{parseDate(this.state.meta.birthDate)}</span>
                <span className={'text--small'}>{calculateAge(this.state.meta.birthDate)}</span>
            </div>
        </>;
    }

    public render() {
        return <BoxWrapper
            shouldClose={this.state.shouldClose}
            content={<ProfileBoxForm
                onCancel={() => this.setState({ shouldClose: !this.state.shouldClose })}
                meta={this.state.meta}
            />}
            className={'h-100'}
            enableEdit={this.props.enableEdit}
        >
            {this.renderImg()}
            <Hr className={'mb-5'}/>
            {this.renderDetails()}
            <Hr className={'my-5'}/>

            <PhotoBox {...this.props} />
        </BoxWrapper>;
    }
}
