import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../../../libs/subscribable';
import { CollapsibleText } from '../../../../couples-assessment/tabs/extras/collapsible-text';
import { Chart } from '../../../../questionnaires/chart';
import { UserInterface, UserMetaInterface } from '../../../../share/interfaces/user.interface';

export interface UserAnalysisData {
    name?: string;
    gender: UserMetaInterface['gender'];
    compatibilityRate?: number;
    data: { [key: string]: { result: number, diff: number } };
}

export interface ContentProps {
    userAnalysisData: UserAnalysisData | null;
    user?: UserInterface;
    result?: any;
}

export class BehaviourContent extends Subscribable<ContentProps, any> {
    public static colours = {
        adventurer: '#D63E34',
        traditionalist: '#783201',
        rationalist: '#3162CB',
        caretaker: '#F1A93B',
    };

    public state = {
        userValues: null,
        averageValues: null,
        labels: [''],
    };

    public getGenderTitle(): string {
        switch (this.props.userAnalysisData?.gender) {
            case 'account.edit.gender.female':
                return trans('account.data.average.chart.her');
            case 'account.edit.gender.male':
                return trans('account.data.average.chart.him');
            default:
                return trans('account.data.average.chart.avg');
        }
    }

    public componentDidMount() {
        this.setData();
    }

    public componentDidUpdate(prevProps: Readonly<ContentProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.userAnalysisData !== this.props.userAnalysisData) {
            this.setData();
        }
        if (prevProps.user !== this.props.user) {
            this.setData();
        }
    }

    public setData() {
        const data = this.props?.userAnalysisData?.data;

        if (!data) {
            return;
        }

        const userValues = Object.values(data).map((d: { result: number, diff: number }) => d?.result);
        const averageValues = Object.values(data as any).map((d: { result: number, diff: number }) => d.diff * -1);
        const labels = Object.keys(data as any);

        this.setState({ userValues, averageValues, labels });
    }

    public renderTopSection() {
        const primaryOptionLabel = this.getGenderTitle();
        const title = trans('user.profile.temperament.chart');
        const titleVariance = trans('user.profile.temperament.variance.chart');

        return <div className={'row'}>
            <div className={'col-24'}>
                <h6 className="fw-bold text--small">{trans('account.data.behavioural.questionnaire')}</h6>
            </div>
            <div className={'col-24'}>
                <CollapsibleText description={trans('account.data.behavioural.questionnaire.description.complete')}/>
            </div>
            <div className={'col-24'}>
                <Chart
                    doNotDisplayLegend={true}
                    title={title}
                    chartWrapper={'fix-height-300'}
                    user={this.props?.userAnalysisData as any}
                    colorIndex={0}
                    chartName={'Bar'}
                    data={this.state.userValues as any}
                    labels={this.state.labels}
                    primaryOptionLabel={primaryOptionLabel}
                />
            </div>
            <div className={'col-24 py-7'}>
                <Chart
                    doNotDisplayLegend={true}
                    title={titleVariance}
                    chartWrapper={'fix-height-300'}
                    user={this.props?.userAnalysisData as any}
                    chartName={'Bar'}
                    customBarColours={this.getColours()}
                    data={this.state.averageValues as any}
                    labels={this.state.labels}
                    primaryOptionLabel={primaryOptionLabel}
                />
            </div>
        </div>;
    }

    public getColours() {
        const labels = Object.keys(this.props?.userAnalysisData?.data || {});

        if (!labels?.length) {
            return false;
        }

        const labelColours = labels.map(label => BehaviourContent.colours[label?.toLowerCase()]);

        return {
            backgroundColor: labelColours,
            borderColor: labelColours
        };
    }

    public render() {
        return <div className={'row pt-6'}>
            <div className={'col-24 pb-7'}>
                {this.renderTopSection()}
            </div>
        </div>;
    }
}
