import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { getLanguage } from '../libs/get-language';
import { Subscribable } from '../libs/subscribable';

export class LanguageLayout<P, S> extends Subscribable<P, S> {
    public componentDidMount() {
        const langInParams = getLanguage();
        const langInLocale = TranslationLib.getLang();

        if (langInLocale !== langInParams && langInLocale) {
            const newUrl = window.location.pathname.replace(new RegExp(`^\/${langInParams}`), `/${langInLocale}`);
            window.location.href = `${newUrl}${window.location.search}`;
        }

        if (langInLocale !== langInParams && !langInLocale) {
            TranslationLib.setLang(langInParams);
            window.location.reload();
        }
    }
}
