import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import * as React from 'react';
import { BackgroundData } from '../../components/profile/background-data/background-data';
import { BehaviourCompatibilityBox } from '../../components/profile/compatibility-infos/behaviour-compatibility-box';
import { LoveStyleCompatibilityBox } from '../../components/profile/compatibility-infos/love-style-compatibility-box';
import { PersonalityCompatibilityBox } from '../../components/profile/compatibility-infos/personality-compatibility-box';
import { StarSignCompatibilityBox } from '../../components/profile/compatibility-infos/star-sign-compatibility-box';
import { ContactInfos } from '../../components/profile/contact-infos/contact-infos';
import { PersonalAttributes } from '../../components/profile/personal-attributes/personal-attributes';
import { PersonalPreference } from '../../components/profile/personal-preference/personal-preference';
import { ProfileBox } from '../../components/profile/profile-box/profile-box';
import { ManageAccess } from '../../components/share/manage-access';
import { OverlaySpinner } from '../../components/share/ui-components/overlay-spinner';
import { Subscribable } from '../../libs/subscribable';
import '../me-profile/profile.screen.scss';

const mapStateProps = (store: any) => ({
    profileUser: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class UserProfileScreen extends Subscribable<any, any> {
    public state = { purchases: null, user: undefined, isLoading: false, shouldReveal: false };

    public async componentDidMount() {
        await this.getUserData();
    }

    public async getUserData() {
        try {
            this.setState({ isLoading: true });
            const response = await Repository.get(`/user/${this.props.match.params.id}`, {});

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({ purchases: response?.purchases || {}, user: response.user, isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <OverlaySpinner isLoading={this.state.isLoading}/>
            <div className="container max-width-1138">
                <div className={'row'}>
                    <div className={'col-24 col-md-6 pb-6 pb-md-0'}>
                        <ProfileBox profileUser={this.props.profileUser} user={this.state.user}/>
                    </div>
                    <div className={'col-24 col-md-10 pb-6 pb-md-0'}>
                        <PersonalAttributes user={this.state.user}/>
                    </div>
                    <div className={'col-24 col-md-8'}>
                        <ManageAccess
                            profileUser={this.props.profileUser}
                            user={this.state.user}
                            isHidden={(shouldReveal: boolean) => this.setState({ shouldReveal })}
                            purchases={this.state.purchases}
                        >
                            <ContactInfos shouldReveal={this.state.shouldReveal} user={this.state.user} isMainUser={false}/>
                        </ManageAccess>
                    </div>
                </div>
                <div className={'row pt-7'}>
                    <div className={'col-24 col-md-8'}>
                        <div className={'row'}>
                            <div className={'col-24 pb-6'}>
                                <BackgroundData user={this.state.user}/>
                            </div>
                            <div className={'col-24 pb-6'}>
                                <PersonalPreference user={this.state.user}/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-24 pb-6 col-md-16'}>
                        <div className={'row'}>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <BehaviourCompatibilityBox purchases={this.state.purchases} user={this.state.user}/>
                            </div>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <PersonalityCompatibilityBox purchases={this.state.purchases} user={this.state.user}/>
                            </div>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <StarSignCompatibilityBox purchases={this.state.purchases} user={this.state.user}/>
                            </div>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <LoveStyleCompatibilityBox purchases={this.state.purchases} user={this.state.user}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
