import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class ChangePassword extends Subscribable<any, any> {
    public form = new Form({
        current: new Field({
            label: trans('account.edit.changepsw.old'),
            value: '',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        new: new Field({
            label: trans('account.edit.changepsw.new'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        repassword: new Field({
            label: trans('account.edit.changepsw.newagain'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required')),
                Validator.EQUALS_TO_FIELD('new')(trans('global.input.repassword'))
            ]
        }),
    });

    public isDisabled(): boolean {
        return false;
    }

    public async handleSubmit() {
        if (this.props.onSubmit) {

            const data = {
                current: this.form.toJSON().current,
                new: this.form.toJSON().new
            };

            return this.props.onSubmit(data);
        }
    }

    public handleCancel() {
        if (this.props.onCancel) {
            return this.props.onCancel();
        }
    }

    public render(): React.ReactNode {
        return <div className="row display-flex flex-column flex-fill align-items-center">
            {this.renderForm()}
        </div>;
    }

    private renderForm(): React.ReactNode {

        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row">
                <div className="w-100 col-24 p-box">
                    <Input field={this.form.field('current')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="password" disabled={this.isDisabled()}/>
                    <Input field={this.form.field('new')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="password" disabled={this.isDisabled()}/>
                    <Input field={this.form.field('repassword')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="password" disabled={this.isDisabled()}/>
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 display-flex justify-content-end px-box pb-box">

                    <Button type={'button'} onClick={() => this.handleCancel()} className="variant-link size-medium" title={trans('global.buttons.cancel')}/>
                    <Button type={'submit'} className="variant-primary size-medium" title={''}>
                        <span className="material-icons mr-4">check</span>
                        <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                    </Button>

                </div>
            </div>
        </AbstractForm>;
    }
}
