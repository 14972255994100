import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../libs/generate-link';
import { routes } from '../../routes';
import { LanguageSelector } from '../language-selector/language-selector';
import './footer.scss';

const logo = require('../../assets/images/selectivita-logo-symbol.svg');

export class Footer extends React.Component<any, any> {
    public render(): React.ReactNode {
        return <div className={'Footer p-box'}>
            <div className="row">
                <div className="col-md-8 mb-4 col-24 display-flex justify-content-start align-items-center">
                    <div className={'px-8'}>
                        <Link to={generateLink(routes.homeExtended)} className={'text-decoration-none'}>
                            <div className={'justify-content-start align-items-center display-flex flex-row'}>
                                <img src={logo} className={'Logo'} alt="logo"/>
                                <h2 className={'mb-0 ml-7'}>Selectivita</h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-12 col-24 mb-4 display-flex justify-content-md-center justify-content-start">
                    {this.renderFooterMenu()}
                </div>
                <div className="col-md-4 mb-4 col-24 display-flex justify-content-md-end justify-content-center">
                    <div className="px-8">
                        <LanguageSelector/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-24 display-flex justify-content-center align-items-center">
                    <span className={'palette--c-neutral-5'}>
                        {this.getCopyRightText()}
                    </span>
                </div>
            </div>
        </div>;
    }

    public getCopyRightText() {
        const start = trans('global.footermenu.copyright.start');
        const end = trans('global.footermenu.copyright.end');
        const currentYear = moment().format('y').toString();

        return `${start}${currentYear} ${end}`;
    }

    public renderFooterMenu() {
        return <div className={'display-flex justify-content-center align-items-center w-100 px-8 px-md-0 row'}>
            <div className="col-24 col-lg-12 mb-8">
                <div className="display-flex flex-column justify-content-start align-items-start">
                    <h6 className={'text-uppercase palette--c-neutral-5 mb-4'}>{trans('global.footermenu.about')}</h6>
                    <div className={'display-flex align-items-center'}>
                        <Link className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-center align-items-center fs-medium'} to={generateLink(routes.faq)}>{trans('global.footermenu.faq')}</Link>

                        <span className={'SeparatorDot'}/>

                        {/* <Link className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-center align-items-center fs-medium'} to={'/pricing'}>{trans('global.footermenu.pricing')}</Link>
                        <span className={'SeparatorDot'}/>*/}

                        <Link className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-center align-items-center fs-medium'} to={generateLink(routes.contact)}>{trans('global.menu.contact')}</Link>

                        <span className={'SeparatorDot'}/>

                        <a className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-center align-items-center fs-medium'} target={'_blank'} href={'http://facebook.com'}>
                            <img src={'/assets/images/icons/icon-facebook@2x.webp'} className={'facebook-icon'} alt={'facebook icon'}/>
                            {trans('global.footermenu.facebook')}
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-24 col-lg-12 mb-8">
                <div className="display-flex flex-column justify-content-start align-items-start">
                    <h6 className={'text-uppercase palette--c-neutral-5 mb-4'}>{trans('global.footermenu.legal')}</h6>
                    <div className={'display-flex align-items-center'}>
                        <Link className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-center align-items-center fs-medium'} to={generateLink(routes.privacy)}>{trans('global.footermenu.privacy')}</Link>
                        <span className={'SeparatorDot'}/>
                        <Link className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-center align-items-center fs-medium'} to={generateLink(routes.termsOfUse)}>{trans('global.footermenu.terms')}</Link>
                    </div>
                </div>
            </div>
        </div>;
    }

}
