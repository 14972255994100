import * as React from 'react';
import './message-box.scss';

export interface MessageBoxProps {
    type: 'success' | 'error' | 'warning';
    message: string;
    closeAble?: boolean;
}

export class MessageBox extends React.Component<MessageBoxProps, {}> {

    public render(): React.ReactNode {
        return <div className={`MessageBox ${this.props.type}`}>
            <div>{this.renderIcon()}</div>
            <span>{this.props.message}</span>
        </div>;
    }

    public renderIcon() {
        switch (this.props.type) {
            case 'error':
                return <span className={'material-icons'}>error</span>;
            case 'success':
                return <span className={'material-icons'}>check_circle</span>;
            case 'warning':
                return <span className={'material-icons'}>warning</span>;
        }
    }
}
