import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import { filter, find, get, map } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import { handleError } from '../../share/helpers/util';
import { ALCOHOL, DRUGS, LIBIDO, RELATIONSHIP_TYPE, RELIGIOUS_PRACTICES, SEXUAL_ORIENTATION, SMOKING_HABIT, TATTOO } from '../edit-profile/constants';
import { SingleLineSelect } from './extras/single-line.select';

export class PersonalPreferencesForm extends Subscribable<any, any> {

    public form = new Form({
        libido: new Field({
            label: trans('account.data.libido'),
            value: find(LIBIDO, (option) => option.value === get(this.props, 'meta.libido')),
            multi: false,
            options: map(LIBIDO, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        isSmoker: new Field({
            label: trans('account.data.is.smoker'),
            value: find(SMOKING_HABIT, (option) => option.value === get(this.props, 'meta.isSmoker')),
            multi: false,
            options: map(SMOKING_HABIT, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        alcoholConsumption: new Field({
            label: trans('account.data.alcohol.consumption'),
            value: find(ALCOHOL, (option) => option.value === get(this.props, 'meta.alcoholConsumption')),
            multi: false,
            options: map(ALCOHOL, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        tattoos: new Field({
            label: trans('account.data.tattoos'),
            value: find(TATTOO, (option) => option.value === get(this.props, 'meta.tattoos')),
            multi: false,
            options: map(TATTOO, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        worshipFrequency: new Field({
            label: trans('account.data.worship.frequency'),
            value: find(RELIGIOUS_PRACTICES, (option) => option.value === get(this.props, 'meta.worshipFrequency')),
            multi: false,
            options: map(RELIGIOUS_PRACTICES, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        drugUsage: new Field({
            label: trans('account.data.drug.usage'),
            value: find(DRUGS, (option) => option.value === get(this.props, 'meta.drugUsage')),
            multi: false,
            options: map(DRUGS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        sexualOrientation: new Field({
            label: trans('account.data.sexual.orientation'),
            value: find(SEXUAL_ORIENTATION, (option) => option.value === get(this.props, 'meta.sexualOrientation')),
            multi: false,
            options: map(SEXUAL_ORIENTATION, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        relationshipInterests: new Field({
            label: trans('account.data.relationship.interests'),
            value: filter(RELATIONSHIP_TYPE, (option) => (this.props?.meta?.relationshipInterests || []).includes(option.value)),
            multi: true,
            options: map(RELATIONSHIP_TYPE, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
    });

    public setForm() {
        map(this.form.fields, (field, key) => {
            return field.setValue(get(this.props, `meta[${key}]`));
        });
    }

    public parseData() {
        const data = this.form.toJSON();
        const payload = {};

        for (const prop of Object.keys(data)) {
            switch (prop) {
                case 'relationshipInterests':
                    payload[prop] = (data[prop] || []).map(option => option?.value);
                    break;
                default:
                    payload[prop] = data[prop]?.value || data[prop];
            }
        }

        return payload;
    }

    public async handleSubmit() {
        try {
            const data = this.parseData();

            const response = await Repository.put('/user', data);

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }

    public render() {
        return <div className={'p-box'}>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <h2 className={'mb-8'}>{trans('account.edit.title')}</h2>
                <div className="row">
                    <div className="w-100 col-24 px-7">
                        <div className="row">
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('sexualOrientation')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('libido')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('worshipFrequency')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('isSmoker')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('alcoholConsumption')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('drugUsage')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('tattoos')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    field={this.form.field('relationshipInterests')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="w-100 col-24 display-flex justify-content-end">
                                <Button
                                    type={'button'}
                                    onClick={() => this.handleCancel()}
                                    className="variant-link size-medium"
                                    title={trans('global.buttons.cancel')}
                                />
                                <Button type={'submit'} className="variant-primary size-medium">
                                    <span className="material-icons mr-4">check</span>
                                    <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <div className="col-24 pt-6">
                            <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                        </div>
                    </div>
                </div>}
            </AbstractForm>
        </div>;
    }

    public handleCancel() {
        this.setForm();
        this.props.onCancel();
    }
}
