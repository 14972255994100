import { connect } from '@codebuild/cookie-jar/libs/connect';
import * as React from 'react';
import { tabs, Tabs } from '../../components/matchmaking/tabs';
import { StorageHelper } from '../../components/share/helpers/storage.helper';
import { Subscribable } from '../../libs/subscribable';
import './matchmaking-results.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class MatchmakingResultsScreen extends Subscribable<any, any> {
    public state = { activeTab: tabs.matchFinder };
    public tabStorage = new StorageHelper('tabs');

    public componentDidMount() {
        const currentTab = this.tabStorage.get();
        if (!!currentTab && !!currentTab?.active) {
            this.setState({ activeTab: currentTab?.active });
        }
    }

    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <div className="container max-width-1138">
                <Tabs tabStorage={this.tabStorage} defaultTab={this.state.activeTab} user={this.props.user}/>
            </div>
        </div>;
    }
}
