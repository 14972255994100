import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Checkbox } from '@codebuild/cookie-jar/uikit/components/input/checkbox';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { UserActions } from '../../../actions/user.actions';
import { HandleToasts } from '../../../handle.toasts';
import { FacebookAuth } from '../../../libs/facebook.auth';
import { getLanguage } from '../../../libs/get-language';
import { QueryManager } from '../../../libs/query.manager';
import { Subscribable } from '../../../libs/subscribable';
import { BirthDateInput } from '../../birth-date-picker/birth-date-input';
import { LanguageSelector } from '../../language-selector/language-selector';
import { MessageBox } from '../../message-box/message-box';
import { handleError } from '../../share/helpers/util';
import { Spinner } from '../../spinner';
import './registration.component.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    registrationRequest: store.authentication.finishRegistrationRequest,
    whoAmIRequest: store.authentication.whoAmIRequest,
});

const mapDispatchProps = (dispatch: any) => ({
    registration: (credentials: any, type?: any) => dispatch(UserActions.finishRegistration(credentials, type)),
    login: (credentials: any) => dispatch(UserActions.login(credentials, true, '/profile'))
});

@connect(mapStateProps, mapDispatchProps)
export class RegistrationComponent extends Subscribable<any, any> {
    public $recaptchaRef: any;

    public state: any = {
        error: null,
        response: null,
        captchaError: false,
    };

    public form = new Form({
        firstName: new Field({
            label: trans('signup.firstname'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        lastName: new Field({
            label: trans('signup.lastname'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        email: new Field({
            label: trans('signup.email'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        birthDate: new Field({
            label: '',
            value: '',
            isDate: true,
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        password: new Field({
            label: trans('signup.password'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        repassword: new Field({
            label: trans('signup.passwordagain'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))]
        }),
        agreement: new Field({
            label: '',
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        })
    });

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (!isEqual(prevProps.registrationRequest, this.props.registrationRequest)) {
            this.setState({ error: this.props.registrationRequest.error });
        }

        if (!isEqual(prevProps.registrationRequest, this.props.registrationRequest) && this.props.registrationRequest.response) {
            this.setState({ response: this.props.registrationRequest.response });
        }

        if (!isEqual(prevProps.registrationRequest.error, this.props.registrationRequest.error) && this.props.registrationRequest.error) {
            HandleToasts.error(this.props.registrationRequest.error);
        }

        if ((prevProps.whoAmIRequest.error !== this.props.whoAmIRequest.error) && this.props.whoAmIRequest.error) {
            HandleToasts.error(this.props.whoAmIRequest.error);
        }
    }

    public isDisabled(): boolean {
        return this.props.registrationRequest.loading;
    }

    public async handleSubmit() {
        try {
            this.setState({ captchaError: false });

            if (!this.$recaptchaRef.getValue()) {
                this.setState({ captchaError: true });
                return;
            }

            this.setState({ error: null });

            if (this.props.registrationRequest.loading) {
                return;
            }

            const lang = getLanguage();

            await this.props.registration({
                ...this.form.toJSON(),
                invitationToken: this.getToken(),
                lang: lang.toUpperCase()
            });

            if (this.props.registrationRequest.response) {
                await this.props.login({
                    lang,
                    type: 'password',
                    credentials: {
                        email: this.form.toJSON().email,
                        password: this.form.toJSON().password
                    }
                });
            }

            this.props.login({ lang, type: 'password', credentials: { email: this.form.toJSON().email, password: this.form.toJSON() } });
        } catch (err) {
            handleError(err);
            this.setState({ error: err });
        }
    }

    public getToken() {
        const searchString = window?.location?.search || '';

        if (!searchString) {
            return '';
        }

        const queryManager = new QueryManager(searchString);

        const token = queryManager.get('token');

        if (!token) {
            return '';
        }

        return token;
    }

    public render(): React.ReactNode {
        return <div className="display-flex flex-column flex-fill align-items-center RegistrationComponent">
            {this.renderForm()}
            <div className="flex-fill">
                {this.renderCheckingWhoAmI()}
            </div>
            {this.renderFooter()}
        </div>;
    }

    private getLink(): string {
        const lang = TranslationLib.getLang();
        const link = trans('signup.termsLink');

        return `/${lang}${link}`;
    }

    private renderForm(): React.ReactNode {
        if (this.props.whoAmIRequest.loading || this.props.whoAmIRequest.response) {
            return null;
        }

        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row justify-content-start">
                <div className="w-100 pt-box px-box">
                    <h1>{trans('signup.title')}</h1>
                    <span className={'palette--c-neutral-5 fs-large'}>{trans('signup.description')}</span>
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 p-box">
                    {this.renderFacebookRegistrationButton()}
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 p-box">
                    <span className={'palette--c-neutral-5 fs-large mb-7'}>{trans('signup.description2')}</span>
                    <div className="row">
                        <div className="col-md-12 col-24">
                            <Input field={this.form.field('firstName')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="text" disabled={this.isDisabled()}/>
                        </div>
                        <div className="col-md-12 col-24">
                            <Input field={this.form.field('lastName')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="text" disabled={this.isDisabled()}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-24">
                            <Input field={this.form.field('email')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="email" disabled={this.isDisabled()}/>
                        </div>
                        <div className=" col-md-12 col-24 mb-4">
                            <span className={'mb-2 fs-small fw-bold palette--c-neutral-5 text-uppercase'}>{trans('signup.dateofbirth')}</span>
                            <BirthDateInput field={this.form.field('birthDate')}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-24">
                            <Input field={this.form.field('password')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="password" disabled={this.isDisabled()}/>
                        </div>
                        <div className="col-md-12 col-24">
                            <Input field={this.form.field('repassword')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="password" disabled={this.isDisabled()}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-24 mb-4">
                            <div className="display-flex flex-row justify-content-start align-items-center">
                                <Checkbox
                                    disabled={false}
                                    field={this.form.field('agreement')}
                                    className="size-small variant-primary mr-4"
                                    inputClassName={'display-none'}
                                />
                                <Link target={'_blank'} to={this.getLink()} className={'palette--c-neutral-5 text-decoration-none'}>{trans('signup.terms')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-24 mb-4">
                    {this.renderReCaptcha()}
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 display-flex justify-content-start px-box pb-box">

                    <Button className="variant-outline size-medium" title={this.props.registrationRequest.loading ? <Spinner theme={'custom'} customStroke={'#9b79f7'}/> : trans('signup.button')}/>

                </div>
            </div>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                </div>
            </div>}
        </AbstractForm>;
    }

    private renderFacebookRegistrationButton() {
        return <Button
            type={'button'}
            className={'variant-submit size-large FacebookButton'}
            onClick={() => this.facebookRegister()}
        >
            <img className={'facebook-icon'} src="/assets/images/icons/facebook-logo-white.svg" alt="facebook logo"/>
            <span>{trans('signup.facebookbutton')}</span>
        </Button>;
    }

    private renderFooter() {
        return <div className={'display-flex flex-column justify-content-center align-items-center'}>
            <div className={'mb-6'}>
                <LanguageSelector/>
            </div>
            <div className={''}>
                <span className={'text-center'}>{this.getCopyRightText()}</span>
            </div>
        </div>;
    }

    private getCopyRightText() {
        const start = trans('global.footermenu.copyright.start');
        const end = trans('global.footermenu.copyright.end');
        const currentYear = moment().format('y').toString();

        return `${start}${currentYear} ${end}`;
    }

    private async facebookRegister() {
        const authInstance = new FacebookAuth();
        const appId = Environment.get('facebookAppId');
        await authInstance.initialize({ appId });

        try {
            const response = await authInstance.signIn(['email']);

            await this.props.registration({
                ...response,
                invitationToken: this.getToken(),
                lang: getLanguage().toUpperCase()
            }, 'facebook');
            const lang = getLanguage();
            console.log('got past the api registration');
            this.props.login({ lang, type: 'facebook-v5', credentials: { ...response, notFirstLogin: true } });
        } catch (err) {
            console.log({ facebook_register: err });

            this.setState({ error: err });
        }
    }

    private renderCheckingWhoAmI(): React.ReactNode {
        if (!this.props.whoAmIRequest.loading) {
            return null;
        }

        return <div className={''}>

            <div className="w-100 p-box display-flex justify-content-center">
                <Spinner size="large" theme="dark"/>
            </div>
        </div>;
    }

    private renderReCaptcha() {
        return <div className={'display-flex flex-column'}>
            <ReCAPTCHA
                ref={(ref) => this.$recaptchaRef = ref}
                sitekey="6LcHfqsZAAAAAOYaREOy8LjbziXWDA6KzljdreHq"
                onChange={(value) => {
                    this.setState({ captchaError: !value });
                }}
            />
            {this.state.captchaError && <label className={'palette--c-red-2 fs-small'}>Captcha is required</label>}
        </div>;
    }
}
