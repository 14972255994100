import { connect } from '@codebuild/cookie-jar/libs/connect';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { Filters } from '../profile-listings/filters';
import { ProfileCards } from '../profile-listings/profile-cards';
import * as CONSTANTS from '../profile/edit-profile/constants';
import { UserInterface } from '../share/interfaces/user.interface';
import { MatchmakingResults } from './matchmaking-results';

export interface UserListProps {
    listType?: 'user-list' | 'like-list' | 'matched-user-list';
    user?: UserInterface;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class UserList extends Subscribable<UserListProps, any> {
    public state = { filters: {} };

    public getCountryFilter() {
        const userCountry = this.props.user?.meta?.country;

        if (!userCountry) {
            return null;
        }

        const country = CONSTANTS.COUNTRY_OPTIONS.filter(i => i.value === userCountry)?.[0];

        if (!country) {
            return null;
        }

        return country;
    }

    public getGender() {
        const genderCompatibility = this.props.user?.meta?.genderCompatibility;

        if (!genderCompatibility || !genderCompatibility?.length || genderCompatibility?.length > 1) {
            return null;
        }

        const gender = CONSTANTS.GENDER_OPTIONS.filter(i => i.value === genderCompatibility[0])?.[0];

        if (!gender) {
            return null;
        }

        return gender;
    }

    public renderRegularUserList() {
        const gender = this.getGender();
        const country = this.getCountryFilter();

        return <div className={'row'}>
            <div className={'col-24 col-md-8'}>
                <Filters
                    disabled={!this.props.user?.meta?.isProfileComplete || !this.props.user?.meta?.isProfilePublic}
                    filters={(filters: any) => this.setState({ filters })}
                    user={this.props.user}
                />
            </div>
            <div className={'col-24 col-md-16'}>
                <ProfileCards
                    defaultFilter={{ gender, country }}
                    type={'normal-list'}
                    disabled={!this.props.user?.meta?.isProfileComplete || !this.props.user?.meta?.isProfilePublic}
                    filters={this.state.filters}
                    user={this.props.user as any}
                />
            </div>
        </div>;
    }

    public renderLikeUserList() {
        return <div className={'row'}>
            <div className={'col-24'}>
                <ProfileCards
                    type={'like-list'}
                    disablePaginator={true}
                    filters={this.state.filters}
                    user={this.props.user as any}
                />
            </div>
        </div>;
    }

    public renderMatchedUsers() {
        return <div className={'row'}>
            <div className={'col-24'}>
                <MatchmakingResults
                    disablePaginator={true}
                    filters={this.state.filters}
                    user={this.props.user as any}
                />
            </div>
        </div>;
    }

    public renderUserList() {
        switch (this.props.listType) {
            case 'user-list':
                return this.renderRegularUserList();
            case 'like-list':
                return this.renderLikeUserList();
            case 'matched-user-list':
                return this.renderMatchedUsers();
            default:
                return null;
        }
    }

    public render() {
        return <div className={'position-center w-100'}>
            <div className="max-width-1200">
                {this.renderUserList()}
            </div>
        </div>;
    }
}
