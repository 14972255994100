import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { BehaviourContent } from '../../profile/compatibility-infos/extras/modal-content/behaviour-content';
import { LoveStyleContent } from '../../profile/compatibility-infos/extras/modal-content/love-style-content';
import { Chart } from '../chart';

export class RegularCharts extends React.Component<any, any> {
    public getDifference(datasets1: any[], datasets2: any[]) {
        return datasets1.map((dataset, index) => dataset - datasets2[index]);
    }

    public getColours(labels: string[]) {
        switch (this.props.questionnaire) {
            case 'behavioural':
                const behaviouralLabels = labels.map(label => BehaviourContent.colours[label?.toLowerCase()]);

                return {
                    backgroundColor: behaviouralLabels,
                    borderColor: behaviouralLabels
                };
            default:
                const loveStyleLabels = labels.map(label => LoveStyleContent.colours[label]);

                return {
                    backgroundColor: loveStyleLabels,
                    borderColor: loveStyleLabels
                };
        }
    }

    public render() {
        const questionnaireFromMeta = this.props.user?.meta?.[`${this.props.questionnaire}Questionnaire`] || {};
        const labels = Object.keys(questionnaireFromMeta).map(questionString => trans(questionString));
        const questionnaire: any = Object.values(questionnaireFromMeta);
        const datasets = Object.values(questionnaire || []).map((value: any) => value?.result);
        const averageDatasets = Object.values(questionnaire || []).map((value: any) => value?.result + value?.diff);
        const diffChartDatasets = this.getDifference(datasets, averageDatasets);

        return <div className={'row'}>
            <div className={'mt-md-0 mt-7 col-md-12 col-24'}>
                <Chart
                    title={trans(this.props.title)}
                    user={this.props.user.meta}
                    chartName={'Bar'}
                    data={datasets}
                    labels={labels}
                    primaryOptionLabel={trans('account.data.my.score')}
                    secondaryOptionLabel={trans('account.data.average')}
                />
            </div>
            <div className={'mt-md-0 mt-7 col-md-12 col-24'}>
                <Chart
                    title={trans('account.data.variance.from.population')}
                    user={this.props.user.meta}
                    chartName={'Bar'}
                    data={diffChartDatasets}
                    customBarColours={this.getColours(Object.keys(questionnaireFromMeta)) as any}
                    labels={labels}
                    primaryOptionLabel={trans('account.data.my.score')}
                    doNotDisplayLegend={true}
                />
            </div>
        </div>;
    }
}
