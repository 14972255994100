import { mapObject } from '../../../libs/helpers/helpers';

export class PersonalityChartCategoryOrganizer {
    public categoryLabels: any[] = [];
    public category: any[] = [];
    public subCategoryLabels: any[] = [];
    public subCategory: any[] = [];
    public subCategoryDataset: Record<string, number>;
    public categoryDataset: Record<string, number>;
    public subCategoryGroupings = {
        Agreeableness: ['Flexibility', 'Forgiveness', 'Gentleness', 'Patience'],
        Conscientiousness: ['Diligence', 'Organization', 'Perfectionism', 'Prudence'],
        Emotionality: ['Anxiety', 'Dependence', 'Fearfulness', 'Sentimentality'],
        Extroversion: ['Liveliness', 'Sociability', 'Social-Boldness', 'Social-Esteem'],
        'Honesty-Humility': ['Corrupt-Avoidance', 'Greed-Avoidance', 'Modesty', 'Own-Interest-Avoidance'],
        'Open-Mindedness': ['Creativity', 'Cultured', 'Inquisitiveness', 'Unconventionality'],
    };

    public constructor(personalityQuestionnaire: any, mainCategories: any) {
        this.formatQuestionnaire(personalityQuestionnaire, mainCategories);
    }

    public toObject(data: any[], labels: any[]) {
        const array = data.map((d: number, index) => ({ [labels[index]]: d }));
        let obj: any = {};

        for (const item of array) {
            obj = { ...obj, ...item };
        }

        return obj;
    }

    public formatQuestionnaire(questionnaire: any, mainCategories: any): any {
        if (!questionnaire) {
            return null;
        }

        const formattedQuestionnaire: any = {};

        const formatter = (key, value) => {
            formattedQuestionnaire[key] = value.map(prop => ({ [prop]: questionnaire[prop] })).sort();
        };

        mapObject(this.subCategoryGroupings, formatter);

        this.categoryLabels = Object.keys(mainCategories).sort();

        let flattenedSubCategories: any[] = [];

        for (const category of this.categoryLabels) {
            flattenedSubCategories = [...flattenedSubCategories, ...(formattedQuestionnaire[category] || [])];
        }

        for (const item of flattenedSubCategories) {
            const [label] = Object.keys(item);

            this.subCategoryLabels.push(label);
            this.subCategory.push(item[label]);
        }

        this.category = this.categoryLabels.map(label => mainCategories[label]);
        this.subCategoryDataset = this.toObject(this.subCategory, this.subCategoryLabels);
        this.categoryDataset = this.toObject(this.category, this.categoryLabels);
    }
}
