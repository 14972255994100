import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { Spinner } from '../spinner';
import { handleError } from './helpers/util';

export interface NotificationModalProps {
    endpoint: string;
    text?: string;
    header?: string;
    onSuccess?: () => void;
    triggerClass?: string;
}

export class DeleteNotificationModal extends Subscribable<NotificationModalProps, any> {
    public modal$: Modal;
    public state = {
        loading: false,
    };

    public renderDeleteModalContent() {
        return <div className={'py-6'}>
            <div className={' mb-6  display-flex align-items-start justify-content-space-between'}>
                <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                    <span className={'material-icons palette--c-red-2 mr-4'}>info</span>
                    <div>
                        <h2>{this.props.header ?? trans('starsign.result.delete.title')}</h2>
                        <span>{this.props.text ?? trans('starsign.result.delete.desc')}</span>
                    </div>
                </div>
            </div>
            <div>
                <div className={'display-flex justify-content-end align-items-center'}>
                    <Button
                        className={'variant-outline size-medium mr-3'}
                        onClick={() => this.modal$.close()}
                    >
                        {trans('global.buttons.cancel')}
                    </Button>
                    <Button
                        className={'variant-error size-medium'}
                        onClick={() => this.delete()}
                    >
                        {this.renderBtnContent()}
                    </Button>
                </div>
            </div>
        </div>;
    }

    public renderBtnContent() {
        if (this.state.loading) {
            return <Spinner theme={'custom'} customStroke={'#637dcd'}/>;
        }

        return <>
            <span className="material-icons mr-4">delete</span>{trans('global.buttons.delete')}
        </>;
    }

    public async delete() {
        try {
            this.setState({ loading: true });
            const response = await Repository.delete(this.props.endpoint);

            if (!response) {
                throw new Error('account.data.error.something.went.wrong');
            }

            this.setState({ loading: false });

            if (this.props.onSuccess) {
                this.props.onSuccess();
            }

            this.modal$.close();
        } catch (e) {
            this.setState({ loading: false });
            handleError(e);
        }
    }

    public open() {
        this.modal$.open();
    }

    public close() {
        this.modal$.close();
    }

    public render(): React.ReactNode {
        return <>
            <div className={`${this.props.triggerClass} position-center cursor-pointer`} onClick={() => this.modal$.open()}>
                <span className={'material-icons palette--c-neutral-5'}>delete</span>
            </div>
            <Modal
                ref={(ref: any) => (this.modal$ = ref)}
                content={() => this.renderDeleteModalContent()}
                wrapperClasses="DeleteModalWrapper border-left-3 palette--bc-red-2 palette--bgc-neutral-1 elevation-2 border-radius-1"
                footer={() => null}
                onDismissRequest={() => this.close()}
            />
        </>;
    }
}
