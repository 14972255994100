import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { sleep } from '@codebuild/cookie-jar/libs/sleep';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { get } from 'lodash';
import * as React from 'react';
import { MessageBox } from '../message-box/message-box';
import { Spinner } from '../spinner';
import './profile-image-uploader.scss';

export class ProfileImageUploader extends React.Component<any, {}> {
    public imageInputRef$: any;
    public attachmentServiceEndpoint: string = Environment.get('attachment');
    public state: any = {
        image: { variants: [{ payload: this.props.currentImage }] } || null,
        loading: false,
        error: null
    };

    public render(): React.ReactNode {
        return <div className={'ProfileImageUploader'}>
            <label htmlFor={'profileImageUpload'}>
                <div>
                    <div className={'cursor-pointer display-flex flex-row align-items-center'}>
                        <div className={'ImagePreview elevation-1 mr-7'}>
                            {this.state.loading && <div className={'ImageLoader'}><Spinner size={'medium'} theme={'dark'}/></div>}
                            <img src={get(this.state, 'image.variants[0].payload', null) || '/assets/images/profile/profile-image-empty.webp'} alt={'profile image'}/>
                        </div>
                        <div>
                            <span className={'palette--c-blue-2'}>{trans('account.edit.photo')}</span>
                        </div>
                    </div>
                </div>
            </label>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                </div>
            </div>}
            <input
                onChange={(e) => this.browseFile(e)}
                ref={(ref) => this.imageInputRef$ = ref}
                accept="image/*"
                className={'display-none'}
                type={'file'}
                id={'profileImageUpload'}
            />
        </div>;
    }

    public async browseFile(e: any) {
        this.setState({ loading: true });
        console.log("hitting loading ture");
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
            try {
                this.setState({ error: null });
                const dataString = Buffer.from((reader.result as Buffer)).toString('base64');
                const attachment = await this.createAttachment(dataString);
                if (this.props.onUploadSuccess) {
                    this.props.onUploadSuccess(attachment);
                    this.setState({ image: attachment });
                    await sleep(1000);
                }
                this.setState({ loading: false });
            } catch (err) {
                this.setState({ loading: false, error: err });
            }
        };
    }

    public async createAttachment(data: string) {
        try {
            const query = {
                data: data
            };

            const response = await Repository.post('/attachment', query, {}, {}, this.attachmentServiceEndpoint);

            this.setState({ loading: false });

            return response;
        } catch (e) {
            this.setState({ loading: false });
            return Promise.reject(e);
        }
    }
}
