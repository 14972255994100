import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';

interface EditModaProps {
    content: JSX.Element | null;
    className?: string;
    onClose?: () => void;
    hideClose?: boolean;
    modalClass?: string;
}

export class EditModal extends Subscribable<EditModaProps, any> {
    public modal$: any;

    public render(): React.ReactNode {
        return <div className={'EditProfile'}>
            <Modal
                hideClose={this.props.hideClose}
                ref={(ref: any) => (this.modal$ = ref)}
                content={() => this.props.content}
                wrapperClasses={`${this.props.modalClass ? this.props.modalClass : 'ConfirmModal'} ${this.props.className} palette--bgc-neutral-1 elevation-2 border-radius-2`}
                footer={() => null}
            />
        </div>;
    }

    public openModal() {
        this.modal$.open();
    }

    public closeModal() {
        this.modal$.close();
    }
}
