import * as React from 'react';
import { LanguageLayout } from './language.layout';

export class LoginLayout extends LanguageLayout<any, any> {
    public render(): React.ReactNode {
        return <div className="Layout">
            <div className="">
                {this.props.children}
            </div>
        </div>;
    }
}
