import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { UserMetaInterface } from '../share/interfaces/user.interface';
import { Hr } from '../share/ui-components/hr';
import { ColourInterface, COLOURS } from './chart';
import { Indicator, IndicatorProps } from './indicator';

export interface ChartData {
    range: { start: number, finish: number, middle: number };
    average: number;
    userValue: number;
    diff: number;
    unit: number;
    label: string;
    isOverlapping: boolean;
    headerColour?: string;
}

interface AverageChartProps {
    chartData: ChartData;
    isOverlapping: boolean;
    gender?: UserMetaInterface['gender'];
    oppositeGender?: UserMetaInterface['gender'];
    labelToBeOppositeGender?: boolean;
    genderLabel?: boolean;
    size?: IndicatorProps['size'];
}

const GENDER_COLOURS = {
    female: { borderColor: '#EF6BA4', backgroundColor: '#ef6ba42b' },
    male: { borderColor: '#2995F8', backgroundColor: '#2995f836' }
};

export class AverageChart extends Subscribable<AverageChartProps, any> {
    public renderVerticals() {
        return <div className={'display-flex justify-content-space-between no-clear-fix align-items-center'}>
            <Hr className={'h-px-32'} type={'vertical'}/>
            <Hr className={'h-px-16'} type={'vertical'}/>
            <Hr className={'h-px-32'} type={'vertical'}/>
        </div>;
    }

    public renderValues() {
        const { start, finish, middle } = this.props.chartData.range;

        return <div className={'display-flex justify-content-space-between no-clear-fix align-items-center chart-values-center'}>
            <span className={'text--small fs-12'}>{start}%</span>
            <span className={'text--small fs-12'}>{middle}%</span>
            <span className={'text--small fs-12'}>{finish}%</span>
        </div>;
    }

    public renderHorizontal() {
        return <Hr className={'w-100 position-to-center'} type={'light'}/>;
    }

    public renderIndicator(value: number, label: string, color: ColourInterface, isOverlapping = false) {
        return <Indicator
            value={value}
            label={trans(label)}
            color={color}
            size={this.props.size}
            isOverlapping={isOverlapping}
            chartData={this.props.chartData}
        />;
    }

    public getColourForSex(gender?: UserMetaInterface['gender']): ColourInterface {
        switch (gender) {
            case 'account.edit.gender.female':
                return GENDER_COLOURS.female;
            case 'account.edit.gender.male':
                return GENDER_COLOURS.male;
            default:
                return GENDER_COLOURS.female;
        }
    }

    public getTitleBasedOnGender(): ColourInterface {
        switch (this.props.oppositeGender) {
            case 'account.edit.gender.female': // female
                return trans('account.data.average.chart.her');
            case 'account.edit.gender.male': // male
                return trans('account.data.average.chart.him');
            default:
                return trans('account.data.average.chart.avg');
        }
    }

    public render(): React.ReactNode {
        const { average, userValue } = this.props.chartData;
        const title = this.props.labelToBeOppositeGender ? this.getTitleBasedOnGender() : trans('account.data.average.chart.avg');
        const colour = this.getColourForSex(!this.props.oppositeGender ? this.props.gender : this.props.oppositeGender);
        const secondaryTitle = this.props.genderLabel ? this.getTitleBasedOnGender() : trans('account.data.average.chart.you');

        return <div className="average-chart">
            {this.renderIndicator(average, title, colour)}
            {this.renderIndicator(userValue, secondaryTitle, COLOURS.baseColours[0], this.props.isOverlapping)}
            {this.renderVerticals()}
            {this.renderHorizontal()}
            {this.renderValues()}
        </div>;
    }
}
