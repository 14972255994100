import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { get } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class EmailVerificationMessage extends Subscribable<any, any> {

    public render(): React.ReactNode {
        if (!!get(this.props, 'user.verifiedAt') && !!get(this.props, 'user')) {
            return null;
        }

        return <div className="w-100">
            <MessageBox type={'warning'} message={trans('verifyemail.message')}/>
        </div>;
    }

}
