import { get } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './faq.scss';

const data = require('./faq.json');

export class Faq extends Subscribable<any, any> {
    public state: any = {
        opened: null
    };

    public render(): React.ReactNode {

        return <div className={'FaqContainer elevation-1 border-radius-2'}>
            {this.renderQuestions()}
            {this.renderFooter()}
        </div>;
    }

    public renderQuestions() {
        return <div className={'p-box palette--bgc-neutral-1'}>
            {data.map((item, index) => this.renderQuestion(item, index))}
        </div>;
    }

    public renderQuestion(item: any, index: number) {
        return <div className={`FaqCollapse border-bottom-1 palette--bc-neutral-3 ${this.state.opened === index ? 'open' : ''}`}>
            <div className={'FaqCollapseTitle p-box'} onClick={() => this.toggleOpened(index)}>
                <span className={'fw-bold fs-large flex-fill'}>{get(item, 'title', '')}</span>
                <span className={'material-icons'}>add_circle_outline</span>
            </div>
            <div className={'FaqCollapseBody'}>
                <div className={'p-box  palette--bgc-neutral-1'}>
                    <span className={'fs-large palette--c-neutral-5'}>{get(item, 'description', '')}</span>
                </div>
            </div>
        </div>;
    }

    public toggleOpened(index) {
        if (index === this.state.opened) {
            this.setState({ opened: null });
            return;
        }
        this.setState({ opened: index });
    }

    public renderFooter() {
        return <div className={'FaqFooter p-8'}>
            <div className={'display-flex flex-column align-items-center justify-content-center'}>
                <span className={'palette--c-neutral-1 fs-large fw-bold text-center mb-6'}>Ha nem találod a választ a kérdésedre akkor keress minket bizalommal</span>
                <a className={'MailLink palette--c-primary-3'} href={'mailto:info@selectivita.com'}>info@selectivita.com</a>
            </div>
        </div>;
    }
}
