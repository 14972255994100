import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { MeasurementInput } from '../profile/forms/extras/measurement-input';
import { SingleLineSelect } from '../profile/forms/extras/single-line.select';

export type InputType = 'input' | 'select' | 'multi-select' | 'custom-name' | 'custom-age' | 'custom-measurement';

export interface FilterInputProps {
    data: { field: string; field2?: string; type: InputType, isWeight?: boolean, label?: string; imperial?: string; metric?: string; placeholder?: string; };
    form: any;
    reset?: boolean;
}

export class FilterInput extends Subscribable<FilterInputProps, any> {

    public renderInput() {
        return <div className="col-24 mb-md-0 mb-7">
            <Input
                field={this.props.form.field(this.props.data.field)}
                labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                className="mb-box variant-primary size-medium"
                type="text"
                disabled={false}
            />
        </div>;
    }

    public renderSelect() {
        return <div className="col-24 mb-md-0 mb-7">
            <SingleLineSelect
                field={this.props.form.field(this.props.data.field)}
                labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                className="mb-box variant-primary size-medium"
                disabled={false}
            />
        </div>;
    }

    public renderMultiSelect() {
        return <div className="col-24 mb-md-0 mb-7">
            <Select
                field={this.props.form.field(this.props.data.field)}
                labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                className="mb-box variant-primary size-medium"
                disabled={false}
            />
        </div>;
    }

    public renderNameField() {
        return <div className="col-24 mb-md-0 mb-7">
            <Input
                searchable={false}
                labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                className="mb-box size-medium variant-primary"
                field={this.props.form.field(this.props.data.field)}
                inputClassName="border-radius-1 border-1 palette--bc-neutral-4"
                type={'text'}
            />
            <span className={'material-icons search-icon'}>search</span>
        </div>;
    }

    public renderAge() {
        return [
            <div key={1} className="col-md-12 col-24 mb-md-0 mb-7">
                <Input
                    field={this.props.form.field(this.props.data.field)}
                    labelClassName={'fs-small fw-bold palette--c-neutral-5 s text-uppercase'}
                    className="mb-box variant-primary size-medium"
                    type="text"
                    disabled={false}
                />
            </div>,
            <div key={2} className="col-md-12 col-24 mb-md-0 mb-7 display-flex align-items-end w-100 no-clear-fix">
                <Input
                    field={this.props.form.field(this.props.data.field2)}
                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                    className="mb-box variant-primary size-medium w-100"
                    type="text"
                    disabled={false}
                />
            </div>
        ];
    }

    public renderMeasurement() {
        return [
            <div key={1} className="col-md-12 col-24 mb-7">
                <MeasurementInput
                    field={this.props.form.field(this.props.data.field)}
                    label={`account.data.${this.props.data.label}`}
                    imperial={this.props.data.imperial}
                    metric={this.props.data.metric}
                    placeholder={'min'}
                    reset={this.props.reset}
                    noErrors={true}
                />
            </div>,
            <div key={2} className="col-md-12 col-24 mb-7 display-flex align-items-end w-100 no-clear-fix">
                <MeasurementInput
                    field={this.props.form.field(this.props.data.field2)}
                    imperial={this.props.data.imperial}
                    metric={this.props.data.metric}
                    placeholder={'max'}
                    reset={this.props.reset}
                    noErrors={true}
                />
            </div>
        ];
    }

    public render() {
        switch (this.props.data.type) {
            case 'input':
                return this.renderInput();
            case 'select':
                return this.renderSelect();
            case 'multi-select':
                return this.renderMultiSelect();
            case 'custom-name':
                return this.renderNameField();
            case 'custom-age':
                return this.renderAge();
            case 'custom-measurement':
                return this.renderMeasurement();
            default:
                return this.renderInput();
        }
    }
}
