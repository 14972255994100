import { SyncStorage } from '@codebuild/cookie-jar/libs/sync.storage';
import { AnyAction } from 'redux';
import { UserActions } from '../actions/user.actions';

const initial: any = {
    user: null,
    currency: SyncStorage.getItem(UserActions.CURRENCY_KEY),
    resultRequest: SyncStorage.getItem(UserActions.RESULT_REQUEST_KEY),
    production: SyncStorage.getItem(UserActions.CURRENT_PRODUCTION_KEY),
    accessToken: SyncStorage.getItem(UserActions.ACCESS_TOKEN_KEY),
    accessTokenExpiredAt: SyncStorage.getItem(UserActions.ACCESS_TOKEN_KEY_EXPIRED_AT),
    refreshToken: SyncStorage.getItem(UserActions.REFRESH_TOKEN_KEY),
    accessTokenType: SyncStorage.getItem(UserActions.ACCESS_TOKEN_TYPE_KEY),
    // REQUESTS
    loginRequest: { response: null, error: null, loading: false },
    refreshTokenRequest: { response: null, error: null, loading: false },
    forgotPasswordRequest: { response: null, error: null, loading: false },
    registrationRequest: { response: null, error: null, loading: false },
    resetPasswordRequest: { response: null, error: null, loading: false },
    finishRegistrationRequest: { response: null, error: null, loading: false },
    whoAmIRequest: { response: null, error: null, loading: false },
};

export const authenticationReducer = (state = initial, action: AnyAction) => {
    switch (action.type) {
        case UserActions.CURRENT_PRODUCTION: {
            state = { ...state, production: action.payload };
            break;
        }
        case UserActions.SET_USER: {
            state = { ...state, user: action.payload };
            break;
        }
        case UserActions.SET_CURRENCY: {
            state = { ...state, currency: action.payload };
            break;
        }
        case UserActions.SET_RESULT_REQUEST: {
            state = { ...state, resultRequest: action.payload };
            break;
        }
        case UserActions.SET_ACCESS_TOKEN: {
            state = { ...state, token: action.payload };
            break;
        }
        case UserActions.SET_ACCESS_TOKEN_EXPIRED_AT: {
            state = { ...state, accessTokenExpiredAt: action.payload };
            break;
        }
        case UserActions.SET_ACCESS_TOKEN_TYPE: {
            state = { ...state, tokenType: action.payload };
            break;
        }
        case UserActions.SET_REFRESH_TOKEN: {
            state = { ...state, refreshToken: action.payload };
            break;
        }
        case UserActions.SET_WHO_AM_I_REQUEST: {
            state = { ...state, whoAmIRequest: action.payload };
            break;
        }
        case UserActions.SET_REGISTRATION_REQUEST: {
            state = { ...state, registrationRequest: action.payload };
            break;
        }
        case UserActions.SET_LOGIN_REQUEST: {
            state = { ...state, loginRequest: action.payload };
            break;
        }
        case UserActions.SET_REFRESH_TOKEN_REQUEST: {
            state = { ...state, refreshTokenRequest: action.payload };
            break;
        }
        case UserActions.SET_FORGOT_PASSWORD_REQUEST: {
            state = { ...state, forgotPasswordRequest: action.payload };
            break;
        }
        case UserActions.SET_FINISH_REGISTRATON_REQUEST: {
            state = { ...state, finishRegistrationRequest: action.payload };
            break;
        }
        case UserActions.SET_RESET_PASSWORD_REQUEST: {
            state = { ...state, resetPasswordRequest: action.payload };
            break;
        }
    }

    return state;
};
