import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Dropdown } from '@codebuild/cookie-jar/uikit/components/dropdown/dropdown';
import { capitalize, find, get, map } from 'lodash';
import * as React from 'react';
import { getLanguage } from '../../../../../libs/get-language';
import { Subscribable } from '../../../../../libs/subscribable';
import { MessageBox } from '../../../../message-box/message-box';
import { Spinner } from '../../../../spinner';
import { ContentProps } from './behaviour-content';

export class StarSignContent extends Subscribable<ContentProps, any> {
    public languageDropdown$: any;
    public descriptionDetailsModal$: any;
    public langs = ['En', 'Hu', 'Ro'];
    public state: any = {
        result: this.props.result,
        error: null,
        currentLanguage: capitalize(TranslationLib.getLang())
    };

    public hasGenerateAble(result, lang?: string): boolean {
        let hasDetails = false;

        if (!result) {
            return false;
        }

        if (!!lang) {
            const details = find(result.generatableResults, (r) => r.key === `details${lang}` && r.generatable);
            const preview = find(result.generatableResults, (r) => r.key === `detailsPreview${lang}` && r.generatable);
            return (details && preview);
        }

        map(this.langs, (l) => {
            const details = find(result.generatableResults, (r) => r.key === `details${l}` && r.generatable);
            const preview = find(result.generatableResults, (r) => r.key === `detailsPreview${l}` && r.generatable);
            hasDetails = hasDetails || (details && preview);
        });

        return hasDetails;
    }

    public renderComingSoon() {
        return <div
            className={'palette--bgc-neutral-3 border-radius-1 py-2 px-3 palette--c-neutral-5'}
        >
            {trans('starsign.result.comingsoon')}
        </div>;
    }

    public isPaid(result, lang: 'Hu' | 'Ro' | 'En') {
        const isPaid = get(result, `publicResults.details${lang as any}`);
        const hasGenerateAble = this.hasGenerateAble(this.state.result, lang);

        if (!isPaid || !hasGenerateAble) {
            return null;
        }

        return <div
            className={'palette--bgc-green-2 border-radius-1 py-2 px-3 palette--c-neutral-1'}
        >
            {this.getText(`details${lang}` as any)}
        </div>;
    }

    public renderDropdownBox() {
        return <div className={'p-box DropdownBox'}>
            {this.renderLanguageItem('Hu')}
            {this.renderLanguageItem('En')}
            {this.renderLanguageItem('Ro')}
        </div>;
    }

    public renderLanguageItem(lang: 'Hu' | 'Ro' | 'En') {
        return <div
            className={'mb-4 display-flex justify-content-space-between'}
            onClick={() => this.setState({ currentLanguage: lang }, () => this.languageDropdown$.close())}
        >
            <span className={'fs-large flex-fill mr-8'}>{trans(`global.language.${lang.toLowerCase()}`)}</span>
            {!this.hasGenerateAble(this.state.result, lang) && this.renderComingSoon()}
            {this.isPaid(this.state.result, lang)}
        </div>;
    }

    public getText(type: 'detailsHu' | 'detailsEn' | 'detailsRo') {
        return `${get(find(get(this.state, 'result.generatableResults'), (r) => r.key === type), 'cost')} credit`;
    }

    public render() {
        return <div className={'p-box'}>
            <div className={'w-100 mb-8 display-flex align-items-center justify-content-space-between px-6 py-4 palette--bgc-neutral-2 border-radius-1'}>
                <h2 className={'mb-0'}>{get(this.state, 'result.meta.name')}</h2>
                <h2 className={'mb-0 flex-fill text-right'}>{get(this.state, 'result.publicResults.percentage')}</h2>
            </div>
            <div className={'DetailsLangSelector mb-8'}>
                <Dropdown
                    className="DropdownButton"
                    backdropClasses=""
                    ref={(ref: Dropdown) => (this.languageDropdown$ = ref)}
                    direction="left"
                    renderDropdown={(ctx: Dropdown) => this.renderDropdownBox()}
                    renderContent={() => <div onClick={() => this.languageDropdown$.toggle()}>
                        <div className={'LanguageDropdown palette--bc-neutral-3 border-1 py-4 px-6 border-radius-1 display-inline-flex justify-content-center align-items-center cursor-pointer'}>
                            {this.getCurrentLang()}
                            <span className={'ml-8 material-icons'}>keyboard_arrow_down</span>
                        </div>
                    </div>}
                />
            </div>
            <div className={'mb-8'}>
                {this.getDetailsToLang()}
            </div>
            {(this.state.error) && <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>}
            <div className={'display-flex justify-content-end'}>
                <Button
                    className={'variant-link size-medium'}
                    onClick={() => this.setState({ resultDetails: null }, () => this.descriptionDetailsModal$.close())}
                    title={trans('global.buttons.close')}
                />
            </div>
        </div>;
    }

    public renderCreditButtonContent(icon: string, title: string) {
        return <div className={'display-flex'}>
            <span>{this.state.isLoading
                ? <div className={'pl-4'}><Spinner/></div>
                : <span>{trans(title)} <span className={'text-italic'}>({2} {trans('global.label.credits')})</span></span>
            }</span>
        </div>;
    }

    public hasPaid(result, lang?: string) {
        if (!lang) {
            let hasDetails = false;

            map(this.langs, (l) => {
                hasDetails = hasDetails || get(result, `publicResults.details${l}`);
            });

            return hasDetails;
        }

        return get(result, `publicResults.details${lang}`);
    }

    public getDetailsToLang() {
        const lang = get(this.state, 'currentLanguage');

        if (this.hasPaid(this.state.result, lang)) {
            const chunks = get(this.state, `result.publicResults.details${lang}`, '').split('\n');

            return chunks.map((item, index) => <p className="pb-4" key={index} dangerouslySetInnerHTML={{ __html: item }}/>);
        }

        if (this.hasGenerateAble(this.state.result, lang)) {
            return <div>
                <div className={'mb-8'}>
                    <span>{get(this.state, `result.publicResults.detailsPreview${lang}`)}</span>
                </div>
                <div className={'py-6 mb-6 border-top-1 border-bottom-1 palette--bc-neutral-4 display-flex align-items-start justify-content-space-between'}>
                    <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                        <span className={'material-icons palette--c-blue-2 mr-4'}>info</span>
                        <div>
                            <p className={'fs-large'}>{trans('starsign.resultinfo.title')}</p>
                            <span>{trans('starsign.resultinfo.desc')}</span>
                        </div>
                    </div>
                    <div>
                        <h4>{`${get(find(get(this.state, 'result.generatableResults'), (r) => r.key === `details${lang}`), 'cost')} credit`}</h4>
                    </div>
                </div>
                <Button
                    onClick={() => this.payResult(`details${lang}`)}
                    title={this.renderCreditButtonContent('book', 'starsign.result.get')}
                    className={'variant-primary size-medium'}
                />
            </div>;
        }

        return <div className={'py-6 mb-6 border-top-1 border-bottom-1 palette--bc-neutral-4 display-flex align-items-start justify-content-space-between'}>
            <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                <span className={'material-icons palette--c-blue-2 mr-4'}>info</span>
                <div>
                    <p className={'fs-large'}>{trans('starsign.noresult.title')}</p>
                    <span>{trans('starsign.noresult.desc')}</span>
                </div>
            </div>
        </div>;
    }

    public async payResult(details: string) {
        try {
            this.setState({ error: null });

            await Repository.post(`/competition/${get(this.state, 'result._id')}/pay`, {
                resultTypes: [
                    details
                ]
            });

            await this.refetchResult();
        } catch (err) {
            this.setState({ error: err });
        }
    }

    public async refetchResult() {
        try {
            this.setState({ error: null });

            const result = await Repository.get(`/competition/${this.state.result._id}`);

            if (!result) {
                throw new Error('something went wrong');
            }

            this.setState({ result });
        } catch (error) {

            this.setState({ error });
        }
    }

    public getCurrentLang() {
        const lang = (get(this.state, 'currentLanguage') as string).toLowerCase() || 'en';

        return trans(`global.language.${lang}`);
    }

    // todo delete this...
    public getTextBlocks(text: string) {
        if (!text) {
            return ' - ';
        }

        const items = text?.split('\n');

        return (items || []).map((item, index) => <span key={index} className={'text--span mb-6'}>{item}</span>);
    }

    public srender() {
        const lang = getLanguage();

        return <div className={'row pt-6'}>
            <div className={'col-24'}>
                <h4 className={'text--h3 pb-4'}>{trans('account.data.description')}</h4>
                {this.getTextBlocks(this.props.userAnalysisData?.data?.[lang] as any)}
            </div>
        </div>;
    }
}
