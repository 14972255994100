import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { CollapsibleText } from '../../../../couples-assessment/tabs/extras/collapsible-text';
import { Chart } from '../../../../questionnaires/chart';
import { PersonalityChartCategoryOrganizer } from '../../../../questionnaires/libs/personality-chart-category-organizer';
import { DimensionTabs } from '../../../../share/dimension-tabs';
import { ContentProps } from './behaviour-content';

export interface Dataset {
    result: number;
    diff: number;
}

export class PersonalityContent extends DimensionTabs<any, any> {
    public static colours = {
        Agreeableness: { colour: '#F1A93B', subCategories: ['Forgiveness', 'Gentleness', 'Flexibility', 'Patience'] },
        Conscientiousness: { colour: '#3162CB', subCategories: ['Organization', 'Diligence', 'Perfectionism', 'Prudence'] },
        Emotionality: { colour: '#A539D3', subCategories: ['Fearfulness', 'Anxiety', 'Dependence', 'Sentimentality'] },
        Extroversion: { colour: '#D63E34', subCategories: ['Social-Esteem', 'Social-Boldness', 'Sociability', 'Liveliness'] },
        'Honesty-Humility': { colour: '#E5E5E5', subCategories: ['Own-Interest-Avoidance', 'Corrupt-Avoidance', 'Greed-Avoidance', 'Modesty'] },
        'Open-Mindedness': { colour: '#73F6E6', subCategories: ['Cultured', 'Inquisitiveness', 'Creativity', 'Unconventionality'] },
    };

    public state = {
        ...this.state,
        categoryDataset: null,
        categoryLabels: [''],
        subCategoryDataset: null,
        subCategoryLabel: [''],
        subCategory: null,
        averageValuesForSubCategories: null,
        averageValuesForMainCategories: null,
        isDataSet: false,
    };

    public getGenderTitle(): string {
        switch (this.props.userAnalysisData?.gender) {
            case 'account.edit.gender.female': // female
                return trans('account.data.average.chart.her');
            case 'account.edit.gender.male': // male
                return trans('account.data.average.chart.him');
            default:
                return trans('account.data.average.chart.avg');
        }
    }

    public componentDidMount() {
        this.setData();
    }

    public componentDidUpdate(prevProps: Readonly<ContentProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.userAnalysisData !== this.props.userAnalysisData) {
            this.setData();
        }
        if (prevProps.user !== this.props.user) {
            this.setData();
        }
    }

    public setData() {
        const rawCategory: any = this.props?.userAnalysisData?.data?.category;
        const rawSubCategory: any = this.props?.userAnalysisData?.data?.subCategory;
        const { subCategoryLabels, subCategory, category, categoryLabels } = new PersonalityChartCategoryOrganizer(rawSubCategory, rawCategory);

        if (!rawCategory || !rawSubCategory) {
            return;
        }

        const categoryDataset = category.map((d: Dataset) => d.result);
        const averageValuesForMainCategories = Object.values(category).map((d: Dataset) => d?.diff * -1);

        const subCategoryDataset = subCategory.map((d: Dataset) => d?.result);
        const averageValuesForSubCategories = subCategory.map((d: Dataset) => d?.diff * -1);

        this.setState({
            categoryDataset,
            categoryLabels,
            subCategoryDataset,
            averageValuesForSubCategories,
            averageValuesForMainCategories,
            subCategoryLabel: subCategoryLabels,
            isDataSet: true,
        });
    }

    public renderMainContent() {
        if (!this.state.isDataSet) {
            return null;
        }

        const title = trans('user.profile.personality.dimensions');
        const titleVariance = trans('user.profile.personality.dimensions.variance.chart');

        const primaryOptionLabel = this.getGenderTitle();

        return <div className={'row'}>
            <div className={'col-24'}>
                <CollapsibleText description={trans('account.data.personality.main.dimensions')}/>
            </div>
            <div className={'col-24'}>
                <Chart
                    doNotDisplayLegend={true}
                    title={title}
                    chartWrapper={'fix-height-320'}
                    user={this.props?.userAnalysisData as any}
                    colorIndex={0}
                    chartName={'Bar'}
                    data={this.state.categoryDataset as any}
                    labels={this.state.categoryLabels}
                    primaryOptionLabel={primaryOptionLabel}
                />
            </div>
            <div className={'col-24 pt-7'}>
                <Chart
                    doNotDisplayLegend={true}
                    title={titleVariance}
                    chartWrapper={'fix-height-320'}
                    user={this.props?.userAnalysisData as any}
                    colorIndex={0}
                    customBarColours={this.getColours(this.state.categoryLabels)}
                    chartName={'Bar'}
                    data={this.state.averageValuesForMainCategories as any}
                    labels={this.state.categoryLabels}
                    primaryOptionLabel={primaryOptionLabel}
                />
            </div>
        </div>;
    }

    public renderSubContent() {
        if (!this.state.isDataSet) {
            return null;
        }

        const title = trans('user.profile.personality.traits');
        const titleVariance = trans('user.profile.personality.traits.variance.chart');

        const primaryOptionLabel = this.getGenderTitle();

        return <div className={'row'}>
            <div className={'col-24'}>
                <CollapsibleText description={trans('account.data.personality.sub.traits')}/>
            </div>
            <div className={'col-24'}>
                <Chart
                    doNotDisplayLegend={true}
                    title={title}
                    chartWrapper={'fix-height-320'}
                    user={this.props?.userAnalysisData as any}
                    colorIndex={0}
                    chartName={'Bar'}
                    data={this.state.subCategoryDataset as any}
                    labels={this.state.subCategoryLabel}
                    primaryOptionLabel={primaryOptionLabel}
                />
            </div>
            <div className={'col-24 pt-7'}>
                <Chart
                    doNotDisplayLegend={true}
                    title={titleVariance}
                    chartWrapper={'fix-height-320'}
                    user={this.props?.userAnalysisData as any}
                    colorIndex={0}
                    customBarColours={this.getSubColours()}
                    chartName={'Bar'}
                    data={this.state.averageValuesForSubCategories as any}
                    labels={this.state.subCategoryLabel}
                    primaryOptionLabel={primaryOptionLabel}
                />
            </div>
        </div>;
    }

    public getColours(labels) {
        if (!labels?.length) {
            return false;
        }

        const labelColours = labels.map(label => PersonalityContent.colours[label].colour);

        return {
            backgroundColor: labelColours,
            borderColor: labelColours
        };
    }

    public getSubColours() {
        const rawCategory: any = this.props?.userAnalysisData?.data?.category;
        const rawSubCategory: any = this.props?.userAnalysisData?.data?.subCategory;
        const { subCategoryLabels } = new PersonalityChartCategoryOrganizer(rawSubCategory, rawCategory);

        if (!subCategoryLabels?.length) {
            return false;
        }

        const labelColours = subCategoryLabels.map(label => {
            for (const key of Object.keys(PersonalityContent.colours)) {
                const hasLabel = PersonalityContent.colours[key].subCategories.find(category => category === label);

                if (hasLabel) {
                    return PersonalityContent.colours[key].colour;
                }
            }
        });

        return {
            backgroundColor: labelColours,
            borderColor: labelColours
        };
    }

    public renderActiveTab() {
        switch (this.state.active) {
            case 'main-dimension.selector':
                return this.renderMainContent();
            case 'sub-dimension':
                return this.renderSubContent();
            default:
                return null;
        }
    }

    public render() {
        return <div className={'row'}>
            <div className={'col-24'}>
                <h6 className="fw-bold text--small pt-4">{trans('account.data.personality.questionnaire')}</h6>
            </div>
            <div className={'col-24 pb-7'}>
                {this.renderSelectors()}
                {this.renderActiveTab()}
            </div>
        </div>;
    }
}
