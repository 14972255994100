import * as React from 'react';
import { Subscribable } from '../../../../libs/subscribable';
import { Chart, GraphProps } from '../../../questionnaires/chart';
import { UserInterface } from '../../../share/interfaces/user.interface';
import { CollapsibleText } from './collapsible-text';

export interface ChartsWithDescriptionProps {
    description?: string;
    user?: UserInterface;
    datasetsColor?: number;
    datasetsColor2?: number;
    fullSized?: boolean;
    chartName: GraphProps['chartName'];
    datasets: { [key: string]: number };
    datasets2?: { [key: string]: number };
    primaryOptionLabel?: string;
    secondaryOptionLabel?: string;
    doNotDisplayLegend?: boolean;
    customColors?: GraphProps['customBarColours'];
    chartWrapper?: string;
    classNames?: string;
    title?: string;
    barThickness?: number;
    hideVertical?: boolean;
    labels?: string[];
}

export class ChartsWithDescription extends Subscribable<ChartsWithDescriptionProps, any> {
    public render() {
        const datasets = Object.values(this.props.datasets);
        const datasets2 = Object.values(this.props.datasets2 || {});
        const labels = this.props?.labels ?? Object.keys(this.props.datasets);
        const fullSized = this.props.fullSized ? 24 : 12;
        const classes = `col-md-${fullSized} col-24 ${this.props.classNames} max-width-vw-87`;

        return <div className={'row'}>
            <div className={classes}>
                {this.props.description && <CollapsibleText description={this.props.description}/>}
            </div>
            <div className={classes}>
                <Chart
                    hideVertical={this.props.hideVertical}
                    barThickness={this.props.barThickness}
                    title={this.props?.title || ''}
                    chartWrapper={this.props.chartWrapper}
                    customBarColours={this.props.customColors}
                    user={this.props.user?.meta as any}
                    colorIndex={this.props.datasetsColor}
                    colorIndex2={this.props.datasetsColor2}
                    chartName={this.props.chartName}
                    data={datasets}
                    data2={datasets2}
                    labels={labels}
                    primaryOptionLabel={this.props.primaryOptionLabel}
                    secondaryOptionLabel={this.props.secondaryOptionLabel}
                    doNotDisplayLegend={this.props.doNotDisplayLegend}
                />
            </div>
        </div>;
    }

}
