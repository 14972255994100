import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { ValidatorResponseInterface } from '@codebuild/cookie-jar/libs/form/validator/validator-response.interface';

export class UrlValidator extends Validator {
    public static httpsRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    public static urlValidator(response: string) {
        return (field: Field): ValidatorResponseInterface => {
            const links = field.getValue();

            if (!links || !links.length) {
                return {
                    valid: true,
                    error: null,
                };
            }

            for (const link of links) {
                const isValid = UrlValidator.httpsRegex.test(link);

                if (!isValid) {
                    return {
                        valid: false,
                        error: response
                    };
                }
            }

            return {
                valid: true,
                error: null,
            };
        };
    }
}
