import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { get } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { EmailVerificationMessage } from '../../../components/authentication/email-verification/email-verification-message';
import { CurrencySelector } from '../../../components/currency-selector/currency-selector';
import { handleError } from '../../../components/share/helpers/util';
import { generateLink } from '../../../libs/generate-link';
import { Subscribable } from '../../../libs/subscribable';
import { routes } from '../../../routes';
import './pricing.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    currency: store.authentication.currency
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class Pricing extends Subscribable<any, any> {
    public state: any = {
        offers: [],
        loading: false
    };

    public componentDidMount(): void {
        this.fetchOffers();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.currency !== this.props.currency) {
            this.fetchOffers();
        }
    }

    public render(): React.ReactNode {
        return <div className={'Pricing p-box elevation-1 border-radius-2 palette--bgc-neutral-1'}>
            <div className="mb-7">
                <EmailVerificationMessage/>
            </div>
            <div className="display-flex justify-content-space-between align-items-center mb-8">
                <div className={'display-flex flex-row justify-content-start'}>
                    <span className={'palette--c-neutral-5 mr-4'}>Valuta módosítása:</span>
                    <CurrencySelector/>
                </div>
            </div>
            <div>
                {this.renderOffers()}
            </div>
        </div>;
    }

    public renderOffers() {
        return <div className={'Offers mb-8'}>
            {(this.state.offers || []).map((offer, index) => <div key={index} className={'px-4 display-flex flex-column align-items-center'}>
                {this.renderOffer(offer)}
            </div>)}
        </div>;
    }

    public async fetchOffers() {
        try {
            const offers = await Repository.get('/topup', { currency: this.props.currency });
            if (!offers) {
                throw Error('no offers');
            }
            this.setState({ offers: get(offers, 'items') });
        } catch (err) {
            handleError(err);
        }
    }

    public renderOffer(offer: any) {
        return <div className={`${offer.mostPopular ? 'popular' : ''} display-flex flex-column justify-content-center align-items-center`}>
            <span className={'PopularBadge mb-6'}>{offer.mostPopular ? trans('topup.mostpopular') : 'Offer'}</span>
            <div className={`OfferCard ${get(offer, 'colorClass')} border-radius-1 elevation-1`}>
                <div className={''}>
                    <h3 className={'text-center OfferCredit palette--c-neutral-1 mb-0'}>{offer.balance} <span className={'palette--c-neutral-1'}>{offer.balance > 1 ? trans('global.label.credits') : trans('global.label.credit')}</span>
                    </h3>

                    <div className={'display-flex justify-content-center align-items-center p-box'}>
                        <h2 className={'text-center display-flex justify-content-center align-items-center flex-row mb-0 palette--c-neutral-1'}>
                            {offer.price}
                            <h6 className={'mb-0 ml-2 text-uppercase palette--c-neutral-1'}>
                                {offer.currency}
                            </h6>
                        </h2>
                    </div>
                    <div className={'display-flex justify-content-center align-items-center p-box'}>
                        <div>
                            <Link to={generateLink(routes.registration)} className={'text-decoration-none'}>
                                <Button onClick={() => null} title={trans('global.signup')} className={'variant-secondary size-medium'}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

}
