import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { NotificationStrip } from './notification-strip';

export class ProfileCompletionMeter extends Subscribable<any, any> {
    public renderContent() {
        const completionRate = this.props.user.meta?.completionRate || 0;
        const text1 = trans('account.data.profile.completion.1');
        const text2 = trans('account.data.profile.completion.2');

        return <div className={'w-100 display-flex'}>
            <i className={'fa fa-comment-alt color--light px-6 relative-top-2'}/>
            <span className={'color--light fw-bold fs-md-14 fs-10 Ellipsis'}>{text1} {completionRate}%{text2}</span>
        </div>;
    }

    public render() {
        return <NotificationStrip content={this.renderContent()} backgroundColour={'orange'}/>;
    }
}
