import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { handleError } from '../../share/helpers/util';
import { Photo } from './photo';
import { UploaderMulti } from './uploader/uploader-multi';

export class EditPhotos extends Subscribable<any, any> {
    public state = { photos: [] };

    public componentDidMount() {
        this.setState({ photos: this.props.meta?.photos });
    }

    public async deletePhoto(photoId: string) {
        try {
            const photos = (this.state.photos || []).filter(photo => photo !== photoId);

            const response = await Repository.put('/user', { photos });

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({ photos });
        } catch (err) {
            handleError(err);
        }
    }

    public renderPhoto(photo: string, index: number) {
        return <div className={'col-md-4 col-12'} key={`${photo}-${index}`}>
            <Photo photo={photo} id={index} onClick={(photoId: string) => this.deletePhoto(photoId)}/>
        </div>;
    }

    public async updateUserInfo(photos: string[]) {
        try {
            const response = await Repository.put('/user', { photos });

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }

    public addMore() {
        return <div className={' col-md-4 col-12'}><UploaderMulti
            onUploadSuccess={(attachments) => this.updateUserInfo(attachments)}
            trigger={<div className={'img-wrapper img-wrapper--rounded hover-opacity background-dark position-center mb-6 mx-4'}>
                <span className={'material-icons color--light'}>add_a_photo</span>
            </div>}
        /></div>;
    }

    public renderPhotos() {
        return <div className={'row max-height-483 overflow-scroll overflow-x-hidden'}>
            {this.addMore()}
            {!!this.state?.photos?.length && (this.state.photos || []).map((photo, index) => this.renderPhoto(photo, index))}
        </div>;
    }

    public handleCancel() {
        this.props.onCancel();
        window.location.reload();
    }

    public render() {
        return <div className={'p-box w-100'}>
            <h2 className={'mb-8'}>{trans('account.data.more.photos')}</h2>
            {this.renderPhotos()}
            <div className="row mt-8">
                <div className="w-100 col-24 display-flex justify-content-end">
                    <Button
                        type={'button'}
                        className="variant-contact size-medium mr-7"
                        onClick={() => this.props.onCancel()}
                    >
                        <span className="material-icons mr-4">close</span>
                        <span>{trans('global.buttons.cancel')}</span>
                    </Button>

                    <Button
                        type={'button'}
                        className="variant-primary size-medium"
                        onClick={() => this.handleCancel()}
                    >
                        <span className="material-icons mr-4">check</span>
                        <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                    </Button>
                </div>
            </div>
        </div>;
    }
}
