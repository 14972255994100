// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/comingsoon/comingsoon_workshop.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/comingsoon/comingsoon_assessment.webp");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/comingsoon/comingsoon_questionnaire.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito:300,600,700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".ComingSoon{position:relative;background-size:cover;background-position:50%}.ComingSoon .backdrop{background:rgba(0,0,0,0.5);position:absolute;top:0;right:0;left:0;bottom:0;z-index:0}.ComingSoon .content{z-index:1}.ComingSoon.Workshop{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.ComingSoon.Assessment{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.ComingSoon.Questionnaires{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.ComingSoon h1{font-size:104px;line-height:120px}.ComingSoon h1,.ComingSoon h3{color:#fefeff;font-weight:700;text-align:center}.ComingSoon h3{font-size:29px;line-height:32px}.ComingSoon .ScreenLogo{width:48px;height:48px;border-radius:12px;display:flex;justify-content:center;align-items:center;padding:8px;background-color:#fefeff;margin-right:16px}.ComingSoon .ScreenLogo img{max-width:100%}.chart-wrapper{height:320px;border-radius:8px;border:1px solid #e1e4ea;color:#f1eef8;background-color:#e1e4ea}.uikit-button.variant-primary:disabled{background-color:red !important}.colored-dot{border-radius:50%;width:8px;height:8px;background-color:#9b79f7}.value-indicator{padding:2px 6px;border-radius:4px;border-style:solid;border-width:1px}.position-to-center{position:relative;bottom:18px}.average-chart{width:100%;max-width:88%;position:relative}.chart-values-center{width:111%;margin-left:-7px}.move-dot-bottom{bottom:-124%;position:absolute}\n", ""]);
// Exports
module.exports = exports;
