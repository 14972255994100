import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { StorageHelperInterface } from '../share/helpers/storage.helper';
import { UserInterface } from '../share/interfaces/user.interface';
import { UserList } from './user-list';

export const tabs: { [key: string]: TabsStateProps['active'] } = {
    recommendations: 'our-recommendations',
    matchFinder: 'match-finder',
    likedList: 'like-list',
};

export interface TabsStateProps {
    active: 'our-recommendations' | 'match-finder' | 'like-list' | string;
}

export interface TabsProps {
    defaultTab: TabsStateProps['active'];
    user: UserInterface;
    tabStorage: StorageHelperInterface;
}

export class Tabs extends React.Component<TabsProps, TabsStateProps> {
    public state = { active: tabs.recommendations };

    public componentDidMount() {
        this.setState({ active: this.props.defaultTab });
    }

    public componentDidUpdate(prevProps: Readonly<TabsProps>, prevState: Readonly<TabsStateProps>, snapshot?: any) {
        if (prevProps.defaultTab !== this.props.defaultTab) {
            this.setState({ active: this.props.defaultTab });
        }
    }

    public setTab(tab: TabsStateProps['active']) {
        this.setState({ active: tab });
        this.props.tabStorage.set({ active: tab });
    }

    public renderSelector(tab: string, icon: string, text: string) {
        const isActive = this.state.active === tab;

        const isActiveClass = isActive
            ? 'ui-base uikit-button variant-primary size-medium mr-4'
            : 'ui-base uikit-button border-solid hover-opacity size-medium background-white mr-4';

        const textColour = isActive ? 'palette--c-neutral-1' : 'palette--c-neutral-5';

        return <Button
            className={isActiveClass}
            onClick={() => this.setTab(tab)}
        >
            <i className={`fa fa-${icon} mr-4 ${isActive ? 'palette--c-neutral-1' : 'palette--c-neutral-5'}`}/>
            <span className={textColour}>{trans(text)}</span>
        </Button>;
    }

    public renderActiveTab() {
        switch (this.state.active) {
            case tabs.recommendations:
                return <UserList listType={'matched-user-list'}/>;
            case tabs.matchFinder:
                return <UserList listType={'user-list'}/>;
            case tabs.likedList:
                return <UserList listType={'like-list'}/>;
            default:
                return <UserList listType={'user-list'}/>;
        }
    }

    public render() {
        return <div>
            <div className={'pb-7 display-flex'}>
                <div className={'display-flex'}>
                    {this.renderSelector(tabs.matchFinder, 'search', 'account.data.match.finder')}
                    {this.renderSelector(tabs.recommendations, 'people-arrows', 'account.data.our.recommendations')}
                    {this.renderSelector(tabs.likedList, 'heart', 'account.data.like.list')}
                </div>
            </div>
            {this.renderActiveTab()}
        </div>;
    }
}
