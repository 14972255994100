import { AnyAction } from 'redux';
import { HeaderActions } from '../actions/header.actions';

const initial = {
    screenContent: null
};

export const headerReducer = (state = initial, action: AnyAction) => {

    switch (action.type) {
        case HeaderActions.SET_SCREEN_CONTENT: {
            state = {
                ...state,
                screenContent: {
                    ...(state.screenContent || {}),
                    ...(action.payload || {})
                }
            };
        }
    }

    return state;
};
