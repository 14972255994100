import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { UserActions } from '../../../actions/user.actions';
import { HandleToasts } from '../../../handle.toasts';
import { Subscribable } from '../../../libs/subscribable';
import { BirthDateInput } from '../../birth-date-picker/birth-date-input';
import { GaugeChart } from '../../gauge-chart/gauge-chart';
import { PersonalityDetails } from '../../profile/profile-box/personality-details';
import { handleNotEnoughBalance } from '../../share/helpers/util';
import './new-compatibility-test.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    resultRequest: store.authentication.resultRequest
});

const mapDispatchProps = (dispatch: any) => ({
    setResultRequest: (requestData: any) => dispatch(UserActions.setResultRequest(requestData)),
    checkWhoAmI: () => dispatch(UserActions.checkWhoAmI())
});

@connect(mapStateProps, mapDispatchProps)
export class NewCompatibilityTest extends Subscribable<any, any> {
    public gaugeChartRef$: GaugeChart | null;

    public state: any = {
        competitionResult: null,
        isCalculated: false,
        myBirthDate: get(this.props, 'user.meta.birthDate')
    };

    public form = new Form({
        birthDate: new Field({
            label: 'Birthdate',
            value: get(this.props, 'resultRequest.birthDate') || null,
            validators: [Validator.REQUIRED('Birth date is required')]
        }),
        name: new Field({
            label: '',
            placeholder: trans('starsign.new.partnername'),
            value: '',
            validators: [Validator.REQUIRED('Name is required')]
        }),
        myBirthDate: new Field({
            label: 'Birthdate',
            value: null,
            validators: this.hasBirthDate() ? [Validator.REQUIRED('Birth date is required')] : []
        }),
    });

    public componentDidMount(): void {
        this.setState({ myBirthDate: get(this.props, 'user.meta.birthDate') });

        if (this.hasBirthDate()) {
            this.form.field('myBirthDate').validators = [];
        }

        this.subscriptions$.push(
            this.form.field('myBirthDate').value$.subscribe((value) => this.updateBirthDate(value))
        );
    }

    public async updateBirthDate(date: any) {
        try {
            if (date) {
                if (!this.hasBirthDate()) {

                    const response = await Repository.put('/user', { birthDate: date });

                    if (!response) {
                        throw new Error('UnableToUpdateBirthDate');
                    }

                    this.props.checkWhoAmI();
                }
            }
        } catch (e) {
            HandleToasts.error(e);
        }
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (get(prevProps, 'user.meta.birthDate') !== get(this.props, 'user.meta.birthDate')) {
            this.setState({ myBirthDate: get(this.props, 'user.meta.birthDate') });

            this.form.field('myBirthDate').validators = [];
        }
    }

    public render(): React.ReactNode {
        return <div className={'NewCompatibilityTest w-100 border-radius-2 palette--bgc-neutral-1 elevation-1 p-box'}>
            <div>
                <h6 className={'text-uppercase mb-6'}>{trans('starsign.new.title')}</h6>
            </div>
            <div className={'row display-flex justify-content-center align-items-center'}>
                <div className={'col-lg-6 col-md-8 col-12 display-flex justify-content-end align-items-center'}>
                    {this.renderMyData()}
                </div>
                <div className={'col-lg-8 col-md-8 col-24 display-flex justify-content-center align-items-center'}>
                    {this.renderResult()}
                </div>
                <div className={'col-lg-6 col-md-8 col-12 display-flex justify-content-start align-items-center'}>
                    {this.renderPartnerData()}
                </div>
            </div>
        </div>;
    }

    public renderMyData() {
        return <div className={'MyData w-100 display-flex flex-column justify-content-center align-items-center mb-6 mb-md-0'}>
            <div className="ProfileImage mb-7">
                <img src={get(this.props, 'user.meta.photo', null) || '/assets/images/profile/profile-image-empty.webp'} alt={'profile image'}/>
            </div>
            <div className={'display-flex w-100 flex-column align-items-center justify-content-center'}>
                <span className={'fs-large mb-4'}>{trans('starsign.new.you')}</span>

                {!this.hasBirthDate() && <BirthDateInput field={this.form.field('myBirthDate')}/>}

                {this.hasBirthDate() && <h2>{moment(get(this.props, 'user.meta.birthDate')).format('YYYY.MM.DD.')}</h2>}

                <PersonalityDetails
                    readMoreTitle={trans('compatibility.myResultButton')}
                    readMoreClasses="variant-outline size-medium"
                    comingSoonClasses="variant-outline size-medium"
                    hideCommingSoon={!this.hasBirthDate()}
                    hidePreview={true}
                    result={get(this.props, 'user.meta.starSignCompatibilityDetails')}
                />
            </div>
        </div>;
    }

    public renderPartnerData() {
        return <div className={'PartnerData w-100 display-flex flex-column justify-content-center align-items-center'}>
            <div className="ProfileImage mb-7">
                <img src={'/assets/images/profile/profile-image-empty.webp'} alt={'profile image'}/>
            </div>
            <div className={'display-flex w-100 flex-column align-items-center justify-content-center'}>
                <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => null}>
                    <Input
                        className="variant-primary size-medium mb-4"
                        inputClassName={'palette--c-neutral-5'}
                        type="text"
                        disabled={false}
                        field={this.form.field('name')}
                    />
                    <BirthDateInput field={this.form.field('birthDate')}/>
                </AbstractForm>
            </div>
        </div>;
    }

    public renderButtonContent(text: string, credit: number) {
        const creditText = trans(credit > 1 ? 'global.label.credits' : 'global.label.credit');

        return <span>{trans(text)} <span className={'text-italic'}>({credit} {creditText})</span></span>;
    }

    public renderResult() {
        return <div className={'TestResult'}>
            <div className={'GaugeChartWrapper'}>
                <GaugeChart ref={ref => this.gaugeChartRef$ = ref} isAnimated={true} value={this.getCompetitionResult()}/>
            </div>
            <div className={'p-box display-flex justify-content-center'}>
                {!this.state.isCalculated && <Button
                    type={'submit'}
                    onClick={() => this.calculateResult()}
                    className={'variant-primary size-medium'}
                >
                    {this.renderButtonContent('starsign.new.button', 1)}
                </Button>}
                {this.state.isCalculated && <Button
                    type={'button'}
                    onClick={() => this.onNewCompatibility()}
                    className={'variant-primary size-medium'}
                    title={trans('starsign.new.buttonagain')}
                />}
            </div>
        </div>;
    }

    public onNewCompatibility() {
        (this.gaugeChartRef$ as any).reset();

        this.form.field('name').setValue(null);
        this.form.field('birthDate').setValue(null);
        this.form.field('name').setDirty(false);
        this.form.field('birthDate').setDirty(false);

        this.setState({ competitionResult: null, isCalculated: false });

        if (this.props.onNewCompatibility) {
            this.props.onNewCompatibility();
        }
    }

    public getCompetitionResult() {

        if (!this.state.competitionResult) {
            return 0;
        }
        const publicResult = get(this.state, 'competitionResult.publicResults');
        const percentage = get(publicResult, 'percentage', 0).replace('%', '');
        return (percentage / 100);
    }

    public async fetchCompetitionConfig() {
        try {
            return Repository.get('/competition-config/STAR_SIGN/type');
        } catch (err) {
            return {};
        }
    }

    public async calculateResult() {
        try {
            const validation = await this.form.validate();

            await this.form.field('name').setDirty(true);

            await this.form.field('birthDate').setDirty(true);

            if (validation.length) {
                return;
            }

            const config = await this.fetchCompetitionConfig();

            const start = await Repository.post('/competition/start', {
                config: config._id,
                meta: {
                    name: get(this.form.toJSON(), 'name')
                }
            });

            const answer = await Repository.post(`/competition/${get(start, '_id')}/answer`, {
                answer: {
                    birthDate: moment(get(this.form.toJSON(), 'birthDate')).format('YYYY.MM.DD')
                }
            });

            const pay = await Repository.post(`/competition/${get(answer, '_id')}/pay`, {
                resultTypes: ['percentage']
            });

            this.setState({
                competitionResult: pay
            });

            this.props.setResultRequest(null);

            this.setState({ isCalculated: true });
        } catch (err) {
            HandleToasts.error(err);
            handleNotEnoughBalance(err);
        }
    }

    public hasBirthDate() {
        return !!get(this.state, 'myBirthDate');
    }
}
