import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Questionnaire } from '../../components/questionnaires/questionnaire';
import { StorageHelper } from '../../components/share/helpers/storage.helper';
import { OverlaySpinner } from '../../components/share/ui-components/overlay-spinner';
import { Subscribable } from '../../libs/subscribable';
import './questionnaires.scss';

export interface QuestionnaireMetas {
    title: string;
    description: string;
    boxLabel: string;
    isComplete: boolean;
    identifier: 'love-style' | 'personality' | 'behavioural';
}

export interface QuestionnaireProps {
    items: any[];
    meta: QuestionnaireMetas;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class Questionnaires extends Subscribable<any, any> {
    public state = { isLoading: false, questionnaires: [] };
    public storage = new StorageHelper('questionnaires');

    public async componentDidMount() {
        await this.getQuestionnaires();
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            await this.getQuestionnaires();
        }
    }

    public async getQuestionnaires() {
        try {
            this.setState({ isLoading: true });
            const response = await Repository.get(`/questionnaires/${this.props.user._id}`);

            if (!response) {
                throw new Error('something went wrong');
            }

            this.storage.set(response.questionnaires);

            this.setState({ questionnaires: response.questionnaires, isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public renderQuestionnaire({ title, identifier, isComplete, description }: QuestionnaireMetas, index: number) {
        return <div key={identifier} className={'w-100 mb-7'}>
            <Questionnaire
                index={index}
                user={this.props.user}
                description={description}
                questionnaire={identifier}
                boxLabel={`${trans(title)} ${trans('account.data.questionnaire')}`}
                isComplete={isComplete}
            />
        </div>;
    }

    public render(): React.ReactNode {
        return <div className="flex-fill display-flex justify-content-center align-items-center flex-column">
            <div className="backdrop"/>
            <OverlaySpinner isLoading={this.state.isLoading}/>
            <div className={'max-width-1138 w-100 mt-8'}>
                {this.state.questionnaires.map((questionnaire: QuestionnaireProps, index) => this.renderQuestionnaire(questionnaire.meta, index))}
            </div>
        </div>;
    }
}
