import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { EditModal } from '../profile/shared/edit.modal';

interface EditModaProps {
    className?: string;
    successMessage: string;
    redirectTo: string;
    buttonText: string;
    show: boolean;
}

export class SuccessModal extends Subscribable<EditModaProps, any> {
    public modal$: any;

    public renderSuccessMessage() {
        return <div className={'position-center flex-column mb-7 pb-7'}>
            <div className={'position-center h-px-300'}>
                <h4 className={'text--h3'}>{this.props.successMessage}</h4>
            </div>
            <div>
                <a
                    className={'ui-base uikit-button text-decoration-none variant-green anchor-tag-to-button hover-opacity'}
                    href={this.props.redirectTo}
                >{trans(this.props.buttonText)}
                </a>
            </div>
        </div>;
    }

    public componentDidUpdate(prevProps: Readonly<EditModaProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.show !== this.props.show) {
            this.modal$.openModal();
        }
    }

    public render(): React.ReactNode {
        return <div>
            <div onClick={() => this.modal$.openModal()}>
                {this.props.children}
            </div>
            <EditModal hideClose={true} content={this.renderSuccessMessage()} ref={ref => this.modal$ = ref}/>
        </div>;
    }

}
