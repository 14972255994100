import { LOCATION_CHANGE } from 'connected-react-router';
import { Base64 } from 'js-base64';
import { parseUrl } from 'query-string';
import { AnyAction } from 'redux';

const initial = {
    query: parseUrl(window.location.href).query
};

export const queryBaseToReal = (query: any = {}) => {
    try {
        const base: string = (query && query.base) || '';

        const decoded = Base64.decode(base) || '{}';

        const parsed = JSON.parse(decoded);

        return parsed;
    } catch (e) {
        return {};
    }
};

export const queryRealToBase = (query: any = {}) => {
    try {
        const s = JSON.stringify(query);

        const encoded = Base64.encode(s);

        return {
            base: encoded
        };
    } catch (e) {
        return {};
    }
};

export const urlReducer = (state = initial, action: AnyAction) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            const data: any = parseUrl(action.payload.location.search).query;
            const real = queryBaseToReal(data);

            state = { query: real };

            break;
        }
    }

    return state;
};
