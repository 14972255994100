import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { get, map } from 'lodash';
import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { MessageBox } from '../message-box/message-box';
import * as CONSTANTS from '../profile/edit-profile/constants';
import { UserInterface, UserMetaInterface } from '../share/interfaces/user.interface';
import { Dropdown } from '../share/ui-components/dropdown';
import { FilterInput, FilterInputProps } from './filter.input';

export type FilterTypes = FilterInputProps['data'];

const metaInfoFilters: FilterTypes[] = [
    { field: 'name', type: 'custom-name' },
    { field: 'ageStart', field2: 'ageFinish', type: 'custom-age' },
    { field: 'heightStart', field2: 'heightFinish', type: 'custom-measurement', label: 'height', imperial: 'ft', metric: 'cm' },
    { field: 'weightStart', field2: 'weightFinish', type: 'custom-measurement', label: 'weight', imperial: 'lbs', metric: 'kg' },
    { field: 'ethnicity', type: 'multi-select' },
    { field: 'hairColor', type: 'select' },
    { field: 'eyeColor', type: 'select' },
    { field: 'bodyType', type: 'select' },

];
const personalInfoFilters: FilterTypes[] = [
    { field: 'country', type: 'select' },
    { field: 'state', type: 'input' },
    { field: 'city', type: 'input' },
    { field: 'religion', type: 'select' },
    { field: 'educationLevel', type: 'select' },
    { field: 'professionType', type: 'multi-select' },
    { field: 'children', type: 'select' },
    { field: 'livingStatus', type: 'select' },
    { field: 'relationshipStatus', type: 'select' },
    { field: 'languages', type: 'multi-select' },
];
const premiumFilters: FilterTypes[] = [
    { field: 'libido', type: 'select' },
    { field: 'relationshipInterests', type: 'multi-select' },
    { field: 'isSmoker', type: 'select' },
    { field: 'alcoholConsumption', type: 'select' },
    { field: 'drugUsage', type: 'select' },
    { field: 'tattoos', type: 'select' },
    { field: 'worshipFrequency', type: 'select' },
];

export class Filters extends Subscribable<any, any> {
    public user: UserInterface = this.props.user;
    public meta: UserMetaInterface = this.props.user?.meta || {};

    public state = {
        ...this.state,
        reset: false,
        show: false,
    };

    public form = new Form({
        name: new Field({
            label: trans('starsign.results.name'),
            value: '',
            multi: false,
            validators: []
        }),
        city: new Field({
            label: trans('account.data.city'),
            value: '',
            validators: []
        }),
        state: new Field({
            label: trans('account.data.state'),
            value: '',
            validators: []
        }),
        country: new Field({
            label: trans('account.data.country'),
            value: '',
            multi: false,
            options: CONSTANTS.COUNTRY_OPTIONS,
            validators: []
        }),
        heightStart: new Field({
            label: trans('account.data.height'),
            placeholder: trans('account.data.min'),
            value: '',
            validators: []
        }),
        heightFinish: new Field({
            label: '',
            placeholder: trans('account.data.max'),
            value: '',
            validators: []
        }),
        weightStart: new Field({
            label: trans('account.data.weight'),
            placeholder: trans('account.data.min'),
            value: '',
            validators: []
        }),
        weightFinish: new Field({
            label: '',
            placeholder: trans('account.data.max'),
            value: '',
            validators: []
        }),
        ethnicity: new Field({
            label: trans('account.data.ethnicity'),
            value: [],
            multi: true,
            options: map(CONSTANTS.ETHNICITY, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        hairColor: new Field({
            label: trans('account.data.hair.color'),
            value: '',
            multi: false,
            options: map(CONSTANTS.HAIR_COLOR, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        eyeColor: new Field({
            label: trans('account.data.eye.color'),
            value: '',
            multi: false,
            options: map(CONSTANTS.EYE_COLOR, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        bodyType: new Field({
            label: trans('account.data.body.type'),
            value: '',
            multi: false,
            options: map(CONSTANTS.BODY_TYPE, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        relationshipStatus: new Field({
            label: trans('account.data.relationship.status'),
            value: '',
            multi: false,
            options: map(CONSTANTS.RELATIONSHIP_STATUS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),

        languages: new Field({
            label: trans('account.data.languages.label'),
            value: [],
            multi: true,
            options: map(CONSTANTS.LANGUAGES, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        libido: new Field({
            label: trans('account.data.libido'),
            value: '',
            multi: false,
            options: map(CONSTANTS.LIBIDO, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        isSmoker: new Field({
            label: trans('account.data.is.smoker'),
            value: '',
            multi: false,
            options: map(CONSTANTS.SMOKING_HABIT, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        alcoholConsumption: new Field({
            label: trans('account.data.alcohol.consumption'),
            value: '',
            multi: false,
            options: map(CONSTANTS.ALCOHOL, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        drugUsage: new Field({
            label: trans('account.data.drug.usage'),
            value: '',
            multi: false,
            options: map(CONSTANTS.DRUGS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        tattoos: new Field({
            label: trans('account.data.tattoos'),
            value: '',
            multi: false,
            options: map(CONSTANTS.TATTOO, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        ageStart: new Field({
            label: trans('account.data.age'),
            placeholder: trans('account.data.min'),
            value: '',
            validators: []
        }),
        ageFinish: new Field({
            label: '',
            placeholder: trans('account.data.max'),
            value: '',
            validators: []
        }),
        children: new Field({
            label: trans('account.data.children'),
            value: '',
            multi: false,
            options: map(CONSTANTS.CHILDREN, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        livingStatus: new Field({
            label: trans('account.data.living.status'),
            value: '',
            multi: false,
            options: map(CONSTANTS.LIVING_STATUS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        relationshipInterests: new Field({
            label: trans('account.data.relationship.interests'),
            value: [],
            multi: true,
            options: map(CONSTANTS.RELATIONSHIP_TYPE, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        professionType: new Field({
            label: trans('account.data.profession.type'),
            value: [],
            multi: true,
            options: map(CONSTANTS.PROFESSIONS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        religion: new Field({
            label: trans('account.data.religion'),
            value: '',
            multi: false,
            options: map(CONSTANTS.RELIGION, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        worshipFrequency: new Field({
            label: trans('account.data.worship.frequency'),
            value: '',
            multi: false,
            options: map(CONSTANTS.FREQUENCY_OF_WORSHIP, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
        educationLevel: new Field({
            label: trans('account.data.education.level'),
            value: '',
            multi: false,
            options: map(CONSTANTS.EDUCATION, (option) => ({ title: trans(option.title), value: option.value })),
            validators: []
        }),
    });

    public componentDidMount() {
        const country = this.getCountryFilter();

        this.props.filters({ country });

        this.subscriptions$.push(
            this.form.field('ethnicity').value$.subscribe((v: string[]) => {
                if (v?.length && v.length > 2) {
                    this.form.field('ethnicity').setValue(v.slice(0, 2));
                }
            }),
            this.form.field('professionType').value$.subscribe((v: string[]) => {
                if (v?.length && v.length > 5) {
                    this.form.field('professionType').setValue(v.slice(0, 5));
                }
            }),
            this.form.field('languages').value$.subscribe((v: string[]) => {
                if (v?.length && v.length > 5) {
                    this.form.field('languages').setValue(v.slice(0, 5));
                }
            }),
            this.form.field('relationshipInterests').value$.subscribe((v: string[]) => {
                if (v?.length && v.length > 3) {
                    this.form.field('relationshipInterests').setValue(v.slice(0, 3));
                }
            }),
        );

        this.form.field('country').setValue(country);
    }

    public getCountryFilter() {
        const userCountry = this.props.user?.meta?.country;

        if (!userCountry) {
            return null;
        }

        const country = CONSTANTS.COUNTRY_OPTIONS.filter(i => i.value === userCountry)?.[0];

        if (!country) {
            return null;
        }

        return country;
    }

    public parseData() {
        const rawFilters = this.form.toJSON();
        const filters = {};

        for (const key of Object.keys(rawFilters)) {
            if (!rawFilters[key]) {
                continue;
            }
            filters[key] = rawFilters[key];
        }

        return filters;
    }

    public async handleSubmit() {
        this.props.filters(this.parseData());

        if (this.state.show) {
            this.setState({ show: false });
        }
    }

    public renderTrigger(title: string) {
        return <h2 className={'mb-5 mt-6 text-uppercase fs-14 fw-bold'}>{title}</h2>;
    }

    public renderDesktop() {
        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row">
                <div className="w-100 col-24">
                    <Dropdown showByDefault={true} trigger={this.renderTrigger(trans('account.data.personal.info.filters'))}>
                        <div className="row px-7">
                            {(metaInfoFilters || []).map(field => <FilterInput form={this.form} key={field.field} data={field}/>)}
                        </div>
                    </Dropdown>
                    <Dropdown trigger={this.renderTrigger(trans('account.data.background.info.filters'))}>
                        <div className="row px-7">
                            {(personalInfoFilters || []).map(field => <FilterInput reset={this.state.reset} form={this.form} key={field.field} data={field}/>)}
                        </div>
                    </Dropdown>
                    <Dropdown trigger={this.renderTrigger(trans('account.data.miscellaneous.filters'))}>
                        <div className="row px-7">
                            {(premiumFilters || []).map(field => <FilterInput form={this.form} key={field.field} data={field}/>)}
                        </div>
                    </Dropdown>

                    <div className="row mt-8">
                        <div className="col-12 display-flex justify-content-start">
                            <Button type={'button'} onClick={() => this.clearFilter()} className="border-solid hover-opacity size-medium mr-4" title={''}>
                                <span className="material-icons mr-4">clear</span>
                                <span className="palette--c-neutral-5">{trans('account.data.clearFilters')}</span>
                            </Button>
                        </div>
                        <div className="col-12 display-flex justify-content-end">
                            <Button
                                disabled={!!this.props.disabled}
                                type={'submit'}
                                className={`variant-${this.props.disabled ? 'disabled' : 'primary'} size-medium ml-4`}
                            >
                                <span className="material-icons mr-4">search</span>
                                <span className="palette--c-neutral-1">{trans('account.data.showResults')}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <div className="col-24 pt-6">
                        <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                    </div>
                </div>
            </div>}
        </AbstractForm>;
    }

    public renderMobile() {
        return <div className={'mobile-filter'}>
            <div onClick={() => this.setState({ show: !this.state.show })} className={'display-flex justify-content-space-between no-clear-fix align-items-center'}>
                <h2 className={'text-uppercase mb-0 fs-17 fw-bold'}>{trans('account.data.filters')}</h2>
                <span className={'material-icons'}>menu_open</span>
            </div>
            <div className={`dropdown--${this.state.show ? 'show mt-8' : 'hide'} transition--smooth overflow-hidden`}>
                {this.renderDesktop()}
            </div>
        </div>;
    }

    public render() {
        return <div className={'p-box border-radius-px-8 p-7 background-white mb-8'}>
            <div className={'display-none display-md-flex flex-column'}>
                <h2 className={'text-uppercase mb-4 fs-17 fw-bold'}>{trans('account.data.filters')}</h2>
                {this.renderDesktop()}
            </div>
            <div className={'display-md-none display-flex'}>{this.renderMobile()}</div>
        </div>;
    }

    public clearFilter() {
        map(this.form.fields, (field, key) => {
            return field.setValue('');
        });
        this.setState({ reset: !this.state.reset });
    }
}
