import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';

export interface BuyAccessProps {
    show?: boolean;
    children: any;
    className: string;
}

export class BuyAccess extends Subscribable<BuyAccessProps, any> {
    public render() {
        if (this.props.show) {
            return this.props.children;
        }

        return <div className={`blur-children ${this.props.className}`}>
            {this.props.children}
        </div>;
    }
}
