import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { handleError } from '../../share/helpers/util';
import { CompatibilityBoxProps } from './behaviour-compatibility-box';
import { CompatibilityBox } from './extras/compatibility-box';

export class LoveStyleCompatibilityBox extends Subscribable<CompatibilityBoxProps, any> {
    public state = { isPurchased: false };

    public async componentDidMount() {
        this.checkIfPurchased();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.checkIfPurchased();
        }
        if (prevProps.purchases !== this.props.purchases) {
            this.checkIfPurchased();
        }
    }

    public checkIfPurchased() {
        try {
            const result = this.props.purchases?.loveStyleCharts?.find((i: any) => i === this.props.user?._id);

            this.setState({ isPurchased: !!result });
        } catch (e) {
            handleError(e);
        }
    }

    public renderAlternativeContent() {
        return <div className={'w-100 pt-8 position-center'}>
            <img
                className={'analysis-image h-px-185 width-auto'}
                src={'/assets/images/profile/love-style.png'}
                alt={trans('account.data.alt.icon.text')}
            />
        </div>;
    }

    public render() {
        return <CompatibilityBox
            isPurchased={this.state.isPurchased}
            userId={this.props.user?._id || ''}
            viewedUser={this.props.user}
            analysisType={'love-styleQuestionnaire'}
            tabName={'love-style'}
            boxTitle={trans('account.data.loveStyle')}
            type={'love-style-chart'}
            renderAlternativeContent={() => this.renderAlternativeContent()}
        />;
    }
}
