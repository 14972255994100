import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Dropdown } from '@codebuild/cookie-jar/uikit/components/dropdown/dropdown';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import { capitalize, find, get, map } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import { handleNotEnoughBalance } from '../../share/helpers/util';
import { Spinner } from '../../spinner';
import './compatibility-details.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class CompatibilityDetails extends Subscribable<any, any> {
    public languageDropdown$: any;
    public descriptionDetailsModal$: any;
    public langs = ['En', 'Hu', 'Ro'];

    public state: any = {
        result: this.props.result,
        error: null,
        currentLanguage: capitalize(TranslationLib.getLang()),
        showTooltip: '',
    };

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.result !== this.props.result) {
            this.setState({ result: this.props.result });
        }
    }

    public render(): React.ReactNode {
        return <div className={'CompatibilityDetails'}>
            {this.renderResultActions(get(this.state, 'result'))}
            <Modal
                hideClose={true}
                ref={(ref) => this.descriptionDetailsModal$ = ref}
                content={() => this.renderDetailsModalContent()}
                wrapperClasses="DescriptionPreviewModal palette--bgc-neutral-1 elevation-2 border-radius-2"
                footer={() => null}
            />
        </div>;
    }

    public openDetailsModal(result) {
        this.setState({ resultDetails: result }, () => this.descriptionDetailsModal$.open());
    }

    public closeDetailsModal() {
        this.setState({ resultDetails: null }, () => this.descriptionDetailsModal$.close());
    }

    public renderDetailsModalContent() {
        return <div className={'p-box'}>
            <div className={'w-100 mb-8 display-flex align-items-center justify-content-space-between px-6 py-4 border-radius-1'}>
                <h4 className={'text--h3 pb-4'}>{trans('account.data.description')}</h4>
            </div>
            <div className={'DetailsLangSelector mb-8'}>
                <Dropdown
                    className="DropdownButton"
                    backdropClasses=""
                    ref={(ref: Dropdown) => (this.languageDropdown$ = ref)}
                    direction="left"
                    renderDropdown={(ctx: Dropdown) => this.renderDropdownBox()}
                    renderContent={() => this.dropdownContent()}
                />
            </div>
            <div className={'mb-8'}>
                {this.getDetailsToLang()}
            </div>
            {(this.state.error) && <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>}
            <div className={'display-flex justify-content-end'}>
                <Button className={'variant-link size-medium'} onClick={() => this.closeDetailsModal()}>{trans('global.buttons.close')}</Button>
            </div>
        </div>;
    }

    public renderButtonText(text: string, credit: number) {
        const creditText = trans(credit > 1 ? 'global.label.credits' : 'global.label.credit');
        return <span>{trans(text)} <span className={'text-italic'}>({credit} {creditText})</span></span>;
    }

    public getTitle() {
        return this.props.primaryButton ? 'account.data.read.description' : 'starsign.result.get';
    }

    public renderCreditButtonContent() {
        const title = this.getTitle();
        return <div className={'display-flex'}>
            <span>{this.state.isLoading ? <div className={'pl-4'}><Spinner/></div> : this.renderButtonText(title, 2)}</span>
        </div>;
    }

    public getDetailsToLang() {
        const lang = get(this.state, 'currentLanguage');

        if (this.hasPaid(this.state.result, lang)) {
            const chunks = get(this.state, `result.publicResults.details${lang}`, '').split('\n');

            return chunks.map((item, index) => <p className="pb-4" key={index} dangerouslySetInnerHTML={{ __html: item }}/>);
        }

        if (this.hasGenerateAble(this.state.result, lang)) {
            return <div>
                <div className={'mb-8'}>
                    <span>{get(this.state, `result.publicResults.detailsPreview${lang}`)}</span>
                </div>

                <div className={'py-6 mb-6 border-top-1 border-bottom-1 palette--bc-neutral-4 display-flex align-items-start justify-content-space-between'}>
                    <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                        <span className={'material-icons palette--c-blue-2 mr-4'}>info</span>
                        <div>
                            <p className={'fs-large'}>{trans('starsign.resultinfo.title')}</p>
                            <span>{trans('starsign.resultinfo.desc')}</span>
                        </div>
                    </div>
                    <div>
                        {/*<h4>{`${get(find(get(this.state, 'result.generatableResults'), (r) => r.key === `details${lang}`), 'cost')} credit`}</h4>*/}
                    </div>
                </div>

                <Button
                    onClick={() => this.payResult(`details${lang}`)}
                    title={this.renderCreditButtonContent()}
                    className={'variant-primary size-medium'}
                />
            </div>;
        }

        return <div>
            <div className={'py-6 mb-6 border-top-1 border-bottom-1 palette--bc-neutral-4 display-flex align-items-start justify-content-space-between'}>
                <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                    <span className={'material-icons palette--c-blue-2 mr-4'}>info</span>
                    <div>
                        <p className={'fs-large'}>{trans('starsign.noresult.title')}</p>
                        <span>{trans('starsign.noresult.desc')}</span>
                    </div>
                </div>
            </div>
        </div>;
    }

    public dropdownContent() {
        return <div onClick={() => this.languageDropdown$.toggle()}>
            <div className={'LanguageDropdown palette--bc-neutral-3 border-1 py-4 px-6 border-radius-1 display-inline-flex justify-content-center align-items-center cursor-pointer'}>
                {this.getCurrentLang()}
                <span className={'ml-8 material-icons'}>keyboard_arrow_down</span>
            </div>
        </div>;
    }

    public getCurrentLang() {
        const lang = (get(this.state, 'currentLanguage') as string).toLowerCase() || 'en';

        return trans(`global.language.${lang}`);
    }

    public renderDropdownBox() {
        return <div className={'p-box DropdownBox'}>
            {this.renderLanguageItem('Hu')}
            {this.renderLanguageItem('En')}
            {this.renderLanguageItem('Ro')}
        </div>;
    }

    public renderLanguageItem(lang: 'Hu' | 'Ro' | 'En') {
        return <div
            className={'mb-4 display-flex justify-content-space-between'}
            onClick={() => this.setState({ currentLanguage: lang }, () => this.languageDropdown$.close())}
        >
            <span className={'fs-large flex-fill mr-8'}>{trans(`global.language.${lang.toLowerCase()}`)}</span>
            {this.isPaid(this.state.result, lang)}
        </div>;
    }

    public isPaid(result, lang: 'Hu' | 'Ro' | 'En') {
        const hasGenerateAble = this.hasGenerateAble(this.state.result, lang);
        if (!hasGenerateAble) {
            return <div className={'palette--bgc-neutral-3 border-radius-1 py-2 px-3 w-100'}>
                <span className=" palette--c-neutral-5 fs-14">{trans('starsign.result.comingsoon')}</span>

            </div>;
        }

        const isPaid = get(result, `publicResults.details${lang}`);

        if (!isPaid) {
            return <div className="border-radius-1 py-2 px-3 palette--bgc-primary-5 w-100 position-center">
                <span className="palette--c-neutral-1 fs-14">2 {trans('global.label.credits')}</span>
            </div>;
        }

        return null;
    }

    public renderPopOver(tooltip: string) {
        return <div className={'popup--star-sign'}>
            <span className={'fs-12 py-2 px-7'}>{trans(tooltip)}</span>
        </div>;
    }

    public async payResult(details: string) {
        try {
            this.setState({ error: null });
            const id = get(this.state, 'result._id');
            const data = { resultTypes: [details] };

            await Repository.post(`/competition/${id}/pay`, data);

            await this.refetchResult();
        } catch (err) {
            this.setState({ error: err });

            handleNotEnoughBalance(err);
        }
    }

    public async refetchResult() {
        try {
            this.setState({ error: null });
            const result = await Repository.get(`/competition/${this.state.result._id}`);

            if (!result) {
                throw new Error('something went wrong');
            }

            this.setState({ result });
        } catch (error) {
            this.setState({ error });
        }
    }

    public hasGenerateAble(result, lang?: string) {
        let hasDetails = false;

        if (!result?.generatableResults) {
            return false;
        }

        if (!!lang) {
            const details = find(result.generatableResults, (r) => r.key === `details${lang}` && r.generatable);
            const preview = find(result.generatableResults, (r) => r.key === `detailsPreview${lang}` && r.generatable);
            return (details && preview);
        }
        map(this.langs, (l) => {
            const details = find(result.generatableResults, (r) => r.key === `details${l}` && r.generatable);
            const preview = find(result.generatableResults, (r) => r.key === `detailsPreview${l}` && r.generatable);
            hasDetails = hasDetails || (details && preview);
        });

        return hasDetails;
    }

    public hasPaid(result, lang?: string) {
        if (!lang) {
            let hasDetails = false;

            map(this.langs, (l) => {
                hasDetails = hasDetails || get(result, `publicResults.details${l}`);
            });

            return hasDetails;
        }

        return get(result, `publicResults.details${lang}`);
    }

    public renderResultActions(result) {
        return <div>
            {this.renderBuyButton(result)}
        </div>;
    }

    public renderBuyButton(result) {
        if (!!(this.hasPaid(result))) {
            const btnClasses = this.props.primaryButton ? 'variant-primary size-medium' : 'variant-outline size-medium';

            return <Button
                onClick={() => this.openDetailsModal(result)}
                className={btnClasses}
                title={trans(this.getTitle())}
            />;
        }

        if (!this.hasGenerateAble(result)) {
            return this.props.primaryButton ? <>
                <Button
                    onMouseOver={() => this.setState({ showTooltip: true })}
                    onMouseOut={() => this.setState({ showTooltip: false })}
                    className={'variant-disabled size-medium ComingSoonButton'}
                    title={this.renderCreditButtonContent()}
                />
                {this.state.showTooltip && this.renderPopOver('you.need.to.purchase.the.calculation.first')}
            </> : null;
        }

        return <Button
            onClick={() => this.openDetailsModal(result)}
            title={this.renderCreditButtonContent()}
            className={'variant-primary size-medium'}
        />;
    }
}
