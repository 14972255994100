import { randomId } from '@codebuild/cookie-jar/uikit/libs/random-id';
import * as d3 from 'd3';
import { range } from 'lodash';
import * as React from 'react';
import { Subject } from 'rxjs';

import { Subscribable } from '../../libs/subscribable';

export interface GaugeChartProps {
    value: number;
    isAnimated: boolean;
    noResize?: boolean;
}

export class GaugeChart extends Subscribable<GaugeChartProps, any> {
    public static readonly BACKGROUND_DATA = require('./background');
    public static readonly NUMBER_OF_DOTS = 44;
    public static readonly DOT_PADDING_FACTOR = 0.025;
    public static readonly DOT_SIZE_FACTOR = 0.01;
    public static readonly GAUGE_PADDING_FACTOR = 0.03;
    public static readonly START_ANGLE = -170;
    public static readonly START_ANGLE_GAP = 0;
    public static readonly END_ANGLE = 170;
    public static readonly DR_FACTOR = 0.06;
    public static readonly TEXT_SCALE_FACTOR = 0.25;

    public resizeListener;
    public resize$ = new Subject();

    public $root: HTMLElement;
    public $rootSelection: d3.Selection<any, any, any, any>;
    public $rootSvg: d3.Selection<any, any, any, any>;

    public gaugeArc: any;
    public $gauge: d3.Selection<any, any, any, any>;
    public $background: d3.Selection<any, any, any, any>;

    public $textGroup: d3.Selection<any, any, any, any>;
    public $text: d3.Selection<any, any, any, any>;

    public componentDidMount() {
        this.$rootSelection = d3.select(this.$root);
        if (!this.props.noResize) {
            // this.resizeListener = jQuery(window).on('resize', () => this.resize$.next());
            //
            // this.subscriptions$.push(
            //     this.resize$.pipe(debounceTime(400)).subscribe(() => this.resize())
            // );
        }

        this.init();
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.updateValue(this.props.value);
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount();

        if (!this.props.noResize) {
            // this.resizeListener.off();
        }
    }

    public resize() {
        this.$rootSvg.remove();
        this.init();
    }

    public init() {
        this.initSvg();
        this.initGaugeBackground();
        this.initGauge();
        this.initText();
        this.initDots();

        if (this.props.value) {
            this.updateValue(this.props.value);
        }
    }

    public render(): React.ReactNode {
        return <div ref={(ref: any) => (this.$root = ref)}/>;
    }

    public getRootWidth() {
        return Math.max(this.$root.offsetWidth, 0);
    }

    public getRootHeight() {
        return Math.max(this.$root.offsetWidth, 0);
    }

    public initSvg() {
        const svgRoot = this.$rootSelection
            .append('svg')
            .attr('width', this.getRootWidth())
            .attr('height', this.getRootHeight())
            .append('g');
        // const filters = svgRoot
        //     .append('defs')
        //     .append('filter')
        //     .attr('id', 'blur');
        //
        // filters.append('feGaussianBlur').attr('stdDeviation', 8);

        this.$rootSvg = svgRoot;
    }

    public initDots() {
        const rotateStep = 360 / GaugeChart.NUMBER_OF_DOTS;
        const padding = GaugeChart.DOT_PADDING_FACTOR * this.getRootWidth();
        const size = GaugeChart.DOT_SIZE_FACTOR * this.getRootWidth();

        const x = this.getRootWidth() / 2;
        const y = this.getRootHeight() / 2;

        for (const item of range(GaugeChart.NUMBER_OF_DOTS)) {
            const group = this.$rootSvg.append('g')
                .attr('transform', `translate(${x}, ${y}), rotate(${rotateStep * item})`);

            group.append('circle')
                .attr('r', size)
                .attr('cx', -(x - padding - (size / 2)))
                .attr('fill', 'rgb(210,97,93)');
        }
    }

    public initText() {
        this.$textGroup = this.$rootSvg
            .append('g');

        this.$text = this.$textGroup
            .append('text')
            .attr('fill', 'rgb(53, 60, 78)')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'start')
            .attr('font-size', this.getRootWidth() * GaugeChart.TEXT_SCALE_FACTOR)
            .attr('font-weight', 900)
            .text('0%');

        this.translateText();
    }

    public initGaugeBackground() {
        const padding = GaugeChart.GAUGE_PADDING_FACTOR * this.getRootWidth();
        const r = (this.getRootWidth() / 2) - (padding * 2);
        const x = (this.getRootWidth() / 2);
        const y = (this.getRootHeight() / 2);
        const dr = (this.getRootWidth() * GaugeChart.DR_FACTOR);

        const arc = d3.arc()
            .outerRadius(r)
            .innerRadius(r - dr)
            .startAngle(this.degToRad(-180))
            .endAngle(this.degToRad(180));

        const parent = this.$rootSvg
            .append('g')
            .attr('transform', `translate(${x}, ${y})`);

        parent.append('path')
            .style('fill', '#e8e8e8')
            .attr('d', arc);
    }

    public initGauge() {
        const padding = GaugeChart.GAUGE_PADDING_FACTOR * this.getRootWidth();
        const r = (this.getRootWidth() / 2) - (padding * 2);
        const x = (this.getRootWidth() / 2);
        const y = (this.getRootHeight() / 2);
        const dr = (this.getRootWidth() * GaugeChart.DR_FACTOR);

        this.gaugeArc = d3.arc()
            .outerRadius(r)
            .innerRadius(r - dr)
            .cornerRadius(dr)
            .startAngle(this.degToRad(GaugeChart.START_ANGLE));

        const parent = this.$rootSvg
            .append('g')
            .attr('transform', `translate(${x}, ${y})`);

        const ID = `gauge-clip-path-${randomId()}`;

        this.$gauge = parent
            .append('clipPath')
            .attr('id', ID)
            .append('path')
            .datum({
                endAngle: this.degToRad(GaugeChart.START_ANGLE + GaugeChart.START_ANGLE_GAP)
            })
            .attr('d', this.gaugeArc);

        this.$background = parent
            .append('svg:image')
            .attr('opacity', 0)
            .attr('fill', 'red')
            .attr('width', (r + 1) * 2)
            .attr('y', -(r + 1))
            .attr('x', -(r + 1))
            .attr('xlink:href', GaugeChart.BACKGROUND_DATA)
            .attr('clip-path', `url(#${ID})`);
    }

    public updateValue(value: number) {
        const angle = this.degToRad(d3.interpolate(GaugeChart.START_ANGLE + GaugeChart.START_ANGLE_GAP, GaugeChart.END_ANGLE)(value));

        this.$gauge
            .transition()
            .duration(!this.props.isAnimated ? 0 : 18000)
            .call((transition, v) => {
                transition.attrTween('d', (d) => (t) => {
                    d.endAngle = d3.interpolate(d.endAngle, angle)(t);

                    return this.gaugeArc(d);
                });
            });

        this.$text
            .transition()
            .duration(!this.props.isAnimated ? 0 : 5000)
            .call((transition) => {
                transition.attrTween('text', (d, a, node) => {
                    const selection = d3.select(node[0]);
                    const oldValue = selection.html().replace('%', '');
                    const interpolator = d3.interpolate(oldValue, (value * 100) as any);

                    return (t) => {
                        selection.text(`${Math.round(interpolator(t))}%`);
                        return d;
                    };
                });
            });

        this.$background
            .transition()
            .delay(30)
            .duration(200)
            .attr('opacity', 1);

    }

    public degToRad(deg) {
        return deg * Math.PI / 180;
    }

    public reset() {
        const angle = this.degToRad(d3.interpolate(GaugeChart.START_ANGLE + GaugeChart.START_ANGLE_GAP, GaugeChart.END_ANGLE)(0));

        this.$gauge
            .transition()
            .duration(0)
            .call((transition, v) => {
                transition.attrTween('d', (d) => (t) => {
                    d.endAngle = d3.interpolate(d.endAngle, angle)(t);
                    return this.gaugeArc(d);
                });
            });

        this.$text
            .transition()
            .duration(0)
            .call((transition) => {
                transition.attrTween('text', (d, a, node) => {
                    const selection = d3.select(node[0]);

                    const oldValue = selection.html().replace('%', '');

                    const interpolator = d3.interpolate(oldValue, (0) as any);

                    return (t) => {
                        selection.text(`${Math.round(interpolator(t))}%`);

                        return d;
                    };
                });
            });

        return;

        if (this.props.value) {
            this.updateValue(this.props.value);
        }
    }

    private translateText() {
        const height = this.$textGroup.node().getBoundingClientRect().height;
        const textTranslateY = (this.getRootHeight() / 2) + (height / 4);

        this.$textGroup.attr('transform', `translate(${(this.getRootWidth()) / 2}, ${textTranslateY})`);
    }
}
