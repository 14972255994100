import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';

export function translationGetName(firstName, lastName) {
    const lang = TranslationLib.getLang();

    if (['hu'].includes(lang.toString().toLowerCase())) {
        return `${lastName} ${firstName}`;
    }

    return `${firstName} ${lastName}`;
}
