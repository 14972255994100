import { generateLink } from '../../libs/generate-link';
import { routes } from '../../routes';

module.exports = [
    {
        title: 'global.menu.group',
        icon: null,
        image: null,
        link: null,
        subs: [
            {
                title: 'global.menu.assessments',
                icon: null,
                image: '/assets/images/menu-icons/couples-assesment.webp',
                link: generateLink(routes.coupleAssessments)
            },
            {
                title: 'global.menu.matchmaking',
                icon: null,
                image: '/assets/images/menu-icons/match-finder.webp',
                link: generateLink(routes.matchmakingResults),
            },

            {
                title: 'global.menu.questionnaires',
                icon: null,
                image: '/assets/images/menu-icons/self-analysis.webp',
                link: generateLink(routes.questionnaires)
            },
            {
                title: 'global.menu.starsign',
                icon: null,
                image: '/assets/images/menu-icons/star-sign.webp',
                link: generateLink(routes.starSign),
                linkAuthorized: generateLink(routes.compatibilityTest)
            },
        ]
    }
];
