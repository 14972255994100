import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Dropdown } from '@codebuild/cookie-jar/uikit/components/dropdown/dropdown';
import { map } from 'lodash';
import * as React from 'react';
import { UserActions } from '../../actions/user.actions';
import './currency-selector.scss';

export const currencies = ['huf', 'eur', 'usd', 'gbp', 'aud', 'nzd', 'cad', 'ron'];

const mapStateProps = (store: any) => ({
    currency: store.authentication.currency,
});

const mapDispatchProps = (dispatch: any) => ({
    setCurrency: (currency) => dispatch(UserActions.setCurrency(currency)),
});

@connect(mapStateProps, mapDispatchProps)
export class CurrencySelector extends React.Component<any, any> {
    public $dropdown: Dropdown;
    public state: any = {
        currentCurrency: this.props.currency
    };

    public render(): React.ReactNode {
        return <div className={'CurrencySelector'}>
            <Dropdown
                className="DropdownButton"
                backdropClasses=""
                ref={(ref: Dropdown) => (this.$dropdown = ref)}
                direction="right"
                renderDropdown={(ctx: Dropdown) => this.renderDropdownBox()}
                renderContent={() => this.dropdownContent()}
            />
        </div>;
    }

    public dropdownContent() {
        return <div onClick={() => this.$dropdown.toggle()}>
            <span className={'palette--c-neutral-5 fw-bold display-flex justify-content-center align-items-center cursor-pointer text-uppercase'}>
                {this.getCurrentCurrency()}
                <span className={'material-icons ml-2'}>keyboard_arrow_down</span></span>
        </div>;
    }

    public renderDropdownBox() {
        return <div className={'p-box display-flex flex-column palette--bgc-neutral-1 elevation-1 border-radius-1'}>
            <h6 className={'fs-small text-nowrap text-uppercase palette--c-neutral-5 mb-3'}>{trans('topup.currency.title')}</h6>
            <div className={'display-flex flex-column CurrencyOptions'}>
                {map(currencies, (value) => this.renderOption(value))}
            </div>
        </div>;
    }

    public isCurrent(currency: string) {
        return this.state.currentCurrency === currency;
    }

    public renderOption(currency: string) {
        const cur = currency || 'usd';
        return <div onClick={() => this.setCurrency(currency)} className={`py-2 ${this.isCurrent(currency) ? 'active' : ''} CurrencyOption`}>
            <span className={'material-icons fs-medium mr-2'}>{this.isCurrent(currency) ? 'check_circle' : 'panorama_fish_eye'}</span>
            <span className={'text-uppercase'}>{cur}</span>
        </div>;
    }

    public setCurrency(currency: string) {
        this.setState({ currentCurrency: currency }, () => {
            this.props.setCurrency(currency);
        });
    }

    public getCurrentCurrency() {
        if (!this.props.currency) {
            this.setCurrency('usd');
            return 'usd';
        }

        return this.props.currency;
    }

}
