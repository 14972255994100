import { FacebookAuthCredentialInterface } from '@codebuild/cookie-jar/libs/authentication/facebook/facebook-auth.interface';
import { SocialAuthInterface, SocialAuthSignInResponseInterface } from '@codebuild/cookie-jar/libs/authentication/social-auth.interface';
import { sleep } from '@codebuild/cookie-jar/libs/sleep';

declare const window: any;

export class FacebookAuth implements SocialAuthInterface {
    public async initialize(credentials: FacebookAuthCredentialInterface): Promise<void> {
        try {
            await this.download();

            window.FB.init({
                appId: credentials.appId,
                cookie: true,
                xfbml: true,
                version: 'v14.0'
            });

            return Promise.resolve();
        } catch (err) {
            return Promise.reject('FacebookAuthInitializeError');
        }
    }

    public async signIn(scopes: string[] = ['email'], fields: string[] = ['email']): Promise<SocialAuthSignInResponseInterface> {
        await this.waitUntilInitialized();

        return new Promise((resolve, reject) => {
            try {
                const options = {
                    scope: scopes.join(','),
                    return_scopes: true,
                };

                window.FB.login((response) => {
                    if (response.status !== 'connected') {
                        return reject('FacebookAuthNotAuthorized');
                    }

                    window.FB.api(`/me?fields=${fields.join(',')}`, (meResponse) => {
                        if (!!meResponse?.errors || !!meResponse?.error) {
                            return reject('FacebookAuthMeError');
                        }

                        return resolve({
                            accessToken: response.authResponse.accessToken,
                            me: meResponse,
                            raw: response.authResponse
                        });
                    });
                }, options);
            } catch (e) {
                reject('FacebookAuthNotAuthorized');
            }
        });
    }

    public async signOut(): Promise<void> {
        await this.waitUntilInitialized();

        window.FB.logout();

        return Promise.resolve();
    }

    private download(): Promise<void> {
        if (window.FB) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const fjs = document.getElementsByTagName('script')[0];
            const js = document.createElement('script');

            js.onload = () => resolve();
            js.id = 'facebook-jssdk';
            js.src = 'https://connect.facebook.net/en_US/sdk.js';

            if (!fjs.parentNode) {
                return reject('FacebookAuthInitializationError');
            }

            fjs.parentNode.insertBefore(js, fjs);
        });
    }

    private async waitUntilInitialized(attempts = 0) {
        if (window.FB) {
            return;
        }

        if (attempts >= 3) {
            return Promise.reject('FacebookAuthInitializationTimeout');
        }

        await sleep(300);

        return this.waitUntilInitialized(attempts + 1);
    }
}
