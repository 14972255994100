import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { GaugeWrapper } from '../../gauge-chart/gauge-wrapper';
import { CompatibilityBoxProps } from './behaviour-compatibility-box';
import { CompatibilityBox } from './extras/compatibility-box';

export class PersonalityCompatibilityBox extends Subscribable<CompatibilityBoxProps, any> {
    public async componentDidMount() {
        this.checkIfPurchased();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.checkIfPurchased();
        }
        if (prevProps.purchases !== this.props.purchases) {
            this.checkIfPurchased();
        }
    }

    public renderGauge(compatibility: number, isAnimated: boolean) {
        if (isAnimated) {
            return <div className={'w-100 position-center'}>
                <GaugeWrapper
                    isAnimated={true}
                    value={compatibility}
                    width={150}
                    height={150}
                />
            </div>;
        }

        return <div className={'w-100 position-center'}>
            <GaugeWrapper
                isAnimated={false}
                value={compatibility}
                width={150}
                height={150}
            />
        </div>;
    }

    public checkIfPurchased() {
        return !!this.props.purchases?.personalityCompatibility?.find((i: any) => i === this.props.user?._id);
    }

    public checkIfCalculated() {
        return !!this.props.purchases?.personalityCharts?.find((i: any) => i === this.props.user?._id);
    }

    public render() {
        return <CompatibilityBox
            isPurchased={this.checkIfPurchased()}
            isCalculated={this.checkIfCalculated()}
            userId={this.props.user?._id || ''}
            viewedUser={this.props.user}
            analysisType={'personalityQuestionnaire'}
            tabName={'personality'}
            boxTitle={trans('account.data.personality.questionnaire')}
            type={'personality-chart'}
            calcType={'personality-compatibility'}
            renderGaugeContent={(compatibility, isAnimated) => this.renderGauge(compatibility, isAnimated)}
        />;
    }
}
