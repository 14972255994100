import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Dropdown } from '@codebuild/cookie-jar/uikit/components/dropdown/dropdown';
import { get } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../../libs/generate-link';
import { getLanguage } from '../../../libs/get-language';
import { Subscribable } from '../../../libs/subscribable';
import { routes } from '../../../routes';
import { translationGetName } from '../../../translation/translation-helper';
import { Logout } from '../../authentication/logout/logout';
import './profile-menu.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class ProfileMenu extends Subscribable<any, any> {
    public $dropdown: Dropdown;

    public render(): React.ReactNode {
        return <div className={'ProfileMenu'}>
            <Dropdown
                className="DropdownButton DropdownWithArrow"
                backdropClasses=""
                ref={(ref: Dropdown) => (this.$dropdown = ref)}
                direction="right"
                renderDropdown={(ctx: Dropdown) => this.renderDropdownBox()}
                renderContent={() => this.dropdownContent()}
            />
        </div>;
    }

    public dropdownContent() {
        return <div className={'ProfileButton'}>
            <Button onClick={() => this.$dropdown.toggle()} className={'ProfileDropdownButton size-medium border-radius-50'}>
                <div className="display-flex align-items-center justify-content-center">
                    <img src={get(this.props, 'user.meta.photo') || '/assets/images/profile/profile-image-empty-sm.webp'} className={'mr-4 ProfileImage'} alt={'me-profile photo'}/>
                    <span className={'material-icons palette--c-neutral-5 fs-large'}>keyboard_arrow_down</span>
                </div>
            </Button>
        </div>;
    }

    public redirect() {
        const langInParams = getLanguage();
        const langInLocale = TranslationLib.getLang();

        window.location.href = `/${langInParams || langInLocale || 'en'}/topup`;
    }

    public getInvitationUrl() {
        const lang = (TranslationLib.getLang() || 'en').toLowerCase();

        return lang === 'hu' ? '/hu/meghivo-kuldes' : '/en/invite-friend';
    }

    public renderDropdownBox() {
        return <div className={'DropdownBox'}>
            <div className={'display-flex flex-column'}>
                <div className={''}>
                    <div className="display-flex flex-column mb-4">
                        <div className={'display-flex flex-row justify-content-start align-items-center'}>
                            <span className={'fw-bold palette--c-nautral-6'}> {translationGetName(get(this.props, 'user.meta.firstName', ''), get(this.props, 'user.meta.lastName', ''))}</span>
                        </div>
                        <div>
                            <span className={'fw-bold fs-small palette--c-nautral-5'}>{get(this.props, 'user.email', '')}</span>
                        </div>
                    </div>
                    <div className="display-flex flex-column py-4 px-4 palette--bgc-neutral-3 border-radius-1">
                        <div className={'display-flex flex-row justify-content-space-between align-items-center'}>
                            <div className={'display-flex flex-row justify-content-start align-items-center flex-fill'}>
                                <span className={'text-uppercase fs-small palette--c-neutral-5 mr-3'}>{trans('global.balance')}:</span>
                                <span className={'fw-bold'}>{`${get(this.props, 'user.meta.balance')} ${trans('global.credit')}`}</span>
                            </div>
                            <Button
                                onClick={() => this.redirect()}
                                title={trans('global.accountmenu.topup')}
                                className={'variant-outline size-small border-radius-4'}
                            />
                        </div>
                    </div>
                </div>
                <div className={'border-bottom-1 palette--bc-neutral-3 py-4'}>
                    <div>
                        <Link to={this.getInvitationUrl()} className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-start align-items-center'}>
                            <span className={'material-icons fs-normal palette--c-neutral-5 mr-2'}>email</span> <span className={'fs-normal palette--c-neutral-5'}>{trans('account.data.invite.friend')}</span>
                        </Link>
                    </div>
                </div>
                <div className={'border-bottom-1 palette--bc-neutral-3 py-4'}>
                    <div>
                        <Link to={generateLink(routes.profile)} className={'text-decoration-none palette--c-neutral-5 display-flex justify-content-start align-items-center'}>
                            <span className={'material-icons fs-normal palette--c-neutral-5 mr-2'}>account_circle</span> <span className={'fs-normal palette--c-neutral-5'}>{trans('global.accountmenu.accountDetails')}</span>
                        </Link>
                    </div>
                </div>
                <div className="display-flex flex-column py-4">
                    <Logout/>
                </div>
            </div>
        </div>;
    }

}
