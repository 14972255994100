import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { CompatibilityProfileCard, MatchedUser } from '../profile-listings/compatiblity-profile-card';
import { UserInterface, UserMetaInterface } from '../share/interfaces/user.interface';
import { OverlaySpinner } from '../share/ui-components/overlay-spinner';
import { SeeMorePaginator } from './see-more-paginator';

export class MatchmakingResults extends Subscribable<any, any> {
    public user: UserInterface = this.props.user;
    public meta: UserMetaInterface = this.props.user?.meta || {};

    public state = {
        isPurchased: false,
        error: '',
        match: null,
        profiles: [],
        total: 0,
        currentPage: 1,
        fetchPage: 0,
        isLoading: false
    };

    public async componentDidMount() {
        await this.getProfiles(0);
    }

    public async getProfiles(nextPage: number) {
        try {
            this.setState({ isLoading: true });
            const response = await Repository.get('/user/matches', { ...(this.props.filters || {}), page: nextPage });

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({
                profiles: [...this.state.profiles, ...(response.items || [])],
                total: response.total,
                isLoading: false
            });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public renderMatches() {
        if (!this.state.profiles?.length) {
            return <div className={'w-100 position-center py-8 background-white '}>
                <h3 className={'text--h3'}>{trans('account.data.noMatches')}</h3>
                <Button
                    className={'to-invite-btn w-px-184 ml-7'}
                    title={trans('account.edit.invite.friends')}
                    onClick={() => window.location.href = 'invite-friend'}
                />
            </div>;
        }

        return <div className={'row w-100'}>
            {this.state.profiles.map((profile: MatchedUser, index) => {
                return <div
                    key={profile._id}
                    className={'col-24 col-xms-12 col-md-6 col-lg-4 min-width-180'}
                >
                    <CompatibilityProfileCard
                        key={index}
                        profile={profile}
                    />
                </div>;
            })}
        </div>;
    }

    public render() {
        return <div className={'w-100 tab-content p-3'}>
            <OverlaySpinner isLoading={this.state.isLoading}/>
            <div className={'py-4 row w-100'}>
                {this.renderMatches()}
            </div>
            <SeeMorePaginator
                loadMore={(nextPage) => this.getProfiles(nextPage)}
                totalProfiles={this.state.total}
                profileListLength={(this.state.profiles || []).length}
                currentPage={this.state.currentPage}
            />
        </div>;
    }
}
