import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { getImageSrc } from '../share/helpers/util';
import { Hr } from '../share/ui-components/hr';

export interface Profile {
    photo?: string;
    name: string;
    gender: string;
    id?: string;
    isProfilePublic?: boolean;
}

export interface ProfileCardProps {
    profile: Profile;
}

export class ProfileCard extends Subscribable<ProfileCardProps, any> {
    public renderTopPart({ photo, name, gender, isProfilePublic }: Profile) {
        const fontSize = name.length > 20 ? 15 : 17;

        return <div className={'display-flex flex-column align-items-center justify-content-center'}>
            <div
                className={'img-wrapper img-wrapper--round-small'}
                style={{ backgroundImage: `url(${getImageSrc(photo, gender)})`, opacity: isProfilePublic ? 1 : 0.7 }}
            />
            <h4 className={`text--h3 mt-4 fs-${fontSize} fix-height-54`}>{name}</h4>
        </div>;
    }

    public renderBottomPart({ isProfilePublic }: Profile) {
        return <div className={'position-center w-100'}>
            <Button
                className={`variant-${isProfilePublic ? 'contact' : 'disabled'} size-large hover-opacity`}
                title={trans('account.data.view.profile')}
            />
        </div>;
    }

    public renderCard(profile: Profile) {
        return <div className={'border-radius-px-8 p-7 background-white mb-5 hover-scale'}>
            {this.renderTopPart(profile)}
            <Hr className={'mb-7'}/>
            {this.renderBottomPart(profile)}
        </div>;
    }

    public render() {
        const lang = TranslationLib.getLang() || 'en';
        const { profile } = this.props;

        return <a className={'text-decoration-none'} href={`/${lang}/users/${profile.id}`}>{this.renderCard(profile)}</a>;
    }
}
