import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get, map } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import { ErrorModal } from '../../share/ui-components/error.modal';
import { CustomMultiInput } from './extras/custom-multi-input';
import { UrlValidator } from './libs/url.validator';

export type Social = 'instagram' | 'twitter' | 'facebook' | 'tiktok' | 'snapchat' | 'linkedin';

export class ContactInfoForm extends Subscribable<any, any> {
    public $errorModal: ErrorModal;
    public state = {
        errorMessage: ''
    };
    public form = new Form({
        firstName: new Field({
            label: trans('account.edit.firstName'),
            value: this.props.meta?.firstName || '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        lastName: new Field({
            label: trans('account.edit.lastName'),
            value: this.props.meta?.lastName || '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        mobile: new Field({
            label: trans('account.data.mobile'),
            value: this.props.meta?.mobile || '',
            validators: []
        }),
        facebook: new Field({
            label: trans('account.data.facebook'),
            value: this.props.meta?.facebook || '',
            validators: []
        }),
        instagram: new Field({
            label: trans('account.data.instagram'),
            value: this.props.meta?.instagram || '',
            validators: []
        }),
        twitter: new Field({
            label: trans('account.data.twitter'),
            value: this.props.meta?.twitter || '',
            validators: []
        }),
        tiktok: new Field({
            label: trans('account.data.tiktok'),
            value: this.props.meta?.tiktok || '',
            validators: []
        }),
        linkedin: new Field({
            label: trans('account.data.linkedin'),
            value: this.props.meta?.linkedin || '',
            validators: []
        }),
        snapchat: new Field({
            label: trans('account.data.snapchat'),
            value: this.props.meta?.snapchat || '',
            validators: []
        }),
        other: new Field({
            label: trans('account.data.other'),
            value: this.props.meta?.other || [],
            validators: [
                // UrlValidator.urlValidator(trans('account.data.error.invalid.url.format'))
            ]
        }),
    });

    public setForm() {
        map(this.form.fields, (field, key) => {
            return field.setValue(get(this.props, `meta[${key}]`));
        });
    }

    public formatUrl(value: string, type: Social) {
        if (!value) {
            return '';
        }

        if (UrlValidator.httpsRegex.test(value)) {
            return value;
        }

        switch (type) {
            case 'facebook':
                return `https://www.facebook.com/${value}`;
            case 'instagram':
                return `https://www.instagram.com/${value}`;
            case 'linkedin':
                return `https://www.linkedin.com/in/${value}`;
            case 'snapchat':
                return `https://www.snapchat.com/${value}`;
            case 'tiktok':
                return `https://www.tiktok.com/${value}`;
            case 'twitter':
                return `https://www.twitter.com/${value}`;
            default:
                return value;
        }
    }

    public checkIfAtLeastOneSocialIsFilled(data: any) {
        const socials = ['instagram', 'facebook', 'tiktok', 'tiktok', 'snapchat', 'linkedin', 'twitter'];
        for (const key of socials) {
            if (data[key]) {
                return true;
            }
        }

        return false;
    }

    public parseData() {
        const data = this.form.toJSON();

        data.instagram = this.formatUrl(data.instagram, 'instagram');
        data.facebook = this.formatUrl(data.facebook, 'facebook');
        data.tiktok = this.formatUrl(data.tiktok, 'tiktok');
        data.snapchat = this.formatUrl(data.snapchat, 'snapchat');
        data.linkedin = this.formatUrl(data.linkedin, 'linkedin');
        data.twitter = this.formatUrl(data.twitter, 'twitter');

        if (!this.checkIfAtLeastOneSocialIsFilled(data)) {
            throw new Error(trans('account.data.error.one.social.is.required'));
        }

        return data;
    }

    public async handleSubmit() {
        try {
            const data = this.parseData();
            const response = await Repository.put('/user', data);

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            this.setState({ errorMessage: err?.message || err.toString() });
            this.$errorModal.open();
        }
    }

    public render() {
        return <div className={'p-box'}>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <h2 className={'mb-8'}>{trans('account.data.contact.infos')}</h2>
                <div className="row">
                    <div className="w-100 col-24 px-7">
                        <div className="row">
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('firstName')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('lastName')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('mobile')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('facebook')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('instagram')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('twitter')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('tiktok')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('linkedin')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('snapchat')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <CustomMultiInput
                                    existingLinks={this.props.meta?.other}
                                    field={this.form.field('other')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="w-100 col-24 display-flex justify-content-end">
                                <Button
                                    type={'button'}
                                    onClick={() => this.handleCancel()}
                                    className="variant-link size-medium"
                                    title={trans('global.buttons.cancel')}
                                />
                                <Button type={'submit'} className="variant-primary size-medium">
                                    <span className="material-icons mr-4">check</span>
                                    <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <div className="col-24 pt-6">
                            <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                        </div>
                    </div>
                </div>}
            </AbstractForm>
            <ErrorModal
                ref={(ref: any) => this.$errorModal = ref}
                errorText={this.state.errorMessage}
            />
        </div>;
    }

    public handleCancel() {
        this.setForm();
        this.props.onCancel();
    }
}
