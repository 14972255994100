import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { CouplesAssessment } from '../../components/couples-assessment/couples-assessment';
import { Subscribable } from '../../libs/subscribable';
import './couples-assessment.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class CouplesAssessmentScreen extends Subscribable<any, any> {
    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <div className="container max-width-1138">
                <h3 className={'pb-5 fw-bold fs.27'}>{trans('account.data.couples.assessment')}</h3>
                <CouplesAssessment user={this.props.user}/>
            </div>
        </div>;
    }
}
