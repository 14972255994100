import React from 'react';
import { Spinner } from '../../spinner';

export class OverlaySpinner extends React.Component<any, any> {
    public renderLoading(): React.ReactNode {
        return <div className="background-overlay position absolute absolute-center z-200">
            <Spinner customStroke={this.props.strokeColor} theme="custom" size="large"/>
        </div>;
    }

    public render() {
        return this.props.isLoading ? this.renderLoading() : null;
    }
}
