import React from 'react';

export class UserBase extends React.Component<any, any> {
    public state: any = {
        shouldClose: false,
        meta: {},
        user: {},
        profileUser: null,
        profileMeta: null
    };

    public componentDidMount() {
        this.setData();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.setData();
        }
    }

    public setData() {
        this.setState({
            user: this.props.user,
            meta: this.props.user?.meta || {},
            profileUser: this.props?.profileUser,
            profileMeta: this.props?.profileUser?.meta || {},
        });
    }
}
