import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { RegistrationComponent } from '../../components/authentication/registration/registration.component';
import { generateLink } from '../../libs/generate-link';
import { Subscribable } from '../../libs/subscribable';
import { HOME_PAGE, routes } from '../../routes';
import './registration.screen.scss';

const logo = require('../../assets/images/selectivita-logo-symbol.svg');

export class RegistrationScreen extends Subscribable<any, any> {
    public render(): React.ReactNode {
        return <div className={'RegistrationScreen p-box'}>
            <div className="row flex-fill">
                <div className="col-xl-8 col-lg-10 col-md-14 col-24">
                    {this.renderRegistrationBox()}
                </div>
                <div className="col-xl-16 col-lg-14 display-lg-flex display-none p-box">
                    <img className={'ImageSlide'} src={trans('loginImage')} alt="slider image"/>
                </div>
            </div>
        </div>;
    }

    public renderRegistrationBox() {
        const loginContainer = arrayToClass([
            ' palette--bgc-neutral-2 display-flex flex-column p-box',
            'RegistrationContainer'
        ]);

        return <div className={loginContainer}>
            {this.renderRegistrationHeadline()}
            <RegistrationComponent searchParams={this.props.location.search}/>
        </div>;
    }

    public renderRegistrationHeadline() {
        return <div className={'row mb-7'}>
            <a href={HOME_PAGE} className={'col-sm-10 col-24 text-decoration-none mb-sm-0 mb-7'}>
                <div className={'justify-content-start align-items-center display-flex flex-row'}>
                    <img src={logo} className={'Logo'} alt="logo"/>
                    <h2 className={'mb-0 ml-7'}>Selectivita</h2>
                </div>
            </a>
            <div className={'col-sm-14 col-24 justify-content-end align-items-sm-end align-items-center display-flex flex-column'}>
                <span className={'palette--c-neutral-5'}>{trans('signup.login.title')}</span>
                <Link to={generateLink(routes.login)} className={'fs-large palette--c-blue-2 fw-bold text-decoration-none'}>{trans('signup.login.button')}</Link>
            </div>
        </div>;
    }
}
