module.exports = {
    'account.edit.country': 'Țară',
    "user.profile.temperament.chart": "Dimensiunile Temperamentului",
    "user.profile.temperament.variance.chart": "Varianța față de media populației",
    "user.profile.personality.dimensions": "Dimensiuni de Pesonalitate",
    "user.profile.personality.dimensions.variance.chart": "Varianța față de media populației",
    "user.profile.personality.traits": "Trăsături de Personalitate",
    "user.profile.personality.traits.variance.chart": "Varianța față de media populației",
    "user.profile.love.style.dimensions": "Stiluri de Iubire",
    "user.profile.love.style.dimensions.variance.chart": "Varianța față de media populației",
    "account.data.calculate.compatibility": "Calcula compatibilitatea",
    "account.data.take.questionnaire": "Completați chestionarul",
    "account.data.read.description": "Citește descrierea",
    "spread.the.word": "Recomandă-ne prietenilor tai",
    "earn.free.credits": "te răsplătim!",
    "free.credit.for.refering.a.friend": "Veți primi 1 credit gratuit pentru fiecare prieten pe care îl invitați și înregistrează.",
    "meta.title": "Experți în compatibilitate de dragoste și relații intime.",
    "meta.description": "Decizia în selecţia unui amant(ă) este una dintre cele mai importante din viaţa noastră. Selectivita vă oferă informaţii care îmbunătăţesc procesul de selecţie.",
    "global.menu.title": "Meniu",
    "global.menu.home": "Acasă",
    "global.menu.group": "Servicii",
    "global.menu.starsign": "Compatibilitatea Zodiilor",
    "global.menu.assessments": "Evaluarea Cuplurilor",
    "global.menu.workshop": "Workshopuri",
    "global.menu.couples": "Evaluarea Cuplurilor",
    "global.menu.comingsoon": "În curând",
    "global.menu.contact": "Contact",
    "global.menu.close": "Inchidere meniu",
    "global.balance": "Soldul contului",
    "global.credit": "Credit(e)",
    "global.signin": "Intră în cont",
    "global.signup": "Încearcă gratuit",
    "global.signout": "Ieşire din cont",
    "global.accountmenu.accountDetails": "Profilul meu",
    "global.footermenu.about": "Despre noi",
    "global.footermenu.faq": "Întrebări frecvente",
    "global.footermenu.pricing": "Tarife",
    "global.footermenu.facebook": "Facebook",
    "global.footermenu.legal": "Condiții legale",
    "global.footermenu.privacy": "Politica de confidenţialitate",
    "global.footermenu.terms": "Condiţii de utilizare",
    "global.languagemenu.title": "Limba",
    "global.datepicker.submit": "Procesează",
    "global.buttons.save": "Salvare",
    "global.buttons.cancel": "Anulare",
    "global.buttons.delete": "Șterg",
    "global.buttons.close": "Închide",
    "global.input.required": "Obligatoriu",
    "global.input.repassword": "Parolele nu se potrivesc",
    "cookie.title": "Acest site web folosește cookie-uri",
    "cookie.description": "Acest website folosește cookie-uri pentru a ne asigura că primiți cele mai bune experiențe.",
    "cookie.link": "Vizualizează politică de confidenţialitate.",
    "cookie.button": "Accept",
    "mainpage.slide1.title": "Te ajutam sa selectezi Alesul/Aleasa.",
    "mainpage.slide1.subtitle": "Una dintre cele mai importante decizii pe care le luăm este cu cine decidem să ne împărtășim noi înșine și viața noastră.",
    "mainpage.slide2.title": "Ce spun stelele despre tine și compatibiliatetea relației tale.",
    "mainpage.slide2.subtitle": "Conform cercetărilor noastre, comparația dintre datele de naștere al partenerilor este un indicator puternic al unei relații satisfacatoare.",
    "mainpage.slide3.title": "Îmbunătățește satisfacția relației cu prietenii și membrii familiei tale.",
    "mainpage.slide3.subtitle": "Îmbunătățește-ți capacitatea de a înțelege oamenii și bucură-te mai mult de cei apropiați.",
    "mainpage.slide4.title": "Ateliere gratuite, îmbunătățiți-vă selecția abilităților și schimbați-vă viața.",
    "mainpage.slide4.subtitle": "Lăsaţi-ne pe noi să vă invațăm să utilizați Selectiva.com.",
    "mainpage.slide5.title": "Primește evaluarea vitală a compatibilității cu partenerul și îmbunătățește-ți relația.",
    "mainpage.slide5.subtitle": "Înainte de a incepe o terapie scumpă, încercați evaluarea noastră și primiți o estimare obiectivă asupra puterii relației voastre.",
    "mainpage.section1.subtitle": "Oferă date obiective",
    "mainpage.section1.title": "Ce facem noi mai exact?",
    "mainpage.section1.description": "Îmbogăţeşte-ţi relaţiile interpersonale prin analiza obiectivelor şi selectie. Principalul obiectiv al Selectivita este de a cuantifica compatibilitatea cu cealaltă persoană indiferent că sunt prieteni, colegi de serviciu sau iubite/iubiti. O relație bună sau rea nu este doar o intămplare, în spate se află o adevarată stiință.",
    "mainpage.section2.subtitle": "Relații compatibile.",
    "mainpage.section2.title": "Propunerea noastră.",
    "mainpage.section2.description": "Înțelegerea sexului opus poate însemnă o adevarată provocare dar noi suntem aici pentru a vă oferi tot sprijinul nostru.  Dorim să vă îmbunățătim calitatea relației printr-o viziune/gandire nealterată asupra problemelor, care apoi să ajute în procesul de selecție a partenerilor.  Folosiți ca parghie experiența noastră pentru a va ințelege mai bine și pe cei din jurul vostru pentru a aduce calitate in relațiile existente și viitoare.",
    "mainpage.section3.subtitle": "Selecţie mai bună = relații mai sănătoase.",
    "mainpage.section3.title": "Rezultatul  așteptat.",
    "mainpage.section3.description": "Cu căt avem mai multe informații cu atăt putem oferi o mai bună ințelegere a propriei persoane și  a celor din anturajul nostru.  Credem că o selecție riguroasă aduce ca rezultat o reație mai armonioasă și mai puternică.  O relație sănatoasă pentru noi înseamnă: o reducere a stărilor de conflict, mai multă armonie, o comunicare libera/pozitivă intre parteneri și în general o atmosferă sănatoasă în relație, toate acestea cu un efort minim.  Suna prea bine ca să fie adevărat?  Încearcă și vezi rezultatele!",
    "mainpage.section4.subtitle": "Chestionare de autoevaluare",
    "mainpage.section4.title": "Cum procedam?",
    "mainpage.section4.description": "Metodă de a te ințelege căt mai bine este bazată pe o serie de chestionare de auto-evaluare.  Fiecare răspuns ne aduce mai aproape de scopul nostru și ne permite să iți descoperim caracterul ca mai apoi de a face recomandarile de compatibilitate căt mai aproape de realitate.  În concluzie, acuratetea rezultatelor depind în totalitate de răspunsurile tale, cu căt răspunzi mai corect și complet cu atăt rezultatele obținute vor fi mai bune.",
    "mainpage.section4.button": "Citește mai departe",
    "landingpage.cta.title": "Nu ezita, înregistrează-te și încearcă gratuit.",
    "landingpage.cta.description": "Ți se pare că faci aceleași greșeli de fiecare dată?  Ai fost abandonat de 'cel mai bun prieten'?  Ai fost tradat de un coleg 'de incredere'?  Ești din nou intr-o relație fără succes?  Am dezvoltat Selectivita pentru acele persoane care iși doresc un nou start și un viitor mai bun!  Sună bine?  Atunci dă-ne voie să te ajutam!",
    "landingpage.cta.button": "Încerc",
    "starlanding.slide.title": "Calculează-ți compatibilitatea astrologică cu diferite persoane după data nașterii.",
    "starlanding.slide.name": "Numele persoanei",
    "starlanding.slide.date": "an/lună/zi",
    "starlanding.slide.button": "Calcula",
    "starlanding.section.subtitle": "Înțelege și cunoaște mai bine",
    "starlanding.section.title": "Vezi caracterizarea personalității tale sau al altei persoane în funcție de zodie.",
    "starlanding.section.description": "Alinierea planetelor la naștere se pare că joacă un mic rol in formarea și dezvoltarea caracterului personal. Ca indivizi avem o personalitate mult mai complexă decăt poate sumariza o caracterizare astrologica dar o cercetare detaliată indică o anumită corelare intre zodie și caracter.",
    "starlanding.celebrity.badge": "Indicele de Compatibilitate Astrologică",
    "starlanding.celebrity.title": "Celebritătile sunt mai bune in selectarea partenerilor?",
    "starlanding.celebrity.description": "Am analizat sute de relații cu celebrități, mai jos sunt rezultatele a doar câteva. Cercetările noastre indică faptul că nu sunt mai inteligenți sau mai norocoși decât alții, deoarece se confruntă cu aceleași dificultăți atunci când vine vorba de alegerea partenerului romantic potrivit.",
    "login.signup.title": "Încă nu aveţi un cont?",
    "login.email": "E-mail",
    "login.password": "Parola",
    "login.forgetpassword": "Ați uitat parola?",
    "signup.login.title": "Aveţi deja un cont?",
    "signup.description2": "Creați un cont nou",
    "signup.firstname": "Prenume",
    "signup.lastname": "Nume",
    "signup.email": "E-mail",
    "signup.dateofbirth": "Data nașterii",
    "signup.password": "Parola",
    "signup.passwordagain": "Parola din nou",
    "signup.terms": "Accept termenii și conditiile",
    "signup.termsLink": "/termeni-de-utilizare",
    "signup.birthDatePlaceholder": "an/lună/zi",
    "forgotpsw.title": "Cerere resetare parolă",
    "forgotpsw.description": "Îți vom trimite un link pe adresa dvs. de e-mail pentru schimbarea parolei.  Dacă nu găsiți mesajul nostru in Inbox-ul dvs., vă rugăm să verificaţi şi folder-ul de spam sau junk.",
    "starsign.new.title": "Calculator de compatibilitate zodiilor",
    "starsign.new.you": "Eu",
    "starsign.new.button": "Calcula",
    "starsign.new.buttonagain": "Salva",
    "starsign.new.partnername": "Numele persoanei",
    "starsign.empty": "Încă nu avem rezultate",
    "starsign.loading": "Încărcare.....",
    "starsign.results.result": "Rezultate",
    "starsign.results.name": "Nume",
    "starsign.results.create": "Data",
    "starsign.results.born": "Născut",
    "starsign.results.created": "Creat",
    "topup.title": "Încărcaţi-vă contul",
    "topup.currency.label": "Valută",
    "topup.currency.title": "Alegeţi valută",
    "topup.mostpopular": "Recomandat",
    "topup.buynow": "Cumpăr",
    "payment.status.title": "Plata",
    "payment.status.description": "Așteptăm răspunsul operatorului de plata.  Scuze pentru întârziere și vă mulțumim pentru ințelegere.",
    "payment.status.button": "Reîmprospătarea",
    "account.edit.invite.friends": "Invita prieteni",
    "account.edit.email": "E-mail",
    "account.edit.firstName": "Nume",
    "account.edit.lastName": "Prenume",
    "account.edit.title": "Editarea profilului",
    "account.edit.photo": "Încărcaţi-vă o poză nouă de profil",
    "account.edit.nickname": "Poreclă",
    "account.edit.gender": "Sex",
    "account.edit.dateOfBirth": "Data nașterii",
    "account.edit.city": "Oraș",
    "account.edit.changepsw": "Schimbați parola",
    "account.edit.changepsw.title": "Schimbați-vă parola",
    "account.edit.changepsw.old": "Parola veche",
    "account.edit.changepsw.new": "Parolă nouă",
    "account.edit.changepsw.newagain": "Parola nouă din nou",
    "login.button": "Intrați în cont",
    "login.title": "Conectare la cont",
    "login.description": "Începeți să vă schimbați viitorul astăzi!",
    "login.facebookbutton": "Conectare cu Facebook",
    "login.description2": "Conectează-te pentru a accesa contul.",
    "signup.login.button": "Intrați în cont",
    "signup.description": 'Veți primi 5 credite după înregistrare și validare e-mailul.',
    "signup.facebookbutton": "Înregistrare cu Facebook",
    "forgotpsw.backbutton": "Înapoi la conectare",
    "signup.button": "Creare cont",
    "starsign.results.title": "Calcule anterioare",
    "account.edit.empty": "Gol",
    "global.menu.questionnaires": "Autoevaluare",
    "global.menu.profiles": "Caută utilizatorii",
    "global.menu.matchmaking": "Dating",
    "global.accountmenu.topup": "Reîncărcare",
    "global.footermenu.copyright.start": "© 2019-",
    "global.footermenu.copyright.end": "Toate drepturile rezervate. Selectivita.com",
    "global.buttons.submit.questionnaire": "Trimite",
    "account.data.meta.infos": "META INFOS",
    "account.data.edit.profile": "EDITEAZĂ PROFILUL",
    "global.buttons.next.question": "urmatoarea",
    "global.buttons.previous.question": "înapoi",
    "account.edit.relationship.status": "Relaţia actuală",
    "test.option.totally.disagree": "dezacord total",
    "test.option.disagree": "dezacord",
    'test.option.slightly.disagree': 'dezacord parțial',
    "test.option.neutral": "neutru",
    "test.option.slightly.agree": "acord parțial",
    "test.option.agree": "acord",
    "test.option.totally.agree": "acord total",
    "account.data.open.for.relationship": "Sunt singur(ă)",
    "account.data.closed.for.relationship": "Nu sunt singur(ă)",
    "account.data.birthday": "Data nașterii",
    "account.data.pay.credit.to.reveal": "Dezvălui",
    "account.data.fill.out": "COMPLETAȚI",
    "account.data.test": "TEST",
    "account.data.alt.icon.text": "analysis icon",
    "account.data.find.profile": "Căutare utilizator",
    "account.data.history": "Istorie",
    "sent.invites": "Invitații trimise",
    "previous.assessments": "Evaluări anterioare",
    "account.data.noMatches": "Niciun profil nu corespunde criteriilor dvs.",
    "account.data.profiles": "Profiluri",
    "account.data.age": "Vârsta",
    'age': 'Vârsta',
    'birthday': 'Născut',
    "account.data.startsign": "Compatibilitatea Zodiilor",
    "account.data.gender": "Sex",
    "account.data.ethnicity": "Rasă și Ascendență (max. 2)",
    "account.data.city": "Oraș",
    "account.data.country": "Țara",
    "account.data.height": "Înălțime",
    "account.data.weight": "Greutate",
    "account.data.hair.color": "Culoarea naturală a părului",
    "account.data.eye.color": "Culoarea ochilor",
    "account.data.body.type": "Tipul de corp",
    "account.data.marital.status": "Starea civilă",
    "account.data.languages": "Limbi pe care le pot vorbi",
    "account.data.mobile": "Mobil",
    "account.data.email": "E-mail",
    "account.data.empty": "Nicio o poză de afişat",
    "account.data.more.photos": "POZELE",
    "account.data.personal.infos": "ATRIBUTE PERSONALE",
    "account.data.our.recommendations": "RECOMANDĂRILE NOASTRE",
    "account.data.couples.assessment": "EVALUARE CUPLURI",
    "account.data.match.finder": "CAUTĂ",
    "account.data.like.list": "FAVORITE",
    "account.data.contact.infos": "INFORMAȚII DE CONTACT",
    "account.data.social.media": "CONTURI DE SOCIAL MEDIA",
    "account.data.living.status": "Cu cine locuiți în prezent?",
    "account.data.relationship.history": "Istoria maritală/relațiilor",
    "account.data.children": "Câți copii ai?",
    "account.data.relationship.interests": "Tipul de relație de care sunt interesat",
    "account.data.religion": "Afilierea religioasă",
    "account.data.libido": "Care este frecvența nevoilor tale de intimitate sexuală?",
    "account.data.sexual.orientation": "Orientare sexuală",
    "account.data.worship.frequency": "Cât de des mergeți la locurile de închinare?",
    "account.data.education.level": "Diploma obținut(ă) (nivel de educație):",
    "account.data.drug.usage": "Ați consumat sau ați consumat vreodată droguri ilegale?",
    "account.data.profession.type": "Domeniul de activitate sau cariera care este cel mai aproape de mine",
    "account.data.is.smoker": "Fumați?",
    "account.data.alcohol.consumption": "Cât de mult alcool consumați?",
    "account.data.tattoos": "Aveți vreun tatuaj?",
    "account.data.openProfile": "Deschide profilul",
    "account.data.view.profile": "Vedeți profilul",
    "account.data.clearFilters": "Șterg filtrele",
    "account.data.showResults": "Rezultate",
    "account.data.filters": "CRITERIILE DE FILTRARE",
    "account.data.meta.info": "META INFO",
    "account.data.miscellaneous.filters": "PREFERINȚE PERSONALE",
    "account.data.personal.info.filters": "ATRIBUTE PERSONALE",
    "account.data.background.info.filters": "INFORMAȚII GENERALE",
    "account.data.meta.info.filters": "",
    "account.data.fill.out.for.free": "Completa chestionarul",
    "account.data.retake.questionnaire": "Reluați chestionarul",
    "account.data.personality": "PERSONALITATE",
    "account.data.behaviour": "TEMPERAMENT",
    "account.data.loveStyle": "STILURI DE IUBIRE",
    "account.data.starSign": "ZODIE",
    "account.data.level.of.love": "NIVEL DE IUBIRE",
    "account.data.temperament.content": "Conținutul temperamentului este în curs de scriere.",
    "account.data.personality.content": "Conținutul personalității este în curs de scriere.",
    "account.data.level.of.love.content": "Conținutul stilului de iubire este în curs de scriere.",
    "account.data.read.my.description": "Citiți profilul",
    "account.data.read.my.analysis": "Citiți analiza",
    "account.data.view.data": "Vizualizați rezultate",
    "account.data.buy.data": "Cumpărați rezultate",
    "account.data.my.behavioural.description": "Analiza temperamentului",
    "account.data.my.behaviour.comparative.analysis": "Analiza comparativă",
    "account.data.my.personality.description": "Analiza personalității",
    "account.data.my.personality.comparative.description": "Analiza comparativă",
    "account.data.my.love-style.description": "Analiza stilului de iubire",
    "account.data.my.love-style.comparative.analysis": "Analiza comparativă",
    "account.data.min": "min",
    "account.data.max": "max",
    "account.data.read.about": "Citesc despre noi",
    "account.data.languages.label": "Limbi vorbite",
    "account.data.buy.read": "Citi",
    "account.data.contact": "Contactați",
    "account.data.profile.completion.1": "Profilul dvs. este",
    "account.data.profile.completion.2": " complet. Vă rugăm să răspundeți la toate întrebările pentru a putea apărea pa pagina de Dating!",
    "account.data.profile.visibility.true": "Profil vizibil pe pagina de Dating",
    "account.data.profile.visibility.false": "Profil ascuns din pagina de Dating. (Înscrieți-vă dacă doriți să căutați printre alți utilizatori)",
    "account.data.hide.profile": "Înscrieți-vă",
    "account.data.show.profile": "Descrieți-vă",
    "account.data.me.small": "eu",
    "account.data.average.chart.her": "EA",
    "account.data.average.chart.him": "EL",
    "account.data.average.chart.avg": "MED",
    "account.data.average.chart.you": "TU",
    "account.data.you": "Tu",
    "account.data.me": "Eu",
    "account.data.invite.friend": "Recomandați un prieten",
    "account.data.buy.analysis": "Calcula compatibilitatea",
    "account.data.read.analysis": "Evaluarea",
    "account.data.analyze.us": "Evaluează-ne",
    "account.data.gender.is.required.in.order.to.proceed": "Vă rugăm să furnizați sexul dvs. pentru a continua cu chestionarul",
    "account.data.please.provide.your.date.of.birth": "Vă rugăm să furnizați data nașterii",
    "account.data.facebook": "Facebook",
    "account.data.instagram": "Instagram",
    "account.data.twitter": "Twitter",
    "account.data.tiktok": "TikTok",
    "account.data.linkedin": "LinkedIn",
    "account.data.other": "Alte",
    "account.data.snapchat": "Snapchat",
    "account.data.empty.list": "Încă nu ați invitat niciun prieten.",
    "account.data.see.more": "Încărcați mai mulțe persoane",
    "not.enough.balance": "Sold insuficient",
    "account.data.error.header": "EROARE",
    "account.data.error.user.profile.is.not.complete": "Nu vă putem evalua, deoarece acest utilizator nu și-a completat încă profilul.",
    "account.data.error.something.went.wrong": "Ceva nu funcționează, încercați din nou mai târziu.",
    "account.data.error.you.have.already.assessed.with.this.user": "V-ați evaluat deja cu acest utilizator.",
    "account.data.error.invalid.url.format": "Format url nevalid.",
    "account.data.error.already.assessed": "V-ați evaluat deja cu acest utilizator.",
    "account.data.error.no.email.has.been.provided": "Vă rugăm să furnizați un e-mail",
    "account.data.error.this.user.profile.is.incomplete": "Ne pare rău, dar profilul acestui utilizator este incomplet",
    "account.data.error.cannot.evaluate.with.yourself": "Ne pare rău, dar nu puteți efectua această acțiune",
    "account.data.error.user.not.found": "Persoana nu a fost găsită",
    "account.data.error.your.profile.is.incomplete": "Profilul dvs. este incomplet",
    "account.data.error.email.already.in.use": "Cineva este deja înregistrat cu această adresă de e-mail",
    "account.data.error.one.social.is.required": "Este necesar cel puțin un cont de social media",
    "account.data.behavioural.questionnaire": "TIP DE TEMPERAMENT",
    "account.data.behavioural.questionnaire.description.complete": "Graficele de mai jos dezvăluie care este tipul tău de temperament. Primul grafic afișează nivelul fiecărei dimensiuni dintr-un total de 100%. Înălțimea fiecărei bare este egală cu măsura în care contribuie la tendințele tale comportamentale. Graficul colorat arată nivelul de variație al fiecărei dimensiuni față de media populației pentru sexul dvs. Această diferență față de media descrie cel mai bine stilul tău de temperament. Cele mai înalte bare reprezintă stilul tău dominant și influențează cel mai mult personajul tău. Barele cele mai joase au cel mai mic impact asupra ta. Combinația tuturor diferențelor de dimensiune are ca rezultat stilul tău de temperament natural.",
    "account.data.love.style.questionnaire": "STIL DE IUBIRE",
    "account.data.love.style.questionnaire.description.complete": "Diagramele de mai jos afișează stilul iubirii dvs. Natural de dragoste bazat pe o combinație de șase dimensiuni. Prima diagramă afișează proporția fiecărei dimensiuni dintr-un total de 100%. Înălțimea fiecărei bare dezvăluie nivelul de importanță pe care îl joacă în compoziția stilului dvs. Diagrama colorată arată variația fiecărei dimensiuni față de media populației pentru genul dvs. Această diagramă ilustrează intensitatea fiecărei dimensiuni din mentalitatea voastră. Dimensiunile cu cea mai mare variație pozitivă vă afectează cel mai mult, în timp ce valorile negative cel mai puțin. Este combinația tuturor celor șase dimensiuni care dezvăluie modul în care vă exprimați și care sunt motivatorii voștri înnăscuți într-o relație romantică.",
    "account.data.personality.questionnaire": "TIP DE PERSONALITATE",
    "account.data.state": "Județ",
    "account.data.questionnaire.is.not.completed.yet": "Mai întâi trebuie să completați chestionarul",
    "account.data.questionnaire.is.not.completed.yet.by.user": "Această persoană nu a completat încă chestionarul",
    "account.data.description": "Descrierea zodiilor",
    "account.data.overview": "Descrierea zodiilor generală",
    "forgotpsw.button": "Resetare parola",
    "forgotpsw.email": "E-mail folosit când ați creat contul",
    "account.data.available.profiles": "Persoane",
    "login.signup.button": "Alăturați-vă aici",
    "signup.title": "Înregistraţi-vă acuma să primiţi credite gratuite.",
    "account.data.friend.invitations": "PROGRAM DE RECOMANDARE",
    "contact.invite": "Trimite",
    "account.data.background.infos": "INFORMAȚII GENERALE",
    "compatibility.myResultButton": "Descrierea mea",
    "account.data.fulfilled": "creditat",
    "account.data.unfulfilled": "invitat",
    "account.data.header.email": "E-MAIL",
    "account.data.invited.at": "DATA INVITAȚIEI",
    "account.data.is.registered": "STARE",
    "account.data.registered.at": "DATA ÎNREGISTRĂRII",
    "you.need.to.purchase.the.calculation.first": "Mai întâi trebuie să cumpărați calculul.",
    "setting.password": "Introduceți o noua parolă. Mai târziu puteți autentifica cu această parolă.",
    "change.password": "Parolă schimbată cu succes!",
    "change.login..password": "Vă puteți autentifica deja cu această parolă.",
    "account.data.name": "NUME",
    "read.more": "citește mai mult",
    "read.less": "citește mai puțin",
    "account.data.behavioural.chart.title.1": "Dimensiunile Temperamentului",
    "account.data.temperament.predisposition": "Dimensiunile Temperamentului",
    "account.data.variance.from.population": "Varianța față de media populație",
    "account.data.love.style.dimensions": "Dimensiunile Stilului de Iubire",
    "account.data.personality.dimensions": "Dimensiunile Personalității",
    "account.data.personality.traits": "Trăsături de personalitate",
    "main-dimension.selector": "Dimensiunile Personalității",
    "main-dimension": "Dimensiunile personalității noastre",
    "our-sub-dimensions": "Trăsăturile noastre de personalitate",
    "love-style-dimension": "Stilurile noastre de iubire",
    "personality.differences": "Varianța dintre noi",
    "variance.between.us": "Varianța dintre noi",
    "sub-dimension": "Trăsături de personalitate",
    "account.edit.relationship.status.1": "Căsătorit fericit",
    "account.edit.relationship.status.2": "Căsătorit nefericit",
    "account.edit.relationship.status.3": "Într-o relație superbă",
    "account.edit.relationship.status.4": "Într-o relație nefericit",
    "account.edit.relationship.status.5": "Singur(ă) şi dezamagit(ă)",
    "account.edit.relationship.status.6": "Singur(ă) şi in căutare",
    "account.data.ethnicity.0": "Arab (Africa de Nord)",
    "account.data.ethnicity.1": "Arab (Orientul Mijlociu)",
    "account.data.ethnicity.2": "Asiatic - Central",
    "account.data.ethnicity.3": "Asiatic - Est (Chineză)",
    "account.data.ethnicity.4": "Asiatic - Est (Japoneză)",
    "account.data.ethnicity.5": "Asiatic - Est (Coreeană)",
    "account.data.ethnicity.6": "Asiatic - Est (Alte)",
    "account.data.ethnicity.7": "Asiatic - Nord",
    "account.data.ethnicity.8": "Asiatic - Sud (Indian)",
    "account.data.ethnicity.9": "Asiatic - Sud (Pakistanez)",
    "account.data.ethnicity.10": "Asiatic - Sud (Alte)",
    "account.data.ethnicity.11": "Asiatic - South-East (Indonezian)",
    "account.data.ethnicity.12": "Asiatic - South-East (Pilipino)",
    "account.data.ethnicity.13": "Asiatic - South-East (Thailandez)",
    "account.data.ethnicity.14": "Asiatic - South-East (Vietnamez)",
    "account.data.ethnicity.15": "Asiatic - South-East (Alte)",
    "account.data.ethnicity.16": "Negru (American)",
    "account.data.ethnicity.17": "Negru (African)",
    "account.data.ethnicity.18": "Negru (Caraibe)",
    "account.data.ethnicity.19": "Latino (Caraibe - Cubanez)",
    "account.data.ethnicity.20": "Latino (Caraibe - Dominicană)",
    "account.data.ethnicity.21": "Latino (Caraibe - Haitian)",
    "account.data.ethnicity.22": "Latino (Caraibe - Portorican)",
    "account.data.ethnicity.23": "Latino (America Centrală - Mexican)",
    "account.data.ethnicity.24": "Latino (America Centrală - Alte)",
    "account.data.ethnicity.25": "Latino (Sud America - Argentinian)",
    "account.data.ethnicity.26": "Latino (Sud America - Brazilian)",
    "account.data.ethnicity.27": "Latino (Sud America - Columbian)",
    "account.data.ethnicity.28": "Latino (Sud America - Peruvian)",
    "account.data.ethnicity.29": "Latino (Sud America - Venezuelean)",
    "account.data.ethnicity.30": "Latino (Sud America - Alte)",
    "account.data.ethnicity.31": "Orientul Mijlociu (Iranian)",
    "account.data.ethnicity.32": "Orientul Mijlociu (Egiptean)",
    "account.data.ethnicity.33": "Orientul Mijlociu (Israelian)",
    "account.data.ethnicity.34": "Orientul Mijlociu (Irakian)",
    "account.data.ethnicity.35": "Orientul Mijlociu (Kurd)",
    "account.data.ethnicity.36": "Orientul Mijlociu (Persan)",
    "account.data.ethnicity.37": "Orientul Mijlociu (Turc)",
    "account.data.ethnicity.38": "Orientul Mijlociu (Alte)",
    "account.data.ethnicity.39": "Nativ American",
    "account.data.ethnicity.40": "Romani (Țigani)",
    "account.data.ethnicity.41": "Alb (Est European)",
    "account.data.ethnicity.42": "Alb (Est European - Rus)",
    "account.data.ethnicity.43": "Alb (Nordul European - Nordic)",
    "account.data.ethnicity.44": "Alb (Nord-Est European - Baltice)",
    "account.data.ethnicity.45": "Alb (Oceania - Australian/Noua Zeelandă)",
    "account.data.ethnicity.46": "Alb (African)",
    "account.data.ethnicity.47": "Alb (Sud European - Balcanic)",
    "account.data.ethnicity.48": "Alb (Sud European - Italian)",
    "account.data.ethnicity.49": "Alb (Sud European - Portughez)",
    "account.data.ethnicity.50": "Alb (Sud European - Spaniol)",
    "account.data.ethnicity.51": "Alb (Vest European)",
    "account.data.hair.color.1": "Jet Black",
    "account.data.hair.color.2": "Negru",
    "account.data.hair.color.3": "Maro închis",
    "account.data.hair.color.4": "Maro",
    "account.data.hair.color.5": "Maro deschis",
    "account.data.hair.color.6": "Blonda întunecată",
    "account.data.hair.color.7": "Blondă",
    "account.data.hair.color.8": "Blonde ușoare",
    "account.data.hair.color.9": "Auburn",
    "account.data.hair.color.10": "Roșu deschis",
    "account.data.hair.color.11": "Roșu",
    "account.data.hair.color.12": "Roșu închis",
    "account.data.hair.color.13": "Gri",
    "account.data.hair.color.14": "Argint",
    "account.data.eye.color.1": "Albastru",
    "account.data.eye.color.2": "Albastru deschis",
    "account.data.eye.color.3": "Gri",
    "account.data.eye.color.4": "Gri verde",
    "account.data.eye.color.5": "Verde",
    "account.data.eye.color.6": "Hazel",
    "account.data.eye.color.7": "Amber",
    "account.data.eye.color.8": "Maro deschis'",
    "account.data.eye.color.9": "Maro'",
    "account.data.eye.color.10": "Maro închis",
    "account.data.average": "",
    "account.data.my.score": "",
    "account.data.add.to.like.list": "Adăugare la Favorite",
    "account.data.remove.from.like.list": "Eliminați din Favorite",
    "account.data.education.1": "8 clase, nu am nici un diploma",
    "account.data.education.2": "Liceul",
    "account.data.education.3": "Am început facultatea, dar nu am terminat",
    "account.data.education.4": "Institutul tehnic",
    "account.data.education.5": "Asociat",
    "account.data.education.6": "Licență",
    "account.data.education.7": "Masterat",
    "account.data.education.8": "MBA",
    "account.data.education.9": "Profesională (aviație, drept, medicină)",
    "account.data.education.10": "Doctorat (Dr., PhD)",
    "account.data.education.11": "Alte",
    "account.data.sexual.orientation.1": "Bisexual",
    "account.data.sexual.orientation.2": "Heterosexual",
    "account.data.sexual.orientation.3": "Homosexual",
    "account.data.sexual.orientation.4": "Alte",
    "account.data.tattoo.1": "Nu, și nu voi face niciodată",
    "account.data.tattoo.2": "Încă nu",
    "account.data.tattoo.3": "Unul sau două mici și discrete",
    "account.data.tattoo.4": "Câteva mici, dar vizibile",
    "account.data.tattoo.5": "Unele mai mari",
    "account.data.tattoo.6": "Câteva mari cu mai multe de venit",
    "account.data.tattoo.7": "Sunt complet acoperit",
    "account.data.libido.1": "De câteva ori pe zi",
    "account.data.libido.2": "O dată pe zi",
    "account.data.libido.3": "De câteva ori pe săptămână",
    "account.data.libido.4": "De câteva ori pe lună",
    "account.data.libido.5": "O dată pe lună",
    "account.data.libido.6": "O dată pe an",
    "account.data.smoking.habit.1": "Niciodată",
    "account.data.smoking.habit.2": "Ocazional, pentru a-mi potoli nervii",
    "account.data.smoking.habit.3": "Uneori social",
    "account.data.smoking.habit.4": "Un pachet pe zi",
    "account.data.smoking.habit.5": "Fumez ca un coș de fum",
    "account.data.alcohol.1": "Nu beau niciodată",
    "account.data.alcohol.2": "Numai în ocazii speciale cu moderație",
    "account.data.alcohol.3": "Un pahar de bere sau vin pe noapte",
    "account.data.alcohol.4": "Max 2-3 pahare să mă simt bine în situații sociale.",
    "account.data.alcohol.5": "De obicei, mă îmbat destul de tare când mă simt bine.",
    "account.data.alcohol.6": "Beau destul de des, chiar și în timpul zilei",
    "account.data.drugs.1": "Nu, și sunt total împotriva!",
    "account.data.drugs.2": "Uneori, într-un cadru social",
    "account.data.drugs.3": "Ocazional, viața poate fi grea",
    "account.data.drugs.4": "Frecvent, nu pot supraviețui ziua fără ajutor",
    "account.data.drugs.5": "Aparțin unui centru de reabilitare pentru droguri și alcool",
    "account.data.profession.1": "Spălător de geamuri, Îngrijitorul şcolii, om de servici",
    "account.data.profession.2": "Portar, paznic",
    "account.data.profession.3": "Șofer de autobuz/camion, Poștaș, Muncitor în fabrică/construcții”",
    "account.data.profession.4": "Mecanic auto, Tâmplar, Electrician, Instalator, Tehnician TV",
    "account.data.profession.5": "Ospătar/Chelneriță, Barman, Masajist, Îngrijitor de câini",
    "account.data.profession.6": "Bucătar, Frizer/Coafură, Manichiurist, Cosmetician, Însoțitor de zbor",
    "account.data.profession.7": "Caporal/soldat în forțele armate",
    "account.data.profession.8": "Antrenor personal/sportivi, influencer în social media",
    "account.data.profession.9": "Designer grafic, fotograful de nuntă, şef de tură restaurant/hotel",
    "account.data.profession.10": "Profesor/profesoară (grădiniță, școală elementară, liceu)",
    "account.data.profession.11": "Ofițer de poliție, pompier, tehnician medical de urgență",
    "account.data.profession.12": "Funcţionar (contabilitate, vânzări, finanțe, marketing, operațiuni...)",
    "account.data.profession.13": "Infirmieră, Asistent stomatologic, Asistent social, Jurist, Farmacist",
    "account.data.profession.14": "Manager de hotel/restaurant, Curator de muzeu, Director de școală",
    "account.data.profession.15": "Contabil senior, Manager financiar, Psiholog, Asistent social autorizat",
    "account.data.profession.16": "Programator computer, Specialist IT, Detectiv de poliție",
    "account.data.profession.17": "Proprietari de afacere mică, Căpitan de poliție",
    "account.data.profession.18": "Atlet profesionist, Animatoare (actor, actriță, muzician, comedian), Reporter TV",
    "account.data.profession.19": "Scriitor, Autor, Pictor, Artist, Sculptor",
    "account.data.profession.20": "Inginer, Arhitect, Producător de film",
    "account.data.profession.21": "Administrator de nivel înalt, Comisar de poliție",
    "account.data.profession.22": "Avocat, Medic, Stomatolog, Psihiatru",
    "account.data.profession.23": "Bancher de investiții, Profesor universitar, Judecător",
    "account.data.profession.24": "Pilot de avion, Regizor de film",
    "account.data.profession.25": "Directori de societăți",
    "account.data.profession.26": "General de 4-5 stele în Forțele Armate",
    "account.data.profession.27": "Astronaut, Neurochirurg, Politician, Om de știință,",
    "account.data.profession.28": "Alte",
    "account.data.relationship.type.1": "Strict sexuală (aventură de-o noapte)",
    "account.data.relationship.type.2": "Prieteni cu beneficii",
    "account.data.relationship.type.3": "Dating și găsirea de dragoste pe termen scurt și romantism",
    "account.data.relationship.type.4": "O persoană non-monogamă, fie trăind împreună, fie separat",
    "account.data.relationship.type.5": "O persoană strict monogamă, dar care trăiește separat",
    "account.data.relationship.type.6": "Un aranjament de coabitare serios și pe termen lung",
    "account.data.relationship.type.7": "O relație tradițională cu căsătoria și copii",
    "account.data.relationship.type.8": "Nu mă interesează niciun fel de relație chiar acuma",
    "account.data.religion.1": "Ateu",
    "account.data.religion.2": "Agnostic",
    "account.data.religion.3": "Baha'i",
    "account.data.religion.4": "Budist",
    "account.data.religion.5": "Creștin - Adventist",
    "account.data.religion.6": "Creștin - Anglican",
    "account.data.religion.7": "Creștin - Baptist",
    "account.data.religion.8": "Creștin - Catolic",
    "account.data.religion.9": "Creștin - Martorii lui Iehova",
    "account.data.religion.10": "Creștin - Sfinților din Zilele din Urmă",
    "account.data.religion.11": "Creștin - Luteran/Evanghelic",
    "account.data.religion.12": "Creștin - Metodist",
    "account.data.religion.13": "Creștin - Non-denominațional",
    "account.data.religion.14": "Creștin - Ortodox - Est",
    "account.data.religion.15": "Creștin - Ortodox - Oriental",
    "account.data.religion.16": "Creștin - Penticostal",
    "account.data.religion.17": "Creștin - Reformat/Presbiterian",
    "account.data.religion.18": "Creștin - Alte",
    "account.data.religion.19": "Hindus",
    "account.data.religion.20": "Islam (Shi\'ite)",
    "account.data.religion.21": "Islam (Sunni)",
    "account.data.religion.22": "Ervreu - Conservator",
    "account.data.religion.23": "Ervreu - Hasidic",
    "account.data.religion.24": "Ervreu - Reformat",
    "account.data.religion.25": "Ervreu - Ortodox",
    "account.data.religion.26": "Sinto",
    "account.data.religion.27": "Alte",
    "account.data.religious.practices.1": "O dată pe zi",
    "account.data.religious.practices.2": "O dată pe săptămână",
    "account.data.religious.practices.3": "O dată pe lună",
    "account.data.religious.practices.4": "O dată pe an",
    "account.data.religious.practices.5": "Rareori, în special în ocazii speciale",
    "account.data.religious.practices.6": "Niciodată",
    "account.data.language.1": "Arabic",
    "account.data.language.2": "Bengali",
    "account.data.language.3": "Bulgarian",
    "account.data.language.4": "Croatian",
    "account.data.language.5": "Czech",
    "account.data.language.6": "Olandeză",
    "account.data.language.7": "Engleză",
    "account.data.language.8": "Filipino",
    "account.data.language.9": "Franceză",
    "account.data.language.10": "Germană",
    "account.data.language.11": "Greacă",
    "account.data.language.12": "Gujarati",
    "account.data.language.13": "Hindi",
    "account.data.language.14": "Maghiară",
    "account.data.language.15": "Indonesian",
    "account.data.language.16": "Iranian",
    "account.data.language.17": "Italiană",
    "account.data.language.18": "Japoneză",
    "account.data.language.19": "Kannada",
    "account.data.language.20": "Korean",
    "account.data.language.21": "Mandarină",
    "account.data.language.22": "Marathi",
    "account.data.language.23": "Malayalam",
    "account.data.language.24": "Odia",
    "account.data.language.25": "Poloneză",
    "account.data.language.26": "Portugheză",
    "account.data.language.27": "Punjabi",
    "account.data.language.28": "Română",
    "account.data.language.29": "Rusă",
    "account.data.language.30": "Sârbă",
    "account.data.language.31": "Slovacă",
    "account.data.language.32": "Spaniolă",
    "account.data.language.33": "Swahili",
    "account.data.language.34": "Suedeză",
    "account.data.language.35": "Tamil",
    "account.data.language.36": "Telugu",
    "account.data.language.37": "Thai",
    "account.data.language.38": "Turcă",
    "account.data.language.39": "Ucraineană",
    "account.data.language.40": "Urdu",
    "account.data.language.41": "Vietnameză",
    "account.data.language.42": "Alte",
    "account.data.relationship.status": "Starea relației",
    "account.data.relationship.status.1": "Singur",
    "account.data.relationship.status.2": "Într-o relație",
    "account.data.relationship.status.3": "Divorțat",
    "account.data.relationship.status.4": "Căsătorit",
    "account.data.relationship.status.5": "Văduv",
    "account.data.relationship.history.1": "Nu am avut niciodată o relație sau o relație pe termen lung cu cineva",
    "account.data.relationship.history.2": "Nu am avut niciodată o relație care au durat cel puțin 2 ani",
    "account.data.relationship.history.3": "Am avut 1 relații care au durat cel puțin 2 ani",
    "account.data.relationship.history.4": "Am avut 2 relații care au durat cel puțin 2 ani",
    "account.data.relationship.history.5": "Am avut 3 sau mai multe relații care au durat cel puțin 2 ani",
    "account.data.relationship.history.6": "Am trăit într-o căsătorie sau concubinaj",
    "account.data.relationship.history.7": "Am trăit în 2 căsătorii sau parteneriate interne",
    "account.data.relationship.history.8": "Am trăit în 3 sau mai multe căsătorii sau parteneriate interne",
    "account.data.children.1": "Nici unul, și nu vreau nici unul",
    "account.data.children.2": "Niciunul",
    "account.data.children.3": "Un",
    "account.data.children.4": "Două dintr-o relație",
    "account.data.children.5": "Două din relații diferite",
    "account.data.children.6": "Trei sau mai multe dintr-o relație",
    "account.data.children.7": "Trei sau mai multe din relații diferite",
    "account.data.living.status.1": "Locuiesc singur",
    "account.data.living.status.2": "locuiesc cu un prieten/prieteni",
    "account.data.living.status.3": "Locuiesc cu un părinte/părinții",
    "account.data.living.status.4": "Locuiesc cu partenerul sau soțul/soția mea",
    "account.data.living.status.5": "Alte",
    "account.data.body.type.1": "Slab",
    "account.data.body.type.2": "Subţire",
    "account.data.body.type.3": "Tonifiat",
    "account.data.body.type.4": "Musculos",
    "account.data.body.type.5": "Îndesat",
    "account.data.body.type.6": "Obez",
    "account.data.money.management.habits": "Când vine vorba de bani",
    "account.data.money.management.habits.1": "Cheltuiesc, cheltuiesc, cheltuiesc. De aceea s-au inventat cardurile de credit.",
    "account.data.money.management.habits.2": "Salariul meu este să-mi petrec ziua în care îl primesc.",
    "account.data.money.management.habits.3": "Mă scindeez ocazional, dar chiar și atunci cu moderație.",
    "account.data.money.management.habits.4": "Acord o atenție deosebită și cheltuiesc doar pe ceea ce îmi pot permite.",
    "account.data.money.management.habits.5": "Iau fiecare bănuț cu mine în mormânt.",
    "account.data.upbringing": "Creșterea mea din copilărie a fost petrecută mai ales",
    "account.data.upbringing.1": "Într-un orfelinat",
    "account.data.upbringing.2": "În plasament",
    "account.data.upbringing.3": "Trăind cu rude sau prieteni de familie",
    "account.data.upbringing.4": "Trăind exclusiv cu un părinte biologic”;",
    "account.data.upbringing.5": "În timp împărțit între părinți separați",
    "account.data.upbringing.6": "Într-o familie tradițională, strâns unită",
    "account.data.temperament.chart.style": "Tip de Temperament",
    "account.data.temperament.tag": "Temperament",
    "account.data.personality.chart.dimension": "Dimensiuni de Pesonalitate",
    "account.data.personality.dimensions.tag": "Dimensiuni",
    "account.data.personality.chart.traits": "Trăsături de Personalitate",
    "account.data.personality.traits.tag": "Trăsături",
    "account.data.difference.between.us": "Varianța dintre noi”",
    "account.data.love.style.chart.style": "Dimensiuni Stil de Iubire",
    "account.data.love.style.tag": "Stil de Iubire",
    "account.data.full.name": "Nume complet",
    "contact.resend": "Retrimit",
    "contact.resend.invitation": "Retrimit invitație",
    "account.edit.gender.female": "Femenin",
    "account.edit.gender.male": "Masculin",
    "global.button.back.to.questionnaires": "Înapoi la chestionare",
    "global.generic.success.message": "Completat cu succes",
    "category": "",
    "verifyemail.message": "Verificați adresa dvs. de e-mail și apasa pe link pentru verificare prin e-mail.",
    "verifyemail.status.title": "Verificați adresa de e-mail",
    "verifyemail.status.description.success": "Adresa de e-mail a fost verificată cu succes.",
    "verifyemail.status.description.error": "Verificare adresa e-mail nereuşită!",
    "starsign.result.comingsoon": "În curând",
    "starsign.result.show": "Citiți descrierea",
    "starsign.result.get": "Citiți descrierea",
    "starsign.resultinfo.title": "Cumpărați descrierea",
    "starsign.resultinfo.desc": "Costul de a citi descrierea caracterului în întregime costă 2 credite. Dacă nu mai aveți credit în cont, vă rugăm să plasați o comandă.",
    "starsign.noresult.title": "Descrierea nu este disponibil.",
    "starsign.noresult.desc": "Descrierea persoanei nu este încă disponibilă în limba selectată. Puteți selecta dintr-o altă limbă, dacă este cazul.",
    "MissingConfig": "Solicitarea de configurare lipsește.",
    "UnknownConfig": "",
    "UnknownUser": "Utilizator necunoscut",
    "UnknownCompetition": "",
    "NoPermission": "",
    "MissingAnswer": "",
    "AlreadyAnswered": "S-a răspuns deja",
    "NotPayable": "",
    "AlreadyArchived": "",
    "MetaMustBeAnObject": "",
    "UnknownCompetitionConfig": "",
    "MissingTopUpPaymentId": "",
    "UnknownTopUpPayment": "",
    "PaymentAlreadyIssued": "",
    "UnknownPayment": "UnknownPayment",
    "NotYourBusiness": "NotYourBusiness",
    "MissingTopupIdentifier": "MissingTopupIdentifier",
    "UnknownTopup": "UnknownTopup",
    "MissingNewPassword": "Lipsește parola nouă",
    "FatalPasswordChangeResponse": "FatalPasswordChangeResponse",
    "InvalidOldPassword": "InvalidOldPassword",
    "UnableToRetriveValidatedUser": "UnableToRetriveValidatedUser",
    "UnableToRetriveUpdatedUser": "UnableToRetriveUpdatedUser",
    "UnableToRetriveRefreshedUser": "UnableToRetriveRefreshedUser",
    "MissingCredentials": "MissingCredentials",
    "MissingEmail": "Lipsește e-mailul",
    "MissingFirstName": "Lipsește prenume",
    "MissingLastName": "Lipsește nume",
    "MissingBirthDate": "Lipsește data nașterii",
    "InvalidBirthDate": "Dată de naștere nevalidă",
    "MissingPassword": "Lipsește parola",
    "EmailIsTakenByAnotherUser": "Această adresă de e-mail este deja luată.",
    "CannotCreateUser": "Utilizatorul nu poate fi creat",
    "CannotReproduceRegistrationResult": "CannotReproduceRegistrationResult",
    "CannotSetPasswordValidation": "CannotSetPasswordValidation",
    "MissingAccessToken": "Tokenul de acces Facebook lipsește.",
    "NotEnoughBalance": "Nu aveți suficiente credite pentru a continua.",
    "UnableToUpdateBirthDate": "UnableToUpdateBirthDate",
    "MissingResultTypes": "MissingResultTypes",
    "UnableToSupportOneOrMultipleResultTypes": "UnableToSupportOneOrMultipleResultTypes",
    "UnableToGenerateOneOrMultipleResultTypes": "UnableToGenerateOneOrMultipleResultTypes",
    "global.language.hu": "HU",
    "global.language.huLong": "Maghiară",
    "global.language.en": "EN",
    "global.language.enLong": "Engleză",
    "global.language.ro": "RO",
    "global.language.roLong": "Română",
    "global.label.credit": "credit",
    "global.label.credits": "credite",
    "Unauthenticated": "Utilizator sau parola incorecte",
    "profile.buttons.delete": "Șterg contul",
    "starsign.result.delete.title": "Şterge înregistrarea",
    "starsign.result.delete.desc": "Această acțiune îți va șterge complet istoria.",
    "starsign.result.edit.title": "Redenumiți persoana",
    "account.delete.title": "Șterg contul permanent",
    "account.delete.desc": "Aceasta operatiune va şterge ireversibil toate datele din sistem. De asemenea vei pierde istoricul tranzactiilor şi eventualul credit aflat în contul tău.",
    "starsign.result.readmore": "Citeşte mai departe",
    "notfound.title": "Pagina nu poate fi găsit.",
    "notfound.description": "",
    "notfound.backtohome": "Înapoi la pagina principală",
    "comingsoon.title": "În curând",
    "comingsoon.workshop": "Workshopuri",
    "comingsoon.assessment": "Evaluarea cuplurilor",
    "comingsoon.questionnaires": "Chestionare",
    "comingsoon.description": "Acest serviciul este în dezvoltare.",
    "comingsoon.backtohome": "Înapoi la pagina principală.",
    "contact.firstName": "Prenume",
    "contact.lastName": "Nume",
    "contact.email": "Email",
    "contact.message": "Mesaj",
    "contact.send": "Trimit",
    "contact.title": "Vrem să auzim de la tine! Vă rugăm să utilizați acest formular de contact",
    "contact.successTitle": "Mesajul a fost trimis cu succes!",
    "contact.successDescription": "Vă rugăm să fiți înțelegători și să aveți răbdare, revenim cu un răspuns în cel mai scurt timp.",
    "loginImage": "/assets/login-assets/image-slide-ro.webp",
    "celebStories": [
        {
            "first": {
                "name": "Cabral Ibacka",
                image: 'cabral'
            },
            "second": {
                "name": "Andreea Ibacka",
                image: 'andreea'
            },
            "score": 0.9
        },
        {
            "first": {
                "name": "Radu Vâlcan",
                image: 'radu'
            },
            "second": {
                "name": "Adela Popescu",
                image: 'adela'
            },
            "score": 0.85
        },
        {
            "first": {
                "name": "Cătălin Măruță",
                image: 'catalin'
            },
            "second": {
                "name": "Andra Măruță",
                image: 'andra'
            },
            "score": 0.65
        },
        {
            "first": {
                "name": "Mircea Badea",
                image: 'mircea'
            },
            "second": {
                "name": "Carmen Brumă",
                image: 'carmen'
            },
            "score": 0.55
        }
    ],
    "Pragma": "Conștiincios",
    "Mania": "Nevrotic",
    "Storge": "Intim",
    "Agape": "Altruist",
    "Ludus": "Promiscuu",
    "Eros": "Pasionat",
    "traditionalist": "Tradiţionalist",
    "rationalist": "Raţionalist",
    "adventurer": "Entuziast",
    "caretaker": "Idealist",
    "Open-Mindedness": "Deschis",
    "Conscientiousness": "Conștiincios",
    "Agreeableness": "Acordabil",
    "Extroversion": "Sociabil",
    "Emotionality": "Emoțional",
    "Honesty-Humility": "Moral",
    "Flexibility": "Flexibil",
    "Forgiveness": "Iertător",
    "Gentleness": "Blând",
    "Patience": "Pacient",
    "Diligence": "Harnic",
    "Organization": "Organizat",
    "Perfectionism": "Perfecționist",
    "Prudence": "Prudent",
    "Anxiety": "Anxios",
    "Dependence": "Dependent",
    "Fearfulness": "Fricos",
    "Sentimentality": "Sentimental",
    "Liveliness": "Energetic",
    "Sociability": "Prietenos",
    "Social-Boldness": "Curajul social",
    "Social-Esteem": "Stima socială",
    "Corrupt-Avoidance": "Incoruptibil",
    "Greed-Avoidance": "Nematerialist",
    "Modesty": "Modest",
    "Own-Interest-Avoidance": "Autentic",
    "Creativity": "Creativ",
    "Cultured": "Culturat",
    "Inquisitiveness": "Curios",
    "Unconventionality": "Neconvențional",
    "couples.assessment.behaviour.description": "",
    "couples.assessment.personality.dimensions.description": "",
    "couples.assessment.personality.traits.description": "",
    "couples.assessment.love-style.description": "",

    "account.data.behavioural.questionnaire.description.incomplete": `Temperamentul are rădăcini în structura dumneavoastră biologică și se schimbă rareori în timp. Este moștenit de la părinții tăi și formează baza comportamentului tău de bază. Temperamentul determină reacțiile tale instinctive și răspunsurile la factorii externi. Gândiți-vă la cele patru dimensiuni ale temperamentului ca la un model de culoare CMYK, profilul dvs. comportamental este un amestec diferit de toate cele patru culori. Nivelul rezultat al fiecărei scale generează predispoziția dumneavoastră înnăscută la temperament. Înțelegerea stilului tău de temperament nu numai că poate ajuta la creșterea armoniei tale interioare printr-o introspecție mai clară și auto-dezvoltare, dar poate ajuta și la creșterea armoniei interpersonale prin înțelegerea dinamicii din spatele conexiunilor tale personale cu ceilalți. Aplicarea cunoașterii conceptului de stiluri de temperament vă va ajuta, de asemenea, să vă îmbunătățiți relația cu un părinte, o rudă, un prieten, un coleg și mai ales un amant.
</br> </br> <strong>Instrucțiuni:</strong> pur și simplu selectați răspunsul care credeți că este cel mai reprezentativ pentru dvs. Nu există un răspuns corect sau greșit, așa că nu te gândi prea mult la el. Doar mergeți cu instinctul și selectați alegerea care vi se pare cea mai bună mai întâi.`,
    "account.data.love.style.questionnaire.description.incomplete": `Dragostea este un sentiment natural de atracție fizică și emoțională față de o altă persoană. Cu toate acestea, este un fenomen complex pe care toată lumea îl experimentează diferit. Acest chestionar vă va ajuta să înțelegeți de ce dragostea și romantismul înseamnă ceea ce înseamnă pentru dvs. Și ce poate însemna pentru alții. Chestionarul despre stilul de dragoste măsoară înclinația ta naturală de comportament în relațiile romantice. Ea va cuantifica atitudinea ta față de șase dimensiuni din domeniul iubirii. Acest lucru va duce la o evaluare a stilului dvs. De dragoste și vă va informa ce tip vi se potrivește cel mai bine. Diagramele și analiza vă vor oferi un instrument pentru a înțelege mai bine ce are nevoie și ce vrea cineva, sau altcineva, într-o legătură intimă. Astfel de informații pot deschide calea către o viață amoroasă mai împlinită și pot construi relații romantice mai sănătoase.
</br> </br> <strong>Instrucțiuni:</strong> Nu vă gândiți la o persoană anume atunci când completați chestionarul. În schimb, imaginați-vă un partener „ideal” care ar putea să vă îndeplinească cel mai bine nevoile și dorințele instinctuale. Raspunsurile tale vor reflecta stilul tau natural. Dacă aveți în vedere o anumită persoană atunci când completați, rezultatele dvs. Vor reflecta stilul dvs. De dragoste adaptat acelei persoane, deoarece ceilalți oameni influențează de obicei stilul nostru. De aceea este important să fii cu cineva care îți completează stilul natural, mai degrabă decât să-l slăbească.`,
    "account.data.personality.main.dimensions": `Cele două diagrame de mai jos reflectă rezultatele pentru cele 6 dimensiuni de personalitate testate. Prima diagramă afișează nivelul individual de semnificație al fiecărei dimensiuni dintr-un total de 100%. Diagrama colorată afișează diferența fiecărei dimensiuni față de media populației de gen. Această diagramă reflectă mai precis care sunt caracteristicile dvs. Personale, deoarece arată care dimensiune diferă cel mai mult de medie. Aceasta variatie este cea care arata cel mai bine care este personalitatea ta naturala.`,
    "account.data.personality.sub.traits": `Diagramele de mai jos afișează rezultatele a 4 trăsături din fiecare dintre cele 6 dimensiuni ale personalității. Acest lucru echivalează cu un total de 24 de trăsături testate. Prima diagramă arată care este nivelul fiecărei trăsături dintr-un total de 100%. Diagrama colorată afișează variația fiecărei trăsături față de media populației de gen. Trăsăturile și diferențele care le însoțesc față de medie oferă o perspectivă profundă asupra compoziției personalității dvs. unice.`,
    "account.data.personality.questionnaire.description.complete": `</br> </br> <strong>Dimensiunile personalității</strong> </br> </br>
Primele două diagrame de mai jos reflectă rezultatele pentru cele 6 dimensiuni de personalitate testate. Prima diagramă afișează nivelul individual de semnificație al fiecărei dimensiuni dintr-un total de 100%. Diagrama colorată afișează diferența fiecărei dimensiuni față de media populației de gen. Această diagramă reflectă mai precis care sunt caracteristicile dvs. Personale, deoarece arată care dimensiune diferă cel mai mult de medie. Aceasta variatie este cea care arata cel mai bine care este personalitatea ta naturala.
</br> </br> <strong>Trăsături de personalitate</strong> </br> </br>
Ultimele două grafice de mai jos afișează rezultatele a 4 trăsături din fiecare dintre cele 6 dimensiuni ale personalității. Acest lucru echivalează cu un total de 24 de trăsături testate. Prima diagramă arată care este nivelul fiecărei trăsături dintr-un total de 100%. Diagrama colorată afișează variația fiecărei trăsături față de media populației de gen. Trăsăturile și diferențele care le însoțesc față de medie oferă o perspectivă profundă asupra compoziției personalității dvs. unice.`,
    "account.data.personality.questionnaire.description.incomplete": `Modul în care te ocupi de lumea din jurul tău este influențat de personalitatea ta. Factori precum genetica, educația și experiențele de viață contribuie și formează personalitatea pe care o aveți. Personalitatea este relativ stabilă, prin urmare, este, de asemenea, destul de dificil de schimbat. Numai prin conștientizare de sine și muncă grea poți începe să o modifici. Psihologii au identificat mii de trăsături de personalitate care descriu comportamentul uman. Trăsăturile sunt blocurile care alcătuiesc persoana unică care ești, ele contribuie foarte mult la modul în care navighezi în această lume. În consecință, interacțiunea dintre numeroasele tale trăsături are ca rezultat modelul unic de gânduri, sentimente și comportamente pe care le ai.
Trăsăturile de personalitate se comportă într-un spectru, ambele capete fiind opuse (de exemplu, curajul este pe un capăt, în timp ce lașitatea este pe celălalt capăt). Chestionarul de mai jos va analiza 6 dimensiuni principale ale personalității și 24 trăsături. Toate acestea sunt caracteristici universale prezente în toți oamenii într-o anumită măsură, ceea ce ne permite să efectuăm o analiză comparativă între indivizi. Cunoașterea trăsăturilor nu numai că vă va permite să înțelegeți și să vă preziceți mai bine motivele, dar vă va permite, de asemenea, să preziceți comportamentul altora. Ca atare, va deschide calea pentru relații mai bune cu ceilalți, deoarece veți fi echipați cu cunoașterea a cine vă completează în mod natural atributele personale cel mai bine și cine nu.
</br> </br> <strong>Instrucțiuni:</strong>
Rezultatele chestionarului dvs. Nu sunt nici corecte, nici greșite, nici bune sau rele - pur și simplu vă informează în ce măsură fiecare trăsătură vă influențează. Nu vă gândiți prea mult la răspunsurile dvs., selectați doar primul răspuns pe care îl simțiți cel mai adecvat pentru a reflecta opinia dvs. Față de afirmație.`,
    "account.data.questionnaire": `CHESTIONAR`,
    "traditionalist.category.description": `Persoanele care au un scor mare pe dimensiunea Tradiționalist(ă) văd lucrurile fie în alb, fie în negru, nu există nici un mijloc între ele. Ele sunt tipuri precise, precise, structurate și analitice care sunt conduse de fapte și cifre. Sunt persoane consecvente și sistematice care aderă la reguli și rutine. Ei prețuiesc punctualitatea și corectitudinea și sunt unii dintre cei mai de încredere oameni pe care îi poți întâlni. Ei spun ceea ce fac, fac ceea ce spun și așteaptă același lucru de la alții. O promisiune făcută este o promisiune ținută întotdeauna de o persoană extrem de tradiționalistă.
    </br> </br>Tradiționaliștii au nevoie să lucreze metodic pentru a-și face treaba perfect, chiar și în detrimentul timpului. Greșelile și greșelile sunt cel mai mare coșmar al lor. Sunt deliberati si nu le place sa critice munca sau deciziile lor. Ca atare, ei sunt persoane cu opinii și judecăți care nu se simt confortabil să facă mici discuții. Astfel de tendințe îi fac să fie lipsiți de emoții, supra-analitici și inflexibili. Tradiționaliștii se ciocnesc cu entuziaștii, deoarece sunt aproape opuși, și se înțeleg cel mai bine cu alți tradiționaliști logici și rigizi.`,
    "caretaker.category.description": `Persoanele care au un scor mare pe dimensiunea Idealist(ă) își urmează inima, nu creierul. Maica Tereza este întruchiparea unui idealist înalt. Persoanele care au un scor mare pe această dimensiune sunt foarte relaxante, non-conflictuale, și sunt mari ascultători care iubesc să-i ajute pe alții. Idealiștii sunt blânzi, grijulii, calzi și cei mai atenți oameni pe care îi poți întâlni. Ca atare, ei sunt adepți cooperanți, care sunt de încredere și de susținere a altora, ceea ce îi face jucători de echipă mari. Datorită naturii lor altruiste, ei au tendința de a lua decizii bazate pe emoție. Cu toate acestea, judecata lor este adesea irațională și duce la probleme.
    </br> </br>Ei văd ce este mai bun în oameni și au un grad atât de ridicat de compasiune încât sunt adesea văzuți ca niște împingere credulibile. Acest lucru ar putea fi foarte bine adevărat, deoarece ei doresc doar să iubească și să fie iubiți. Se știe că sunt indeciși, nesiguri, se sacrifică de sine și impresionabili. Cu o mare nevoie de siguranță și stabilitate, nu le place incertitudinea și nu sunt tipurile agresive de auto-starter. Ei au probleme în a face față situațiilor de presiune și, de obicei, le provoacă o mare anxietate. În relațiile romantice, idealiștii găsesc echilibrul și armonia în opusul lor, raționaliștii. În timp ce ei pot percepe raționaliștii ca fiind reci sau neemoționali, ei sunt cei care îi completează cel mai bine pe termen lung.`,
    "adventurer.category.description": `Persoanele care au un scor mare pe dimensiunea Entuziast sunt cele mai sociale, energice, pozitive, pline de viață, prietenoase, oameni deschiși pe planetă. Sunt persoane care zâmbesc mereu, râd, glumesc și șmochează cu toată lumea. Au o natură distractivă și jucăușă, făcându-i prieteni amuzanți și populari. Ei sunt „persoana oamenilor” și sunt întotdeauna înconjurați de alții. Entuziaștii sunt foarte creativi și gândesc în afara cutiei, ca atare, disprețuiesc rutinele și tânjesc după noutate. Această tendință îi face predispuși să se plictisească destul de ușor. Cei care au un scor mare pe această scară sunt creaturi spontane și dinamice care se bucură de viață pe banda rapidă. Ei au ouăle în mai multe coșuri și, de obicei, le jonglează pe toate în același timp.
    </br> </br>Extragerea plăcerii maxime din fiecare zi pentru a-și îndeplini dorințele personale este de cea mai mare importanță pentru ei. Motto-ul lor de viață este „o viață fără aventură și entuziasm este o viață care nu merită trăită”. Entuziaștii consideră că este dificil să-și controleze impulsurile și au dificultăți în a spune nimănui, în special ei înșiși. Ei sunt binecuvântați cu abilități incredibile de comunicare, ceea ce îi face mari motivatori care îi pot convinge cu ușurință pe ceilalți. Oamenii extrem de entuziaști sunt animați și pot vorbi cu capul oricui. Din păcate, ei au o înclinație pentru încălcarea regulilor și sunt adesea văzuți ca fiind tardici, haotici, imprevizibili, flydry și nesiguri. Ei se tem de respingere și au o mare dorință de recunoaștere, aprobare și acceptare socială. Entuziaștii vor considera că Tradiționaliștii sunt prea previzibili și plictisitori. Ei vor experimenta cel mai distractiv și mai mulțumit cu un alt entuziast cu inima ușoară.`,
    "rationalist.category.description": `Oamenii care obțin un scor mare pe dimensiunea Raționalist(ă) sunt extrem de obiectivi și sensibili. Ei sunt cei mai autosuficienți, independenți, dominanți, neînfricați și siguri de sine din jur. Sunt persoane curajoase care isi asuma riscuri si iubesc provocarile si rezolva rapid probleme complexe. Sunt luptători cărora le place să concureze, să nu fie de acord, să dezbată și să-și flexeze puterea intelectuală. Persoanele cu un scor mare pe această scară sunt persoane cu rezultate orientate spre rezultate cărora le place să fie în control. Ele sunt directe, decisive și iau măsuri. Niciodată să nu-ți fie frică de conflict, ei prosperă în opoziție și nu au nici o mustrare în legătură cu luarea taurului de coarne. A da putere și autoritate vine în mod natural pentru ei.
    </br> </br>Raționaliștii care se găsesc conduși, mințiți sau exploatați vor fi extrem de nemulțumiți. Sunt nerabdatori si sunt predispusi la accese de furie atunci cand lucrurile nu merg asa cum trebuie. Acest lucru este valabil mai ales atunci când alții sunt înceți, ineficienți sau pur și simplu nu își îndeplinesc așteptările. Această calitate îi face jucători de echipă săraci, cu excepția cazului în care aceștia sunt responsabili de echipă. Raționaliștii pot părea nepoliticoși sau fără tact, frecând mulți oameni într-un mod greșit. Din fericire, ei nu sunt afectați de opinia altora și nu au nici o problemă să fie singuri, în partea de sus, desigur. Acesta este motivul pentru care ele se vor ciocni cu majoritatea celorlalte temperamente, cu excepția idealiștilor. Idealiștii vor fi fericiți să oblige toate dorințele raționalistului și sunt singurii care pot străpunge învelișul lor exterior puternic.`,
    "Honesty-Humility.category.description": `Oamenii care au un scor mare pe dimensiunea Moral(ă) se ferește de comportamentul înșelător. Ei respectă normele sociale, se joacă după reguli și nu vor păcăli niciodată pe alții pentru câștiguri personale. După cum sugerează și numele, acest factor măsoară tendința unei persoane de a spune adevărul, de a fi dreaptă și de a se conforma standardelor sociale a ceea ce este bine și rău. Indivizii morali sunt scrupuloși și iau în considerare efectele acțiunilor lor. Ei sunt, de asemenea, umili și își vor sacrifica propriile interese pe termen scurt în beneficiul societății. Cei care au un scor mare nu sunt materialiști și nu sunt preocupați să obțină bogăție generoasă, lux, privilegii speciale sau să se ridice la un statut social ridicat.
    </br> </br>În schimb, persoanele cu scoruri foarte scăzute sunt imorale și îi vor flata pe alții să obțină ceea ce doresc, sunt înclinate să încalce regulile pentru profitul personal și sunt foarte motivate de câștigul material. În plus, ei simt un sentiment puternic de auto-importanță și au grijă doar de ei înșiși. Acest lucru face ca astfel de persoane să aibă mai multe șanse de a avea calități narcisiste sau machiavelice.
    </br> </br>În ceea ce privește compatibilitatea romantică, similitudinea dintre tovarășii din această dimensiune câștigă pe termen lung. Cu toate acestea, persoanele lipsite de etică preferă, de obicei, să se prade pe parteneri principiali, deoarece pot fi ușor de profitat. Cu toate acestea, pe termen lung, răufăcătorii, hoții și ticăloșii vor găsi mai multă fericire în comun decât lipsa de similitudine, chiar și cu prețul de a nu avea încredere cu adevărat unul în celălalt.`,
    "Agreeableness.category.description": `Persoanele cu scoruri foarte mari pe dimensiunea Agreabil(ă) iartă nedreptățile pe care le-au suferit și sunt tolerante față de neajunsurile altora. Oamenii agreabili nu vor să-i tulbure penele nimănui, astfel, vor face mari eforturi pentru a face compromisuri și a coopera cu alții pentru a menține pacea. De fapt, ei sacrifică adesea ceea ce doresc de fapt doar de dragul mulțumirii celeilalte persoane. În plus, sunt manierați și rareori au sentimente rele față de cei care i-au insultat. Ei nu au resentimente și au capacitatea de a renunța rapid la sentimentele negative.
    </br> </br>Dimpotrivă, oamenii dezagreabili sunt de obicei competitivi, argumentativi și disprețuiți față de cei care i-au nedreptățit. De asemenea, ei sunt destul de critici la adresa greșelilor altora, își apără cu încăpățânare opiniile și simt cu ușurință furia ca răspuns la maltratarea de către alții.
    </br> </br>Oamenii agreabili sunt cei mai compatibili cu un partener argumentativ, deoarece se echilibrează cel mai bine. Dacă ambele persoane sunt prea acomodant, sau invers, nici unul nu cedează, nu va face o potrivire bună pe termen lung din cauza prea multă similitudine. O parte trebuie să cedeze întotdeauna pentru a pune capăt unui impas, ca atare, numai contrariile din această dimensiune pot găsi cu adevărat fericirea pe termen lung.`,
    "Conscientiousness.category.description": `Persoanele care au un scor mare pe dimensiunea Conștiincios(oasă) își planifică timpul și viața. Acești oameni sunt foarte concentrați, motivați și depun un mare efort pentru a-și structura viața și cariera. Le place foarte mult să controleze și să organizeze fiecare aspect al a tot ceea ce pot. Indivizii cu conștiință înaltă sunt disciplinați, determinați, responsabili și de încredere. Sunt extrem de orientate spre detalii și prețuiesc precizia peste orice altceva. Pe langa faptul ca sunt perfectionisti, ei muncesc din greu si vor sacrifica sau intarzia gratificarea personala de dragul atingerii obiectivelor lor. În ceea ce privește procesul de luare a deciziilor, aceștia sunt metodici și trebuie să adune cât mai multe informații cu privire la subiect pentru a asigura o evaluare adecvată.
    </br> </br>Dimpotrivă, persoanele cu scoruri scăzute pe această scară tind să nu fie preocupate de a trăi în medii ordonate sau de a respecta programele stabilite. Ele par neglijente și nefocalizate și sunt atât de relaxate încât permit vântului să le ghideze direcția. Ei evita sarcinile dificile, rareori stabilesc obiective provocatoare, rareori planifica inainte si pur si simplu lasa lucrurile sa se intample. Nu numai că oamenii ocazionali sunt mulțumiți de o muncă grăbită, plină de erori, dar sunt, de asemenea, de obicei dezorganizați și iau decizii din impuls sau cu o deliberare minimă.
    </br> </br>În ceea ce privește compatibilitatea romantică, această dimensiune este dificilă, deoarece unele trăsături trebuie să fie similare și unele opuse polare. Oamenii conștiincioși se înțeleg cel mai bine cu alți oameni conștiincioși. Cu toate acestea, în ceea ce privește relațiile romantice, acest lucru nu este cazul, deoarece acestea vor duce, în general, la cap. Pe termen lung, un amestec de trăsături similare și opuse în această dimensiune se dovedesc a fi cea mai bună combinație pentru satisfacția pe termen lung a relației.`,
    "Emotionality.category.description": `Oamenii care au un scor mare pe dimensiunea Emoțional(ă) se tem de pericolele fizice, experimentează nervozitate atunci când se confruntă cu stresul de zi cu zi al vieții și necesită o mare susținere emoțională. Ei simt empatie profundă și au atașamente sentimentale cu cei din jurul lor. Cei care au un scor extrem de mare au mai multe șanse să experimenteze sentimente sporite de anxietate, paranoia, depresie și chiar să devină agresivi în auto-apărare.
    </br> </br>Pe de altă parte, cei cu scoruri foarte scăzute pe această scară nu sunt speriați sau speriați de gândul la vătămări fizice, sunt persoane care își asumă riscuri calme și simt o îngrijorare minimă chiar și în circumstanțe stresante. Rareori simt nevoia de a-si impartasi problemele cu ceilalti, iar acest lucru ii face sa se simta detasati emotional de ceilalti.
    </br> </br>Când vine vorba de relații romantice, contrariile oferă cel mai bun echilibru unul față de celălalt. O pereche între doi indivizi extrem de emoționali riscă să se dezvolte și să trăiască într-o relație toxică, deoarece se vor conduce unul pe altul pe termen lung. În schimb, doi tovarăși neemoționali pot duce la o conexiune monotonă și apatică. Cel mai bun echilibru este atins atunci când ambii parteneri au scoruri contrastante pe această dimensiune.`,
    "Open-Mindedness.category.description": `Persoanele cu scoruri mari pe dimensiunea Deschis(ă) sunt binevenite de idei noi, subiecte, opinii, obiceiuri, experiențe, și informații, chiar dacă au opinii opuse. Ei sunt curioși despre lumea din jurul lor, se simt confortabil să încerce lucruri noi, să participe la activități creative și au o gamă largă de interese cu o „mentalitate de creștere”. Acești indivizi sunt mai înclinați să exploreze diferite părți ale vieții, concepte alternative și moduri diferite de gândire. Oamenii deschiși sunt dispuși să caute în mod activ dovezi împotriva propriilor convingeri, planuri sau obiective și vor cântări în mod corect orice dovadă împotriva ei. Ei sunt oameni cu o minte largă, care sunt fascinați de frumusețea artei și a naturii, sunt curioși cu privire la diverse domenii ale inteligenței, își aplică imaginația cu generozitate în viața de zi cu zi și se interesează de idei și oameni neconvenționali.
    </br> </br>Pe de altă parte, cei care au un scor scăzut pe spectru sunt considerați închiși sau cu mintea îngustă. Ei sunt destul de neinspirați de operele de artă, nu simt deloc curiozitate intelectuală, evită preocupările creative și au puțină atracție față de filosofii care pot părea radicale sau neortodoxe. Acești oameni preferă lucrurile așa cum sunt și rareori ies din zona lor de confort. Persoanele cu mintea închisă sunt persoane prudente, concrete, consecvente și convenționale cărora nu le place să gândească în afara cutiei. Diverse rutine, activități sau teorii fac ca mentalitatea lor rigidă să devină dezechilibrată.
    </br> </br>Expresia „contrariile se atrag, dar mai târziu atacă” este destul de mișcătoare pentru două persoane cu niveluri opuse în această dimensiune. Partenerul deschis se va simți constrâns și limitat de un partener închis. Mințile strălucite gândesc la fel, dar când o persoană deschisă și închisă devine legată romantic, rareori se termină bine. Nici una dintre părți nu se va simți vreodată mulțumită în uniune, deoarece o parte nu se simte niciodată suficient de bună, în timp ce cealaltă parte simte că poate face mai bine.`,
    "Extroversion.category.description": `Persoanele cu scoruri foarte mari pe dimensiunea Sociabil(ă) sunt persoane vesele și optimiste care se simt în largul lor și al celorlalți. Ei sunt în mod natural indivizi încrezători și asertivi care pot vorbi cu ușurință în fața unei audiențe sau pot conduce un grup de oameni. Ei se bucură de interacțiunile sociale și de stimularea care le însoțește. Extrovertiții sunt persoane expresive și energice care experimentează sentimente de entuziasm și energie atunci când au de-a face cu alții. Ei se simt în largul lor atunci când sunt înconjurați de oameni și răspund pozitiv la o abundență de activități în jurul lor - îi excită și îi împlinește.
    </br> </br>În schimb, persoanele cu scoruri scăzute pe această scară se văd adesea ca nepopulari, se simt inconfortabil în lumina reflectoarelor și pur și simplu preferă să fie singuri. Introvertitii sunt cei care sunt linistiti si retrasi si care nu sunt preocupati de un nivel ridicat de interactiune sociala sau activitate. Prin urmare, ei sunt cei care încearcă să elimine stimulii externi și preferă să se concentreze în interior, ceea ce îi face să se dea peste ei ca fiind plictisitori și pesimiști. A trăi o viață simplă și liniștită este singurul lor scop, deoarece le umple sufletul și le calmează simțurile.
    </br> </br>Din punct de vedere romantic, două contrarii din această dimensiune nu fac cuplul cel mai compatibil. Unul dintre parteneri caută entuziasm, în timp ce celălalt caută liniște. Oamenii plini de viață se înțeleg cel mai bine cu oamenii care sunt activi, în timp ce casele găsesc pace cu persoanele similare care preferă să stea acasă la fel de mult.`,
    "Own-Interest-Avoidance.category.description": `Scara reală măsoară tendința cuiva de a fi onest, direct și autentic în relațiile lor interpersonale. Persoanele cu un scor mare în această trăsătură sunt veridice și nu îi înșală pe alții pentru a obține ceea ce doresc. Ei sunt de obicei grijulii și iau în considerare interesul altora.
    </br> </br>Persoanele care au un scor mic sunt considerate a fi ipocriți. Sunt escrocherii cu două fețe care flatează și pretind că le place pe cineva pentru a obține ceea ce doresc. Escrocii sunt manipulatori fermecători care pot obține cu ușurință partea bună a cuiva și pot obține favoruri de la ei.`,
    "Modesty.category.description": `Scara modestă evaluează tendința unei persoane de a avea o viziune orizontală și realistă asupra ei înșiși. O persoană modestă are o părere limitată și clară despre sine și despre abilitățile sale. Ei nu se laudă, sunt nepretențioși și parțial timizi și își minimalizează capacitățile. Deși s-ar putea să se gândească la ei înșiși ca fiind obișnuiți, s-ar putea foarte bine să nu fie.
    </br> </br>Acei oameni care au un scor scăzut pe această scară sunt indivizi îngâmfați care se consideră mult mai mari decât sunt cu adevărat. Ego-ul lor înalt îi face să se simtă îndreptățiți la privilegii speciale și să ceară tratament preferențial, chiar și atunci când nu sunt vrednici.`,
    "Forgiveness.category.description": `Trasatura de iertare masoara dorinta cuiva de a renunta constient la resentimente si furie fata de o alta persoana. Ei simt încredere și plăcere față de cei care le-au cauzat rău și au dificultăți în a se simți respinși. Cei care au un scor scăzut sunt oameni răzbunători care au tendința de a „avea o ranchiună” împotriva unui infractor și chiar vor complota răzbunarea.
    </br> </br>Cei care au un scor mare pot avea încredere și pot continua cu ușurință relațiile amiabile cu un răufăcător, chiar și după ce au fost maltratați.`,
    "Gentleness.category.description": `Trasatura blanda masoara tendinta cuiva de a fi bun, plin de compasiune si respectuos in relatiile cu ceilalti. Sunt, de asemenea, grijulii și tandre, făcându-i să liniștească oamenii să fie în jur. Cei care au un scor scăzut tind să fie cruzi și grosolani în relațiile lor. Ei sunt, de asemenea, critici în evaluările lor asupra altora, în timp ce indivizii blânzi sunt reticenți în a face judecăți dure asupra altora.`,
    "Perfectionism.category.description": `Perfectionistii au tendinta de a fi meticulosi si preocupati de detalii. Ei sunt, de asemenea, prea alarmați de a face greșeli și vor sacrifica timp pentru a asigura excelența. Ei sunt ciudați de control care se luptă cu termenele limită, deoarece verifică și reverifică în mod constant munca pentru erori. Imperfectiunile starnesc sentimente negative puternice in interiorul lor, si de multe ori perfectionistii traiesc intr-o lume de regrete gandindu-se constant la “ce-ar fi daca…”, “ar fi trebuit sa…”. Sau „Îmi doresc…”.
    </br> </br>Acei indivizi cu rezultate scăzute produc o muncă pripită și neglijentă într-un efort de a termina rapid o sarcină în timp. Pe partea pozitivă, ei trăiesc vieți mai puțin stresate și anxioase, nu se tem de respingere, pot sărbători succesele, sunt mai mulțumiți în general și au, de obicei, o viziune mai valoroasă asupra lor înșiși.`,
    "Sentimentality.category.description": `Persoanele care sunt foarte sentimentale au tendinta de a simti un puternic atasament emotional fata de oameni, locuri, lucruri sau amintiri. Profunzimea legăturilor lor emoționale este atât de profundă încât le afectează capacitatea de a renunța. Oamenii sentimentali sunt adesea unii dintre cei mai empatici si nostalgici oameni pe care ii poti intalni. Sunt persoane sensibile, cărora le pasă cu adevărat de ceilalți, ceea ce le permite să dezvolte relații profunde cu ceilalți. Cu toate acestea, ele au reacții adverse atunci când apare o discontinuitate a unei legături.
    </br> </br>Persoanele cu scor scăzut sunt persoane detașate care pot rămâne fără emoții chiar și în circumstanțe dureroase. A spune la revedere unei persoane iubite pentru o perioadă mai lungă de timp sau a trebui să renunți la vechile amintiri nu le mișcă deloc. De asemenea, tind să fie apatici față de nevoile și dorințele altora, ceea ce slăbește legăturile pe care le au cu cei din jurul lor.`,
    "Diligence.category.description": `Indivizii harnici au tendința de a fi harnici și productivi. Fiind extrem de diligent face ca cineva să acorde o atenție deosebită detaliilor și să adere la programe. Persoanele cu scor scăzut sunt persoane indolente care au o auto-disciplină marginală și nu sunt puternic motivate să vadă un obiectiv sau o sarcină până la finalizare. Ei au o etică a muncii minimalistă, în timp ce oamenii harnici au o „etică a muncii” puternică și sunt dispuși să facă sacrificii și să depună atât de mult efort cât este necesar pentru a realiza.`,
    "Prudence.category.description": `Oamenii prudenți sunt atenți la alegerile pe care le fac și exercită un mare control de sine. Ei încearcă să ia în considerare toate opțiunile disponibile și să gândească cu prudență înainte de a lua o decizie. Oamenii prudenți au capacitatea de a lua în considerare consecințele pe termen lung ale acțiunilor lor. Sunt persoane înțelepte, rezonabile și practice, care rareori au regrete pentru că și-au planificat decizia în avans.
    </br> </br>Cei care au un scor scăzut sunt oameni imprudenți. Ei sunt indivizi nesăbuiți și neînțelepți care acționează din impuls și tind să nu delibereze consecințele viitoare. În timp ce ei ar putea sări în picioare mai întâi fără să se gândească prea mult, deliberarea pentru prea mult timp poate avea, de asemenea, consecințe negative și poate duce la pierderea oportunităților rapide.`,
    "Organization.category.description": `O persoană Organizat(ă) are o tendință naturală de a căuta ordine, în special în mediul său fizic. Ei sunt îngrijiți, punctuali și adoptă o abordare structurată a muncii lor. Cei cu scor scăzut sunt procrastinatori care trăiesc într-o lume a distragerilor și sunt înconjurați de haos și dezordine. Oamenii foarte organizați tind să reușească și să depășească persoanele dezorganizate la locul de muncă, deoarece sunt mai predispuși să planifice, să prioritizeze și să acorde atenție.`,
    "Patience.category.description": `Trasatura pacientului masoara tendinta de a ramane calm si de a nu deveni iritat in timpul conditiilor adverse. Oamenii cu o mare răbdare rămân compacți și toleranți față de ceilalți, chiar și sub presiune. Persoanele cu un scor scăzut sunt persoane nerăbdătoare care au personalități de tip A. Ei zboară rapid de pe mâner, sunt neatenți cu ceilalți și au de obicei un stil de comunicare intuitiv prin care doresc să ajungeți la punct rapid. În schimb, persoanele foarte răbdătoare au un prag ridicat pentru a-și simți sau a-și exprima furia. Capacitatea oamenilor răbdători de a fi toleranți cu ceilalți îi determină în cele din urmă să aibă relații mai bune și cu oamenii.`,
    "Inquisitiveness.category.description": `Scara Curios(oasă) măsoară dorința unei persoane de a dobândi cunoștințe, experiență și de a interacționa cu toate aspectele lumii noastre. Ei sunt dornici să dobândească noi informații și să învețe explicațiile pentru lucruri, chiar și atunci când aplicațiile acestei noi învățări nu sunt imediat aplicabile. Curiozitatea este cea mai comună trăsătură a unei minți inteligente. Este, de asemenea, asociat cu niveluri mai ridicate de emoții pozitive, niveluri mai scăzute de anxietate, mai multă satisfacție cu viața și, în general, o satisfacție psihologică mai mare.
    </br> </br>Cei care au un scor scăzut sunt indiferenți față de lumea din jurul lor. Sunt persoane apatice care sunt marcate de lipsa de interes, entuziasm sau preocupare pentru ceva. Astfel de indivizi sunt de obicei vazuti ca fiind reci, distanti, dezinteresati, nemotivati si lipsiti de pasiune.`,
    "Corrupt-Avoidance.category.description": `Scara Incoruptibil(ă) măsoară tendința cuiva de a evita frauda și mita. Scorurile mari sunt persoane principiale care acționează cu integritate, sunt onești și veridice și au un puternic sentiment de corectitudine. Ei se comportă etic și nu sunt dispuși să profite de alții pentru câștiguri personale.
    </br> </br>Persoanele cu scoruri mici sunt corupte și dispuse să profite prin înșelăciune sau furt. Ei nu se gândesc de două ori să fie incorecți, să manipuleze, să mintă și să-și croiească drum spre vârf. Politicienii, directorii de afaceri, avocații și vânzătorii de mașini au reputația de a fi foarte coruptibili, în timp ce medicii, asistentele medicale, oamenii de știință și profesorii sunt printre cele mai puțin coruptibile profesii.`,
    "Unconventionality.category.description": `Scara Neconvențional(ă) evaluează tendința unei persoane de a accepta nu numai neobișnuit, ci și neobișnuit. Cei care au un scor mare sunt contrari care sunt atrași de neregularitate și sfidează în mod natural comunitatea. Atitudinea, opiniile și comportamentul lor contrazic societatea în general. Deschiderea lor naturală, nerăbdarea și lipsa preconcepțiilor le permit să fie total deschiși la tot felul de posibilități. De asemenea, le permite să vadă diferite soluții la o problemă. Gânditorii extrem de neconvenționali contemplă ce-ar fi și nu sunt uluite de obstacole. Ei visează la noi posibilități și rezolvă probleme complexe cu care alți oameni se tem să se confrunte. Acest lucru îi face indivizi excentrici cu o mentalitate capabilă să producă lucrări originale sau să fie vizionari cu idei originale.
    </br> </br>Persoanele care au un scor scăzut pe această scară evită indivizii ciudați și neconforme. Ei nu sunt receptivi la orice idei care par radicale sau neortodoxe. Ca atare, ele sunt tipuri conservatoare care sunt practice, previzibile și preferă o structură stabilită. Ei disprețuiesc schimbarea, nu vor să iasă în evidență din haită și îmbrățișează o viață obișnuită.`,
    "Dependence.category.description": `Dependent(ă) măsoară nevoia unei persoane de reasigurare emoțională și de asistență repetată din partea celorlalți. Oamenii foarte dependenți au tendința de a fi nevoiași, agasați, pasivi și temători de abandon. Ei au nevoie de multă încurajare și confort și sunt adesea incapabili să ia decizii dificile fără sfaturi sau îndrumare din partea altora.
    </br> </br>La capătul opus al spectrului sunt oameni extrem de independenți care nu au nevoie de sprijin în viață. Ei nu au nevoie de prea mult ajutor, deoarece stima lor de sine și încrederea lor îi vor vedea prin. Deși, a fi prea independent poate fi, de asemenea, dăunător, deoarece a nu avea nevoie de nimeni face dificilă relația cu cineva.`,
    "Sociability.category.description": `Scala Prietenos evaluează tendința cuiva de a participa la interacțiuni și întâlniri sociale și de a se bucura de a vorbi cu mulți oameni. Cei care au un scor mare sunt deschiși la noutatea socială și urmăresc în mod activ compania altora. Se bucură să fie înconjurați de oameni. Prin urmare, au o atitudine de ieșire, sunt adaptabili și au un spirit de echipă abundent.
    </br> </br>La capătul opus al spectrului sunt oameni neprietenoși care nu caută compania altora și aleg în schimb să se închidă și să rămână în singurătate. În cel mai rău caz, ei pot fi persoane ostile cărora nu le place să vorbească, să facă noi cunoștințe sau să participe la activități de grup sau sărbători.`,
    "Greed-Avoidance.category.description": `Scara Nematerialist(ă) măsoară tendința unei persoane de a respinge materialismul. Persoanele cu un scor mare tind să nu fie interesate să dețină bogăție generoasă, obiecte de lux sau să obțină un statut social ridicat. Prin urmare, ei tind să fie persoane profunde care prețuiesc lucruri în viață, lucruri pe care banii nu le pot cumpăra.
    </br> </br>Oamenii materialiști, pe de altă parte, doresc să acumuleze bogăție și să-și etaleze privilegiile. Ei sunt foarte motivați de rang financiar și social. Astfel de indivizi sunt adesea considerați superficiali din cauza credinței lor că banii și posesiunile sunt cele mai importante lucruri în viață.`,
    "Cultured.category.description": `Indivizii care obțin un scor mare pe scara Culturat(ă) sunt rafinați, civilizați și cultivați. Oamenii cultivați sunt descriși ca având bune maniere, sunt bine educați și știu multe despre civilizație. Se spune că o persoană este cultivată dacă a fost expusă și este influențată de științele umaniste, cum ar fi filosofia, muzica clasică, culturile trecute și prezente, istoria artei, știința și tehnologia. Ei nu numai că au o gamă largă de cunoștințe, dar le și îmbrățișează în viața lor de zi cu zi.
    </br> </br>Persoanele care au obținut un scor scăzut pe scară sunt ignorante, nerafinate și nesofisticate. Au o etichetă proastă și nu le place să meargă la teatru sau să viziteze muzee. În plus, ei sunt neinformați și neinteresați să călătorească în locuri îndepărtate pentru a afla despre istoria și tradițiile altor oameni.`,
    "Creativity.category.description": `Scala Creativ(ă) măsoară înclinația unei persoane de a inova și de a experimenta. Caracteristicile cheie inerente ale oamenilor creativi includ curiozitatea, flexibilitatea, intuitia, obiectivitatea, amanuntita, creativitatea, creativitatea, creativitatea, creativitatea, creativitatea, creativitatea, creativitatea, creativitatea, și ambițios. Cei cu scoruri mari sunt gânditori care evaluează o problemă din toate unghiurile posibile, în timp ce caută o soluție. Ei au pus multă gândire și grijă în munca lor și, prin încercare și eroare, au conceput cea mai bună soluție posibilă. În plus, ele sunt de obicei sensibile și au nevoie să-și exprime sentimentele prin crearea.
    </br> </br>Persoanele care au scoruri mici sunt lipsite de creativitate și imaginație. Ei sunt incapabili să conceapă gânduri și idei originale și sunt incapabili să inventeze sau să creeze ceva original. Persoanele aflate la capătul inferior al acestui spectru creativ nu sunt cu adevărat deschise la experiențe noi, nu visează cu ochii deschiși la fel de mult, nu sunt la fel de atente și nu își asumă atât de multe riscuri pe care o persoană creativă le-ar putea lua altfel, pentru ca lumea să experimenteze creația lor.`,
    "Liveliness.category.description": `Un scor mare pe această scară indică faptul că cineva este „plin de viață”. Oamenii extrem de plini de viață sunt foarte entuziaști, optimiști, plini de spirit și dinamici. De obicei, ei experimentează un sentiment de bucurie, sunt mereu în alertă și sunt pregătiți, dispuși și capabili să fie viața oricărei petreceri. Energia lor este atât de magnetică încât atrage cu ușurință oamenii către ei.
    </br> </br>Persoanele care au un scor scăzut pe această scară sunt înclinate să fie plictisitoare și plictisitoare. În general, ei nu se simt deosebit de vivace sau vibrante și pot arunca o stare de spirit sumbră peste orice ocazie. O astfel de trăsătură de personalitate este respingerea față de ceilalți, ceea ce duce la o lipsă de invitații la angajamente sociale.`,
    "Social-Boldness.category.description": `Cei care au un scor mare pe scara Curaj social sunt capabili să fie ei înșiși chiar și în fața adversității. Ei au valoare de sine și se simt confortabil riscând jena socială, excluderea sau chiar respingerea în numele apărării credinței sau opiniei lor. Cei care sunt curajoși din punct de vedere social se simt confortabil, indiferent de situația socială în care se află.
    </br> </br>Persoanele care au un scor scăzut pe această scară tind să se simtă inhibate sau conștiente de sine în lumina reflectoarelor, într-un rol de lider sau atunci când vorbesc în fața unui public. Ei nu sunt dispuși să se apropie de un străin, nu vor da o conversație și rareori își vor exprima gândurile într-un grup.`,
    "Flexibility.category.description": `Caracteristica de Flexibilitate măsoară înclinația cuiva de a face compromisuri și de a coopera cu ceilalți. Cei cu scoruri mari se adaptează bine la punctele de vedere și opiniile diferite ale altora și se gândesc la rezolvarea creativă a dilemelor interpersonale. Persoanele cu scor scăzut sunt persoane rigide care au dificultăți în a se adapta la schimbare și se întâlnesc ca fiind încăpățânate. Ei resping idei contrare ideilor lor și nu sunt dispuși să facă concesii pentru a rezolva un argument. Pe de alta parte, o persoana flexibila va evita argumentele sau se va adapta rapid sugestiilor altora, chiar daca acestea pot fi nerezonabile.`,
    "Anxiety.category.description": `Frica și îngrijorarea pătrund în viața unei persoane extrem de anxioase în mod regulat. Trasatura este activata intr-o varietate de contexte si este o parte stabila a modului in care cineva gandeste si simte. Persoanele cu anxietate ridicată tind să aibă o viziune distorsionată asupra realității și tind să arunce lucrurile în afara proporției. Acest lucru îi determină să-și exprime nervozitatea în situații în care nu este justificată.
    </br> </br>Persoanele aflate la capătul inferior al acestui spectru de trăsături au o dispoziție ușoară și lipsită de griji. În consecință, ei simt puțin stres ca răspuns la probleme sau obstacole, în timp ce persoanele cu scoruri mari tind să devină preocupate de dificultăți relativ minore.`,
    "Social-Esteem.category.description": `Scala încrederii Stimă socială măsoară afinitatea unei persoane pentru o stimă de sine pozitivă, în special într-un context social. Această trăsătură măsoară sentimentul general de valoare personală și de auto-valoare într-un cadru social. Persoanele cu scoruri mari sunt mulțumite de ele însele și cred că posedă calități asemănătoare. De asemenea, ei sunt, de obicei, liderul pachetului.
    </br> </br>Persoanele cu scoruri scăzute se află pe partea opusă a spectrului și, prin urmare, nu au un sentiment de valoare socială personală. Adesea se simt și se văd pe ei înșiși ca indivizi lipsiți de valoare și nepopulari, care oferă puțin sau deloc beneficii suplimentare unui grup. Ele sunt de obicei inhibate și trebuie să bea pentru a lăsa liber, altfel ei doar urmăresc pe toată lumea la petrecere din colțul camerei.`,
    "Fearfulness.category.description": `Oamenii care au un scor mare pe scara Fricos(oasă) au o tendinta mai mare de a experimenta frica. Este mai probabil să fie speriați și tensionați în situații sau circumstanțe în care persoana obișnuită nu ar fi. De multe ori, sentimentele lor de teamă sunt nejustificate și nefondate. În plus, oamenii temători sunt puternic înclinați să evite răul fizic.
    </br> </br>Persoanele care au obținut un scor scăzut pe această scară simt puțină frică de durere sau rănire și sunt persoane relativ puternice și curajoase care sunt impenetrabile la pericol. Cu toate acestea, încrederea oarbă este un dezavantaj în sine, deoarece încrederea excesivă a fost căderea multor figuri istorice. Frica este un ingredient important în atenuarea comportamentului riscant.`,
    "Pragma.category.description": `Persoanele cu un scor mare pe stilul Conștiincios abordează viața lor de dragoste într-un mod de afaceri. Relațiile romantice cu ei sunt un parteneriat care trebuie să aibă utilitate și ajutor în obținerea unui scop. Ei se uită la facilitățile, beneficiile și avantajele care pot rezulta din implicarea cu cineva. Tipurile conștiincioase acordă prioritate practicității și compatibilității în fața chimiei. Ei au o listă strictă de calități pe care le caută la un partener și refuză să accepte orice altceva. Astfel de indivizi evaluează întotdeauna relația din mintea lor cu cât de bine se ridică la obiecția lor. Poate suna rece și neromantic pentru unii, dar este oarecum admirabil că știu exact ce vor în viață.
    </br> </br>Stilul conștiincios este exact opusul stilului altruist. Sunt persoane realiste care gândesc cu capul, nu cu inima, și au ambele picioare ferm plantate pe pământ, în timp ce tipul altruist trăiește în nori, îndeplinindu-și nevoile emoționale și spirituale. Tipul extrem de conștiincios poate deveni implicat în cineva de la locul de muncă, deoarece este atras de alții care sunt orientați spre scop. Indiferent de locul de unde provine acest individ, el trebuie să ofere beneficii care joacă un rol în realizarea stilurilor conștiincioase ale marilor planuri în viață. În caz contrar, nu li se va permite intrarea sau vor fi expulzați în viitor. În ceea ce privește compatibilitatea relației pe termen lung, aceasta va fi cel mai bine găsită cu un partener altruist. Deși combinația a două tipuri de conștiință va face o avere împreună, cel mai probabil va duce și la o tranzacție de afaceri fără inimă plină de lupte pentru putere pe termen lung.`,
    "Mania.category.description": `Persoanele care au un scor mare pe dimensiunea Nevrotic(ă) prezintă un comportament romantic care se caracterizează prin calități cum ar fi obsesivitatea, volatilitatea emoțională și co-dependența. Acest stil de dragoste este o versiune extremă și nesănătoasă a stilului pasional, deoarece este plin de pasiune distructivă. Persoanele aflate în această dimensiune se îndrăgostesc „nebunește”. Cu toate acestea, ei experimentează, de asemenea, maximele euforice ale iubirii și minimele singurătății și disperării într-o măsură mult mai mare decât alte stiluri de dragoste. Astfel de tendințe îi fac adesea să fie extrem de posesivi și nevoiași, deoarece simt că au nevoie de iubitul lor atât de intens în viața lor. Ei tind să aibă un atașament emoțional inerent nesănătos față de partenerul lor și sunt tipurile care sunt susceptibile de a face ceva nebun sau nebun, cum ar fi stalking. Dacă o relație se înrăutățeste sau se termină, poate duce cu ușurință la depresie sau la gânduri suicidare.
    </br> </br>O altă caracteristică a acestui stil de dragoste este una de fantezie extremă. Ei creează adesea o lume imaginară în mintea lor și se gândesc în mod repetat la partenerul lor și la relație. Persoanele nevrotice tind să fie clingy, deoarece au o nevoie constantă de reasigurare și companie de la soțul lor. Din păcate, pentru că rareori ajung la satisfacția lor, există întotdeauna un nivel nerezonabil de anxietate și gelozie în viața lor amoroasă. Amploarea acestor calități negative depinde foarte mult de modul în care partenerul lor își satisface nevoile. Combinația a doi iubiți foarte nevrotici duce, de obicei, la o relație toxică. Este nevoie de multă răbdare și muncă pentru a face față unui stil de dragoste extrem de nevrotic și, ca atare, au nevoie de cineva foarte scăzut pe această scară.`,
    "Storge.category.description": `Acest stil de dragoste se caracterizează printr-o dorință de relații stabile, angajate și emoționale intime. Persoanele care au un scor mare pe această dimensiune apreciază aspectul de prietenie al unei relații. Ei sunt de obicei centrați pe familie, se bucură de un mediu comunitar și prețuiesc legăturile pe care le au. Le place să fie prieteni apropiați la început și au nevoie de timp pentru ca afecțiunea să se dezvolte față de cineva. Relația cu un potențial iubit este o condiție prealabilă înainte ca relația să poată merge mai departe. În consecință, se îndrăgostesc treptat și de oamenii pe care îi cunosc de obicei pentru o perioadă lungă de timp. Un sentiment puternic de încredere, apropiere și înțelegere reciprocă trebuie să fie stabilit înainte de o relație fizică poate avea loc.
    </br> </br>Când vine vorba de dating, calitatea se află deasupra cantității. Gândul de a întâlni pe cineva la un bar sau de a merge la o întâlnire oarbă îi face să se încreadă. Când intră într-o nouă relație, singura lor așteptare este să aibă conversații bune și companie, deoarece ei prețuiesc prietenia, loialitatea și autenticitatea mai presus de orice altceva. A manifesta o mare preocupare, grijă și respect față de un partener are prioritate față de aproape orice, chiar și față de sex. Ei rareori sar în și din pat, sau relații, și preferă legături semnificative de lungă durată. Ca urmare, adesea viața lor amoroasă nu are intensitate și pasiune, ceea ce duce la relații destul de plictisitoare și fără sex. Doar un alt iubit cu aceleași nevoi poate împlini inima și sufletul unui tip intim de dragoste. Fiind prea departe emoțional, fie îi va lăsa să se simtă goi în interior, fie îi va copleși cu prea multă emoție, făcându-i să alerge spre ieșire.`,
    "Agape.category.description": `Partenerii altruiști posedă forma supremă a iubirii – darul iubirii necondiționate. Iubirea transcende toate părțile vieții persoanei altruiste, dă sens existenței sale. Oamenii care au un scor mare pe această dimensiune își exprimă dragostea într-o manieră blândă, afectuoasă, atentă și atentă. Ca urmare, ei sunt cei mai grijulii, generoși, plini de compasiune și altruiști parteneri pe care îi poate avea cineva. Ei sunt răbdători, nu fac cereri și oferă devotament absolut față de partenerul lor. Nici un alt stil de dragoste nu este dispus să facă mai multe sacrificii și să îndure dificultăți pentru partenerul lor așa cum sunt. Ei pun întotdeauna fericirea partenerului înaintea propriei lor fericiri. Interesant este că a da mai mult decât a primi nu pune probleme în viața lor amoroasă. În ciuda acestui fapt, ei încă obțin un nivel ridicat de satisfacție în relație, deoarece partenerii de obicei oferă toată bunătatea, grija și dragostea adevărată pe care o primesc. Îndrăgostiții altruiști trebuie să fie atenți să nu cadă în mâinile greșite și să fie exploatați până când sunt ruinați emoțional. Doi altruiști pot fi mulțumiți pentru o vreme, dar pe termen lung nu se echilibrează cu adevărat unul pe celălalt pe termen lung. Iubitul conștiincios va oferi echilibrul de durată de care are nevoie.`,
    "Ludus.category.description": `Persoanele care au un scor mare pe această dimensiune sunt „filandri” și „femeie fatală”. Dragostea este un joc pentru ei, care trebuie jucat cu cât mai mulți participanți posibil, ceea ce îi face adesea trișori în serie. Sunt parteneri superficiali ale căror relații se bazează pe înșelarea și înșelarea partenerului. Ei își pot mărturisi iubirea veșnică, dar există un număr de destinatari ai unei astfel de devoțiuni nemuritoare. Declarația lor de dragoste poate părea sinceră, dar este de obicei doar pentru a arăta într-un efort de a atenua orice îndoială pe care un partener o poate avea despre infidelitatea lor. Flirtul, minciuna și seducția fac parte din „regulile jocului” pentru ei.
    </br> </br>Rareori iau în serios dragostea și relațiile și, de obicei, ignoră emoția celeilalte părți. Explorarea, urmărirea, vânătoarea și cucerirea de noi perspective sunt ceea ce îi motivează. Poate părea imatură, dar este ceea ce îi excită și le păstrează interesele, până când le pierd, desigur. Se plictisesc cu ușurință odată ce o relație se stabilește și devine banală. Narghilea casual și flings sunt mai aproape de gustul și gustul lor. Ei nu sunt parteneri stabili și rareori caută să se angajeze pe termen lung. Ei sunt hedoniști a căror satisfacție și plăceri personale sunt pe primul loc. Această calitate îi face candidați perfecți cu care să aibă relații ilicite. Tipii promiscuu nu agreeaza de obicei alti parteneri cu o minte similara, dar pe termen lung vor gasi cea mai mare fericire cu propriul lor tip.`,
    "Eros.category.description": `Persoanele care au un scor mare pe această dimensiune sunt romantici fără speranță care iubesc să fie îndrăgostiți. Stilul de dragoste pasional tinde să se încadreze în „dragoste la prima vedere”. Inima lor se încălzește prin ceea ce pot vedea, mirosi, atinge, gusta și auzi. Efectele săgeții lui Cupidon sunt imediate atunci când întâlnesc o perspectivă care se potrivește cu „tipul lor ideal”. Simtul lor intens pentru frumusete, pasiune, sex si atractivitate fizica ii face sa sara repede in pat. Atributele fizice ale unui potențial iubit au un impact atât de profund asupra psihicului lor încât pot dezvolta o conexiune fizică și emoțională instantanee cu ei.
    </br> </br>Ca atare, „chimia” joacă un rol important în procesul de selecție al stilului pasionat și în satisfacția generală a relației. O parte din satisfacția lor va include o nevoie puternică de a-și exprima dragostea nemuritoare prin flori, ciocolată, lenjerie, mese festive, excursii, excursii și multe altele. etc Din păcate, deoarece relațiile lor încep de obicei cu un astfel de bang, ele implozează la fel de repede. Acest lucru poate provoca o tendință pentru cei mai înflăcărați dintre iubiți de a fi dependenți de stadiul de infatuare al relațiilor. Oamenii pasionati se vor potrivi cel mai bine altor tipuri pasionale, deoarece iubitorii nepasionati isi vor stinge focul rapid.`,

    "starsign.on.couples.assessment": `Indiferent dacă credeți sau nu că alinierea planetară influențează gândurile și acțiunile cuiva sau dacă influențează sau nu dinamica relațiilor dintre indivizi, este o zonă care nu ar trebui omisă în căutarea voastră de a vă înțelege pe voi înșivă și pe ceilalți. Există un motiv pentru care astrologia a fost în jur de atât de mult timp, există ceva în ea. Chiar dacă nu este dovedită, poziția planetelor la naștere pare să joace un rol în explicarea caracteristicilor cele mai intime ale oamenilor. Ca atare, nu ar trebui să fie trecut cu vederea în încercarea ta de a te decodifica pe tine sau pe alții și acesta este motivul pentru care am decis să încorporăm astrologia în procesul de selecție Selectivita.
    </br> </br>Metoda noastră proprie de calcul al compatibilității nu se uită la momentul și locul nașterii. Prin urmare, evaluarea compatibilității pe care o oferim este mai generală în natură, cu accent pe compararea caracteristicilor astrologice largi cu persoana pe care o alegeți. Prin urmare, scorul dvs. De compatibilitate cu acea persoană ar trebui să fie folosit mai mult ca punct de plecare sau ghid, mai degrabă decât ca un absolut. În timp ce ora exactă și locul nașterii ar putea fi mai precis, este imposibil să se angajeze în scena single, deoarece nu veți cere astfel de informații înainte de a lua în considerare dating cineva. Cu toate acestea, a cere un potențial interes de dragoste atunci când s-au născut pentru a începe să descopere cine sunt dedesubt este fezabil.
    </br> </br>Pe baza unui studiu realizat pe 523 de cupluri, rezultatele arată că rata divorțurilor pentru cuplurile cu un scor de compatibilitate de 80% sau mai mult este cu 20% mai mică decât cea a cuplurilor cu un scor de 45% sau mai mic. Durata căsătoriilor foarte compatibile a fost, de asemenea, cu 17,5% mai mare față de căsătoriile incompatibile (20yrs. Vs. 16,6yrs.) Interesant, cuplurile care aleg să divorțeze, indiferent de compatibilitate, petrec aceeași perioadă de timp căsătorit (9yrs). În plus, căsătoriile extrem de compatibile au produs aproape dublul numărului de copii comparativ cu cei care sunt incompatibili și divorțați (1,77 vs. 1,03). Din păcate, cuplurile extrem de compatibile pot și se despart, în timp ce multe cupluri extrem de incompatibile au ales să rămână împreună.
    </br> </br>Oricare ar fi procentul de compatibilitate cu cineva, amintiți-vă că o relație armonioasă și de lungă durată cu cineva este mult mai complicată decât astrologia. Cercetările noastre indică faptul că a rămâne împreună și a fi mulțumit nu este suficient pentru a susține o relație. Legăturile de perechi sunt extrem de complexe și dinamice, cu multe părți în mișcare care influențează motivațiile și deciziile ambilor parteneri de a rămâne sau de a pleca. Există multe elemente non-astrologice în joc în cadrul unei relații care influențează satisfacția și longevitatea acesteia (de exemplu, copii, familie, muncă, prieteni, prieteni, etc.). libido, umor, narcisism, inteligență, bani, lăcomie, gelozie, plictiseală, educație, stil de atașament, vârsta etc.). Oricare dintre acestea poate reprezenta obstacole serioase în calea longevității unei legături romantice. Semnul stelar al unei persoane este limitat în capacitatea sa de a explica modul în care cineva se ocupă de toate provocările cu care se confruntă relațiile.`,

};
