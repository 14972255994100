import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import { get, map } from 'lodash';
import * as React from 'react';
import { HandleToasts } from '../../../handle.toasts';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import { GENDER_OPTIONS } from '../../profile/edit-profile/constants';

export class GenderForm extends Subscribable<any, any> {
    public form = new Form({
        gender: new Field({
            label: trans('account.data.gender'),
            value: null,
            multi: false,
            options: map(GENDER_OPTIONS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
    });

    public async handleSubmit() {
        try {
            const response = await Repository.put('/user', { gender: this.form.toJSON()?.gender?.value });

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.href = this.props.href;
        } catch (err) {
            HandleToasts.error(err, trans(err?.response?.data?.message || err?.message));
        }
    }

    public render() {
        return <div className={'p-box'}>
            <h2 className={'palette--c-neutral-5 fs-large text-uppercase fs-21'}>{trans('account.edit.title')}</h2>
            <span className={'palette--c-neutral-5 fs-large'}>{trans('account.data.gender.is.required.in.order.to.proceed')}</span>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <div className="row py-7">
                    <div className="w-100 col-24">
                        <div className="row position-center pb-6">
                            <div className={'col-md-12 col-24'}>
                                <Select
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box size-medium variant-primary"
                                    field={this.form.field('gender')}
                                    inputClassName="border-radius-1 border-1 palette--bc-neutral-4"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-100 col-24 display-flex justify-content-end">
                                <Button
                                    type={'button'}
                                    onClick={() => this.handleCancel()}
                                    className="variant-link size-medium"
                                    title={trans('global.buttons.cancel')}
                                />
                                <Button type={'submit'} className="variant-primary size-medium">
                                    <span className="material-icons mr-4">check</span>
                                    <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <div className="col-24 pt-6">
                            <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                        </div>
                    </div>
                </div>}
            </AbstractForm>
        </div>;
    }

    public handleCancel() {
        this.props.onCancel();
    }
}
