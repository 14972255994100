import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { CollapsibleText } from '../couples-assessment/tabs/extras/collapsible-text';
import { BehaviourContent } from '../profile/compatibility-infos/extras/modal-content/behaviour-content';
import { AverageChart, ChartData } from '../questionnaires/average.chart';
import { IndicatorProps } from '../questionnaires/indicator';
import { round } from './helpers/util';
import { UserInterface, UserMetaInterface } from './interfaces/user.interface';

export interface CategoryDescriptionProps {
    data: any;
    questionnaire: 'love-style' | 'personality' | 'behavioural';
    data2?: any;
    customColour?: string[];
    user?: UserInterface;
    oppositeGender?: UserMetaInterface['gender'];
    labelToBeOppositeGender?: boolean;
    genderLabel?: boolean;
    size?: IndicatorProps['size'];
    extraContent?: JSX.Element;
    customTitle?: string;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class CategoryDescription extends React.Component<CategoryDescriptionProps, any> {
    public renderDescriptionItem(chartData: ChartData, index: number) {

        return <div key={index} className={'row px-5 my-7'}>
            <div className={'col-24 mb-7 mt-md-0 mt-7'}>
                <span
                    style={{ color: chartData.headerColour }}
                    className={'text--span fw-bold text-uppercase fs-16'}
                >
                    {trans(chartData.label)}
                </span>
            </div>
            <div className={'col-24 col-md-9 chart-positioning px-8'}>
                <AverageChart
                    size={this.props.size}
                    oppositeGender={this.props.oppositeGender}
                    genderLabel={this.props.genderLabel}
                    labelToBeOppositeGender={this.props.labelToBeOppositeGender}
                    gender={this.props.user?.meta?.gender}
                    isOverlapping={chartData.isOverlapping}
                    chartData={chartData}
                />
            </div>
            <div className={'col-24 col-md-15 pl-7'}>
                <CollapsibleText description={trans(`${chartData.label}.category.description`)}/>
            </div>
        </div>;
    }

    public formatAverageChartsData(): JSX.Element[] | JSX.Element {
        const isBehavioural = this.props.questionnaire === 'behavioural';

        const chartsData = this.formatChartData(this.props.data, isBehavioural);

        return (chartsData || []).map((chartData: ChartData, index) => this.renderDescriptionItem(chartData, index));
    }

    public formatComparisonChartData(): JSX.Element[] | JSX.Element {
        const isBehavioural = this.props.questionnaire === 'behavioural';

        const chartsData = this.formatDoubleChartData(this.props.data, this.props.data2, isBehavioural);

        return (chartsData || []).map((chartData: ChartData, index) => this.renderDescriptionItem(chartData, index));
    }

    public formatChartData(chartData: any, isBehavioural?: boolean): ChartData[] {
        const labels = Object.keys(chartData);
        const { unit, start, middle, finish, overlap: { first, second, third } } = this.getValueRange();

        return Object.values(chartData).map((data: any, index) => {
            const { result, diff } = data;

            return {
                diff, unit,
                range: { start, middle, finish },
                average: data?.average || round(result + diff),
                userValue: result,
                label: labels[index],
                isOverlapping: Math.abs(diff) < first || result > second && diff + result > second || result < third && diff + result < third,
                headerColour: isBehavioural ? BehaviourContent.colours[labels[index]] : '#5B4791',
            };
        });
    }

    public formatDoubleChartData(data: any, dataset2, isBehavioural?: boolean): ChartData[] {
        const labels = Object.keys(data);

        if (!dataset2) {
            return [];
        }

        const data2: number[] = Object.values(dataset2);
        const { unit, start, middle, finish, overlap: { first, second, third } } = this.getValueRange();

        return Object.values(data).map((value: any, index) => {
            const diff = value - data2[index];
            return {
                diff, unit,
                range: { start, middle, finish },
                average: data2[index],
                userValue: value,
                label: labels[index],
                isOverlapping: Math.abs(diff) < first || value > second && diff + value > second || value < third && diff + value < third,
                headerColour: isBehavioural ? BehaviourContent.colours[labels[index]] : '#5B4791',
            };
        });
    }

    public getValueRange() {
        switch (this.props.size) {
            case 'sm':
                return { unit: 14, start: -5, middle: 0, finish: 5, overlap: { first: 0, second: 2, third: 0 } };
            case 'md':
                return { unit: 10, start: 0, middle: 5, finish: 10, overlap: { first: 3, second: 7, third: 6 } };
            case 'lg':
                return { unit: 4, start: 0, middle: 12.5, finish: 25, overlap: { first: 8, second: 20, third: 6 } };
            default:
                return { unit: 3.333, start: 5, middle: 20, finish: 35, overlap: { first: 9.5, second: 30, third: 10 } };
        }
    }

    public render() {
        const chartsData = this.props.data2 ? this.formatComparisonChartData() : this.formatAverageChartsData();
        const title = this.props.customTitle || `account.data.my.${this.props.questionnaire}.description`;

        return <div className={'py-7 row'}>
            <div className={'col-24'}>
                <h4 className={'text--h3'}>{trans(title)}</h4>
            </div>
            {this.props.extraContent && <div className={'col-24'}>
                {this.props.extraContent}
            </div>}
            <div className={'col-24'}>{chartsData}</div>
        </div>;
    }

}
