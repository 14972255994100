import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import { filter, find, get, map } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import { handleError } from '../../share/helpers/util';
import { BODY_TYPE, ETHNICITY, EYE_COLOR, GENDER_OPTIONS, HAIR_COLOR } from '../edit-profile/constants';
import { MeasurementInput } from './extras/measurement-input';
import { SingleLineSelect } from './extras/single-line.select';

export class PersonalInfosForm extends Subscribable<any, any> {
    public state: any = {
        isGenderSet: false
    };

    public form = new Form({
        gender: new Field({
            label: trans('account.data.gender'),
            value: find(GENDER_OPTIONS, (option) => option.value === get(this.props, 'meta.gender', '')),
            multi: false,
            options: this.manageGenderOptions(),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        ethnicity: new Field({
            label: trans('account.data.ethnicity'),
            value: filter(ETHNICITY, (option) => (this.props.meta?.ethnicity || []).includes(option.value)),
            multi: true,
            options: map(ETHNICITY, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        height: new Field({
            label: trans('account.data.height'),
            value: this.props.meta?.height || '',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        weight: new Field({
            label: trans('account.data.weight'),
            value: this.props.meta?.weight || '',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        hairColor: new Field({
            label: trans('account.data.hair.color'),
            value: find(HAIR_COLOR, (option) => option.value === get(this.props, 'meta.hairColor', '')),
            multi: false,
            options: map(HAIR_COLOR, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        eyeColor: new Field({
            label: trans('account.data.eye.color'),
            value: find(EYE_COLOR, (option) => option.value === get(this.props, 'meta.eyeColor', '')),
            multi: false,
            options: map(EYE_COLOR, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        bodyType: new Field({
            label: trans('account.data.body.type'),
            value: find(BODY_TYPE, (option) => option.value === get(this.props, 'meta.bodyType', '')),
            multi: false,
            options: map(BODY_TYPE, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),

    });

    public manageGenderOptions() {
        if (!this.props.meta?.gender) {
            return map(GENDER_OPTIONS, (option) => ({ title: trans(option.title), value: option.value }));
        }

        const filtered = filter(GENDER_OPTIONS, (option) => option.value === this.props.meta?.gender);

        return map(filtered, (option) => ({ title: trans(option.title), value: option.value }));
    }

    public componentDidMount() {
        if (this.props.meta?.gender) {
            this.setState({ isGenderSet: true });
        }

        this.subscriptions$.push(
            this.form.field('ethnicity').value$.subscribe((v: string[]) => {
                if (v?.length && v.length > 2) {
                    this.form.field('ethnicity').setValue(v.slice(0, 2));
                }
            })
        );
    }

    public setForm() {
        map(this.form.fields, (field, key) => {
            return field.setValue(get(this.props, `meta[${key}]`, ''));
        });
    }

    public parseData() {
        const data = this.form.toJSON();
        const payload = {};

        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                switch (prop) {
                    case 'ethnicity':
                        payload[prop] = (data[prop] || []).map(option => option?.value);
                        break;
                    default:
                        payload[prop] = data[prop]?.value || data[prop];
                }
            }
        }

        return payload;
    }

    public async handleSubmit() {
        try {
            const data = this.parseData();

            const response = await Repository.put('/user', data);

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }

    public render() {
        return <div className={'p-box'}>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <h2 className={'mb-8'}>{trans('account.data.edit.profile')}</h2>
                <div className="row">
                    <div className="w-100 col-24 px-7">
                        <div className="row">
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    searchable={false}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box size-medium variant-primary"
                                    field={this.form.field('gender')}
                                    inputClassName="border-radius-1 border-1 palette--bc-neutral-4"
                                    disabled={this.state.isGenderSet}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('hairColor')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-7">
                                <MeasurementInput
                                    field={this.form.field('height')}
                                    label={'account.data.height'}
                                    imperial={'ft'}
                                    metric={'cm'}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-7">
                                <MeasurementInput
                                    field={this.form.field('weight')}
                                    label={'account.data.weight'}
                                    imperial={'lbs'}
                                    metric={'kg'}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('bodyType')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('eyeColor')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    field={this.form.field('ethnicity')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                        </div>
                        <div className="row">
                            <div className="w-100 col-24 display-flex justify-content-end">
                                <Button
                                    type={'button'}
                                    onClick={() => this.handleCancel()}
                                    className="variant-link size-medium"
                                    title={trans('global.buttons.cancel')}
                                />
                                <Button type={'submit'} className="variant-primary size-medium">
                                    <span className="material-icons mr-4">check</span>
                                    <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <div className="col-24 pt-6">
                            <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                        </div>
                    </div>
                </div>}
            </AbstractForm>
        </div>;
    }

    public handleCancel() {
        this.setForm();
        this.props.onCancel();
    }
}
