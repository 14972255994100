import { generatePath, matchPath } from 'react-router';
export const HOME_PAGE = "https://selectivita.com";

export const routes = {
    login: {
        languages: [
            { lang: 'hu', path: '/hu/bejelentkezes', checkLoggedInTarget: '/hu/profile' },
            { lang: 'en', path: '/en/login', checkLoggedInTarget: '/en/profile' },
            { lang: 'ro', path: '/ro/login', checkLoggedInTarget: '/ro/profile' },
        ]
    },
    forgotPasswordRequest: {
        languages: [
            { lang: 'hu', path: '/hu/elfelejtett-jelszo-kerelem', checkLoggedInTarget: '/hu/profile' },
            { lang: 'en', path: '/en/forgot-password-request', checkLoggedInTarget: '/en/profile' },
            { lang: 'ro', path: '/ro/forgot-password-request', checkLoggedInTarget: '/ro/profile' },
        ]
    },

    registration: {
        languages: [
            {
                lang: 'hu', path: '/hu/regisztracio',
                checkLoggedInTarget: '/hu/profile'
            },
            {
                lang: 'en', path: '/en/signup',
                checkLoggedInTarget: '/en/profile'
            },
            {
                lang: 'ro', path: '/ro/inregistrare',
                checkLoggedInTarget: '/ro/profile'
            },
        ]
    },
    faq: {
        languages: [
            { lang: 'hu', path: '/hu/gyik' },
            { lang: 'en', path: '/en/faq' },
            { lang: 'ro', path: '/ro/faq' },
        ]
    },
    home: {
        languages: [
            { lang: 'hu', path: '/hu' },
            { lang: 'en', path: '/en' },
            { lang: 'ro', path: '/ro' },
        ]
    },
    homeExtended: {
        languages: [
            { lang: 'hu', path: '/hu/kezdolap' },
            { lang: 'en', path: '/en/home' },
            { lang: 'ro', path: '/ro/home' },
        ]
    },
    questionnaires: {
        languages: [
            { lang: 'hu', path: '/hu/ingyenes-szemelyisegtipus-onismereti-teszt' },
            { lang: 'en', path: '/en/free-online-personality-self-assessment-test' },
            { lang: 'ro', path: '/ro/test-de-pesonalitate-gratuit' },
        ]
    },

    startQuestionnaire: {
        languages: [
            {
                lang: 'hu', path: '/hu/start-questionnaire/:questionnaire',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:questionnaire' });

                    return generatePath('/:lang/start-questionnaire/:questionnaire', { lang: 'hu', questionnaire: params.params.questionnaire });
                }
            },
            {
                lang: 'en', path: '/en/start-questionnaire/:questionnaire',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:questionnaire' });

                    return generatePath('/:lang/start-questionnaire/:questionnaire', { lang: 'en', questionnaire: params.params.questionnaire });
                }
            },
            {
                lang: 'ro', path: '/ro/start-questionnaire/:questionnaire',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:questionnaire' });

                    return generatePath('/:lang/start-questionnaire/:questionnaire', { lang: 'ro', questionnaire: params.params.questionnaire });
                }
            },
        ]
    },
    coupleAssessments: {
        languages: [
            { lang: 'hu', path: '/hu/online-párkapcsolati-felmeres' },
            { lang: 'en', path: '/en/online-relationship-assessment-for-couples' },
            { lang: 'ro', path: '/ro/evaluarea-relației-de-cuplu-online' },
        ]
    },
    inviteFriend: {
        languages: [
            { lang: 'hu', path: '/hu/meghivo-kuldes' },
            { lang: 'en', path: '/en/invite-friend' },
            { lang: 'ro', path: '/ro/invite-friend' },
        ]
    },
    starSign: {
        languages: [
            { lang: 'hu', path: '/hu/parkapcsolati-asztrologiai-elemzes-szuletesi-datum-szerint' },
            { lang: 'en', path: '/en/astrological-compatibility-by-date-of-birth' },
            { lang: 'ro', path: '/ro/compatibilitatea-zodiilor-dupa-data-nasterii' },
        ]
    },

    // Authorized routes
    pricing: {
        languages: [
            { lang: 'hu', path: '/hu/arak' },
            { lang: 'en', path: '/en/pricing' },
            { lang: 'ro', path: '/ro/pricing' },
        ]
    },
    compatibilityTest: {
        languages: [
            { lang: 'hu', path: '/hu/csillagjegy-kompatibilitas-kalkulator-szuletesi-datum-szerint' },
            { lang: 'en', path: '/en/star-sign-compatibility-calculator-by-date-of-birth' },
            { lang: 'ro', path: '/ro/calculator-de-compatibilitate-zodiilor-dupa-data-nasterii' },
        ]
    },
    profile: {
        languages: [
            { lang: 'hu', path: '/hu/profile' },
            { lang: 'en', path: '/en/profile' },
            { lang: 'ro', path: '/ro/profile' },
        ]
    },
    topUp: {
        languages: [
            { lang: 'hu', path: '/hu/topup' },
            { lang: 'en', path: '/en/topup' },
            { lang: 'ro', path: '/ro/topup' },
        ]
    },

    profiles: {
        languages: [
            {
                lang: 'hu', path: '/hu/users/:id',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:id' });

                    return generatePath('/:lang/users/:id', { lang: 'hu', id: params.params.id });
                }
            },
            {
                lang: 'en', path: '/en/users/:id',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:id' });

                    return generatePath('/:lang/users/:id', { lang: 'en', id: params.params.id });
                }
            },
            {
                lang: 'ro', path: '/ro/users/:id',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:id' });

                    return generatePath('/:lang/users/:id', { lang: 'ro', id: params.params.id });
                }
            },
        ]
    },
    matchmakingResults: {
        languages: [
            { lang: 'hu', path: '/hu/online-tarskereso-oldal' },
            { lang: 'en', path: '/en/matchfinder-online-dating-site' },
            { lang: 'ro', path: '/ro/site-de-dating-si-intalniri-romania' },
        ]
    },
    terms: {
        languages: [
            // Ezek a routeok NEM fordíthatóak le!!!!!! Ez nagyon fontos

            { lang: 'hu', redirect: '/hu/felhasznalasi-feltetelek', path: '/hu/terms' },
            { lang: 'en', redirect: '/en/terms-of-use', path: '/en/terms' },
            { lang: 'ro', redirect: '/ro/termeni-de-utilizare', path: '/ro/terms' },
        ]
    },
    termsOfUse: {
        languages: [
            { lang: 'hu', path: '/hu/felhasznalasi-feltetelek' },
            { lang: 'en', path: '/en/terms-of-use' },
            { lang: 'ro', path: '/ro/termeni-de-utilizare' },
        ]
    },
    privacy: {
        languages: [
            { lang: 'hu', path: '/hu/adatvedelmi-tajakoztato' },
            { lang: 'en', path: '/en/privacy' },
            { lang: 'ro', path: '/ro/privacy' },
        ]
    },
    contact: {
        languages: [
            { lang: 'hu', path: '/hu/kapcsolat' },
            { lang: 'en', path: '/en/contact' },
            { lang: 'ro', path: '/ro/contact' },
        ]
    },

    // Technical Routes
    payment: {
        languages: [
            // Ezek a routeok NEM fordíthatóak le!!!!!! Ez nagyon fontos

            {
                lang: 'hu',
                path: '/hu/payment/:id',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:id' });

                    return generatePath('/:lang/payment/:id', { lang: 'hu', id: params.params.id });
                }
            },
            {
                lang: 'en',
                path: '/en/payment/:id',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:id' });

                    return generatePath('/:lang/payment/:id', { lang: 'en', id: params.params.id });
                }
            },
            {
                lang: 'ro',
                path: '/ro/payment/:id',
                targetCompiler: (location) => {
                    const params: any = matchPath(location.pathname, { path: '/:lang/:area/:id' });

                    return generatePath('/:lang/payment/:id', { lang: 'ro', id: params.params.id });
                }
            },
        ]
    },
    forgotPasswordValidate: {
        languages: [
            // Ezek a routeok NEM fordíthatóak le!!!!!! Ez nagyon fontos

            { lang: 'hu', path: '/hu/forgot-password/validate' },
            { lang: 'en', path: '/en/forgot-password/validate' },
            { lang: 'ro', path: '/ro/forgot-password/validate' },
        ]
    },
    verificationValidate: {
        languages: [
            // Ezek a routeok NEM fordíthatóak le!!!!!! Ez nagyon fontos

            { lang: 'hu', path: '/hu/verification/validate' },
            { lang: 'en', path: '/en/verification/validate' },
            { lang: 'ro', path: '/ro/verification/validate' },
        ]
    },
    notFound: {
        languages: [
            // Ezek a routeok NEM fordíthatóak le!!!!!! Ez nagyon fontos

            { lang: 'hu', path: '/hu/*', targetCompiler: () => '/hu/not-found' },
            { lang: 'en', path: '/en/*', targetCompiler: () => '/en/not-found' },
            { lang: 'ro', path: '/ro/*', targetCompiler: () => '/ro/not-found' },
        ]
    },
};
