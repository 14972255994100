import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { PersonalityContent } from '../compatibility-infos/extras/modal-content/personality-content';
import { EditModal } from '../shared/edit.modal';
import { DisplayBox } from './libs/display-box';

export class PersonalityAnalysis extends DisplayBox {
    public title = trans('account.data.personality.questionnaire');
    public buttonTitle = trans('account.data.view.data');
    public image = '/assets/images/profile/personality.png';
    public modal$: EditModal | null;
    public questionnaire = 'personality';
    public isQuestionnaireFilled = !!this.props.user.meta[`${this.questionnaire}Questionnaire`];

    public renderDescription(questionnaire: any) {
        if (!questionnaire) {
            return null;
        }

        const userAnalysisData = {
            gender: this.props.user.meta?.gender,
            data: this.props.user.meta?.[`${this.questionnaire}Questionnaire`] || {}
        };

        return <PersonalityContent user={this.props.user} userAnalysisData={userAnalysisData}/>;
    }

    public renderImage() {
        return <img
            className={'h-px-175 width-auto'}
            src={this.image}
            alt={this.alt || trans('account.data.alt.icon.text')}
        />;
    }

    public renderModal(): null | JSX.Element {
        return <EditModal
            className={'max-width-848'}
            content={this.renderDescription(this.props.user.meta[`${this.questionnaire}Questionnaire`])}
            ref={ref => this.modal$ = ref}
        />;
    }

    public handleButtonClick(): void {
        this.modal$?.openModal();
    }
}
