import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { HandleToasts } from '../../handle.toasts';
import { Subscribable } from '../../libs/subscribable';
import { BuyAccess } from '../couples-assessment/buy-access';
import { checkShouldReveal, handleNotEnoughBalance } from './helpers/util';
import { UserInterface } from './interfaces/user.interface';

export interface PurchaseDocument {
    behaviouralCharts: string[];
    createdAt: string;
    loveStyleCharts: string[];
    personalityCharts: string[];
    personalityCompatibility: string[];
    behaviouralCompatibility: string[];
    purchasedContacts: string[];
    starSignCharts: string[];
    updatedAt: string;
    userId: string;
    _id: string;
}

export interface ManageAccessProps {
    purchases: PurchaseDocument | null;
    user?: UserInterface | null;
    profileUser?: UserInterface;
    isHidden: (shouldReveal: boolean) => void;
}

export class ManageAccess extends Subscribable<ManageAccessProps, any> {
    public state = { shouldReveal: false };

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.checkIfUserContactShouldBeRevealed();
        }
        if (prevProps.profileUser !== this.props.profileUser) {
            this.checkIfUserContactShouldBeRevealed();
        }
        if (prevState.shouldReveal !== this.state.shouldReveal && this.props.isHidden) {
            this.props.isHidden(this.state.shouldReveal);
        }
    }

    public checkIfUserContactShouldBeRevealed() {
        const shouldReveal = checkShouldReveal(this.props.purchases, this.props.user?._id || '', 'contact-info');

        this.setState({ shouldReveal });
    }

    public renderButtonContent(text: string, credit: number) {
        const creditText = trans(credit > 1 ? 'global.label.credits' : 'global.label.credit');

        return <span className={'palette--c-neutral-1'}>{trans(text)} <span className={'text-italic palette--c-neutral-6'}>({credit} {creditText})</span></span>;
    }

    public renderPayWithCreditButton() {
        return <Button
            className={'variant-primary credit-button hover-opacity'}
            onClick={() => this.buyThisUserContactDetails()}
            title={''}
        >
            <span
                className={'palette--c-neutral-1'}
            >{trans('account.data.pay.credit.to.reveal')} <span className={'text-italic palette--c-neutral-1'}>(1 {trans('global.label.credit')})</span>
            </span>
        </Button>;
    }

    public async buyThisUserContactDetails() {
        try {
            const profileId = this.props.user?._id;

            const response = await Repository.put('/analysis-purchase/purchase-profile-infos', { profileId, type: 'contact-info' });

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (e) {
            HandleToasts.error(e, trans(e?.response?.data?.message || e?.message || 'account.data.error.something.went.wrong'));
            handleNotEnoughBalance(e);
        }
    }

    public render() {
        return <BuyAccess className={'h-100'} show={this.state.shouldReveal}>
            {!this.state.shouldReveal && this.renderPayWithCreditButton()}
            {this.props.children}
        </BuyAccess>;
    }
}
