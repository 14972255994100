import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './legal-page.scss';
import { PRIVACY } from './privacy';

export class PrivacyScreen extends Subscribable<any, any> {
    public render(): React.ReactNode {
        return <div className={'FaqScreen p-box'}>
            <div className="container">
                <div className={'p-box'}>
                    <div className="row justify-content-center">
                        <div className="col-lg-16 col-24">
                            <div dangerouslySetInnerHTML={{ __html: PRIVACY }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

}
