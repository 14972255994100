import React from 'react';
import { Subscribable } from '../../libs/subscribable';

export class QuestionnaireBox extends Subscribable<any, any> {
    public render() {
        return <div className={`border-radius-px-8 p-7 background-white ${this.props.className} position-relative`}>
            {this.props.children}
        </div>;
    }
}
