import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { get } from '../../share/helpers/util';
import { COUNTRY_OPTIONS } from '../edit-profile/constants';
import { countryTranslator } from '../edit-profile/country-translator';
import { BackgroundDataForm } from '../forms/background-data-form';
import { BoxWrapper } from '../shared/box-wrapper';
import { UserBase } from '../shared/user.base';

export class BackgroundData extends UserBase {
    public getTrans(data: any) {
        return get(trans(data || ''));
    }

    public renderMultiOptions(options: string[], title: string) {
        return <div className={'display-flex flex-column mb-4'}>
            <span className={'text--small-grey'}>{trans(title)}</span>
            {options.length && (options || []).map((option, key) => <span key={key} className={'text--small'}>{this.getTrans(option)}</span>)}
        </div>;
    }

    public findCountry() {
        const userCountry = (COUNTRY_OPTIONS || []).find((option) => option.value === this.state.meta?.country || '');

        return countryTranslator(userCountry?.value);
    }

    public getOptions(options: string[]) {
        return [(options as any || [])].flat().map(option => <span key={option} className={'text--small display-block'}>{this.getTrans(option)}</span>);
    }

    public render() {
        return <BoxWrapper
            shouldClose={this.state.shouldClose}
            modalClass={'max-width-900'}
            content={<BackgroundDataForm
                onCancel={() => this.setState({ shouldClose: !this.state.shouldClose })}
                meta={this.state.meta}
            />}
            enableEdit={this.props.enableEdit}
            title={<h6 className={'fw-bold text--small'}>{trans('account.data.background.infos')}</h6>}
        >
            <div className={'w-100'}>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.country')}</span>
                    <span className={'text--small'}>{this.findCountry()}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.state')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.state)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.city')}</span>
                    <span className={'text--small'}>{get(this.state.meta?.city)}</span>
                </div>

                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.religion')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.religion)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.education.level')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.educationLevel)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.children')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.children)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.living.status')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.livingStatus)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.relationship.history')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.relationshipHistory)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.money.management.habits')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.moneyManagementHabits)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.languages')}</span>
                    <span className={'text--small'}>{this.getOptions(this.state.meta?.languages)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.marital.status')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.relationshipStatus)}</span>
                </div>
                {this.renderMultiOptions([this.state.meta?.upbringing].flat(), 'account.data.upbringing')}
                {this.renderMultiOptions([this.state.meta?.professionType].flat(), 'account.data.profession.type')}
            </div>
        </BoxWrapper>;
    }
}
