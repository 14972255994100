module.exports = {
    'account.edit.country': 'Ország',
    "account.data.state": "Megye",
    "account.data.questionnaire.is.not.completed.yet": "Először ki kell töltened a kérdőívet.",
    "account.data.questionnaire.is.not.completed.yet.by.user": "Ez a felhasználó még nem töltötte ki a kérdőívet.",
    "account.data.description": "Csillagjegy profil",
    "account.data.overview": "Csillagjegyek jellemzésének összefoglalása",
    "forgotpsw.button": "Jelszóváltoztatás",
    "forgotpsw.email": "Fiók létrehozásához használt email",
    "account.data.available.profiles": "Felhasználók",
    "login.signup.button": "Csatlakozz itt!",
    "signup.title": "Regisztrálj most, és próbáld ki ingyen.",
    "account.data.friend.invitations": "ÚJ FELHASZNÁLÓ AJÁNLÓPROGRAM",
    "contact.invite": "Meghívom",
    "account.data.background.infos": "HÁTTÉRADATOK",
    "compatibility.myResultButton": "Saját leírásom",
    "account.data.fulfilled": "jóváírva",
    "account.data.unfulfilled": "meghívott",
    "account.data.header.email": "EMAIL",
    "account.data.invited.at": "MEGHÍVÁS DÁTUMA",
    "account.data.is.registered": "STÁTUSZ",
    "account.data.registered.at": "FELIRATKOZÁS DÁTUMA",
    "you.need.to.purchase.the.calculation.first": "Először meg kell vásárolnia a számítást.",
    "setting.password": "Írja be az új jelszavát. Később ezzel a jelszóval lehet bejelentkezni.",
    "change.password": "Jelszó módosítás sikeres!",
    "change.login..password": "Ezzel a jelszóval már bejelentkezhetsz.",
    "account.data.name": "NÉV",
    "read.more": "Olvass tovább",
    "read.less": "Olvass kevesebbet",
    "account.data.behavioural.chart.title.1": "Temperamentum dimenziók",
    "account.data.temperament.predisposition": "Temperamentum dimenziók",
    "account.data.variance.from.population": "Eltérés az átlagtól",
    "account.data.love.style.dimensions": "Szerelemi Stílus dimenziók",
    "account.data.personality.dimensions": "Személyiségdimenziók",
    "account.data.personality.traits": "Személyiségjegyek",
    "main-dimension.selector": "Személyiség dimenziói",
    "main-dimension": "Személyiségünk dimenziói",
    "our-sub-dimensions": "Személyiségvonásaink",
    "love-style-dimension": "Szerelemi Stílus dimenziók",
    "personality.differences": "Különbség köztünk",
    "variance.between.us": "Különbség köztünk",
    "sub-dimension": "Személyiségjegyek",
    "account.edit.relationship.status.1": "Boldog házasságban",
    "account.edit.relationship.status.2": "Boldogtalan házasságban",
    "account.edit.relationship.status.3": "Boldog párkapcsolatban",
    "account.edit.relationship.status.4": "Boldogtalan párkapcsolatban",
    "account.edit.relationship.status.5": "Egyedülálló megtört szívvel",
    "account.edit.relationship.status.6": "Szingli és randizok",
    "account.data.ethnicity.0": "Arab (Észak-Afrikai)",
    "account.data.ethnicity.1": "Arab (Közép-Keleti)",
    "account.data.ethnicity.2": "Ázsiai - Közép",
    "account.data.ethnicity.3": "Ázsiai - Keleti (Kínai)",
    "account.data.ethnicity.4": "Ázsiai - Keleti (Japán)",
    "account.data.ethnicity.5": "Ázsiai - Keleti (Koreai)",
    "account.data.ethnicity.6": "Ázsiai - Keleti (Egyéb)",
    "account.data.ethnicity.7": "Ázsiai - Észak",
    "account.data.ethnicity.8": "Ázsiai - Dél (Indiai)",
    "account.data.ethnicity.9": "Ázsiai - Dél (Pakisztáni)",
    "account.data.ethnicity.10": "Ázsiai - Dél (Egyéb)",
    "account.data.ethnicity.11": "Ázsiai - Délkeleti (Indonéz)",
    "account.data.ethnicity.12": "Ázsiai - Délkeleti (Filippínó)",
    "account.data.ethnicity.13": "Ázsiai - Délkeleti (Thai)",
    "account.data.ethnicity.14": "Ázsiai - Délkeleti (Vietnámi)",
    "account.data.ethnicity.15": "Ázsiai - Délkeleti (Egyéb)",
    "account.data.ethnicity.16": "Fekete (Amerikai)",
    "account.data.ethnicity.17": "Fekete (Afrikai)",
    "account.data.ethnicity.18": "Fekete (Karibi)",
    "account.data.ethnicity.19": "Latinó (Karibi - Kubai)",
    "account.data.ethnicity.20": "Latinó (Karibi - Dominikai)",
    "account.data.ethnicity.21": "Latinó (Karibi - Haiti)",
    "account.data.ethnicity.22": "Latinó (Karibi - Puerto Ricó-i)",
    "account.data.ethnicity.23": "Latinó (Közép-Amerikai - Mexikói)",
    "account.data.ethnicity.24": "Latinó (Közép-Amerikai - Egyéb)",
    "account.data.ethnicity.25": "Latinó (Dél-Amerikai - Argentin)",
    "account.data.ethnicity.26": "Latinó (Dél-Amerikai - Brazil)",
    "account.data.ethnicity.27": "Latinó (Dél-Amerikai - Kolumbiai)",
    "account.data.ethnicity.28": "Latinó (Dél-Amerikai - Perui)",
    "account.data.ethnicity.29": "Latinó (Dél-Amerikai - Venezuelai)",
    "account.data.ethnicity.30": "Latinó (Dél-Amerikai - Egyéb)",
    "account.data.ethnicity.31": "Közép-keleti (Iráni)",
    "account.data.ethnicity.32": "Közép-keleti (Egyiptomi)",
    "account.data.ethnicity.33": "Közép-keleti (Izraeli)",
    "account.data.ethnicity.34": "Közép-keleti (Iraki)",
    "account.data.ethnicity.35": "Közép-keleti (Kurd)",
    "account.data.ethnicity.36": "Közép-keleti (Perzsa)",
    "account.data.ethnicity.37": "Közép-keleti (Török)",
    "account.data.ethnicity.38": "Közép-keleti (Egyéb)",
    "account.data.ethnicity.39": "Amerikai Indián",
    "account.data.ethnicity.40": "Romani (Cigány)",
    "account.data.ethnicity.41": "Fehér (Kelet-Európai)",
    "account.data.ethnicity.42": "Fehér (Kelet-Európai - Orosz)",
    "account.data.ethnicity.43": "Fehér (Észak-Európai - Északi)",
    "account.data.ethnicity.44": "Fehér (Észak-Európai - Balti)",
    "account.data.ethnicity.45": "Fehér (Óceánia – Ausztrál/Új-Zélandi)",
    "account.data.ethnicity.46": "Fehér (Afrikai)",
    "account.data.ethnicity.47": "Fehér (Dél-Európai - Balkán)",
    "account.data.ethnicity.48": "Fehér (Dél-Európai - Olasz)",
    "account.data.ethnicity.49": "Fehér (Dél-Európai - Portugál)",
    "account.data.ethnicity.50": "Fehér (Dél-Európai - Spanyol)",
    "account.data.ethnicity.51": "Fehér (Nyugat-Európai)",
    "account.data.hair.color.1": "Koromfekete",
    "account.data.hair.color.2": "Fekete",
    "account.data.hair.color.3": "Sötét barna",
    "account.data.hair.color.4": "Barna",
    "account.data.hair.color.5": "Világos barna",
    "account.data.hair.color.6": "Sötét szőke",
    "account.data.hair.color.7": "Szőke",
    "account.data.hair.color.8": "Világos szőke",
    "account.data.hair.color.9": "Aranybarna",
    "account.data.hair.color.10": "Világos vörös",
    "account.data.hair.color.11": "Vörös",
    "account.data.hair.color.12": "Sötét vörös",
    "account.data.hair.color.13": "Őszülő",
    "account.data.hair.color.14": "Ősz",
    "account.data.eye.color.1": "Kék",
    "account.data.eye.color.2": "Világoskék",
    "account.data.eye.color.3": "Szürke",
    "account.data.eye.color.4": "Szürkészöld",
    "account.data.eye.color.5": "Zöld",
    "account.data.eye.color.6": "Zöldesbarna/Mogyoróbarna",
    "account.data.eye.color.7": "Borostyán",
    "account.data.eye.color.8": "Világosbarna",
    "account.data.eye.color.9": "Barna",
    "account.data.eye.color.10": "Sötétbarna",
    "account.data.average": "Átlag",
    "account.data.my.score": "Eredményem",
    "account.data.add.to.like.list": "Hozzáadás a Kedvencekhez",
    "account.data.remove.from.like.list": "Eltávolítása a Kedvencekből",
    "account.data.education.1": "Kevesebb, mint középiskolai/gimnáziumi végzettség",
    "account.data.education.2": "Középiskola/érettségi vagy utólagos érettségi levelező szakon",
    "account.data.education.3": "Elkezdtem egy főiskolát, de nem jutottam el a végéig",
    "account.data.education.4": "Műszaki",
    "account.data.education.5": "Főiskola",
    "account.data.education.6": "Egyetem",
    "account.data.education.7": "Mesterfokozatú",
    "account.data.education.8": "MBA (gazdasági mesterszak)",
    "account.data.education.9": "Professzionális/Hivatásos",
    "account.data.education.10": "Doktorátus (Dr., PhD)",
    "account.data.education.11": "Egyéb",
    "account.data.sexual.orientation.1": "Biszexuális",
    "account.data.sexual.orientation.2": "Heteroszexuális",
    "account.data.sexual.orientation.3": "Homoszexuális",
    "account.data.sexual.orientation.4": "Egyéb",
    "account.data.tattoo.1": "Nincs és soha nem is szeretnék",
    "account.data.tattoo.2": "Még nincs",
    "account.data.tattoo.3": "Egy két kisebb tetoválás diszkrét helyen",
    "account.data.tattoo.4": "Néhány kisebb tetoválás látható heyen",
    "account.data.tattoo.5": "Néhány nagyobb tetoválás",
    "account.data.tattoo.6": "Néhány nagyobb tetoválás és lesz még több is",
    "account.data.tattoo.7": "Az egész testem tetoválás fedi",
    "account.data.libido.1": "Naponta néhányszor",
    "account.data.libido.2": "Naponta",
    "account.data.libido.3": "Hetente néhányszor",
    "account.data.libido.4": "Havonta néhányszor",
    "account.data.libido.5": "Havonta egyszer",
    "account.data.libido.6": "Évente egyszer",
    "account.data.smoking.habit.1": "Soha",
    "account.data.smoking.habit.2": "Alkalmanként, hogy lenyugodjak",
    "account.data.smoking.habit.3": "Néha közösségben",
    "account.data.smoking.habit.4": "Egy dobozzal egy nap",
    "account.data.smoking.habit.5": "Füstölök, mint a gyárkémény",
    "account.data.alcohol.1": "Soha nem iszom alkoholt",
    "account.data.alcohol.2": "Csak különleges alkalmakkor mértékkelve",
    "account.data.alcohol.3": "Egy pohár sör vagy bor esténként",
    "account.data.alcohol.4": "Nekem egy kis becsiccsentés elég",
    "account.data.alcohol.5": "Társaságban az asztal alá szoktam inni magam",
    "account.data.alcohol.6": "Gyakran iszom, akár a nap folyamán is",
    "account.data.drugs.1": "Soha, teljesen ellene vagyok",
    "account.data.drugs.2": "Időnként, \tha baráti környezetben vagyok",
    "account.data.drugs.3": "Alkalmanként (az élet néha nehéz)",
    "account.data.drugs.4": "Gyakran (nem tudom túlélni a napot segítség nélkül)",
    "account.data.drugs.5": "Elvonóra van szükségem",
    "account.data.profession.1": "Ablakmosó, létesítmények karbantartása (otthoni/irodai tisztító)",
    "account.data.profession.2": "Iskolagondnok, portás, biztonsági őr",
    "account.data.profession.3": "Busz/teherautó sofőr, postafuvarozó, gyári/építői munkás",
    "account.data.profession.4": "Autószerelő, gépszerelő, vízvezeték-szerelő, villanyszerelő, asztalos",
    "account.data.profession.5": "Pincér, bárpultos, légiutaskísérő",
    "account.data.profession.6": "Séf, fodrász, manikűrös, kozmetikus, kutyakozmetikus",
    "account.data.profession.7": "Tizedes/katona a fegyveres erőknél",
    "account.data.profession.8": "Személyi edző, sportedző, közösségi média influencer",
    "account.data.profession.9": "Grafikus, esküvői fotós, étterem műszakvezető",
    "account.data.profession.10": "Tanár (óvoda, általános iskola, gimnázium)",
    "account.data.profession.11": "Rendőr, tűzoltó, sürgősségi egészségügyi technikus",
    "account.data.profession.12": "Irodai ügyintézés (számvitel, értékesítés, pénzügy, marketing)",
    "account.data.profession.13": "Ápoló, orvosi/fogászati asszisztens, szociális munkás, jogász, gyógyszerész",
    "account.data.profession.14": "Hotel/étterem vezető, múzeum felügyelő, iskolaigazgató",
    "account.data.profession.15": "Vezető könyvelő, pénzügyi vezető, pszichológus, minősített szociális munkás",
    "account.data.profession.16": "Számítógép-programozó, informatikus, rendőrnyomozó",
    "account.data.profession.17": "Kisvállalkozó, rendőr hadnagy",
    "account.data.profession.18": "Profi sportoló, szórakoztató ipar (színész, színésznő, zenész, humorista), tévériporter",
    "account.data.profession.19": "Író, szerző, festő, művész, szobrász",
    "account.data.profession.20": "Mérnök, építész, filmproducer",
    "account.data.profession.21": "Legfelső szintű vállalatvezető, rendőrfőkapitány",
    "account.data.profession.22": "Ügyvéd, orvos, fogorvos, pszichiáter",
    "account.data.profession.23": "Befektetési bankár, egyetemi tanár, bíró",
    "account.data.profession.24": "Repülőgép pilóta, filmrendező",
    "account.data.profession.25": "Vállalati vezető (alelnök, elnök, vezérigazgató, igazgató)",
    "account.data.profession.26": "4-5 csillagos fokozattal rendelkező tábornok a fegyveres erőknél",
    "account.data.profession.27": "Asztronauta, idegsebész, politikus, tudós",
    "account.data.profession.28": "Egyéb",
    "account.data.relationship.type.1": "Kizárólag szexuális kapcsolat érdekel (egyéjszakás kaland, szabadidőtárs)",
    "account.data.relationship.type.2": "Barátság extrákkal",
    "account.data.relationship.type.3": "Randizgatás illetve rövid távú szerelem",
    "account.data.relationship.type.4": "Egy nem monogám kapcsolatot keresek, akár együttéléssel vagy különéléssel",
    "account.data.relationship.type.5": "Kizárólag monogám kapcsolatot keresek, de különéléssel",
    "account.data.relationship.type.6": "Komoly kapcsolat érdekel hosszú távú elköteleződéssel",
    "account.data.relationship.type.7": "Hagyományos kapcsolat érdekel házassággal és gyerekekkel",
    "account.data.relationship.type.8": "Most nem vagyok nyitott semmiféle kapcsolatra.",
    "account.data.religion.1": "Ateista",
    "account.data.religion.2": "Agnosztikus",
    "account.data.religion.3": "Baha'i",
    "account.data.religion.4": "Buddhista",
    "account.data.religion.5": "Keresztény - Adventista",
    "account.data.religion.6": "Keresztény - Anglikán",
    "account.data.religion.7": "Keresztény - Baptista",
    "account.data.religion.8": "Keresztény - Katolikus",
    "account.data.religion.9": "Keresztény - Jehova Tanúi",
    "account.data.religion.10": "Keresztény - Utolsó Napi Szentek",
    "account.data.religion.11": "Keresztény - Lutherán/Evangélikus",
    "account.data.religion.12": "Keresztény - Metodista",
    "account.data.religion.13": "Keresztény - Nem felekezeti",
    "account.data.religion.14": "Keresztény - Ortodox - Keleti",
    "account.data.religion.15": "Keresztény - Ortodox - Ázsiai",
    "account.data.religion.16": "Keresztény - Pünkösdi",
    "account.data.religion.17": "Keresztény - Református/Presbiteriánus",
    "account.data.religion.18": "Keresztény - Egyéb",
    "account.data.religion.19": "Hindu",
    "account.data.religion.20": "Iszlám (Shi'ite)",
    "account.data.religion.21": "Iszlám (Sunni)",
    "account.data.religion.22": "Zsidó - Konzervatív",
    "account.data.religion.23": "Zsidó - Haszid",
    "account.data.religion.24": "Zsidó - Református",
    "account.data.religion.25": "Zsidó - Ortodox",
    "account.data.religion.26": "Sinto",
    "account.data.religion.27": "Egyéb",
    "account.data.religious.practices.1": "Naponta",
    "account.data.religious.practices.2": "Hetente",
    "account.data.religious.practices.3": "Havonta",
    "account.data.religious.practices.4": "Evente",
    "account.data.religious.practices.5": "Ritkán, kizárólag különleges alkalmakkor",
    "account.data.religious.practices.6": "Soha",
    "account.data.language.1": "Arab",
    "account.data.language.2": "Bengáli",
    "account.data.language.3": "Bolgár",
    "account.data.language.4": "Horvát",
    "account.data.language.5": "Cseh",
    "account.data.language.6": "Holland",
    "account.data.language.7": "Angol",
    "account.data.language.8": "Filipino",
    "account.data.language.9": "Francia",
    "account.data.language.10": "Német",
    "account.data.language.11": "Görög",
    "account.data.language.12": "Gudzsaráti",
    "account.data.language.13": "Hindi",
    "account.data.language.14": "Magyar",
    "account.data.language.15": "Indonéz",
    "account.data.language.16": "Iráni",
    "account.data.language.17": "Olasz",
    "account.data.language.18": "Japán",
    "account.data.language.19": "Kannada",
    "account.data.language.20": "Koreai",
    "account.data.language.21": "Kínai",
    "account.data.language.22": "Marathi",
    "account.data.language.23": "Malayalam",
    "account.data.language.24": "Odia",
    "account.data.language.25": "Lengyel",
    "account.data.language.26": "Portugál",
    "account.data.language.27": "Pandzsábi",
    "account.data.language.28": "Román",
    "account.data.language.29": "Orosz",
    "account.data.language.30": "Szerb",
    "account.data.language.31": "Szlovák",
    "account.data.language.32": "Spanyol",
    "account.data.language.33": "Szuahéli",
    "account.data.language.34": "Svéd",
    "account.data.language.35": "Tamil",
    "account.data.language.36": "Telugu",
    "account.data.language.37": "Thai",
    "account.data.language.38": "Török",
    "account.data.language.39": "Ukrán",
    "account.data.language.40": "Urdu",
    "account.data.language.41": "Vietnámi",
    "account.data.language.42": "Egyéb",
    "account.data.relationship.status": "Párkapcsolati állapot",
    "account.data.relationship.status.1": "Egyedülálló",
    "account.data.relationship.status.2": "Párkapcsolatban",
    "account.data.relationship.status.3": "Elvált ",
    "account.data.relationship.status.4": "Házas",
    "account.data.relationship.status.5": "Özvegy",
    "account.data.relationship.history.1": "Soha nem éltem együtt senkivel, és nem volt hosszú távú kapcsolatom senkivel.",
    "account.data.relationship.history.2": "Soha nem volt olyan kapcsolatom, ami legalább 2 évig tartott.",
    "account.data.relationship.history.3": "Egy komoly kapcsolatom volt, ami legalább 2 évig tartott.",
    "account.data.relationship.history.4": "Két komoly kapcsolatom volt, amelyek legalább 2 évig tartottak.",
    "account.data.relationship.history.5": "Három vagy több komoly kapcsolatom volt, amelyek legalább 2 évig tartottak.",
    "account.data.relationship.history.6": "Volt egy házasságom/tartós kapcsolatom egy háztartásban.",
    "account.data.relationship.history.7": "Volt két házasságom/két tartós kapcsolatom egy háztartásban.",
    "account.data.relationship.history.8": "Volt 3 vagy több házasságom/tartós kapcsolatom",
    "account.data.children.1": "Nincs, és nem is akarok",
    "account.data.children.2": "Még nincs",
    "account.data.children.3": "1",
    "account.data.children.4": "2, egy kapcsolatból",
    "account.data.children.5": "2, két különböző kapcsolatból",
    "account.data.children.6": "3 vagy több, egy kapcsolatból",
    "account.data.children.7": "3 vagy több, több kapcsolatokból",
    "account.data.living.status.1": "Egyedül élek",
    "account.data.living.status.2": "Baráttal/rokonnal élek együtt",
    "account.data.living.status.3": "Szülővel/szülőkkel élek együtt",
    "account.data.living.status.4": "Élettárssal/házastárssal élek együtt",
    "account.data.living.status.5": "Egyéb",
    "account.data.body.type.1": "Sovány",
    "account.data.body.type.2": "Vékony",
    "account.data.body.type.3": "Tónusos/Szálkás",
    "account.data.body.type.4": "Izmos/Sportos",
    "account.data.body.type.5": "Zömök",
    "account.data.body.type.6": "Túlsúlyos",
    "account.data.money.management.habits": "Amikor pénzről van szó",
    "account.data.money.management.habits.1": "Én költök, költök, költök. Ezért találták ki a hitelkártyákat..",
    "account.data.money.management.habits.2": "A fizetésem szinte el van költve aznap, amikor megkapom.",
    "account.data.money.management.habits.3": "Időnként költök, de akkor is csak mértékkel.",
    "account.data.money.management.habits.4": "Nagyon odafigyelek, és csak arra költök, amit megengedhetek magamnak.",
    "account.data.money.management.habits.5": "Minden fillért magammal viszek a sírba.",
    "account.data.upbringing": "Gyermekkori éveimet itt töltöttem el",
    "account.data.upbringing.1": "Árvaházban",
    "account.data.upbringing.2": "Nevelőotthonban (nevelőszülőkkel)",
    "account.data.upbringing.3": "Rokon(ok)-kal vagy családi barát(ok)-kal",
    "account.data.upbringing.4": "Kizárólag egy biológiai szülőm nevelt fel",
    "account.data.upbringing.5": "Elvált szüleim felváltva neveltek",
    "account.data.upbringing.6": "Egy tradícionális, szoros kötelékkel rendelkező családban",
    "account.data.temperament.chart.style": "Temperamentum",
    "account.data.temperament.tag": "Temperamentum",
    "account.data.personality.chart.dimension": "Személyiség",
    "account.data.personality.dimensions.tag": "Személyiség",
    "account.data.personality.chart.traits": "Személyiségjegyek",
    "account.data.personality.traits.tag": "Személyiségjegyek",
    "account.data.difference.between.us": "Különbség köztünk",
    "account.data.love.style.chart.style": "Szerelmi élet",
    "account.data.love.style.tag": "Szerelmi élet",
    "account.data.full.name": "Teljes név",
    "contact.resend": "Újraküldés",
    "contact.resend.invitation": "Meghívó újraküldése",
    "account.edit.gender.female": "Nő",
    "account.edit.gender.male": "Férfi",
    "global.button.back.to.questionnaires": "Vissza a kérdőívekhez",
    "global.generic.success.message": "Sikeres kitöltés",
    "category": "",
    "verifyemail.message": "Email címed megerősítésre vár. Az ezzel kapcsolatos információkat emailben küldtük el amikor beregisztráltál. Ha a beérkező levelek között nem található a megerősítő email, ellenőrizze, hogy nem került-e a levélszemét mappába",
    "verifyemail.status.title": "Email cím megerősítése",
    "verifyemail.status.description.success": "Az email cím megerősítése sikeresen megtörtént",
    "verifyemail.status.description.error": "Nem sikerült megerősíteni az email címet",
    "starsign.result.comingsoon": "Hamarosan",
    "starsign.result.show": "Leírás olvasása",
    "starsign.result.get": "Leírás olvasása",
    "starsign.resultinfo.title": "Illető leírása",
    "starsign.resultinfo.desc": "A teljes karakter leírás elolvasásának költsége 2 kredit. Ha a kreditje elfogyott, lehetõsége van további kredit megvásárlàsára.",
    "starsign.noresult.title": "A karakter leírás még nem elérhető.",
    "starsign.noresult.desc": "A választott nyelven egyelőre nem elérhető a profil leírása, de hamarosan elérhető lesz.  Amennyiben szeretnéd, megtekintheted az aktuálisan elérhető nyelveken.",
    "MissingConfig": "A konfiguráció kérelem hiányzik.",
    "UnknownConfig": "",
    "UnknownUser": "Ismeretlen felhasználó",
    "UnknownCompetition": "",
    "NoPermission": "A felhasználónak nincs joga végrehajtani ezt a műveletet.",
    "MissingAnswer": "",
    "AlreadyAnswered": "Már válaszolva",
    "NotPayable": "",
    "AlreadyArchived": "",
    "MetaMustBeAnObject": "",
    "UnknownCompetitionConfig": "",
    "MissingTopUpPaymentId": "",
    "UnknownTopUpPayment": "",
    "PaymentAlreadyIssued": "",
    "UnknownPayment": "UnknownPayment",
    "NotYourBusiness": "NotYourBusiness",
    "MissingTopupIdentifier": "MissingTopupIdentifier",
    "UnknownTopup": "UnknownTopup",
    "MissingNewPassword": "Hiányzik az új jelszó",
    "FatalPasswordChangeResponse": "FatalPasswordChangeResponse",
    "InvalidOldPassword": "InvalidOldPassword",
    "UnableToRetriveValidatedUser": "UnableToRetriveValidatedUser",
    "UnableToRetriveUpdatedUser": "UnableToRetriveUpdatedUser",
    "UnableToRetriveRefreshedUser": "UnableToRetriveRefreshedUser",
    "MissingCredentials": "MissingCredentials",
    "MissingEmail": "Hiányzik az email",
    "MissingFirstName": "Hiányzik a keresztnév",
    "MissingLastName": "Hiányzik a vezetéknév",
    "MissingBirthDate": "Hiányzik a születési dátum",
    "InvalidBirthDate": "Érvénytelen születési dátum",
    "MissingPassword": "Hiányzik a jelszó",
    "EmailIsTakenByAnotherUser": "Ez az email már foglalt",
    "CannotCreateUser": "A felhasználó már létezik",
    "CannotReproduceRegistrationResult": "CannotReproduceRegistrationResult",
    "CannotSetPasswordValidation": "CannotSetPasswordValidation",
    "MissingAccessToken": "Hiányzik a facebook access token",
    "NotEnoughBalance": "Nincs elég credit a művelet végrehajtásához",
    "UnableToUpdateBirthDate": "UnableToUpdateBirthDate",
    "MissingResultTypes": "MissingResultTypes",
    "UnableToSupportOneOrMultipleResultTypes": "UnableToSupportOneOrMultipleResultTypes",
    "UnableToGenerateOneOrMultipleResultTypes": "UnableToGenerateOneOrMultipleResultTypes",
    "global.language.hu": "HU",
    "global.language.huLong": "Magyar",
    "global.language.en": "EN",
    "global.language.enLong": "Angol",
    "global.language.ro": "RO",
    "global.language.roLong": "Román",
    "global.label.credit": "kredit",
    "global.label.credits": "kredit",
    "Unauthenticated": "A jelszó vagy a felhasználó név nem megfelelő!",
    "profile.buttons.delete": "Fiók törlése",
    "starsign.result.delete.title": "Eredmény törlése",
    "starsign.result.delete.desc": "A művelet jóváhagyásával az eredmény végleg törölve lesz a rendszerből.",
    "starsign.result.edit.title": "Átnevezem a személyt",
    "account.delete.title": "Fiók végleges törlése",
    "account.delete.desc": "A művelet jóváhagyásával az összes eddigi elemzés, tranzakció és személyes adat törlésre kerül a rendszerből. Az aktív kreditjeid után sajnos nem tudunk visszatérítést biztosítani.",
    "starsign.result.readmore": "Olvass tovább",
    "notfound.title": "Az oldal nem található",
    "notfound.description": "Az oldal lehet hogy törölve lett, vagy talán megváltozott az elérési címe.",
    "notfound.backtohome": "Vissza a főoldalra",
    "comingsoon.title": "Hamarosan",
    "comingsoon.workshop": "Workshop-ok",
    "comingsoon.assessment": "Párkapcsolati elemzés",
    "comingsoon.questionnaires": "Önelemzés",
    "comingsoon.description": "Ez a szolgáltatás fejlesztés alatt áll.",
    "comingsoon.backtohome": "Vissza a főoldalra",
    "contact.firstName": "Keresztnév",
    "contact.lastName": "Vezetéknév",
    "contact.email": "Email cím",
    "contact.message": "Üzenet",
    "contact.send": "Küldöm",
    "contact.title": "Számunkra fontos a véleményed, kérdéseidet itt tudod elküldeni nekünk.",
    "contact.successTitle": "Az üzenet sikeresen el lett küldve.",
    "contact.successDescription": "Szíves türelmét kérjük, amíg munkatársaink válaszolnak a megkeresésére.",
    "loginImage": "/assets/login-assets/image-slide-hu.webp",
    "celebStories": [
        {
            "first": {
                "name": "Bogdán Csaba",
                image: 'csaba'
            },
            "second": {
                "name": "Pintácsi “Szandi",
                image: 'szandi'
            },
            "score": 0.8
        },
        {
            "first": {
                "name": "Hornyák Hajnalka",
                image: 'dundika'
            },
            "second": {
                "name": "Majoros Péter",
                image: 'peti'
            },
            "score": 0.7
        },
        {
            "first": {
                "name": "Sarka Kata",
                image: 'kata'
            },
            "second": {
                "name": "Hajdú Péter",
                image: 'hajdu'
            },
            "score": 0.4
        },
        {
            "first": {
                "name": "Debreczeni Zita",
                image: 'zita'
            },
            "second": {
                "name": "Majoros Péter",
                image: 'peti'
            },
            "score": 0.5
        }
    ],
    "Pragma": "Tudatos",
    "Mania": "Neurotikus",
    "Storge": "Meghitt",
    "Agape": "Önzetlen",
    "Ludus": "Csapodár",
    "Eros": "Szenvedélyes",
    "traditionalist": "Tradicionalista",
    "rationalist": "Racionálista",
    "adventurer": "Lelkesedő",
    "caretaker": "Idealista",
    "Open-Mindedness": "Nyitott",
    "Conscientiousness": "Tudatos",
    "Agreeableness": "Egyetértő",
    "Extroversion": "Társaságkedvelő",
    "Emotionality": "Érzelmes",
    "Honesty-Humility": "Erkölcsös",
    "Flexibility": "Rugalmas",
    "Forgiveness": "Megbocsátó",
    "Gentleness": "Gyengéd",
    "Patience": "Türelmes",
    "Diligence": "Szorgalmas",
    "Organization": "Szervezet",
    "Perfectionism": "Perfekcionista",
    "Prudence": "Körültekintő",
    "Anxiety": "Aggódó",
    "Dependence": "Függő",
    "Fearfulness": "Félénk",
    "Sentimentality": "Szentimentális",
    "Liveliness": "Élénk",
    "Sociability": "Barátságos",
    "Social-Boldness": "Társadalmi merészség",
    "Social-Esteem": "Társadalmi önbecsülés",
    "Corrupt-Avoidance": "Megvesztegethetetlen",
    "Greed-Avoidance": "Nem anyagias",
    "Modesty": "Szerény",
    "Own-Interest-Avoidance": "Valódi",
    "Creativity": "Kreatív",
    "Cultured": "Művelt",
    "Inquisitiveness": "Kíváncsiskodó",
    "Unconventionality": "Nem konvencionális",
    "starsign.on.couples.assessment": `Akár hiszed, akár nem, hogy a planetáris együttállás befolyásolja az ember gondolatait és cselekedeteit, vagy ha ez befolyásolja vagy nem befolyásolja az egyének közötti kapcsolatok dinamikáját, ez egy olyan terület, amelyet nem szabad kihagyni a önmagad és mások megértésére való törekvésedben. Van egy oka annak, hogy az asztrológia olyan régóta létezik, van benne valami. Bár ez nem bizonyított, úgy tűnik, hogy a bolygók helyzete a születéskor szerepet játszik az emberek legbensőbb jellemzőinek magyarázatában. Mint ilyen, nem szabad figyelmen kívül hagyni a törekvésedben, hogy dekódold magad vagy másokat, és ez az oka annak, hogy úgy döntöttünk, hogy az asztrológiát beépítjük a Selectivita kiválasztási folyamatba.
    A kompatibilitás kiszámításának saját módszere nem a születés idejét és helyét vizsgálja. Ezért az általunk nyújtott kompatibilitási minősítés általánosabb jellegű, különös tekintettel a széles asztrológiai jellemzők összehasonlítására a választott személlyel. Következésképpen az adott személlyel való kompatibilitási pontszámodat inkább kiindulópontként vagy útmutatóként kell használni, nem pedig abszolútumként. Míg a pontos idő és a születési hely lehet pontosabb, ez nem praktikus alkalmazni az egyes jelenet, mint akkor nem kér ilyen információt, mielőtt fontolóra társkereső valaki. Azonban lehetséges, hogy megkérdezünk egy potenciális szerelmi érdeklődést, amikor megszülettek, hogy elkezdjék megfejteni, kik vannak alatta.
    A 523 párról készült saját tanulmányunk alapján az eredmények azt mutatják, hogy a 80%-os vagy annál magasabb kompatibilitási pontszámmal rendelkező párok esetében a válás aránya 20%-kal alacsonyabb, mint a 45%-os vagy annál alacsonyabb pontszámmal rendelkező párok esetében. A rendkívül kompatibilis házasságok időtartama is 17,5%-kal magasabb volt, mint az inkompatibilis házasságok (20év. szemben a 16,6év.). Érdekes, hogy azok a párok, akik a házasság felbontását választják, függetlenül a kompatibilitástól, ugyanannyi időt töltenek házasodni (9yrs). Ráadásul a rendkívül kompatibilis házasságok közel kétszer annyi gyermeket szültek, mint azok, akik nem kompatibilisek és elváltak (1,77 vs. 1,03). Sajnálatos módon a nagyon kompatibilis párok képesek és képesek is felbomlani, míg sok nagyon összeegyeztethetetlen pár úgy döntött, hogy együtt maradnak.
    Bármi is legyen a kompatibilitási százalék valakivel, ne feledje, hogy a harmonikus és tartós kapcsolat valakivel sokkal bonyolultabb, mint a puszta asztrológia. Kutatásunk azt mutatja, hogy együtt maradni és elégednek lenni nem elég egy kapcsolat fenntartásához. A páros kötések rendkívül összetettek és dinamikusak, sok mozgó részük befolyásolja mindkét partner motivációját és döntését, hogy maradjanak vagy menjenek. Sok nem-asztrológiai elem játszik egy kapcsolatban, amely befolyásolja az elégedettséget és a hosszú élettartamot (azaz a gyerekek, a család, a munka, a barátok, a barátok, a barátok, a barátok, a barátok, a barátok, a barátok, a barátok, a barátok, a barátok, libidó, humor, nárcizmus, intelligencia, pénz, kapzsiság, féltékenység, unalom, nevelés, ragaszkodás stílus, életkor stb.). Ezek közül bármelyik komoly akadályt jelenthet a romantikus kötődés hosszú élettartamának. Egy személy csillagjele korlátozott abban a képességében, hogy elmagyarázza, hogyan kell kezelni az összes kihívást, amellyel a kapcsolatok szembesülnek.`,
    "couples.assessment.behaviour.description": "",
    "couples.assessment.personality.dimensions.description": "",
    "couples.assessment.personality.traits.description": "",
    "couples.assessment.love-style.description": "",
    "account.data.love.style.questionnaire": "SZERELMI STÍLUS",
    "account.data.personality.questionnaire": "SZEMÉLYISÉGTÍPUS",
    "user.profile.temperament.chart": "Temperamentum dimenziók",
    "user.profile.temperament.variance.chart": "Eltérés az átlagtól",
    "user.profile.personality.dimensions": "Személyiség dimenziók",
    "user.profile.personality.dimensions.variance.chart": "Eltérés az átlagtól",
    "user.profile.personality.traits": "Személyiségjegyek",
    "user.profile.personality.traits.variance.chart": "Eltérés az átlagtól",
    "user.profile.love.style.dimensions": "Szerelmi Stílus dimenziók",
    "user.profile.love.style.dimensions.variance.chart": "Eltérés az átlagtól",
    "account.data.calculate.compatibility": "Kompatibilitási számítás",
    "account.data.take.questionnaire": "Kérdőív kitöltése",
    "account.data.read.description": "Leírás olvasása",
    "spread.the.word": "Ajánlj minket",
    "earn.free.credits": "Szerezz ingyen kreditet!",
    "free.credit.for.refering.a.friend": "Minden meghívott barátod után aki regisztrál, jóváírunk neked 1 kreditet a fiókodban.",
    "meta.title": "Selectivita.com – A szerelmi és párkapcsolati szakértő.",
    "meta.description": "A párkapcsolati boldogság a szerelmi társak kompatibilitásán múlik.  A Selectivita.com tudománya segít a párválasztás döntési folyamatában.",
    "global.menu.title": "Menü",
    "global.menu.home": "Nyitólap",
    "global.menu.group": "Szolgáltatások",
    "global.menu.starsign": "Csillagjegyek kompatibilitása",
    "global.menu.assessments": "Párkapcsolati felmérés",
    "global.menu.workshop": "Workshop-ok",
    "global.menu.couples": "Párkapcsolati elemzés",
    "global.menu.comingsoon": "Hamarosan",
    "global.menu.contact": "Kapcsolat",
    "global.menu.close": "Menü bezárás",
    "global.balance": "Kredit egyenleg",
    "global.credit": "Kredit",
    "global.signin": "Bejelentkezés",
    "global.signup": "Ingyenes próba",
    "global.signout": "Kijelentkezés",
    "global.accountmenu.accountDetails": "Saját profil",
    "global.footermenu.about": "Rólunk",
    "global.footermenu.faq": "GYIK",
    "global.footermenu.pricing": "Árak",
    "global.footermenu.facebook": "Facebook",
    "global.footermenu.legal": "Jogi nyilatkozat",
    "global.footermenu.privacy": "Adatkezelesi tájékoztató",
    "global.footermenu.terms": "Használati feltételek",
    "global.languagemenu.title": "Nyelv kiválasztása",
    "global.datepicker.submit": "Mehet",
    "global.buttons.save": "Mentés",
    "global.buttons.cancel": "Mégse",
    "global.buttons.delete": "Törlés",
    "global.buttons.close": "Bezárás",
    "global.input.required": "kötelező",
    "global.input.repassword": "Az új jelszavak eltérnek",
    "cookie.title": "Az oldal sütiket használ.",
    "cookie.description": "A weboldalunkon sütiket használunk, hogy a legjobb felhasználói élményt nyújthassuk.",
    "cookie.link": "Adatkezelési irányelvek megtekintése.",
    "cookie.button": "Elfogadom.",
    "mainpage.slide1.title": "Segítünk a nagy Ő kiválasztásában.",
    "mainpage.slide1.subtitle": "Az egyik legfontosabb döntés az életünkben az, hogy kivel osztjuk meg önmagunkat és az életünket.",
    "mainpage.slide2.title": "Mit árulnak el rólad és a párkapcsolati kompatibilitásodról a csillagok?",
    "mainpage.slide2.subtitle": "Kutatásaink alapján kijelenthetjük, hogy a két fél születési dátumának összehasonlítása komoly szempont egy párkapcsolat sikerességében.",
    "mainpage.slide3.title": "Emeld egy magasabb elégedettségi szintre a barátaiddal és családtagjaiddal való kapcsolatodat.",
    "mainpage.slide3.subtitle": "Fektess még nagyobb hangsúlyt a hozzád közel álló emberek megértésére, és élvezd az ebből fakadó örömöt.",
    "mainpage.slide4.title": "Ingyenes workshopok, ami által még hatékonyabban tudod majd kiválasztani a számodra megfelelő partnert, ezáltal kedvezőbb irányt vesz az életed.",
    "mainpage.slide4.subtitle": "Engedd meg, hogy személyesen adjuk át neked a tudást, hogyan használd a Selectivita.com-ot.",
    "mainpage.slide5.title": "Tudd meg, hogyan illetek egymáshoz a partnereddel, és fejleszd a tudományunk alapján a kapcsolatotokat.",
    "mainpage.slide5.subtitle": "Mielőtt drága párterápia mellett döntesz, próbáld ki a tesztjeinket és nézd meg, hogy az értékelés alapján milyen erős alapokon nyugszik a kapcsolatotok.",
    "mainpage.section1.subtitle": "Objektív adatokkal szolgálunk.",
    "mainpage.section1.title": "Mit is csinálunk pontosan?",
    "mainpage.section1.description": "Az interperszonális kapcsolatok gazdagítása objektív elemzéssel és szelekcióval. A Selectivita fő célja, hogy segítsen számszerűsíteni egy másik emberrel való kompatibilitásodat. Ezek az egyének lehetnek barátok, családtagok, párkapcsolatok vagy akár munkatársak is. Az, hogy valakivel már elsőre megtalálod a közös hangot vagy egyáltalán nem szimpatikus, nem véletlen, emögött komoly tudomány áll.",
    "mainpage.section2.subtitle": "Kompatibilis párkapcsolatok.",
    "mainpage.section2.title": "A mi célunk.",
    "mainpage.section2.description": "Megérteni a másik nemet talán elsőre túl bonyolultnak tűnhet, ám mi itt vagyunk, hogy segítsünk ebben neked! Szeretnénk a kapcsolataidat magasabb szintre emelni az emberi elme elfogulatlan megértése révén. Fő célunk, hogy segítsünk Neked a megfelelő partner kiválasztásának folyamatában.  Élj a tanulmányunk által nyújtott lehetőséggel, hogy jobban megértsd önmagadat és az embereket, akik közel állnak hozzád.  Gazdagítsd az életed ebben a folyamatban.",
    "mainpage.section3.subtitle": "Jobb választás = Egészségesebb kapcsolatok.",
    "mainpage.section3.title": "Eredmény amire számíthatsz.",
    "mainpage.section3.description": "Jobb rálátás önmagunkra és másokra. Minél több adat áll rendelkezésünkre, annál jobb döntéseket hozunk. Hiszünk abban, hogy a jobb választás egészségesebb és erősebb kapcsolatokat eredményez. Az egészséges kapcsolat számunkra azt jelenti, hogy: kevesebb konfliktus merül fel, harmonikusabb, a pozitív kommunikáció szabad áramlása a két fél között, mindez egy egészséges légkörben. Túl szép, hogy igaz legyen? Próbáld ki és nézd meg az eredményeket!",
    "mainpage.section4.subtitle": "Önértékelési Kérdőívek",
    "mainpage.section4.title": "Hogyan csináljuk?",
    "mainpage.section4.description": "Többféle kérdőív alapján tudjuk felmérni az egyéneket. Minden megválaszolt kérdés közelebb hoz minket ahhoz, hogy objektív információkat közöljünk rólad, valamint lehetővé teszi számunkra, hogy olyan személyt ajánljunk neked, akivel kompatibilis vagy. Az eredmények pontossága teljes mértékben az őszinteségedtől függ, ezért az általunk szolgáltatott információ annyira hiteles, mint amennyire hitelesek a te válaszaid.",
    "mainpage.section4.button": "Olvass tovább",
    "landingpage.cta.title": "Ne habozz, regisztrálj és próbáld ki ingyen.",
    "landingpage.cta.description": "Úgy érzed, mintha újra és újra ugyanazokat a hibákat követnéd el? Csalódtál már valaha a legjobb barátodban/barátnődben?  Voltál már átverve egy megbízhatónak hitt munkatárs által? Egy újabb sikertelen párkapcsolatban élsz?  A Selectivitát azoknak a személyeknek hoztuk létre, akik egy új kezdetre és egy boldogabb jövőre vágynak. Jól hangzik? Engedd meg, hogy segítsünk most neked!",
    "landingpage.cta.button": "Kipróbálom",
    "starlanding.slide.title": "Számold ki a csilagjegy kompatibilitásodat bárkivel születési dátum szerint.",
    "starlanding.slide.name": "Illető neve",
    'starlanding.slide.date': 'év.hó.nap',
    "starlanding.slide.button": "Számítás",
    "starlanding.section.subtitle": "Értsd meg jobban önmagad és mást is",
    "starlanding.section.title": "Nézd meg, mik a te vagy az általad megadott személy csillagjegyére vonatkozó jellegzetességek.",
    "starlanding.section.description": "Úgy tűnik, a bolygók állása születéskor szerepet játszanak az egyéni karakterünk fejlődésében és kialakulásában. Mi emberek, sokkal összetettebbek vagyunk egy általános asztrológiai összefoglalónál, de az ezzel kapcsolatos kutatásunk azt mutaja, hogy a csillagjegyünk és a személyiségünk között vitathatatlan kapcsolat van.",
    "starlanding.celebrity.badge": "Párkapcsolati Csilagjegy Index",
    "starlanding.celebrity.title": "Vajon a hírességek sikeresebbek a párválasztásban?",
    "starlanding.celebrity.description": "Több száz híresség kapcsolatát elemeztük, az alábbiakban néhány eredmény látható. Kutatásaink azt mutatják, hogy ők sem járnak nagyobb sikerrel vagy szerencsével.  Párválasztás tekintetében, ugyanolyan nehézségekkel küszködnek, mint mindeki más.",
    "login.signup.title": "Nincs még fiókod?",
    "login.email": "Email",
    "login.password": "Jelszó",
    "login.forgetpassword": "Elfelejtetted a jelszavad?",
    "signup.login.title": "Van már fiókod?",
    "signup.description2": "Új fiók létrehozása",
    "signup.firstname": "Keresztnév",
    "signup.lastname": "Vezetéknév",
    "signup.email": "Email",
    "signup.dateofbirth": "Születési dátum",
    "signup.password": "Jelszó",
    "signup.passwordagain": "Jelszó ismét",
    "signup.terms": "Elfogadom a feltételeket",
    "signup.termsLink": "/felhasznalasi-feltetelek",
    "signup.birthDatePlaceholder": "év-hó-nap",
    "forgotpsw.title": "Új jelszó igénylés",
    "forgotpsw.description": "Azonnal küldünk egy emailt, amely tartalmazza a jelszó visszaállítási linket.  Néha előfordul, hogy az email automatikusan a kuka vagy a spam mappába kerül. Kérjük ellenőrizze, hogy nem ez történt-e.",
    "starsign.new.title": "Csillagjegy Kompatibilitási Kalkulátor",
    "starsign.new.you": "Én",
    "starsign.new.button": "Számítás",
    "starsign.new.buttonagain": "Mentés",
    "starsign.new.partnername": "Illető neve",
    "starsign.empty": "Még nincsenek eredmények",
    "starsign.loading": "Eredmények betöltése.....",
    "starsign.results.result": "Eredmény",
    "starsign.results.name": "Név",
    "starsign.results.create": "Dátum",
    "starsign.results.born": "Született",
    "starsign.results.created": "Készített",
    "topup.title": "Számlaegyenleg feltöltés",
    "topup.currency.label": "Pénznem",
    "topup.currency.title": "Pénznem választás",
    "topup.mostpopular": "Legnépszerűbb",
    "topup.buynow": "Megveszem",
    "payment.status.title": "Fizetés",
    "payment.status.description": "Várjuk a fizetési kapu válaszát. A késésért szíves türelmüket és megértésüket kérjük.",
    "payment.status.button": "Frissítés",
    "account.edit.invite.friends": "Meghívó küldése",
    "account.edit.email": "Email",
    "account.edit.firstName": "Kereszt név",
    "account.edit.lastName": "Vezetéknév",
    "account.edit.title": "Profil szerkesztése",
    "account.edit.photo": "Új profilkép feltöltése",
    "account.edit.nickname": "Becenév",
    "account.edit.gender": "Nem",
    "account.edit.dateOfBirth": "Születési dátum",
    "account.edit.city": "Város",
    "account.edit.changepsw": "Jelszó csere",
    "account.edit.changepsw.title": "Cseréld a jelszavad",
    "account.edit.changepsw.old": "Régi jelszó",
    "account.edit.changepsw.new": "Új jelszó",
    "account.edit.changepsw.newagain": "Új jelszó ismét",
    "login.button": "Bejelentkezés",
    "login.title": "Fiók bejelentkezés",
    "login.description": "Kezd el megváltoztatni a jövődet ma!",
    "login.facebookbutton": "Belépek Facebookkal",
    "login.description2": "Jelentkezz be, hogy hozzáférj a fiókjához!",
    "signup.login.button": "Bejelentkezés",
    "signup.description": "Email cím megerősítés után, jóváírunk neked 5 kreditet",
    "signup.facebookbutton": "Regisztrálok Facebookkal",
    "forgotpsw.backbutton": "Vissza a Bejelentkezéshez",
    "signup.button": "Fiók létrehozása",
    "starsign.results.title": "Korábbi számítások",
    "account.edit.empty": "Üres",
    "global.menu.questionnaires": "Önelemzés",
    "global.menu.profiles": "Böngésszen a felhasználók között",
    "global.menu.matchmaking": "Társkereső",
    "global.accountmenu.topup": "Egyenlegfeltöltés",
    "global.footermenu.copyright.start": "© 2019-",
    "global.footermenu.copyright.end": "Selectivita.com - Minden jog fenntartva.",
    "global.buttons.submit.questionnaire": "Küldés",
    "account.data.meta.infos": "META INFOS",
    "account.data.edit.profile": "PROFIL SZERKESZTÉSE",
    "global.buttons.next.question": "következő",
    "global.buttons.previous.question": "előző",
    "account.edit.relationship.status": "Párkapcsolati állapot",
    "test.option.totally.disagree": "egyáltalán nem értek egyet",
    "test.option.disagree": "nem értek egyet",
    "test.option.slightly.disagree": "részben nem értek egyet",
    "test.option.neutral": "semleges",
    "test.option.slightly.agree": "részben egyetértek",
    "test.option.agree": "egyetértek",
    "test.option.totally.agree": "teljesen egyetértek",
    "account.data.open.for.relationship": "Szingli vagyok",
    "account.data.closed.for.relationship": "Nem vagyok szingli",
    "account.data.birthday": "Születési dátum",
    "account.data.pay.credit.to.reveal": "Megjelenítés",
    "account.data.fill.out": "TÖLTSD KI A",
    "account.data.test": "KÉRDŐÍV",
    "account.data.alt.icon.text": "analysis icon",
    "account.data.find.profile": "Személy keresése",
    "account.data.history": "Történet",
    "sent.invites": "Elküldött meghívók",
    "previous.assessments": "Korábbi értékelések",
    "account.data.noMatches": "Egyetlen profil sem felel meg a kritériumoknak",
    "account.data.profiles": "Profilok",
    "account.data.age": "Kor",
    'age': 'Kor',
    'birthday': 'Született',
    "account.data.startsign": "Csillagjegyek kompatibilitása",
    "account.data.gender": "Nem",
    "account.data.ethnicity": "Faji hovatartozás (max 2)",
    "account.data.city": "Város",
    "account.data.country": "Ország",
    "account.data.height": "Magasság",
    "account.data.weight": "Súly",
    "account.data.hair.color": "Természetes hajszín",
    "account.data.eye.color": "Szemszín",
    "account.data.body.type": "Alkat",
    "account.data.marital.status": "Családi állapota",
    "account.data.languages": "Folyékonyan beszélt nyelvek",
    "account.data.mobile": "Mobil",
    "account.data.email": "Email",
    "account.data.empty": "Nincs megtekinthető fenykép",
    "account.data.more.photos": "KÉPEK",
    "account.data.personal.infos": "SZEMÉLYES TULAJDONSÁGOK",
    "account.data.our.recommendations": "NEKED AJÁNLJUK",
    "account.data.couples.assessment": "PÁRKAPCSOLATI ELEMZÉS",
    "account.data.match.finder": "KERESŐ",
    "account.data.like.list": "KEDVENCEK",
    "account.data.contact.infos": "ELÉRHETŐSÉG",
    "account.data.social.media": "KÖZÖSSÉGI MÉDIA FIÓKOK",
    "account.data.living.status": "Kivel élsz jelenleg?",
    "account.data.relationship.history": "Eddigi párkapcsolataid",
    "account.data.children": "Gyerekek száma",
    "account.data.relationship.interests": "Milyen kapcsolat érdekel?",
    "account.data.religion": "Vallás",
    "account.data.libido": "Milyen gyakran van szükséged szexuális intimitásra?",
    "account.data.sexual.orientation": "Szexuális irányultság",
    "account.data.worship.frequency": "Milyen gyakran jársz vallásos intézményekbe?",
    "account.data.education.level": "Legmagasabb végzettség",
    "account.data.drug.usage": "Használsz-e vagy használtál-e valaha illegális drogokat?",
    "account.data.profession.type": "Foglalkozásomhoz legközelebb áló terület",
    "account.data.is.smoker": "Dohányzol?",
    "account.data.alcohol.consumption": "Mennyi alkoholt fogyasztasz?",
    "account.data.tattoos": "Tetoválás?",
    "account.data.openProfile": "Profil megtekintése",
    "account.data.view.profile": "Profil megtekintése",
    "account.data.clearFilters": "Szűrők törlése",
    "account.data.showResults": "Keresés",
    "account.data.filters": "SZŰRÉSI FELTÉTELEK",
    "account.data.meta.info": "META INFO",
    "account.data.miscellaneous.filters": "SZEMÉLYES PREFERENCIÁK",
    "account.data.personal.info.filters": "SZEMÉLYES TULAJDONSÁGOK",
    "account.data.background.info.filters": "HÁTTÉRADATOK",
    "account.data.meta.info.filters": "Meta info filters",
    "account.data.fill.out.for.free": "Töltse ki ingyen",
    "account.data.retake.questionnaire": "Kérdőív kitöltése újra",
    "account.data.personality": "SZEMÉLYISÉG",
    "account.data.behaviour": "TEMPERAMENTUM",
    "account.data.loveStyle": "SZERELMI STÍLUS",
    "account.data.starSign": "CSILLAGJEGY",
    "account.data.level.of.love": "SZERELMI SZINT",
    "account.data.temperament.content": "A temperamentum tartalma jelenleg írás alatt van.",
    "account.data.personality.content": "A személyiség tartalma jelenleg írás alatt van.",
    "account.data.level.of.love.content": "A szerelmi stílus tartalma jelenleg írás alatt van.",
    "account.data.read.my.description": "Leírás olvasása",
    "account.data.read.my.analysis": "Elemzés olvasása",
    "account.data.view.data": "Eredmény megtekintése",
    "account.data.buy.data": "Eredmény megvásárlása",
    "account.data.my.behavioural.description": "Temperamentum elemzés",
    "account.data.my.behaviour.comparative.analysis": "Összehasonlító elemzés",
    "account.data.my.personality.description": "Személyiségi elemzés",
    "account.data.my.personality.comparative.description": "Összehasonlító elemzés",
    "account.data.my.love-style.description": "Szerelmi Stílus elemzés",
    "account.data.my.love-style.comparative.analysis": "Összehasonlító elemzés",
    "account.data.min": "min",
    "account.data.max": "max",
    "account.data.read.about": "Olvass rólunk",
    "account.data.languages.label": "Beszélt nyelvek",
    "account.data.buy.read": "Olvasd",
    "account.data.contact": "Kapcsolat",
    "account.data.profile.completion.1": "Jelenleg a profilod",
    "account.data.profile.completion.2": "-os. Töltsd ki 100%-ra hogy jelenj meg a társkeresőben.",
    "account.data.profile.visibility.true": "Profilod látható a társkereső oldalon",
    "account.data.profile.visibility.false": "Profilod el van rejtve a társkereső oldalon (Iratkoz fel, ha szeretnél megjeleni a tobbi felhasználó között)",
    "account.data.hide.profile": "Feliratkozás",
    "account.data.show.profile": "Leiratkozás",
    "account.data.me.small": "én",
    "account.data.average.chart.her": "Ő",
    "account.data.average.chart.him": "Ő",
    "account.data.average.chart.avg": "ÁTLAG",
    "account.data.average.chart.you": "TE",
    "account.data.you": "Ő",
    "account.data.me": "ÉN",
    "account.data.invite.friend": "Hívd meg a barátaidat",
    "account.data.buy.analysis": "Elemzés megvásárlása",
    "account.data.read.analysis": "Elemzés",
    "account.data.analyze.us": "Elemezzen minket",
    "account.data.gender.is.required.in.order.to.proceed": "Kérjük, adja meg nemét a kérdőív kitöltéséhez",
    "account.data.please.provide.your.date.of.birth": "Kérjük, adja meg születési dátumát",
    "account.data.facebook": "Facebook",
    "account.data.instagram": "Instagram",
    "account.data.twitter": "Twitter",
    "account.data.tiktok": "TikTok",
    "account.data.linkedin": "LinkedIn",
    "account.data.other": "Egyéb",
    "account.data.snapchat": "Snapchat",
    "account.data.empty.list": "Egy barátot sem hívtál még meg.",
    "account.data.see.more": "További személyek betöltése",
    "not.enough.balance": "Nincs elég kredited",
    "account.data.error.header": "HIBA",
    "account.data.error.user.profile.is.not.complete": "Nem tudunk értékelni titeket, mert ez a felhasználó még nem töltötte ki a profilját",
    "account.data.error.something.went.wrong": "Hiba történt. Később próbáld meg újra",
    "account.data.error.you.have.already.assessed.with.this.user": "Ezzel a felhasználóval már kiértékelted magad",
    "account.data.error.invalid.url.format": "Érvénytelen URL-formátum",
    "account.data.error.already.assessed": "Ezzel a felhasználóval már kiértékelted magad",
    "account.data.error.no.email.has.been.provided": "Kérjük, adj meg egy email címet",
    "account.data.error.this.user.profile.is.incomplete": "Sajnáljuk, de ennek a felhasználónak a profilja hiányos",
    "account.data.error.cannot.evaluate.with.yourself": "Sajnáljuk, de ezt a műveletet nem tudod végrehajtani",
    "account.data.error.user.not.found": "Ez a felhasználó nem található",
    "account.data.error.your.profile.is.incomplete": "A profilod hiányos",
    "account.data.error.email.already.in.use": "Valaki már regisztrált ezzel az email címmel",
    "account.data.error.one.social.is.required": "Legalább egy közösségi média oldal megadása szükséges",
    "account.data.behavioural.questionnaire": "TEMPERAMENT STÍLUS",
    "account.data.behavioural.questionnaire.description.complete": "Az alábbi diagramok megmutatják, hogy milyen a temperamentumod stíluss. Az első diagram azt mutatja, hogy az egyes dimenziók szintje az összesen 100% -ból áll. Minden rúd magassága megegyezik azzal, hogy milyen mértékben járul hozzá a viselkedési tendenciákhoz. A színes diagram az egyes dimenziók eltérési szintjét mutatja a nemek szerinti népesség átlagától. Ez a különbség az átlagtól a legjobban mutatja, hogy mi a temperamentumod stílusa. A legmagasabb sáv(ok) a domináns stílusodat képviselik, és a leginkább befolyásolják a karakteredet. A legalacsonyabb sáv(ok) a legkevésbé befolyásolják Önt. Az összes dimenzióbeli különbség kombinációja természetes temperamentumstílusodat eredményezi.",

    "account.data.behavioural.questionnaire.description.incomplete": `A temperamentum stílusa a biológiai struktúrában gyökerezik, és ritkán változik az idő múlásával. A szüleidtől öröklődik, és ez képezi az alapvető viselkedésed alapját. A temperamentum vezérli az ösztönös reakciókat és a külső tényezőkre adott válaszokat. Gondolj a négy temperamentum dimenzióra, mint például a CMYK színmodellre, a viselkedési profilod változó keveréke mind a négy színnek. Az egyes skálának ebből eredő szintje létrehozza a veleszületett temperamentumodat. A temperamentum stílusának megértése nemcsak a belső harmónia növelésében segíthet a tisztább önvizsgálat és az önfejlesztés révén, hanem segíthet az interperszonális harmónia növelésében is a másokkal való személyes kapcsolatok dinamikájának megértése révén. A temperamentum stílusok koncepciójának ismerete szintén segít a szülővel, rokonnal, baráttal, munkatárssal és különösen egy romantikus partnerrel való kapcsolat erősítésében.
    </br> </br> <strong>Kitöltési útmutató:</strong> Egyszerűen válassza ki azt a választ, amelyről úgy érzi, hogy a leginkább reprezentatív. Nincs jó vagy rossz válasz, ezért ne gondold túl. Csak menj a béllel, és válassza ki a választás, amely úgy tűnik, a legjobb először.`,
    "account.data.love.style.questionnaire.description.complete": `Az alábbi diagramok a természetes szerelmi stílusodat jelenítik meg hat dimenzió kombinációja alapján. Az első diagram az egyes dimenziók arányát mutatja az összesen 100%-ból. Az egyes rúd magassága megmutatja, hogy mennyire fontos a stíluskompozícióban. A színes diagram az egyes dimenziók eltérését mutatja a nemek szerinti népesség átlagától. Ez a diagram illusztrálja a gondolkodásmódotok minden dimenziójának intenzitását. A legnagyobb pozitív varianciájú dimenziók a legnagyobbak, míg a negatív értékek a legkisebbek. Ez mind a hat dimenzió keveréke, amely feltárja, hogyan fejezed ki magad, és mi a veleszületett motiváló sofőrjeid egy romantikus kapcsolatban.`,
    "account.data.love.style.questionnaire.description.incomplete": `A szeretet a fizikai és érzelmi vonzódás természetes érzése egy másik személy felé. Ez azonban egy összetett jelenség, amelyet mindenki másképp tapasztal. Ez a kérdőív segít megérteni, hogy a szerelem és a romantika miért jelent neked, és mit jelenthet másoknak. A Love Style kérdőív a romantikus kapcsolatokban a természetes viselkedési hajlamodat méri. Ez számszerűsíteni fogja a hozzáállásodat a hat dimenzió felé a szeretet birodalma alatt. Ez azt eredményezi, hogy felméri, hogy mi a szerelmi stílusa, és tájékoztatja Önt arról, hogy melyik típus felel meg a legjobban. A diagramok és elemzések olyan eszközt biztosítanak, amely jobban megérti, hogy mi az Ön vagy bárki más igényei és igényei intim kapcsolatban vannak. Az ilyen információk előkészíthetik az utat egy teljesebb szerelmi élethez és egészségesebb romantikus kapcsolatok kiépítéséhez.
    </br> </br> <strong>Kitöltési útmutató:</strong> Ne gondoljon egy konkrét személyre a kérdőív kitöltésekor. Ehelyett képzeljünk el egy „ideális” társat, aki a legjobban kielégíti ösztönös szükségleteinket és szükségleteinket. A válaszaid tükrözik, hogy milyen a természetes stílusod. Ha egy adott személy jut eszedbe, amikor befejezed, az eredményeid tükrözik az adott személyhez igazított szerelmi stílusodat, mivel a többi ember általában befolyásolja a stílusunkat. Ezért fontos, hogy olyan valakivel legyél, aki kiegészíti a természetes stílusodat, ahelyett, hogy gyengítené azt.`,
    "account.data.personality.main.dimensions": `Az alábbi két diagram a vizsgált 6 személyiségdimenzió eredményeit mutatja. Az első diagram az egyes dimenziók egyedi jelentőségszintjét mutatja az összesen 100% -ból. A színes diagram megjeleníti az egyes dimenziók különbségét a nemek átlagához képest. Ez a diagram pontosabban tükrözi a személyes jellemzőit, mivel megmutatja, hogy melyik dimenzió különbözik a leginkább az átlagtól. Ez az a variancia, ami a legjobban megmutatja, hogy mi a természetes személyiséged.`,
    "account.data.personality.sub.traits": `Az alábbi digram 4 tulajdonság eredményét jelenítik meg a 6 személyiségdimenzió mindegyikében. Ez összesen 24 vizsgált tulajdonságnak felel meg. Az első diagram azt mutatja, hogy az egyes tulajdonságok szintje az összesen 100% -ból áll. A színes diagram megjeleníti az egyes tulajdonságok eltérését a nemek átlagától. A vonások és az átlagtól való kísérő különbségek mély képet adnak a saját egyedi személyiségösszetételéről.`,
    "account.data.personality.questionnaire.description.complete": `<strong>Személyiségi dimenziók</strong> </br> </br>
    A két felső diagram a 6 vizsgált személyiségdimenzió eredményeit mutatja. Az első diagram az egyes dimenziók egyedi jelentőségszintjét mutatja az összesen 100% -ból. A színes diagram megjeleníti az egyes dimenziók különbségét a nemek átlagához képest. Ez a diagram pontosabban tükrözi a személyes jellemzőit, mivel megmutatja, hogy melyik dimenzió különbözik a leginkább az átlagtól. Ez az a variancia, ami a legjobban megmutatja, hogy mi a természetes személyiséged.
    </br> </br> <strong>Személyiségjegyek</strong> </br> </br>
    Az alsó két digram 4 tulajdonság eredményét jelenítik meg a 6 személyiségdimenzió mindegyikében. Ez összesen 24 vizsgált tulajdonságnak felel meg. Az első diagram azt mutatja, hogy az egyes tulajdonságok szintje az összesen 100% -ból áll. A színes diagram megjeleníti az egyes tulajdonságok eltérését a nemek átlagától. A vonások és az átlagtól való kísérő különbségek mély képet adnak a saját egyedi személyiségösszetételéről.`,
    "account.data.personality.questionnaire.description.incomplete": `Az a modor, ahogyan a körülötted lévő világgal foglalkozol, a személyiséged által van befolyásolva. A genetikai tényezők, a nevelés és az élettapasztalatok mind hozzájárulnak és alkotják a személyiségedet. A személyiség viszonylag stabil, ezért meglehetősen nehéz megváltoztatni. Csak az önismeret és a kemény munka által kezdheted el megváltoztatni. A pszichológusok több ezer olyan személyiségvonást azonosítottak, amelyek leírják az emberi viselkedést. A vonások az építőelemek, amelyek az egyedi személyt teszik ki, akik nagyban hozzájárulnak ahhoz, ahogyan navigálsz ebben a világban. Következésképpen a számos tulajdonságod közötti kölcsönhatás a gondolatok, érzések és viselkedések egyedi mintáját eredményezi.
    A személyiségjegyek egy spektrumban viselkednek, mindkét vége ellentétes (azaz a bátorság az egyik végén, míg a gyávaságnak a másik végén van). Az alábbi kérdőív 6 fő személyiségdimenziót és 24 jellemzőt fog vizsgálni. Ezek mind olyan egyetemes jellemzők, amelyek bizonyos mértékig minden emberben jelen vannak, ami lehetővé teszi számunkra, hogy összehasonlító elemzést végelvégeljünk az egyének között. A jellemvonások ismerete nem csak lehetővé teszi, hogy jobban megértsük és megjósoljuk az indítékaidat, hanem lehetővé teszi mások viselkedésének előrejelzését is. Mint ilyen, ez előkészíti az utat a jobb kapcsolatok másokkal, mint akkor fel lesz szerelve a tudás, hogy ki természetesen kiegészíti a személyes tulajdonságait a legjobb, és ki nem.
    </br> </br> <strong>Kitöltési útmutató:</strong>
    A kérdőív eredményei sem nem jók, sem nem rosszak, sem nem jók vagy rosszak – egyszerűen csak arról tájékoztat, hogy az egyes tulajdonságok milyen mértékben befolyásolják Önt. Ne gondolja túl a válaszokat, csak válassza ki az első választ, amelyet úgy érzi, hogy a legjobban tükrözi a véleményét a kijelentéshez.`,
    "account.data.questionnaire": 'KÉRDŐÍV',
    "traditionalist.category.description": `Azok az egyének, akik a Traditionalista dimenzióban magas pontszámot érnek el, a dolgokat akár feketében, akár fehérben látják, nincs közöttük. Ezek pontos, pontos, strukturált és analitikus típusok, amelyeket tények és számok vezéreltek. Következetes és szisztematikus egyének, akik betartják a szabályokat és a rutinokat. A pontosságot és a helyességet díjazták, és a legmegbízhatóbb emberek közé tartoznak. Azt mondják, amit tesznek, azt teszik, amit mondanak, és ugyanezt várják el másoktól is. A tett ígéret olyan ígéret, amelyet mindig egy magasan tradicionalista ember tart be.
  </br> </br>A tradicionalistáknak módszeresen kell dolgozniuk annak érdekében, hogy a munkát tökéletesen elvégezzük, még az idő rovására is. A hibák és a hibák a legrosszabb rémálmuk. Szándékosan és nem szeretik a munkájuk vagy döntéseik kritikáját. Mint ilyen, ők véleményezett és ítélkező egyének, akik nem kényelmes, hogy kis beszélgetés. Az ilyen tendenciák érzéketlen, túlanalitikus és rugalmatlan nit-picker-ekként találkoznak. A tradicionalisták összecsapnak a rajongókkal, mivel szinte ellentétek, és a legjobban kijönnek más logikus és merev tradicionalistákkal.`,
    "caretaker.category.description": `Azok az egyének, akik az idealista dimenzióra magas pontszámot érnek el, a szívüket követik, nem az agyukat. Teréz anya egy magas idealista megtestesítője. Azok az egyének, akik magas pontszámot érnek el ebben a dimenzióban, nagyon lazák, nem konfrontálódnak, és nagyszerű hallgatók, akik szeretnek segíteni másoknak. Az idealisták gyengédek, táplálóak, melegek, és a legfigyelmesebb emberek, akikkel találkozhatsz. Mint ilyen, együttműködő követők, akik megbízhatóak és támogatják a többieket, ami nagyszerű csapatjátékossá teszi őket. Altruista természetük miatt hajlamosak érzelmeken alapuló döntéseket hozni. Azonban az ítéletük gyakran irracionális, és problémákhoz vezet.
  </br> </br>A legjobbat látják az emberekben, és olyan nagyfokú az együttérzésük, hogy gyakran hiszékenynek tartják őket.  Ez nagyon is igaz lehet, hiszen csak szeretni és szeretni akarnak. Ismert, hogy határozatlanok, bizonytalanok, önfeláldozók és befolyásolhatók. Mivel nagy szükség van a biztonságra és a stabilitásra, nem szeretik a bizonytalanságot, és nem agresszív önindító típusok. Nehézségeik vannak a nyomáshelyzetek kezelésével, és ez általában nagy szorongást okoz számukra. A romantikus kapcsolatokban az idealisták egyensúlyt és harmóniát találnak az ellentétekben, a racionalistában. Bár a racionalistákat hidegnek vagy érzelemtelennek érzékelhetik, ők azok, akik hosszú távon a legjobbat teszik ki.`,
    "adventurer.category.description": `Azok az egyének, akik az lelkesedő dimenziójában magas pontszámot érnek el, a leginkább szociális, energikus, pozitív, élénk, barátságos, nyitott emberek a bolygón. Ők azok az egyének, akik mindig mosolyognak, nevetnek, viccelődnek és mindenkivel csevegnek. Szórakoztató és játékos természetük van, szórakoztató és népszerű barátaik. Ők a leglényegesebb „emberek”, és mindig mások veszik körül őket. A rajongók nagyon kreatívak, és a dobozon kívül gondolkodnak, mint ilyenek, megvetik a rutinokat és az újdonságra vágynak. Ez a tendencia teszi őket hajlamos unatkozni elég könnyen. A magas pontozók ezen a skálán spontán és dinamikus lények, akik élvezik az életet a gyors sávban. A tojásaik sok kosárban vannak, és általában egyszerre zsonglőrködnek.
  </br> </br>A napi maximális élvezetek kihozása személyes vágyaik teljesítése érdekében rendkívül fontos számukra. Az élet mantrája: „Az élet kaland és izgalom nélkül olyan élet, amit nem érdemes élni.” A rajongók nehezen tudják irányítani az impulzusaikat, és nehezen tudnak senkit mondani, különösen magukat. Hihetetlen kommunikációs készségekkel vannak megáldva, ami nagyszerű motivátorrá teszi őket, akik könnyen meggyőzhetnek másokat. A nagyon lelkes emberek lelkesek, és bárki fejét lebeszélhetik. Sajnos, van egy hajlamuk a szabályok megszegésére, és gyakran tekintik késedelmes, kiszámíthatatlan, kiszámíthatatlan, könnyelmű és megbízhatatlan. Félnek az elutasítástól, és nagy vágyuk van az elismerésre, a jóváhagyásra és a társadalmi elfogadásra. A rajongók túl kiszámíthatónak és unalmasnak fogják találni a tradicionalistákat. A legszórakoztatóbb és legelégedettebb élményt fogják megtapasztalni egy másik, könnyed, csábító emberrel.`,
    "rationalist.category.description": `Azok az emberek, akik magas pontszámot érnek el a racionalista dimenzióban, rendkívül objektívek és ésszerűek. Ők a legönellátóbb, legfüggetlenebb, legdominánsabb, legfélelmetesebb és legmagabiztosabb egyének a környéken. Merész kockázatvállalók, akik szeretik a kihívásokat és gyorsan megoldják a bonyolult problémákat. Olyan harcosok, akik szeretnek versenyezni, nem értenek egyet, vitatkoznak és meghajlják az agyukat. Azok az egyének, akik magas pontszámot értek el ezen a skálán, eredményorientált teljesítők, akik szeretnek irányítani. Közvetlen, döntő, és lépéseket tesz. Soha senki nem fél a konfliktusoktól, az ellenzéktől virágzik, és nem riad vissza attól, hogy a bikát a szarvak mellé vegye. A hatalom és a hatalom természetesen jön hozzájuk.
  </br> </br>Azok a racionalisták, akik úgy találják magukat, hogy bozontoznak, hazudnak nekik, vagy kihasználják őket, rendkívül elégedetlenek lesznek. Türelmetlenek, és hajlamosak a haragra, amikor a dolgok nem mennek a maguk útján. Ez különösen akkor igaz, ha mások lassúak, nem hatékonyak, vagy egyszerűen nem felelnek meg az elvárásaiknak. Ez a minőség teszi őket gyenge csapatjátékossá, kivéve, ha ők felelősek a csapatért. A racionalisták durvának vagy tapintatlannak tűnhetnek, sok embert rossz irányba dörzsölve. Szerencsére nem érinti őket mások véleménye, és nincs probléma, hogy egyedül vannak, természetesen a tetején. Ez az oka annak, hogy a legtöbb más temperamentummal ütköznek, kivéve az idealistákat. Az idealisták boldogan kötelezik a racionalisták minden kívánságát, és ők az egyetlenek, akik képesek áthatolni erős külső héjukon.`,
    "Honesty-Humility.category.description": `Azok az emberek, akik az erkölcsi dimenziót magasra értékelik, elkerülik a fondorlatos viselkedést. Betartják a társadalmi normákat, betartják a szabályokat, és soha nem fognak becsapni másokat személyes nyereségért. Ahogy a neve is sugallja, ez a tényező az ember azon hajlamát méri, hogy elmondja az igazságot, igazságos legyen, és megfeleljen a társadalmi normáknak, hogy mi a jó és mi a rossz. Az erkölcsi egyének lelkiismeretes és figyelembe veszik cselekedeteik hatásait. Ők is alázatosak, és feláldozzák saját rövid távú érdekeiket a társadalom javára. A magas pontozók nem materialisták, és nem foglalkoznak a gazdag vagyonnal, luxussal, különleges kiváltságokkal, vagy a magasabb társadalmi státuszba való felemelkedéssel.
  </br> </br>Ezzel szemben a nagyon alacsony pontszámmal rendelkező egyének erkölcstelenek, és hízelegnek másoknak, hogy megkapják, amit akarnak, hajlamosak megszegni a személyes nyereségre vonatkozó szabályokat, és erősen motiválják őket az anyagi nyereség. Emellett erős önfontosságot éreznek, és csak magukra néznek. Ez teszi az ilyen egyének nagyobb valószínűséggel rendelkeznek narcisztikus vagy machiavellista tulajdonságokkal.
  </br> </br>Ami a romantikus kompatibilitást illeti, a társak közötti hasonlóság ebben a dimenzióban hosszú távon nyer. Azonban az etikátlan egyének általában szeretik az elvi partnereket, mivel könnyen kihasználhatók. Bár hosszú távon a gazemberek, tolvajok és gazemberek több boldogságot találnak a közösségre, mint a hasonlóságra, még akkor is, ha nem bíznak igazán egymásban.`,
    "Agreeableness.category.description": `Azok az emberek, akik nagyon magas pontszámot értek el az egyetértő dimenzióban, megbocsátják az elszenvedett igazságtalanságokat, és tolerálják mások hiányosságait. Az egyetértő ember nem akar senkit kihozni a sodrából, ezért mindent megtesznek, hogy kompromisszumot kössenek és együttműködjenek másokkal a béke megőrzése érdekében. Valójában gyakran feláldozzák azt, amit valójában akarnak, csak a másik személy elégedettsége érdekében. Ráadásul enyhe sarokba szorultak, és ritkán hordoznak rossz érzéseket azokkal szemben, akik megsértik őket. Nem tartanak haragot, és képesek gyorsan elengedni a negatív érzéseket.
  </br> </br>Ezzel szemben a kellemetlen emberek általában versenyképesek, érvelőek és árvóak azokkal szemben, akik megsértették őket. Nagyon kritikusak mások hibáival szemben, makacsul védik a véleményüket, és könnyen érzik a haragot mások rossz bánásmódjára válaszul.
  </br> </br>A kellemes emberek leginkább összeegyeztethetők egy vitatkozó társsal, mivel a legjobban kiegyensúlyozzák egymást. Ha mindkét személy túlságosan alkalmazkodó, vagy fordítva, egyik sem adja be, akkor nem lesz jó hosszú távú mérkőzés a túl sok hasonlóság miatt. Az egyik oldalnak mindig adnia kell, hogy véget vessen egy patthelyzetnek, mint olyannak, csak az ellentétek ebben a dimenzióban találhatják meg igazán a hosszú távú boldogságot.`,
    "Conscientiousness.category.description": `Azok az egyének, akik a tudatos dimenzió magas pontszámot érnek el, megtervezik idejüket és életüket. Ezek az emberek nagyon koncentráltak, motiváltak, és nagy erőfeszítéseket tesznek életük és karrierjük strukturálására. Nagyon szeretik irányítani és megszervezni minden aspektusát mindennek, amit csak tudnak. A nagyon lelkiismeretes egyének fegyelmezettek, elszántak, felelősségteljesek és megbízhatóak. Ezek rendkívül részletesek és értékesek minden más felett. Amellett, hogy perfekcionisták, keményen dolgoznak, és feláldozzák vagy késleltetik a személyes kielégülést céljaik elérése érdekében. A döntéshozatali folyamat szempontjából módszeresek, és a megfelelő értékelés biztosítása érdekében a lehető legtöbb információt kell gyűjtenie a témáról.
  </br> </br>Éppen ellenkezőleg, az alacsony pontszámmal rendelkező egyének hajlamosak arra, hogy rendezett környezetben éljenek, vagy a meghatározott ütemtervek szerint éljenek. Figyelmetlennek és figyelmetlennek tűnnek, és annyira lazák, hogy hagyják, hogy a szél irányítsa az irányt. Elkerülik a nehéz feladatokat, ritkán tűznek ki kihívást jelentő célokat, ritkán terveznek előre, és egyszerűen hagyják, hogy a dolgok megtörténjenek. Az alkalmi emberek nemcsak általában elégedettek a hibákkal teli elhamarkodott munkával, hanem általában szervezetlenek is, és impulzussal vagy minimális megfontoltság mellett hoznak döntéseket.
  </br> </br>Ami a romantikus kompatibilitást illeti, ez a dimenzió nehéz, mert néhány tulajdonságnak hasonlónak kell lennie, és néhány poláris ellentétnek. A munkahelyes, lelkiismeretes emberek a legjobban kijönnek más lelkiismeretes emberekkel. A romantikus kapcsolatok tekintetében azonban ez nem így van, mivel általában a fejeket fogják megcsípni. Hosszú távon a hasonló és ellentétes tulajdonságok keveréke ezen a dimenzión belül a legjobb kombináció a hosszú távú kapcsolat elégedettségéhez.`,
    "Emotionality.category.description": `Azok az emberek, akik az érzelmi dimension magasabb pontszámmal rendelkeznek, félnek a fizikai veszélyektől, idegességet tapasztalnak, amikor az élet mindennapi stresszeivel szembesülnek, és sok érzelmi támogatást igényelnek. Mély empátiát éreznek, és szentimentális kötődésük van a körülöttük lévőkkel. A rendkívül magas pontozók nagyobb valószínűséggel tapasztalnak fokozott szorongást, paranoiát, depressziót, és még agresszívvá is válnak az önvédelemben.
  </br> </br>Másrészt azok, akiknek nagyon alacsony pontszámuk van ezen a skálán, nem félnek vagy félnek a fizikai károsodás gondolatától, nyugodt kockázatvállalók, és még stresszes körülmények között is minimális aggodalmat éreznek. Ritkán érzik szükségét, hogy megosszák problémáikat másokkal, és ez azt eredményezi, hogy érzelmileg elszakadnak másoktól.
  </br> </br>A romantikus kapcsolatokban az ellentétek biztosítják a legjobb egyensúlyt egymás között. Két nagyon érzelmi egyén párosodása veszélyes lehet egy mérgező kapcsolat kialakulására és megélésére, mivel hosszú távon őrületes módon fogják egymást vezetni. Ezzel szemben két érzelemmentes társ monoton és apatikus kapcsolatot eredményezhet. A legjobb egyensúly akkor érhető el, ha mindkét partner ellentétes eredményeket ér el ebben a dimenzióban.`,
    "Open-Mindedness.category.description": `A nyított dimenzióval rendelkező egyének szívesen fogadják az új ötleteket, témákat, véleményeket, szokásokat, tapasztalatokat, és a különböző típusú, a különböző típusú és típusú, a különböző típusú, a különböző típusú és típusú, a különböző típusú, és az információ, még akkor is, ha ellentétes nézeteket vallanak. Kíváncsiak a körülöttük lévő világra, kényelmesen kipróbálnak új dolgokat, részt vesznek kreatív tevékenységekben, és széles körű érdeklődéssel rendelkeznek a „növekedés-gondolkodásmód” révén. Ezek az egyének hajlamosabbak felfedezni az élet különböző részeit, az alternatív fogalmakat és a különböző gondolkodásmódokat. A nyitott emberek hajlandóak aktívan keresni bizonyítékokat saját meggyőződésük, terveik vagy céljaik ellen, és minden bizonyítékot igazságosan mérlegelnek. Széles látókörű emberek, akiket elbűvöl a művészet és a természet szépsége, kíváncsiak az intelligencia különböző területeire, szabadjára alkalmazzák képzeletüket a mindennapi életben, és érdeklődnek a nem konvencionális ötletek és emberek iránt.
  </br> </br>Másrészt azok, akik alacsony pontszámot érnek el a spektrumban, zárt vagy szűk látókörű. Meglehetősen inspirálatlanok a műalkotások által, kevés intellektuális kíváncsiságot éreznek, elkerülik a kreatív törekvéseket, és kevés vonzerejük van olyan filozófiák iránt, amelyek radikálisnak vagy nem ortodoxnak tűnnek. Ezek az emberek szeretik a dolgokat úgy, ahogy vannak, és ritkán törnek ki a komfort zónájukból. A zárt gondolkodású egyének óvatosak, konkrétak, következetesek és konvencionális emberek, akik nem szeretnek a dobozon kívül gondolkodni. A változatos rutinok, tevékenységek vagy elméletek miatt merev gondolkodásmódjuk kibillen.
  </br> </br>Az „ellentétek vonzzák egymást, de később támadnak” kifejezés elég megrendítő két olyan egyén számára, akiknek ellentétes szintjük van ebben a dimenzióban. A nyitott gondolkodású partner úgy fogja érezni, hogy egy zárt gondolkodású társ korlátozza. A nagy elmék hasonlóan gondolkodnak, de amikor egy nyitott és zárt ember romantikus kapcsolatba kerül, akkor ritkán végződik jól. Egyik fél sem lesz elégedett az unióban, mivel az egyik fél soha nem érzi magát elég jónak, míg a másik fél úgy érzi, hogy tudna job társt találni.`,
    "Extroversion.category.description": `Azok az egyének, akik nagyon magas pontszámot értek el a társaságkedvelő dimenzióban, vidám és optimista emberek, akik jól érzik magukat önmagukkal és másokkal. Természetes módon magabiztos és magabiztos egyének, akik könnyen tudnak beszélni a közönség előtt, vagy egy csoport embert vezetni. Élvezik a szociális interakciókat és az azt kísérő stimulációt. Extrovertáltak, akik kifejező és energikus egyének, akik lelkesedést és energiát tapasztalnak, amikor másokkal foglalkoznak. Az emberek által körülölelve érzik magukat, és pozitívan reagálnak a körülöttük lévő rengeteg tevékenységre - izgatja és teljesíti őket.
   </br> </br>Ezzel szemben az alacsony pontszámmal rendelkező egyének gyakran népszerűtlennek tartják magukat, kényelmetlenül érzik magukat a reflektorfényben, és egyszerűen inkább egyedül vannak. Az introvertáltak azok a csendes és visszahúzódó típusok, akik nem foglalkoznak a magas szintű társadalmi interakcióval vagy tevékenységgel. Következésképpen ők azok, akik megpróbálják kiküszöbölni a külső ingereket, és inkább befelé koncentrálnak, ami miatt tompa és pesszimista. Az egyszerű és csendes élet az egyetlen céljuk, mivel feltölti a lelküket és megnyugtatja az érzékeiket.
    </br> </br>Romantikusan szólva, két ellentét ezen a dimenzión nem teszi a leginkább kompatibilis párokat. Az egyik partner az izgalmat keresi, a másik pedig a nyugalmat. Az élénk emberek a legjobban az aktív emberekkel jönnek ki, míg az otthoni testek békét találnak a hasonló gondolkodású emberekkel, akik ugyanolyan szeretnek otthon maradni.`,
    "Own-Interest-Avoidance.category.description": `Az valódi skála az ember arra való hajlamát méri, hogy őszinte, egyenes és hiteles legyen az interperszonális kapcsolataiban. Azok az egyének, akik magas pontszámot értek el ebben a tulajdonságban, igazak, és nem tévesztenek meg másokat annak érdekében, hogy megkapják, amit akarnak. Általában törődnek, és figyelembe veszik mások érdekeit.
  </br> </br>Az alacsony pontszámot elért személyeket képmutatók tekintik. Kétarcú csalók, akik hízelegnek és úgy tesznek, mintha kedvelnének valakit, hogy megkapják, amit akarnak. A csalók bájos manipulátorok, akik könnyen eljuthatnak valaki jó oldalára, és szívességet kaphatnak tőlük.`,
    "Modesty.category.description": `A szerény skála azt értékeli, hogy az ember hajlamos-e arra, hogy vízszintes és reális képet képezzék magukról.  A szerény embernek korlátozott és világos véleménye van önmagáról és képességeiről. Nem dicsekednek, nem feltételeznek, részben félénkek, és lebecsülik képességeiket. Bár lehet, hogy úgy gondolnak magukra, mint a megszokottra, lehet, hogy nem azok.
    </br> </br> Azok az emberek, akik ezen a skálán alacsony pontszámot érnek el, olyan elbizakodott egyének, akik sokkal nagyabbnak tartják magukat, mint valójában. Magas egójuk arra készteti őket, hogy különleges kiváltságokra legyenek jogosultak, és preferenciális bánásmódot követeljenek, még akkor is, ha nem méltók rá.`,
    "Forgiveness.category.description": `A megbocsátó tulajdonság az egyén hajlandóságát méri arra, hogy tudatosan elengedje a haragot és a haragot egy másik személy felé. Bizalmat és kedvt éreznek azok iránt, akik kárt okoztak nekik, és nehezükre esik neheztelni. Az alacsony pontozók bosszúálló emberek, akik hajlamosak "haragot tartani" az elkövető ellen, és még bosszút is terveznek.  A magas pontozók könnyen megbízhatnak és folytathatják a baráti kapcsolatokat a jogsértővel, még akkor is, ha rosszul bántak vele.`,
    "Gentleness.category.description": `A gyengéd jellemvonás azt méri, hogy az ember hajlamos-e kedvesnek, könyörületesnek és tiszteletteljesnek lenni másokkal való kapcsolataiban. Ők is megfontolt és szelídek, így megnyugtatja az embereket, hogy körül. Az alacsony góllövők általában kegyetlenek és durvák az ügyleteikben. Mások megítélésében is kritikusak, míg a szelíd egyének idegenkednek attól, hogy durva ítéleteket hozzanak másokkal szemben.`,
    "Perfectionism.category.description": `A perfekcionisták hajlamosak aprólékosak lenni és a részletekkel törődni. Ők is túlságosan megijedtek, hogy hibákat követnek el, és időt áldoznak a kiválóság biztosítására. Ők a kontroll őrültek, akik küzdenek a határidőkkel, mert folyamatosan ellenőrzik és újra ellenőrzik a munkát a hibák miatt. A tökéletlenségek erős negatív érzéseket keltnek bennük, és gyakran a perfekcionisták olyan világban élnek, ahol a megbánások állandóan azon gondolkodnak, hogy „Mi lenne, ha…”, „nekem kellett volna…”. Vagy: „Bárcsak…”.
    </br> </br>Azok az egyének, akik alacsony eredményeket produkálnak, elhamarkodott és hanyag munkát végeznek annak érdekében, hogy gyorsan befejezzék a feladatot időben. A pozitív oldalon kevésbé stresszes és szorongó életet élnek, nem félnek az elutasítástól, ünnepelhetik a sikereket, összességében elégedettebbek, és általában értékesebbek.`,
    "Sentimentality.category.description": `Azok az egyének, akik nagyon szentimentálisak, hajlamosak erős érzelmi kötődést érezni az emberek, helyek, dolgok vagy emlékek iránt. Az érzelmi kötések mélysége olyan mély, hogy ez rontja a képességüket, hogy elengedjék. A szentimentális emberek gyakran a leginkább empatikus és nosztalgikus emberek, akikkel találkozhatsz. Érzékeny típusúak, akik igazán törődnek másokkal, ami lehetővé teszi számukra, hogy mély kapcsolatokat alakítsanak ki másokkal. Azonban mellékhatásuk van, ha a kötés megszakadása következik be.
    </br> </br>Az alacsony pontozók olyan elszakított emberek, akik még megrendítő körülmények között is érzelemmentesek maradhatnak. Ha hosszabb ideig búcsút mondasz egy szeretettnek, vagy el kell dobnunk a régi emléktárgyakat, az egyáltalán nem mozdít. Hajlamosak apatikus lenni mások szükségleteivel és szükségleteivel szemben is, ami gyengíti a közelükben lévőkkel való kötődésüket.`,
    "Diligence.category.description": `A szorgalmas egyének hajlamosak szorgalmasak és produktívak lenni. Mivel nagyon szorgalmas, az egyik, hogy nagy figyelmet fordít a részletekre, és tartsa be az ütemterveket. Az alacsony pontozók olyan indolens emberek, akik marginális önfegyelemmel rendelkeznek, és nem motiváltak arra, hogy egy célt vagy feladatot teljesítsen. Minimalista munkamorállal rendelkeznek, míg a szorgalmas emberek erős „munkamorállal” rendelkeznek, és hajlandóak áldozatokat hozni, és annyi erőfeszítést tenni, amennyit szükséges az eléréshez.`,
    "Prudence.category.description": `Az okos emberek óvatosak az általuk hozott döntésekkel, és nagy önkontrollt gyakorolnak. Minden rendelkezésre álló lehetőséget megpróbálnak megfontolni, és óvatosan gondolkodnak, mielőtt döntést hoznak. Az okos emberek képesek megfontolni cselekedeteik hosszú távú következményeit. Bölcs, ésszerű és gyakorlatias egyének, akik ritkán sajnálják, mert előre megtervezték döntésüket.
    </br> </br>Az alacsony pontozók viszont meggondolatlan emberek. Vakmerő és meggondolatlan egyének, akik ösztönösen cselekednek, és hajlamosak nem megfontolt jövőbeli következményeket. Bár lehet, hogy először lábfejbe ugranak, anélkül, hogy sokat gondolkodnának, a túl hosszú mérlegelés negatív következményekkel is járhat, és a gyors lehetőségek hiányához vezethet.`,
    "Organization.category.description": `A szervezett ember természetes hajlama a rend keresésére, különösen a fizikai környezetében. Tiszták, pontosak és strukturált megközelítést alkalmaznak a munkájukhoz. Az alacsony pontozók halogatók, akik a zavaró tényezők világában élnek, és zűrzavar és káosz veszi körül őket. A magasan szervezett emberek általában sikeresebbek és jobban teljesítenek a szervezetlen emberekhez képest a munkahelyen, mivel nagyobb valószínűséggel terveznek, priorizálnak és figyelmet fordítanak.`,
    "Patience.category.description": `A türelmes jellemvonása azt méri, hogy az ember hajlamos-e nyugodt maradni, és nem irritálódik a kedvezőtlen körülmények között. A nagyon türelmes emberek megmaradtak és toleránsak másokkal szemben, még a kényszer alatt is. Az alacsony pontozók türelmetlenek, akik a-típusú személyiséggel rendelkeznek. Gyorsan lerepülnek a fogantyúról, figyelmetlen másokkal szemben, és általában intuitív kommunikációs stílusuk van, amellyel azt akarják, hogy gyorsan eljutjon a lényeghez. Ezzel szemben a nagyon türelmes egyének magasabb küszöbértékkel rendelkeznek a haragjuk érzésére vagy kifejezésére. Az a képesség, hogy a türelmes emberek toleránsak legyenek másokkal szemben, végső soron arra készteti őket, hogy jobb kapcsolatokat építsenek ki az emberekkel.`,
    "Inquisitiveness.category.description": `A kíváncsiskodó skála méri az ember vágyát, hogy tudást, tapasztalatot szerezzen, és kölcsönhatásba lépjen a világ minden aspektusával. Szívesen szereznek új információkat, és megtanulják a dolgok magyarázatát, még akkor is, ha az új tanulás alkalmazásai nem azonnal alkalmazhatók. A kíváncsiság az intelligens elme leggyakoribb tulajdonsága. A pozitív érzelmek magasabb szintjével, a szorongás alacsonyabb szintjével, az élettel való nagyobb elégedettséggel és általában nagyobb pszichológiai elégedettséggel is jár.
    </br> </br>Az alacsony pontozók közömbösek a körülöttük lévő világgal szemben. Ők apatikus emberek, akiket az érdeklődés, a lelkesedés vagy a valamiért való aggodalom hiánya jellemez. Az ilyen egyéneket általában hidegnek, zárkózónak, érdektelennek, motiválatlannak és szenvedélyhiányosnak tekintik.`,
    "Corrupt-Avoidance.category.description": `A Megvesztegethetetlen skála az egyén azon tendenciáját méri, hogy elkerülje a csalást és a megvesztegetést. A magas pontszámok olyan elveken alapuló egyének, akik integritással járnak el, őszinték és igazságosak, és erős méltányossággal rendelkeznek. Etikusan viselkednek, és nem hajlandók kihasználni mások személyes nyereségét.
    </br> </br>Az alacsony pontszámmal rendelkező egyének korruptak, és hajlandóak csalással vagy lopással profitálni. Nem gondolnak kétszer arra, hogy tisztességtelenek legyenek, manipuláljanak, hazudjanak, és a csúcsra irányítsák az útjukat. A politikusok, az üzleti vezetők, az ügyvédek és az autókereskedők hírneve nagyon korrupt, míg az orvosok, az ápolók, a tudósok és a tanárok a legkevésbé korrupt szakmák közé tartoznak.`,
    "Unconventionality.category.description": `A nem konvencionális skála azt értékeli, hogy az ember nemcsak elfogadja a szokatlant, hanem szokatlan is. A magas góllövők olyan ellentétek, akik vonzódnak a szabálytalansághoz, és eredendően dacolnak a közösséggel. Hozzáállásuk, nézeteik és viselkedésük ellentmond a társadalomnak. Természetes nyitottságuk, lelkesedésük és előítéleteik hiánya lehetővé teszi számukra, hogy teljesen nyitottak legyenek mindenféle lehetőségre. Azt is lehetővé teszi számukra, hogy különböző megoldásokat találjanak egy problémára. A rendkívül szokatlan gondolkodók a mi-ifs-t szemlélik, és nem hamisítják meg őket az akadályok. Új lehetőségekről álmodnak, és olyan összetett problémákat oldanak meg, amelyekkel mások félnek szembenézni. Ez teszi őket excentrikus egyének, akik olyan gondolkodásmóddal rendelkeznek, amely képes eredeti műveket előállítani, vagy eredeti ötletekkel rendelkező látnokok lenni.
    </br> </br>Azok az emberek, akik ezen a skálán alacsony pontszámot érnek el, elkerülik a sajátos és nem megfelelő egyéneket. Nem fogadják el azokat az eszméket, amelyek radikálisnak vagy unortodoxnak tűnnek. Mint ilyen, konzervatív típusok, akik gyakorlatiasak, kiszámíthatók és előnyben részesítik a létrehozott struktúrát. Megvetik a változást, nem akarnak kiemelkedni a falkából, és a mainstream életet támogatják.`,
    "Dependence.category.description": `A függőség az érzelmi megnyugtatás és a mások ismételt támogatásának szükségességét méri. A nagymértékben függő emberek hajlamosak arra, hogy szűkölködjenek, tapsoljanak, passzívak és féljenek az elhagyatottól. Sok bátorítást és vigasztalást igényelnek, és gyakran képtelenek nehéz döntéseket hozni mások TANÁCSA vagy útmutatása nélkül.
    </br> </br>A spektrum másik végén nagyon független emberek vannak, akiknek nincs szükségük támogatásra az életben. Nem igényelnek sok segítséget, mivel magas önbecsülésük és magabiztosságuk át fogja látni őket. Bár a túl független is káros lehet, mivel nincs szükség senkire, megnehezíti, hogy kapcsolatban legyen valakivel.`,
    "Sociability.category.description": `A barátságos skála felméri valakinek az igenyét hogy részt vegyen a társadalmi interakciókban és összejöveteleken, és élvezze a sok emberrel való beszélgetést. A magas gólszerzők nyitottak a társadalmi újdonságokra, és aktívan követik mások társaságát. Öröm, hogy emberek veszik körül. Következésképpen kimenő hozzáállásuk van, alkalmazkodóképesek, és rengeteg csapatszellemük van.
    </br> </br>A spektrum másik végén barátságtalan emberek vannak, akik nem keresik mások társaságát, hanem úgy döntenek, hogy bezárják magukat, és magányban maradnak. A legrosszabb esetben ellenséges egyének lehetnek, akik nem szeretnek beszélni, új ismerősöket készíteni, vagy csoportos tevékenységekben vagy ünnepségeken részt venni.`,
    "Greed-Avoidance.category.description": `A nem-materialista skála az ember azon tendenciáját méri, hogy elutasítja a materializmust. A magas pontszámmal rendelkező egyének általában nem érdekeltek a gazdag vagyon, a luxuscikkek birtoklásában vagy a magas társadalmi státusz megszerzésében. Következésképpen hajlamosak mély egyének lenni, akik értékelik az élet dolgait, olyan dolgokat, amelyeket a pénz nem tud megvásárolni.
    </br> </br>A materialista emberek viszont a gazdagságot akarják felhalmolni és a kiváltságaikat fitogtatni. A pénzügyi és társadalmi rangok motiválják őket. Az ilyen embereket gyakran felületesnek tekintik, mert úgy vélik, hogy a pénz és a vagyon a legfontosabb dolog az életben.`,
    "Cultured.category.description": `Azok az egyének, akik magas pontszámot érnek el a művelt skálán, finomítottak, civilizáltak és termesztettek. A kulturált embereket jó modorként írják le, jól képzettek, és sokat tudnak a civilizációról. Egy személy akkor kulturált, ha olyan humán tudományoknak volt kitéve, mint a filozófia, a klasszikus zene, a múlt és a jelen kultúrái, a művészettörténet, a tudomány és a technológia. Nemcsak széleskörű tudással rendelkeznek, hanem a mindennapi életükben is.
    </br> </br>Azok az emberek, akik alacsony pontszámot értek el a skálán, tudatlanok, finomítatlanok és kifinomultak. Rossz etikettel rendelkeznek, és nem szeretik a színházat vagy a múzeumokat. Emellett tájékozatlanok és érdeketlenek a távoli és távoli helyekre való utazásban, hogy megismerjék más emberek történelmét és hagyományait.`,
    "Creativity.category.description": `A Kreatív skála az ember innovációs és kísérletező hajlamát méri. A kreatív emberekben rejlő legfontosabb jellemzők közé tartozik a kíváncsi, rugalmas, intuitív, objektív, alapos, és ambiciózus. A magas pontozók olyan out-of-the-box gondolkodók, akik minden lehetséges szempontból értékelik a problémát, amikor megoldást keresnek. Sokat gondolkodtak és törődtek a munkájukkal, és próbák és tévedések révén a lehető legjobb megoldást kitalálták. Ezenkívül általában érzékenyek, és szükségük van arra, hogy kifejezzék érzéseiket az alkotással.
    </br> </br>Az alacsony pontszámmal rendelkező egyének nem kreatívak és fantáziátlanok. Nem képesek eredeti gondolatokat és gondolatokat felfogni, és nem képesek valami eredetit kitalálni vagy létrehozni. A kreatív spektrum alsó végén lévő egyének nem igazán nyitottak az új élményekre, nem álmodnak annyira, nem annyira éberek, és nem vállalnak annyi kockázatot, mint egy kreatív ember, hogy a világ megtapasztalja a teremtésüket.`,
    "Liveliness.category.description": `A magas pontszám ezen a skálán azt jelzi, hogy az ember „tele van élettel”. A rendkívül élénk emberek nagyon lelkesek, optimisták, lelkesek és dinamikusak. Általában vidámságot tapasztalnak, mindig éberek, készen állnak, hajlandóak és képesek bármely fél életének lenni. Az energiájuk annyira mágneses, hogy könnyen vonzza az embereket.
    </br> </br>Azok az emberek, akik alacsony pontszámot értek el ezen a skálán, hajlamosak arra, hogy unalmas és unalmas legyen. Általában nem érzik magukat különösebben élénknek vagy élénknek, és bármilyen alkalomra komor hangulatot tudnak kelteni. Az ilyen személyiségjegyek taszítják a többieket, ami a társadalmi elkötelezettségekre való meghívások szűkösségét eredményezi.`,
    "Social-Boldness.category.description": `A társadalmi merészet magas pontszerzők képesek önmaguk lenni még a csapásokkal szemben is. Önbecsüléssel rendelkeznek, és kényelmesen kockáztatják a társadalmi szégyent, kirekesztést vagy akár elutasítást a hitük vagy véleményük védelmében. Azok, akik társadalmilag bátrak, kényelmesen érzik magukat, függetlenül attól, hogy milyen társadalmi helyzetben vannak.
    </br> </br>Azok az egyének, akik ezen a skálán alacsony pontszámot érnek el, hajlamosak gátolva vagy öntudatosnak érezni magukat a reflektorfényben, vezetői szerepben, vagy amikor a közönség előtt beszélnek. Nem hajlandóak egy idegen felé közeledni, nem fognak beszélgetést kezdeményezni, és ritkán mondják el az elméjüket egy csoportban.`,
    "Flexibility.category.description": `A rugalmassági tulajdonság az egyén hajlandóságát méri a kompromisszumra és a másokkal való együttműködésre. A magas pontozók jól alkalmazkodnak mások eltérő nézőpontjához és véleményéhez, és kreatívan gondolkodnak az interperszonális dilemmák megoldásáról. Az alacsony pontozók merev emberek, akiknek nehéz alkalmazkodni a változáshoz, és makacsul találkoznak. Elutasítják az övékkel ellentétes eszméket, és nem hajlandók engedményeket tenni egy vita megoldására. Másrészt egy rugalmas személy vagy elkerüli az érveket, vagy gyorsan alkalmazkodik mások javaslatához, még akkor is, ha ésszerűtlen lehet.`,
    "Anxiety.category.description": `A félelem és az aggodalom rendszeresen áthatja egy nagyon szorongó ember életét. Ez a tulajdonság különböző kontextusokban aktiválódik, és stabil része annak, ahogyan valaki gondolkodik és érez. A nagy szorongással rendelkező emberek hajlamosak torz képet adni a valóságról, és hajlamosak a dolgokat aránytalanra fújni. Ez arra készteti őket, hogy idegességet fejjenek ki olyan helyzetekben, amikor ez nem indokolt.
    </br> </br>Az e jellemvonási spektrum alsó végén lévő egyének könnyen megy és gondtalan hajlamot rendelkeznek. Ennek következtében kevés stresszt éreznek a problémákra vagy akadályokra adott válaszként, míg a magas pontozókat viszonylag kis nehézségek foglalkoztatják.`,
    "Social-Esteem.category.description": `A társadalmi önbecsülés skála az egyén pozitív önbecsüléshez való affinitását méri, különösen társadalmi kontextusban. Ez a tulajdonság az egyén általános személyes értékérzetét és önértékét méri egy társadalmi környezetben. A magas pontszámmal rendelkező egyének elégedettek magukkal, és úgy vélik, hogy hasonló tulajdonságokkal rendelkeznek. Általában ők is a csomag vezetője.
    </br> </br>Az alacsony pontszámmal rendelkező emberek a spektrum másik oldalán találják magukat, és ezért hiányzik a személyes társadalmi érték érzése. Gyakran úgy érzik és tekintik magukat, mint értéktelen és népszerűtlen egyének, akik kevés vagy semmilyen hozzáadott hasznot nem nyújtanak egy csoportnak. Általában gátoltak, és inni kell, hogy elengedjék, különben csak a szoba sarkából nézik a bulit.`,
    "Fearfulness.category.description": `Azok az emberek, akik a félelmetes skálán magas pontszámot érnek el, nagyobb hajlamot tapasztalnak a félelemre. Nagyobb valószínűséggel félnek és feszültek olyan helyzetekben vagy körülmények között, amikor az átlagember nem lenne. Gyakran a félelem érzése indokolatlan és alaptalan. A félelmetes emberek hajlamosak elkerülni a fizikai károkat.
    </br> </br>Azok az egyének, akik alacsony pontszámot értek el ezen a skálán, kevés félelmet éreznek a fájdalomtól vagy sérüléstől, és viszonylag erős és bátor egyének, akik nem tudnak veszélyt. A vak bizalom azonban önmagában is hátrány, mivel a túlbizalom számos történelmi adat bukását okozta. A félelem fontos összetevője a kockázatos viselkedés mérséklésének.`,
    "Pragma.category.description": `Azok az egyének, akik magas pontszámot értek el a tudatos stílusban, üzleti stílusban közelítik meg szerelmi életüket. A velük való romantikus kapcsolatok olyan partnerség, amelynek hasznosnak kell lennie és segítenie kell a cél elérésében. Megnézik azokat a kényelmeket, előnyöket és előnyöket, amelyek abból eredhetnek, ha valaki kapcsolatba kerül valakivel. A tudatos típusok előnyben részesítik a praktikusságot és a kompatibilitást a kémiával szemben. Szigorú listájuk van azokról a tulajdonságokról, amelyeket egy partnerben keresnek, és nem hajlandók elfogadni semmi mást. Az ilyen egyének mindig értékelik a kapcsolatot a fejükben, hogy mennyire jól él az ellenvetésüknek. Egyesek számára hidegnek és romantikusnak tűnhet, de kissé csodálatra méltó, hogy pontosan tudják, mit akarnak az életben.
    </br> </br>A tudatos stílus pontosan az altruista stílus ellentéte. Realista egyének, akik fejükkel gondolkodnak, nem szívükkel, és mindkét lábukat szilárdan a földre ültetik, míg az altruista típus a felhőkben él, kielégítik érzelmi és spirituális szükségleteiket. A nagyon tudatos típus kapcsolatba kerülhet valakivel a munkából, mivel vonzódik másokhoz, akik célorientáltak. Nem számít, honnan származik ez az egyén, olyan előnyöket kell kínálnia, amelyek szerepet játszanak az élet tudatos stílusainak nagy terveinek megvalósításában. Ha nem, akkor a jövőben nem fogják beutazási engedélyt kapni, vagy kiutasítják őket. Ami a hosszú távú kapcsolat kompatibilitását illeti, a legjobb egy altruista társsal megtalálni. Bár a két tudatos típus kombinációja együtt szerencsét hoz, valószínűleg egy szívtelen üzleti tranzakciót is eredményez, amely hosszú távon hatalmi harcokkal teli.`,
    "Mania.category.description": `Azok az egyének, akik magas pontszámot érnek el a neurotikus dimenzióban, romantikus viselkedést mutatnak, amelyet olyan tulajdonságok jellemeznek, mint a megszállottság, az érzelmi volatilitás és a társfüggőség. Ez a szerelmi stílus a szenvedélyes stílus szélsőséges és egészségtelen változata, mivel romboló szenvedéllyel van tele. Az ezen a dimenzión magasan lévő egyének „őrülten” szerelmesek. Ugyanakkor a szeretet eufórikus csúcsait, a magányosság és a kétségbeesés mélyeit is sokkal nagyobb mértékben tapasztalják meg, mint más szerelmi stílusok. Az ilyen tendenciák gyakran rendkívül birtoklásra és szűkölködésre késztetik őket, mivel úgy érzik, hogy nagyon szükségük van a szerettejükre az életükben. Hajlamosak eredendően egészségtelen érzelmi kötődést tanúsítani partnerük iránt, és azok a típusok, amelyek valószínűleg valami őrültet vagy bolondot csinálnak, például a zaklatást. Ha egy kapcsolat megsavanyodik vagy véget ér, könnyen depresszióhoz vezethet, vagy öngyilkossági gondolatai lehetnek.
    </br> </br>Ennek a szerelmi stílusnak egy másik jellemzője az extrém fantázia. Gyakran képzelt világot hoznak létre az elméjükben, és többször gondolnak a partnerükre és a kapcsolatukra. A neurotikus egyének hajlamosak arra, hogy szorosak legyenek, mivel állandó szükségük van a házastársuk megnyugtatására és társaságára. Sajnálatos módon, mivel ritkán kapják meg elégedettségüket, szerelmi életükben mindig ésszerűtlen szintű szorongás és féltékenység van. Az ilyen negatív tulajdonságok mértéke nagyban függ attól, hogy partnerük hogyan elégíti ki igényeit. A két erősen neurotikus szerető kombinációja általában mérgező kapcsolatot eredményez. Sok türelmet és munkát igényel egy nagyon neurotikus szerelmi stílus kezelése, és mint ilyen, nagyon alacsony szintű embert igényelnek.`,
    "Storge.category.description": `Ezt a szerelmi stílust a stabil, elkötelezett és érzelmileg intim kapcsolatok iránti vágy jellemzi. Azok az egyének, akik magasra értékelik ezt a dimenziót, értékelik a kapcsolat baráti aspektusát. Általában családcentrikus, közösségi környezetet élvez, és értékeli a kötvényeket. Kezdetben szeretnek közeli barátok lenni, és időre van szükségük ahhoz, hogy a szeretet valaki felé fejlődjön. A leendő szeretővel való kapcsolat először előfeltétele annak, hogy a kapcsolat előre haladhasson. Következésképpen fokozatosan szerelembe esnek, és olyan emberekkel, akiket általában hosszú ideig ismertek. A bizalom, a közelség és a kölcsönös megértés erős érzését kell kialakítani, mielőtt egy fizikai kapcsolat létrejöhet.
    </br> </br>Amikor a randevúról van szó, a minőség a mennyiség felett áll. Az a gondolat, hogy valakivel találkozol egy bárban, vagy vak randevú, meggörnyíti őket. Amikor új kapcsolatba lépnek, az egyetlen elvárásuk, hogy jó beszélgetéseket és társaságot folyjanak, mivel mindennél többre értékelik a barátságot, a hűséget és a valódiságot. A nagy aggodalom, gondoskodás és tisztelet a társ iránt elsőbbséget élvez a legtöbb mindennel szemben, még a szexszel is. Ritkán ugranak be és ki az ágyból vagy a kapcsolatokból, és inkább a hosszú távú, értelmes kötelékeket részesítik előnyben. Ennek eredményeként gyakran szerelmi életük általában hiányzik az intenzitás és a szenvedély, ami meglehetősen unalmas és szexmentes kapcsolatokat eredményez. Csak egy másik, azonos szükségletekkel rendelkező szerető tudja betölteni az intim szeretettípus szívét és lelkét. Ha érzelmileg túlságosan távol vannak egymástól, akkor vagy üresnek érzik magukat, vagy túl sok érzelemmel árasztják el őket, ami miatt a kilépéshez futnak.`,
    "Agape.category.description": `Az altruista társaknak megvan a szeretet végső formája – a feltétel nélküli szeretet ajándéka. A szeretet meghaladja az altruista ember életének minden részét, értelmet ad létezésének. Azok az emberek, akik ezen a dimenzión magasra érnek, gyengéd, szeretetteljes, figyelmes és figyelmes módon fejezik ki szeretetüket. Ennek eredményeként ők a leggondoskodóbb, legnagylelkűbb, könyörületesebb és önzetlen társak, akik csak lehetnek. Türelmesek, nem támasztanak követelményeket, és abszolút odaadást nyújtanak partnerük iránt. Egyetlen más szerelmi stílus sem hajlandó több áldozatot hozni, és elviselni a nehézségeket a partneréért, ahogy vannak. A partner boldogsága mindig a sajátja elé kerül. Érdekes, hogy többet adni, mint kapni, nem jelent problémát a szerelmi életükben. Ennek ellenére még mindig magas szintű kapcsolati elégedettséget kapnak, mert a partnerek általában viszonozzák az összes kedvességet, gondoskodást és igaz szeretetet, amit kapnak. Az altruista szeretőknek vigyázniuk kell arra, hogy ne essenek rossz kezekbe, és kizsákmányolják őket, amíg érzelmileg tönkremennek. Lehet, hogy két altruista egy ideig elégedett, de hosszú távon nem igazán egyensúlyozzák egymást. A tudatos társ biztosítja a tartós egyensúlyt, amit igényel.`,
    "Ludus.category.description": `Azok az egyének, akik magas pontszámot érnek el ebben a dimenzióban, a „nőbolond” és a „végzet asszonya”. A szerelem olyan játék számukra, amelyet a lehető legtöbb résztvevővel kell játszani, ami gyakran sorozatcsalóvá teszi őket. Felületes partnerek, akiknek a kapcsolatai a partnerük megtévesztésén és megtévesztésén alapulnak. Lehet, hogy örök szeretetüket vallják, de számos ilyen halhatatlan áhítat befogadója van. A szeretetnyilatkozatuk őszintének tűnhet, de általában csak azért, hogy bizonyítsák a társuk hűtlenségét. A flörtölés, a hazugság és a csábítás mind a „játékszabályok” részét képezik számukra.
    </br> </br>Ritkán veszik komolyan a szerelmet és a kapcsolatokat, és általában figyelmen kívül hagyják a másik fél érzelmeit. Az új kilátások felfedezése, üldözése, vadászata és meghódítása motiválja őket. Éretlennek tűnhet, de ez az, ami izgatja és megtartja érdekeiket, amíg természetesen el nem veszítik. Könnyen unatkoznak, ha egy kapcsolat rendeződik, és hétköznapossá válik. Az alkalmi kapcsolatok és flings közelebb állnak az ízléshez és a kedveléshez. Nem jó stabil partnereket keresnek, és ritkán hosszú távú elkötelezettséget keresnek. Ők hedonisták, akiknek a saját örömük és személyes örömük az első. Ez a minőség teszi őket tökéletes jelölteknek, hogy illegális kapcsolatokat. A promiszkuus típusok általában nem szeretik a hasonló gondolkodású partnereket, de hosszú távon megtalálják a legtöbb boldogságot a saját fajtájával.`,
    "Eros.category.description": `Azok az egyének, akik magasra kerülnek ezen a dimenzión, reménytelen romantikusok, akik szeretnek szerelmes lenni. A szenvedélyes szerelmi stílus hajlamos „első látásra szerelembe esni”. A szívük felmelegszik, amit látnak, szagolnak, tapintanak, ízlelnek és hallanak. A cupid nyílvessző hatása azonnal jelentkezik, ha olyan kilátásba kerül, amely megfelel az „ideális típusuknak”. A szépség, a szenvedély, a szex és a fizikai vonzerő iránti intenzív érzékük miatt gyorsan ágyba ugranak. A leendő szerető fizikai tulajdonságai olyan mély hatást gyakorolnak a pszichéjére, hogy azonnali fizikai és érzelmi kapcsolatot alakíthatnak ki velük.
    </br> </br>Mint ilyen, a „kémia” fontos szerepet játszik a szenvedélyes stílus kiválasztási folyamatában és az általános kapcsolat kielégítésében. Elégedettségük egy része magában foglalja azt az erős igényt, hogy kifejezzék halhatatlan szeretetüket virágokon, csokoládén, fehérneműn, vacsorákon, kirándulásokon, kirándulásokon, stb. keresztül. stb. Sajnos, mivel a kapcsolataik általában egy ilyen bumm-tal kezdődnek, ugyanolyan gyorsan imádkoznak. Ez hajlamot okozhat a leglelkesebb szeretőknek, hogy függjenek a kapcsolatok fellángoló szakaszától. A szenvedélyes emberek a legjobb más szenvedélyes típusokhoz illeszkednek, mivel a nem szenvedélyes szerelmesek gyorsan eloltják a tüzet.`,
};
