import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { getUrlByLang } from '../profile/analysis/libs/get-url-by-lang';
import { QuestionnaireBox } from '../questionnaires/questionnaire-box';
import { StorageHelper } from '../share/helpers/storage.helper';
import { Spinner } from '../spinner';
import { QuestionnnaireFrom } from './questionnaire.form';
import { SuccessModal } from './success.modal';

const mapStateProps = (store: any) => ({
    accessToken: store.authentication.accessToken,
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class QuestionnaireController extends Subscribable<any, any> {
    public state = { current: 1, hasNotPickedYet: false, isLoading: false, showSuccessModal: false };
    public questionnaireForm = new StorageHelper('questionnaire-form', 'session');

    public componentDidMount() {
        if (!this.isInProgress()) {
            const accessToken = this.props.accessToken;

            const { meta: { identifier, title, description, gender }, items } = this.props.questionnaire;
            this.questionnaireForm.set({ meta: { identifier, title, description, gender, accessToken }, items: this.mapQuestionIds(items) });
        }
    }

    public isInProgress(): boolean {
        const questionnaireForm = this.questionnaireForm.get();
        const accessToken = this.props.accessToken;

        if (!questionnaireForm
            || questionnaireForm?.meta?.identifier !== this.props.questionnaire?.meta?.identifier
            || questionnaireForm?.meta?.accessToken !== accessToken) {
            return false;
        }

        const current = this.getCurrentPosition(questionnaireForm?.items || []);

        if (!current) {
            return false;
        }

        this.setState({ current });

        return true;
    }

    public getCurrentPosition(questions: object): number {
        let index = 0;
        for (const prop in questions) {
            if (questions.hasOwnProperty(prop)) {
                if (!questions[prop]?.value) {
                    return index;
                }
                index += 1;
            }
        }

        return index;
    }

    public mapQuestionIds(items: any[]) {
        const mappedItems = {};
        for (const item of items) {
            if (!item?.identifier) {
                continue;
            }

            mappedItems[item.identifier] = {
                question: item.text,
                value: '',
                category: item.category,
                'sub-category': item?.['sub-category'] || '',
            };
        }

        return mappedItems;
    }

    public renderBackButton() {
        const desktopBackButton = <Button
            disabled={this.state.current < 2}
            onClick={() => this.setState({ current: this.state.current - 1, hasNotPickedYet: false })}
            className={'ui-base uikit-button variant-primary-outline size-large mx-6'}
            type={'button'}
        >
            <span className="material-icons mr-6 palette--c-primary-5">arrow_left</span>
            <span className="palette--c-primary-5">{trans('global.buttons.previous.question')}</span>
        </Button>;

        const mobileBackButton = <Button
            disabled={this.state.current < 2}
            onClick={() => this.setState({ current: this.state.current - 1, hasNotPickedYet: false })}
            className={'ui-base uikit-button h-px-40  variant-primary-outline size-large mx-6'}
            type={'button'}
        >
            <span className="material-icons palette--c-primary-5">arrow_left</span>
        </Button>;

        const buttons = <div className={'w-100'}>
            <div className={'display-md-none display-flex'}>
                {mobileBackButton}
            </div>
            <div className={'display-none display-md-flex justify-content-end'}>
                {desktopBackButton}
            </div>
        </div>;

        return this.state.current > 1 ? buttons : <div className={'w-100'}/>;
    }

    public renderNextQuestion() {
        const desktopNextButton = <Button
            disabled={this.state.hasNotPickedYet}
            onClick={() => this.setState({ current: this.state.current + 1 })}
            className={`${this.state.hasNotPickedYet ? 'button-disabled' : ''} ui-base uikit-button variant-primary-outline size-large mx-6`}
            type={'button'}
        >
            <span className="palette--c-primary-5">{trans('global.buttons.next.question')}</span>
            <span className="material-icons ml-6 palette--c-primary-5">arrow_right</span>
        </Button>;

        const mobileNextButton = <Button
            disabled={this.state.hasNotPickedYet}
            onClick={() => this.setState({ current: this.state.current + 1 })}
            className={`${this.state.hasNotPickedYet ? 'button-disabled' : ''} ui-base uikit-button variant-primary-outline size-large mx-6`}
            type={'button'}
        >
            <span className="palette--c-primary-5">{trans('global.buttons.next.question')}</span>
            <span className="material-icons ml-6 palette--c-primary-5">arrow_right</span>
        </Button>;

        const buttons = <div className={'w-100'}>
            <div className={'display-md-none display-flex min-width-max-content'}>
                {mobileNextButton}
            </div>
            <div className={'display-none display-md-flex'}>
                {desktopNextButton}
            </div>
        </div>;

        if (this.state.current !== this.props.questionnaire?.items.length) {
            return buttons;
        }

        const submitButton = <Button
            disabled={this.state.hasNotPickedYet}
            onClick={() => this.finishQuestionnaire()}
            className={'ui-base uikit-button variant-primary size-large'}
            type={'button'}
        >
            {this.state.isLoading
                ? <Spinner/>
                : <span className="palette--c-primary-1">{trans('global.buttons.submit.questionnaire')}</span>}
        </Button>;

        return this.state.hasNotPickedYet ? buttons : submitButton;
    }

    public getPayload() {
        return this.questionnaireForm.get();
    }

    public async finishQuestionnaire() {
        try {
            this.setState({ isLoading: true });
            const { identifier } = this.props.questionnaire.meta;

            const response = await Repository.post(`/questionnaires/finish-${identifier}-questionnaire`, { ...this.getPayload() });

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({ isLoading: false, showSuccessModal: !this.state.showSuccessModal });

            this.questionnaireForm.remove();
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public render() {
        const redirectTo = getUrlByLang('questionnaires');

        return <QuestionnaireBox>
            <QuestionnnaireFrom
                onPick={(hasNotPickedYet) => this.setState({ hasNotPickedYet })}
                questionnaire={this.props.questionnaire}
                current={this.state.current}
                questionnaireForm={this.questionnaireForm}
            />
            <div className={'w-100 position-center pt-7 mt-7 pb-7'}>
                {this.renderBackButton()}
                {this.renderNextQuestion()}
            </div>
            <SuccessModal
                redirectTo={redirectTo}
                buttonText={'global.button.back.to.questionnaires'}
                show={this.state.showSuccessModal}
                successMessage={trans('global.generic.success.message')}
            />
        </QuestionnaireBox>;
    }
}
