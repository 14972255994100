import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { PersonalityContent } from '../../profile/compatibility-infos/extras/modal-content/personality-content';
import { Chart } from '../chart';
import { PersonalityChartCategoryOrganizer } from './personality-chart-category-organizer';

export class PersonalityCharts extends React.Component<any, any> {

    public getColours(labels: string[]) {
        if (!labels?.length) {
            return false;
        }

        const labelColours = labels.map(label => PersonalityContent.colours[label].colour);

        return {
            backgroundColor: labelColours,
            borderColor: labelColours
        };
    }

    public getSubColours(rawCategory, rawSubCategory) {
        const { subCategoryLabels } = new PersonalityChartCategoryOrganizer(rawSubCategory, rawCategory);

        if (!subCategoryLabels?.length) {
            return false;
        }

        const labelColours = subCategoryLabels.map(label => {
            for (const key of Object.keys(PersonalityContent.colours)) {
                const hasLabel = PersonalityContent.colours[key].subCategories.find(category => category === label);

                if (hasLabel) {
                    return PersonalityContent.colours[key].colour;
                }
            }
        });

        return {
            backgroundColor: labelColours,
            borderColor: labelColours
        };
    }

    public getDifference(datasets1: any[], datasets2: any[]) {
        return datasets1.map((dataset, index) => dataset - datasets2[index]);
    }

    public render() {
        const categoryFromMeta = this.props.user.meta?.[`${this.props.questionnaire}Questionnaire`]?.category || {};
        const subCategoryFromMeta = this.props.user.meta?.[`${this.props.questionnaire}Questionnaire`]?.subCategory || {};
        const { subCategoryLabels, subCategory, category, categoryLabels } = new PersonalityChartCategoryOrganizer(subCategoryFromMeta, categoryFromMeta);
        const categoryDatasets = (category || []).map((value: any) => value?.result);
        const subCategoryDatasets = (subCategory || []).map((value: any) => value?.result);
        const categoryAverageDatasets = (category || []).map((value: any) => value?.result + value?.diff);
        const subCategoryAverageDatasets = (subCategory || []).map((value: any) => value?.result + value?.diff);

        return <div className={'row'}>
            <div className={'mt-md-0 mt-7 col-md-12 col-24'}>
                <Chart
                    title={trans('account.data.personality.dimensions')}
                    user={this.props.user.meta}
                    chartName={'Bar'}
                    data={categoryDatasets}
                    labels={categoryLabels}
                    primaryOptionLabel={trans('account.data.my.score')}
                    secondaryOptionLabel={trans('account.data.average')}
                />
            </div>
            <div className={'mt-md-0 mt-7 col-md-12 col-24'}>
                <Chart
                    title={trans('account.data.variance.from.population')}
                    user={this.props.user.meta}
                    chartName={'Bar'}
                    data={this.getDifference(categoryDatasets, categoryAverageDatasets)}
                    labels={categoryLabels}
                    primaryOptionLabel={trans('account.data.my.score')}
                    secondaryOptionLabel={trans('account.data.average')}
                    doNotDisplayLegend={true}
                    customBarColours={this.getColours(categoryLabels) as any}
                />
            </div>
            <div className={'mt-md-0 mt-7 col-24'}>
                <Chart
                    chartWrapper={'min-height-380'}
                    title={trans('account.data.personality.traits')}
                    user={this.props.user.meta}
                    chartName={'Bar'}
                    data={subCategoryDatasets}
                    labels={subCategoryLabels}
                    primaryOptionLabel={trans('account.data.my.score')}
                    secondaryOptionLabel={trans('account.data.average')}
                />
            </div>
            <div className={'mt-md-0 mt-7 col-24'}>
                <Chart
                    chartWrapper={'min-height-380'}
                    title={trans('account.data.variance.from.population')}
                    user={this.props.user.meta}
                    chartName={'Bar'}
                    data={this.getDifference(subCategoryDatasets, subCategoryAverageDatasets)}
                    labels={subCategoryLabels}
                    primaryOptionLabel={trans('account.data.my.score')}
                    secondaryOptionLabel={trans('account.data.average')}
                    doNotDisplayLegend={true}
                    customBarColours={this.getSubColours(categoryFromMeta, subCategoryFromMeta) as any}
                />
            </div>
        </div>;
    }
}
