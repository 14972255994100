import * as React from 'react';
import { Subscription } from 'rxjs';

export class Subscribable<P, S> extends React.Component<P, S> {
    public subscriptions$: Subscription[] = [];

    public componentWillUnmount(): void {
        this.subscriptions$.forEach((s: Subscription) => s.unsubscribe());
    }
}
