import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { InputProps } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './birth-date-input.scss';
import { BirthDatePicker } from './birth-date-picker';

export class BirthDateInput extends Subscribable<InputProps, any> {
    public birthDatePicker$: BirthDatePicker;
    public state: any = {
        selectedBirthDate: get(this.props, 'field.value') || null,
        isDirty: get(this.props, 'field.dirty') || false
    };

    public componentDidMount(): void {
        this.subscriptions$.push(
            this.props.field.dirty$.subscribe((value) => this.setState({ isDirty: value })),
        );
    }

    public componentDidUpdate(prevProps: Readonly<InputProps>, prevState: Readonly<any>, snapshot?: any): void {
        this.subscriptions$.push(
            this.props.field.value$.subscribe((value) => this.setState({ selectedBirthDate: value }))
        );
    }

    public render(): React.ReactNode {
        return <div className={'BirthDateInput w-100'}>
            <div className={`BirthDateInputField ${this.props.disabled ? 'disabled' : ''}`} onClick={() => this.openPicker()}>
                <div className="InputContent display-flex justify-start-center align-items-center">
                    <span className={'palette--c-neutral-5 mb-0 '}>{moment(this.state.selectedBirthDate).isValid() ?
                        moment(get(this.state, 'selectedBirthDate')).format('YYYY.MM.DD') :
                        <span className={'palette--c-neutral-4'}>{trans('signup.birthDatePlaceholder')}</span>}</span>
                </div>
                <div className={'InputIcon display-flex justify-content-center align-items-center'}>
                    <span className={'material-icons palette--c-neutral-5'}>date_range</span>
                </div>
            </div>
            {(!!get(this.state, 'isDirty') && !!get(this.props, 'field.errors', []).length) && <div>
                <span className={'palette--c-red-2 fs-small'}>{get(this.props, 'field.errors[0].error')}</span>
            </div>}
            <BirthDatePicker ref={(ref: any) => this.birthDatePicker$ = ref} onSubmit={(date: any) => this.dateSelected(date)}/>
        </div>;
    }

    public openPicker() {
        if (this.props.disabled) {
            return;
        }

        this.birthDatePicker$.open();
    }

    public dateSelected(date) {
        if (this.props.disabled) {
            return;
        }
        if (!this.props.field) {
            return;
        }
        this.setState({ selectedBirthDate: date }, () => {
            this.props.field.setValue(date);
        });
    }
}
