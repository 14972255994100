import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { getLanguage } from '../../../libs/get-language';
import { EditModal } from '../shared/edit.modal';
import { DisplayBox } from './libs/display-box';

export class StarSignAnalysis extends DisplayBox {
    public title = trans('account.data.starSign');
    public buttonTitle = trans('account.data.read.description');
    public image = '/assets/images/profile/star-sign.png';
    public modal$: EditModal | null;
    public isQuestionnaireFilled = true;

    public getTextBlocks(text: string) {
        if (!text) {
            return ' - ';
        }

        const items = text?.split('\n');

        return (items || []).map((item, index) => <span key={index} className={'text--span mb-6'}>{item}</span>);
    }

    public renderImage() {
        return <img
            className={'h-px-185 width-auto'}
            src={this.image}
            alt={this.alt || trans('account.data.alt.icon.text')}
        />;
    }

    public renderDescription() {
        const starSignAnalysis = this.props.user.meta['starSignCompatibilityDetails']?.[getLanguage()]
            ? this.props.user.meta['starSignCompatibilityDetails']?.[getLanguage()]
            : this.props.user.meta['starSignCompatibilityDetails']?.en;

        if (!starSignAnalysis) {
            return null;
        }

        return <div className={'row pt-6'}>
            <div className={'col-24'}>
                <h4 className={'text--h3 pb-4'}>{trans('account.data.description')}</h4>
                {this.getTextBlocks(starSignAnalysis)}
            </div>
        </div>;
    }

    public renderModal(): null | JSX.Element {
        return <EditModal
            className={'max-width-848'}
            content={this.renderDescription()}
            ref={ref => this.modal$ = ref}
        />;
    }

    public handleButtonClick(): void {
        this.modal$?.openModal();
    }
}
