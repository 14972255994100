import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import { get } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import './compatibility-results.scss';

export class EditResult extends Subscribable<any, any> {
    public editModalRef$: Modal | null;
    public form = new Form({
        name: new Field({
            label: '',
            value: get(this.props, 'result.meta.name'),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
    });

    public render(): React.ReactNode {
        return <div>
            <span onClick={() => this.openEditModal()} className={'material-icons palette--c-neutral-5 ml-3 cursor-pointer'}>edit</span>
            <Modal
                hideClose={true}
                ref={(ref) => this.editModalRef$ = ref}
                content={() => this.renderEditModalContent()}
                wrapperClasses="EditModalWrapper palette--bgc-neutral-1 elevation-2 border-radius-1 p-box"
                footer={() => null}
            />
        </div>;
    }

    public openEditModal() {
        this.editModalRef$?.open();
    }

    public async handleSubmit() {
        if (!this.props.result) {
            return;
        }
        try {
            await Repository.put(`/competition/${this.props.result._id}`, { meta: { name: this.form.toJSON().name } });
            this.editModalRef$?.close();
            if (this.props.onSubmit) {
                this.props.onSubmit();
            }
        } catch (err) {
            /**/
        }
    }

    public handleCancel() {
        this.form.field('name').setValue(this.props.result.meta.name);
        this.editModalRef$?.close();
    }

    public renderEditModalContent() {
        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row">
                <div className="col-24 mb-8">
                    <h3>{trans('starsign.result.edit.title')}</h3>
                </div>
                <div className="w-100 col-24 px-7">
                    <div className="row">
                        <div className="col-24 mb-md-0 mb-7">
                            <Input field={this.form.field('name')} labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'} className="mb-box variant-primary size-medium" type="text" disabled={false}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="w-100 col-24 display-flex justify-content-end">

                            <Button type={'button'} onClick={() => this.handleCancel()} className="variant-link size-medium" title={trans('global.buttons.cancel')}/>
                            <Button type={'submit'} className="variant-primary size-medium" title={''}>
                                <span className="material-icons mr-4">check</span>
                                <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                </div>
            </div>}
        </AbstractForm>;
    }
}
