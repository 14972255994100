import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { get } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { CurrencySelector } from '../../currency-selector/currency-selector';
import { MessageBox } from '../../message-box/message-box';
import { round } from '../../share/helpers/util';
import './top-up-box.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    currency: store.authentication.currency
});

const mapDispatchProps = (dispatch: any) => ({});
const Stripe: any = (window as any).Stripe;

@connect(mapStateProps, mapDispatchProps)
export class TopUpBox extends Subscribable<any, any> {
    public stripe = new Stripe(Environment.get('stripePublicKey'), {
        apiVersion: '2020-03-02',
    });

    public state: any = {
        offers: [],
        error: null,
        loading: false
    };

    public componentDidMount(): void {
        this.fetchOffers();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.currency !== this.props.currency) {
            this.fetchOffers();
        }
    }

    public render(): React.ReactNode {
        return <div className={'TopUpBox p-box elevation-1 border-radius-2 palette--bgc-neutral-1'}>
            <div className="display-flex justify-content-space-between align-items-center mb-7">
                <div className={'flex-fill'}>
                    <h6 className={'text-uppercase mb-0'}>{trans('topup.title')}</h6>
                </div>
                <div className={'display-flex flex-row justify-content-end'}>
                    <span className={'palette--c-neutral-5 mr-4'}>{trans('topup.currency.label')}:</span>
                    <CurrencySelector/>
                </div>
            </div>
            <div>
                {this.renderOffers()}
            </div>
            {get(this.state, 'error') && <div className="row">
                <div className="col-24">
                    <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                </div>
            </div>}
        </div>;
    }

    public async fetchOffers() {
        try {
            this.setState({ err: null });

            const offers = await Repository.get('/topup', { currency: this.props.currency });

            if (!offers) {
                throw Error('something.went.wrong');
            }

            this.setState({ offers: get(offers, 'items') });
        } catch (err) {
            this.setState({ error: err });
        }
    }

    public renderOffers() {
        return <div className={'Offers'}>
            {(this.state.offers || []).map((offer, index) => {
                return <div
                    key={index}
                    className={'px-4 display-flex flex-column align-items-center mb-4 '}
                >
                    {this.renderOffer(offer)}
                </div>;
            })}
        </div>;
    }

    public renderOffer(offer: any) {
        const { price, grossPrice, currency, balance } = offer;
        const packagePrice = this.formatPrice(price, currency);
        const unitPrice = this.formatPrice(grossPrice / balance, currency);
        const creditText = offer.balance > 1 ? trans('global.label.credits') : trans('global.label.credit');

        return <div className={'display-flex flex-column justify-content-center align-items-center'}>
            <div className={`OfferCard ${get(offer, 'colorClass')} border-radius-1 elevation-1`}>
                <div>
                    <div className={'OfferCredit position-center'}>
                        <h2 className={'mb-0 palette--c-neutral-1 fs-26'}>
                            {packagePrice.main}
                        </h2>
                        <h2 className={'mb-0 palette--c-neutral-1 fs-17 top-3'}>
                            {packagePrice.divider}{packagePrice.decimal}
                        </h2>
                    </div>
                    <div className={'display-flex justify-content-center align-items-center p-box'}>
                        <h3 className={'coloured-text text-center palette--c-neutral-1 mb-0 fs-15'}>{offer.balance} <span className={'coloured-text fs-13'}>{creditText}</span>
                        </h3>
                    </div>
                    <div className={'position-center flex-column p-3'}>
                        <div className={'w-100 position-center'}>
                            <h3 className={'gradient-text coloured-text palette--c-neutral-1 mb-0 fs-18'}>{unitPrice.main}</h3>
                            <h3 className={'gradient-text coloured-text top-1 palette--c-neutral-1 mb-0 fs-14'}>{unitPrice.divider}{unitPrice.decimal}</h3>
                        </div>
                        <div className={'w-100 position-center bottom-7'}>
                            <span className={'palette--c-neutral-14 fs-12'}>/{trans('global.label.credit')}</span>
                        </div>
                    </div>
                    <div className={'display-flex justify-content-center align-items-center p-box'}>
                        <Button
                            onClick={() => this.topup(offer)}
                            className={'variant-tryout-2 size-medium'}
                        >
                            <span className={'palette--c-neutral-1 px-4'}>{trans('topup.buynow')}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>;
    }

    public formatPrice(price: number, curr: string): { main: string; divider?: string; decimal?: string } {
        const options = {
            huf: { locale: 'hu-HU', currency: 'HUF' },
            eur: { locale: 'de-DE', currency: 'EUR' },
            usd: { locale: 'en-US', currency: 'USD' },
            gbp: { locale: 'en-UK', currency: 'GBP' },
            aud: { locale: 'en-AU', currency: 'AUD' },
            nzd: { locale: 'en-NZ', currency: 'NZD' },
            cad: { locale: 'en-CA', currency: 'CAD' },
            ron: { locale: 'ro-RO', currency: 'RON' }
        };

        const amount = round(price, curr !== 'huf' ? 100 : 1);
        const { locale, currency } = options[curr.toLowerCase()];
        const exceptions = ['huf', 'ron'];

        const formattedPrice = Intl
            .NumberFormat(locale, {
                currency,
                style: 'currency',
                maximumFractionDigits: exceptions.includes(curr) ? 0 : 2
            })
            .format(amount);
        if (exceptions.includes(curr)) {
            return { main: formattedPrice };
        }

        if (formattedPrice.includes('.')) {
            const [main, decimal] = formattedPrice.split('.');

            return { main, decimal, divider: '.' };
        }

        if (formattedPrice.includes(',')) {
            const [main, decimal] = formattedPrice.split(',');

            return { main, decimal, divider: ',' };
        }

        return { main: formattedPrice };
    }

    public async topup(offer) {
        try {
            this.setState({ error: null });
            const payment = await Repository.post('/payment', {
                topupIdentifier: offer.identifier
            });
            await this.stripe.redirectToCheckout(payment.gatewayCredentials);
        } catch (err) {
            this.setState({ error: err });
        }
    }

}
