import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { Subscribable } from '../../../../libs/subscribable';
import { GenderForm } from '../../../questionnaires/forms/gender.form';
import { handleError } from '../../../share/helpers/util';
import { AnalysisType } from '../../compatibility-infos/extras/compatibility-box';
import { EditModal } from '../../shared/edit.modal';
import { getUrlByLang } from './get-url-by-lang';

export interface DisplayBoxProps {
    user: any;
    analysisType?: AnalysisType;
}

export abstract class DisplayBox extends Subscribable<DisplayBoxProps, any> {
    public title: string;
    public buttonTitle: string;
    public image: string;
    public alt?: string;
    public questionnaire: string;
    public isQuestionnaireFilled: boolean;
    public genderModal$: EditModal | null;

    public abstract renderImage(): JSX.Element;

    public abstract renderModal(): null | JSX.Element;

    public handleButtonClick(): void {
        handleError('cannot be empty');
    }

    public renderGenderAssignmentModal() {
        if (!this.props.user?.meta?.gender) {
            return <a
                className={'text-decoration-none position-center'}
                onClick={(e) => {
                    e.preventDefault();
                    this.genderModal$?.openModal();
                }}
            >
                <span className={'fs-14'}>{trans('account.data.take.questionnaire')}</span>
            </a>;
        }
        const baseHref = getUrlByLang('startQuestionnaire');

        return <a
            className={'text-decoration-none position-center'}
            href={`${baseHref}/${this.questionnaire}`}
        >
            <span className={'fs-14'}>{trans('account.data.take.questionnaire')}</span>
        </a>;
    }

    public renderActionButton() {
        if (this.isQuestionnaireFilled) {
            return <Button
                onClick={() => this.handleButtonClick()}
                className={'ui-base uikit-button ui-base uikit-button variant-primary size-medium w-px-140'}
                title={this.buttonTitle}
            />;
        }

        return <Button className={'ui-base uikit-button ui-base uikit-button variant-primary size-medium w-px-140'}>
            {this.renderGenderAssignmentModal()}
        </Button>;
    }

    public render() {
        const baseHref = getUrlByLang('startQuestionnaire');

        return <div className={'display-flex flex-column justify-content-space-between no-clear-fix border-radius-px-8 p-7 background-white fix-height-280'}>
            <div className={'row'}>
                <div className={'col-9 display-flex justify-content-start align-items-center'}>
                    <span className={'fw-bold text--small'}>{this.title}</span>
                </div>
                <div className={'col-15 display-flex justify-content-end align-items-center'}>
                    {this.renderActionButton()}
                </div>
            </div>
            <div className={'w-100 position-center pt-7'}>
                {this.renderImage()}
                <EditModal
                    ref={ref => this.genderModal$ = ref}
                    content={<GenderForm
                        href={`${baseHref}/${this.questionnaire}`}
                        onCancel={() => this.genderModal$?.closeModal()}
                    />}
                />
                {this.renderModal()}
            </div>
        </div>;
    }
}
