import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { handleError } from '../../share/helpers/util';
import { NotificationStrip } from './notification-strip';

export class ProfileVisibility extends Subscribable<any, any> {
    public renderContent() {
        const isProfilePublic = !!this.props.user.meta?.isProfilePublic;
        const visibilityText = trans(`account.data.${isProfilePublic ? 'show' : 'hide'}.profile`);
        const visibility = isProfilePublic ? trans('account.data.profile.visibility.true') : trans('account.data.profile.visibility.false');
        const icon = isProfilePublic ? 'heart-broken' : 'heart';

        return <div className={'w-100 display-flex justify-content-space-between row'}>
            <div className={'col-sm-18 col-24 display-flex justify-content-sm-start justify-content-center pb-sm-0 pb-7'}>
                <span className={'color--light fw-bold'}>{visibility}</span>
            </div>

            <div className={'col-sm-6 col-24 display-flex justify-content-sm-end justify-content-center'}>
                <Button
                    className={'ui-base uikit-button variant-blue-outline h-px-24 mr-7 border-radius-px-4'}
                    onClick={() => this.manageProfileVisibility()}
                >
                    <i className={`fa fa-${icon} fs-12 pr-6 relative-top-1 palette--c-red-5`}/>
                    <span className={'fw-bold fs-12'}>{visibilityText}</span>
                </Button>
            </div>
        </div>;
    }

    public async manageProfileVisibility() {
        try {
            const response = await Repository.put('/user', { isProfilePublic: !this.props.user.meta?.isProfilePublic });

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }

    public render() {
        return <NotificationStrip content={this.renderContent()} backgroundColour={'blue'}/>;
    }
}
