import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Dropdown } from '@codebuild/cookie-jar/uikit/components/dropdown/dropdown';
import { get } from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { UserActions } from '../../actions/user.actions';
import { getLanguage } from '../../libs/get-language';
import { handleError } from '../share/helpers/util';
import { UserMetaInterface } from '../share/interfaces/user.interface';
import './language-selector.scss';

const mapStateProps = (store: any) => ({
    currency: store.authentication.currency,
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({
    setCurrency: (currency) => dispatch(UserActions.setCurrency(currency)),
    checkWhoAmI: () => dispatch(UserActions.checkWhoAmI())
});

@(withRouter as any)
@connect(mapStateProps, mapDispatchProps)
export class LanguageSelector extends React.Component<any, any> {
    public $dropdown: Dropdown;
    public state: any = {
        currentLanguage: getLanguage()
    };

    public async componentDidMount() {
        this.setLang(getLanguage());
        if (this.props.user) {
            const currentLang = this.getCurrentLang().toUpperCase();

            if (!this.props.user?.meta?.lang || this.props.user?.meta?.lang !== currentLang) {
                await this.updateUserLang(currentLang);
            }
        }
    }

    public async updateUserLang(currentLang: UserMetaInterface['lang']) {
        try {
            const response = await Repository.put('/user', { lang: currentLang });

            if (!response) {
                throw new Error('something went wrong');
            }

            await this.props.checkWhoAmI();
        } catch (err) {
            handleError(err);
        }
    }

    public render(): React.ReactNode {
        return <div className={`LanguageSelector ${get(this.props, 'openBottom', null) ? 'bottom' : 'top'}`}>
            <Dropdown
                className="DropdownButton"
                ref={(ref: Dropdown) => (this.$dropdown = ref)}
                direction="right"
                renderDropdown={(ctx: Dropdown) => this.renderDropdownBox()}
                renderContent={() => this.dropdownContent()}
            />
        </div>;
    }

    public dropdownContent() {
        return <div onClick={() => this.$dropdown.toggle()}>
            <span className={'palette--c-neutral-5 fw-bold display-flex justify-content-center align-items-center cursor-pointer'}>
                <span className={'material-icons palette--c-neutral-5 mr-3'}>language</span>
                {this.getCurrentLang()}
                {get(this.props, 'showArrow') && <span className={'material-icons palette--c-neutral-5 ml-3'}>keyboard_arrow_down</span>}
            </span>
        </div>;
    }

    public renderDropdownBox() {
        return <div className={'DropdownBox p-box display-flex flex-column palette--bgc-neutral-1 elevation-1 border-radius-1'}>
            <h6 className={'text-nowrap palette--c-neutral-5 text-uppercase mb-3'}>{trans('global.languagemenu.title')}</h6>
            <div className={'display-flex flex-column LanguageOptions'}>
                {this.renderOption('hu')}
                {this.renderOption('en')}
                {this.renderOption('ro')}
            </div>
        </div>;
    }

    public isCurrent(lang: string) {
        return this.state.currentLanguage === lang;
    }

    public renderOption(lang: string) {
        const language = trans(`global.language.${lang || 'en'}Long`);

        const sourceTarget = (((window as any).ROUTE_SOURCE || {}).languages || []).find((l) => (l.lang === lang));

        if (!sourceTarget) {
            return null;
        }

        const targetLink = sourceTarget.targetCompiler ? sourceTarget.targetCompiler(this.props.location) : sourceTarget.path;

        const targetLang = targetLink.slice(1, 3).toUpperCase();

        return <a onClick={() => this.updateUserLang(targetLang)} href={targetLink} className={`py-2 ${this.isCurrent(lang) ? 'active' : ''} LanguageOption`}>
            <span className={'material-icons fs-medium mr-3'}>{this.isCurrent(lang) ? 'check_circle' : 'panorama_fish_eye'}</span>
            <span>{language}</span>
        </a>;
    }

    public setLang(lang: string) {
        switch (lang.toString().toLowerCase()) {
            case 'hu':
                this.props.setCurrency('huf');
                break;
            case 'en':
                this.props.setCurrency('usd');
                break;
            case 'ro':
                this.props.setCurrency('ron');
                break;
            default:
                break;
        }
    }

    public getCurrentLang() {
        switch (TranslationLib.getLang()) {
            case 'hu':
                return trans('global.language.hu');
            case 'en':
                return trans('global.language.en');
            case 'ro':
                return trans('global.language.ro');
            default:
                return trans('global.language.en');
        }
    }

}
