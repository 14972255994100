import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { UserInterface } from '../share/interfaces/user.interface';
import { AnalysisTabs, AnalysisTabsProps } from './analysis-tabs';

export interface AnalysisModalProps {
    fixedTab?: AnalysisTabsProps['fixedTab'];
    user?: UserInterface;
    match?: any;
    shouldFetch?: boolean;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class AnalysisModal extends Subscribable<AnalysisModalProps, any> {
    public modal$: Modal;
    public state = { isLoading: false, evaluatedData: null };

    public async componentDidMount() {
        await this.getEvaluatedUserData();
    }

    public async componentDidUpdate(prevProps: Readonly<AnalysisModalProps>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.shouldFetch !== this.props.shouldFetch) {
            await this.getEvaluatedUserData();
        }
    }

    public async getEvaluatedUserData(): Promise<void> {
        try {
            const matchedUserId = this.props.match.matchedUserId;
            const matchId = this.props.match.id;

            if (!this.props.shouldFetch) {
                return;
            }

            this.setState({ isLoading: true });

            const response = await Repository.get('/couples-assessment/evaluation', { matchedUserId, matchId });

            if (!response) {
                throw new Error(trans('account.data.error.something.went.wrong'));
            }

            this.setState({ isLoading: false, evaluatedData: response?.evaluatedData });
        } catch (error) {
            this.setState({ isLoading: false, error: error?.message || trans('account.data.error.something.went.wrong') });
        }
    }

    public open() {
        this.modal$.open();
    }

    public close() {
        this.modal$.close();
    }

    public render(): React.ReactNode {
        return <Modal
            ref={(ref: any) => (this.modal$ = ref)}
            content={() => <AnalysisTabs
                fixedTab={this.props.fixedTab}
                user={this.props.user}
                evaluatedData={this.state.evaluatedData}
            />}
            wrapperClasses="AnalysisModal palette--bgc-neutral-1 elevation-2 border-radius-1 p-2"
            footer={() => null}
            onDismissRequest={() => this.close()}
        />;
    }
}
