import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';

interface SeeMorePaginatorProps {
    loadMore: (nextPage: number) => void;
    totalProfiles: number;
    profileListLength: number;
    currentPage: number;
}

export class SeeMorePaginator extends Subscribable<SeeMorePaginatorProps, any> {
    public renderAddMorePage() {
        if (this.props.totalProfiles - this.props.profileListLength > 0) {
            return <div className={'w-100 position-center mb-px-32 mt-md-0 mt-8'}>
                <Button
                    className={'variant-see-more size-large'}
                    onClick={() => this.props.loadMore(this.props.currentPage + 1)}
                >
                    <span>{trans('account.data.see.more')}</span>
                </Button>
            </div>;
        }

        return null;
    }

    public render() {
        return this.renderAddMorePage();
    }
}
