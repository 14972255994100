import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { get } from 'lodash';
import { toast } from 'react-toastify';

export abstract class HandleToasts {
    public static error(error: any, msg?: string) {
        if (msg) {
            toast.error(msg);
            return;
        }

        toast.error(trans(get(error, 'response.data.message', error.message)));
    }

    public static success(msg: any) {
        toast.success(msg);
    }
}
