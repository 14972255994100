import { connect } from '@codebuild/cookie-jar/libs/connect';
import * as React from 'react';
import { CreditBox } from '../../components/profile/credit-box/credit-box';
import { TopUpBox } from '../../components/profile/top-up-box/top-up-box';
import { Subscribable } from '../../libs/subscribable';
import '../me-profile/profile.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class TopUpScreen extends Subscribable<any, any> {
    public state = { isLoading: false, questionnaires: [] };

    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <div className={'w-100 position-center'}>
                <div className={'row width-1390'}>
                    <div className={'col-24 col-mlg-6 mb-7 position-center'}>
                        <CreditBox/>
                    </div>
                    <div className={'col-24 col-mlg-18'}>
                        <TopUpBox/>
                    </div>
                </div>
            </div>
        </div>;
    }
}
