import { connect } from '@codebuild/cookie-jar/libs/connect';
import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { UserActions } from '../actions/user.actions';
import { Subscribable } from './subscribable';

interface UnauthorizedRouteProps extends RouteProps {
    layout: any;
    checkLoggedIn?: boolean;
    checkLoggedInTarget?: string;
    checkWhoAmIAndRedirect?: any;
    checkWhoAmI?: any;
    accessToken?: any;
    routeSource?: any;
}

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    accessToken: store.authentication.accessToken,
});

const mapDispatchProps = (dispatch: any) => ({
    checkWhoAmI: () => dispatch(UserActions.checkWhoAmI(true)),
    checkWhoAmIAndRedirect: (target: string) => dispatch(UserActions.checkWhoAmIAndRedirect(target, true))
});

@connect(mapStateProps, mapDispatchProps)
export class UnauthorizedRoute extends Subscribable<UnauthorizedRouteProps, any> {
    public componentDidMount(): void {
        this.props.checkWhoAmI();

        (window as any).ROUTE_SOURCE = this.props.routeSource;
    }

    public componentDidUpdate(prevProps: Readonly<UnauthorizedRouteProps>, prevState: Readonly<any>, snapshot?: any) {
        (window as any).ROUTE_SOURCE = this.props.routeSource;
    }

    public render(): React.ReactNode {
        this.checkLoggedIn();

        return React.createElement(
            this.props.layout,
            this.props,
            React.createElement(Route, this.props)
        );
    }

    public checkLoggedIn() {
        if (this.props.checkLoggedIn) {
            if (this.props.accessToken) {
                this.props.checkWhoAmIAndRedirect(this.props.checkLoggedInTarget || '/');
            }
        }
    }
}
