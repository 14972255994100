export const mapObject = (object: any, callback: (key, value) => any) => {
    if (!object) {
        return;
    }

    const keys = Object.keys(object);

    for (const key of keys) {
        callback(key, object[key]);
    }
};
