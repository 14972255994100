import { SyncStorage } from '@codebuild/cookie-jar/libs/sync.storage';
import { LOCATION_CHANGE } from 'connected-react-router';
import { AnyAction } from 'redux';
import { NavigationActions } from '../actions/navigation.actions';

const initial = {
    open: SyncStorage.getItem('navigationOpen')
};

export const navigationReducer = (state = initial, action: AnyAction) => {

    switch (action.type) {
        case NavigationActions.SET_NAVIGATION: {
            SyncStorage.setItem('navigationOpen', action.payload);

            state = { ...state, open: action.payload };
            break;
        }
        case NavigationActions.TOGGLE_NAVIGATION: {
            SyncStorage.setItem('navigationOpen', !state.open);

            state = { ...state, open: !state.open };
            break;
        }
        case LOCATION_CHANGE: {
            SyncStorage.setItem('navigationOpen', false);

            state = { ...state, open: false };
            break;
        }
    }

    return state;
};
