import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { generatePath } from 'react-router';

export const generateLink = (linkSource: any, params: any = {}): string => {
    try {
        const lang = TranslationLib.getLang();

        const source = linkSource.languages.find((i) => i.lang === lang);

        return generatePath(`${source.path}`, params);
    } catch (e) {
        return '';
    }
};
