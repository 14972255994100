import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { CategoryDescription } from '../../share/category-description';
import { ChartsWithDescription } from './extras/charts-with-description';

export class BehaviourTab extends Subscribable<any, any> {
    public getColourForOppositeSex(): number {
        switch (this.props.evaluatedData?.rightGender) {
            case 'account.edit.gender.female':
                return 2;
            case 'account.edit.gender.male':
                return 3;
            default:
                return 2;
        }
    }

    public getColourForUser(): any {
        switch (this.props.user?.meta?.gender) {
            case 'account.edit.gender.female':
                return { borderColor: '#FE938C', backgroundColor: 'transparent' };
            case 'account.edit.gender.male':
                return { borderColor: '#6CD4FF', backgroundColor: 'transparent' };
            default:
                return { borderColor: '#FE938C', backgroundColor: 'transparent' };
        }
    }

    public getGenderTitle(): string {
        switch (this.props.evaluatedData?.rightGender) {
            case 'account.edit.gender.female':
                return trans('account.data.average.chart.her');
            case 'account.edit.gender.male':
                return trans('account.data.average.chart.him');
            default:
                return trans('account.data.average.chart.avg');
        }
    }

    public renderTopSection() {
        const primaryOptionLabel = trans('account.data.you');
        const secondaryOptionLabel = this.getGenderTitle();

        return <ChartsWithDescription
            chartWrapper={'max-width-400 w-px-400'}
            customColors={this.getColourForUser()}
            datasets={(this.props.evaluatedData?.behaviouralTestResults?.user1 || {}) as any}
            datasets2={(this.props.evaluatedData?.behaviouralTestResults?.user2 || {}) as any}
            description={trans('couples.assessment.behaviour.description')}
            user={this.props.user}
            chartName={'Radar'}
            datasetsColor={4}
            datasetsColor2={this.getColourForOppositeSex()}
            primaryOptionLabel={primaryOptionLabel}
            secondaryOptionLabel={secondaryOptionLabel}
            fullSized={true}
            classNames={'position-center'}
        />;
    }

    public renderBottomSection() {
        return <CategoryDescription
            customTitle={'account.data.my.behaviour.comparative.analysis'}
            oppositeGender={this.props.evaluatedData?.rightGender}
            labelToBeOppositeGender={true}
            questionnaire={'behavioural'}
            data={(this.props.evaluatedData?.behaviouralTestResults?.user1 || {}) as any}
            data2={(this.props.evaluatedData?.behaviouralTestResults?.user2 || {}) as any}
        />;
    }

    public render() {
        return <div className={'row pt-6'}>
            <div className={'col-24 position-center'}>
                {this.renderTopSection()}
            </div>
            <div className={'col-24 pt-7'}>
                {this.renderBottomSection()}
            </div>
        </div>;
    }
}
