import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { SeeMorePaginator } from '../matchmaking/see-more-paginator';
import { UserInterface, UserMetaInterface } from '../share/interfaces/user.interface';
import { ErrorModal } from '../share/ui-components/error.modal';
import { Hr } from '../share/ui-components/hr';
import { Spinner } from '../spinner';
import { AssessedStrip } from './assessed-strip';

export class CouplesAssessment extends Subscribable<any, any> {
    public user: UserInterface = this.props.user;
    public meta: UserMetaInterface = this.props.user?.meta || {};
    public $errorModal: ErrorModal;
    public state = {
        profiles: [],
        total: 0,
        totalProfiles: 0,
        currentPage: 1,
        fetchPage: 0,
        isLoading: false,
        assessmentIsLoading: false,
        isMobile: window.innerWidth < 700,
        error: '',
        sortOptions: {
            result: null,
            name: null,
        }
    };

    public form = new Form({
        email: new Field({
            label: trans('account.data.email'),
            value: '',
            placeholder: '@',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        })
    });

    public async componentDidMount() {
        await this.getProfiles();
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevState.sortOptions.name !== this.state.sortOptions.name) {
            await this.getProfiles();
        }
        if (prevState.sortOptions.result !== this.state.sortOptions.result) {
            await this.getProfiles();
        }
    }

    public async getProfiles(nextPage: number = 0) {
        try {
            this.setState({ isLoading: true });

            const response = await Repository.get('/couples-assessment/matches', { sort: this.state.sortOptions, page: nextPage });

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({
                profiles: response.users || [],
                total: response.total,
                totalProfiles: response.totalProfiles,
                isLoading: false
            });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public renderButtonContent(icon: string, title: string) {
        return <div className={'display-flex'}>
            <span className={'material-icons icon-positioning'}>{icon}</span>
            {this.state.assessmentIsLoading ? <div className={'pl-4'}><Spinner/></div> : <span>{title}</span>}
        </div>;
    }

    public async handleAssessment() {
        try {
            this.setState({ assessmentIsLoading: true });

            const response = await Repository.post('/couples-assessment/assessment-by-email', { email: this.form.toJSON().email });

            if (!response?.userAssessmentSuccess) {
                throw new Error(trans('account.data.error.something.went.wrong'));
            }

            this.setState({ assessmentIsLoading: false });

            await this.getProfiles();
        } catch (error) {
            this.setState({
                assessmentIsLoading: false,
                error: this.parseError(error?.response?.data?.message || error?.message || error)
            });
            this.$errorModal.open();
        }
    }

    public parseError(errorMessage: string) {
        switch (errorMessage) {
            case 'AlreadyAssessed':
                return trans('account.data.error.already.assessed');
            case 'UserProfileIsNotCompleteYet':
                return trans('account.data.error.this.user.profile.is.incomplete');
            case 'CannotEvaluateWithYourself':
                return trans('account.data.error.cannot.evaluate.with.yourself');
            case 'UserNotFound':
                return trans('account.data.error.user.not.found');
            case 'YourProfileIsIncomplete':
                return trans('account.data.error.your.profile.is.incomplete');
            default:
                return trans('account.data.error.something.went.wrong');
        }
    }

    public renderEmailSearch() {
        return <div className={'row  justify-content-end align-items-center py-8'}>
            <div className={'col-md-7 col-24 mb-md-0 mb-5'}>
                <Input
                    field={this.form.field('email')}
                    labelClassName={'fs-small fw-bold palette--c-neutral-5 '}
                    className="mb-box variant-primary size-medium search-bar"
                    disabled={false}
                />
            </div>
            <div className={'col-md-11 col-24 display-flex align-items-center ml-6 justify-content-md-start justify-content-center'}>
                <Button
                    onClick={() => this.handleAssessment()}
                    title={this.renderButtonContent('search', trans('account.data.find.profile'))}
                    className={'variant-primary size-large mt-px-8'}
                />
            </div>
        </div>;
    }

    public sortResult(property: string) {
        this.setState({
            sortOptions: { [property]: this.state.sortOptions[property] === -1 ? 1 : -1 }
        });
    }

    public renderFilter() {
        return <div className={'row w-100 justify-content-space-around px-7 mx-0 display-md-flex display-none'}>
            <div className={'col-3 pl-7'}>
                <h6
                    className={'fs-medium fw-bold palette--c-neutral-5 text-uppercase display-flex align-items-center cursor-pointer'}
                    onClick={() => this.sortResult('result')}
                >
                    {trans('starsign.results.result')}
                    {this.state.sortOptions.result && <span className={'material-icons ml-2'}>{this.state.sortOptions.result === 1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>}
                </h6>
            </div>
            <div className={'col-lg-3 col-6'}/>
            <div className={'col-lg-17 col-13 pl-8'}>
                <h6
                    className={'fs-medium fw-bold palette--c-neutral-5 text-uppercase display-flex align-items-center cursor-pointer'}
                    onClick={() => this.sortResult('name')}
                >
                    {trans('starsign.results.name')}
                    {this.state.sortOptions.name && <span className={'material-icons ml-2'}>{this.state.sortOptions.name === 1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>}
                </h6>
            </div>
        </div>;
    }

    public render() {
        return <div className={'w-100 background-white tab-content p-3'}>
            {this.renderEmailSearch()}
            <Hr type={'light'} className={'mb-7'}/>
            <h6 className={'text-uppercase mb-6'}>{trans('previous.assessments')}</h6>
            {this.renderFilter()}
            <div className={'pb-4 display-flex flex-column w-100'}>
                {this.state.isLoading && <div className={'position-center py-7 w-100'}><Spinner theme={'dark'} size={'medium'}/></div>}
                {this.state.profiles.length
                    ? this.state.profiles.map((match: any, index) => <AssessedStrip isMobile={this.state.isMobile} key={match.id} profiles={this.state.profiles} match={match} index={index}/>)
                    : <div className={'position-center py-7 w-100'}><span className={'palette--c-neutral-5'}>{trans('account.data.empty.list')}</span></div>}
            </div>
            <SeeMorePaginator
                loadMore={(nextPage) => this.getProfiles(nextPage)}
                totalProfiles={this.state.totalProfiles}
                profileListLength={(this.state.profiles || []).length}
                currentPage={this.state.currentPage}
            />
            <ErrorModal
                ref={(ref: any) => this.$errorModal = ref}
                errorText={this.state.error}
            />
        </div>;
    }
}
