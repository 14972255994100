import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { UserActions } from '../../../actions/user.actions';
import { Subscribable } from '../../../libs/subscribable';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
    accessToken: store.authentication.accessToken,
    loginRequest: store.authentication.loginRequest,
    whoAmIRequest: store.authentication.whoAmIRequest,
});

const mapDispatchProps = (dispatch: any) => ({
    logout: () => dispatch(UserActions.logout())
});

@connect(mapStateProps, mapDispatchProps)
export class Logout extends Subscribable<any, any> {
    public render(): React.ReactNode {

        if (!this.props.accessToken) {
            if (!this.props.user) {
                return null;
            }
        }

        return <div className="display-flex flex-row justify-content-start align-items-center">
            <Button className={'variant-link px-0'} onClick={() => this.props.logout()}>
                <span className={'material-icons mr-4'}>exit_to_app</span>
                <span className={''}>{trans('global.signout')}</span>
            </Button>
        </div>;
    }

}
