import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { get } from 'lodash';

export const getLanguage = () => {
    const defaultLanguage = 'en';
    const langInLocal = TranslationLib.getLang();
    const partials = window.location.pathname.match(/^\/(en|hu|ro)/);

    return get(partials, '[1]') || langInLocal || defaultLanguage;
};

export const parseLanguage = () => {
    const defaultLanguage = 'en';
    const partials = window.location.pathname.match(/^\/(en|hu|ro)/);

    return get(partials, '[1]') || defaultLanguage;
};
