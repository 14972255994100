import { getLanguage } from '../../../../libs/get-language';
import { routes } from '../../../../routes';

export const getUrlByLang = (screen: keyof typeof routes) => {
    const paths = {};
    routes[screen]?.languages?.forEach(item => (paths[item.lang] = item.path));
    const lang = getLanguage();

    return paths[lang]?.replace('/:questionnaire', '');
};
