import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { get, manageHiddenData } from '../../share/helpers/util';
import { Hr } from '../../share/ui-components/hr';
import { ContactInfoForm } from '../forms/contact-info-form';
import { BoxWrapper } from '../shared/box-wrapper';
import { UserBase } from '../shared/user.base';

export class ContactInfos extends UserBase {
    public componentDidMount() {
        super.componentDidMount();
        this.setOpenessForRelationship();
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (prevState.meta !== this.state.meta) {
            this.setOpenessForRelationship();
        }
    }

    public setOpenessForRelationship() {
        const isSingle = this.state?.meta?.isSingle;
        this.setState({ isSingle });
    }

    public renderContact() {
        return <div>
            <div className={'w-100'}>
                <div className={'display-flex flex-column mb-7'}>
                    <span className={'text--small-grey'}>{trans('account.data.full.name')}</span>
                    <span className={'text--small'}>{this.manageRevealedData(`${this.state.meta.firstName} ${this.state.meta.lastName}`)}</span>
                </div>
                <div className={'display-flex flex-column mb-7'}>
                    <span className={'text--small-grey'}>{trans('account.data.mobile')}</span>
                    <span className={'text--small'}>{this.manageRevealedData(get(this.state.meta?.mobile))}</span>
                </div>

                <div className={'display-flex flex-column mb-7'}>
                    <span className={'text--small-grey'}>{trans('account.data.email')}</span>
                    <span className={'text--small'}>{this.manageRevealedData(get(this.state.user?.email))}</span>
                </div>
            </div>
        </div>;
    }

    public manageRevealedData(data: string) {
        return manageHiddenData(data, this.props.shouldReveal);
    }

    public renderSocial() {
        const shouldBlur = this.props.shouldReveal ? '' : 'blur-text';
        return <div>
            <h6 className={`fw-bold text--small ${shouldBlur}`}>{trans('account.data.social.media')}</h6>
            <div className={'w-100 overflow-hidden'}>
                {this.state.meta?.facebook && this.renderSocialLinkSection('Facebook', this.manageRevealedData(this.state.meta?.facebook))}
                {this.state.meta?.instagram && this.renderSocialLinkSection('Instagram', this.manageRevealedData(this.state.meta?.instagram))}
                {this.state.meta?.twitter && this.renderSocialLinkSection('Twitter', this.manageRevealedData(this.state.meta?.twitter))}
                {this.state.meta?.tiktok && this.renderSocialLinkSection('Tiktok', this.manageRevealedData(this.state.meta?.tiktok))}
                {this.state.meta?.linkedin && this.renderSocialLinkSection('Linkedin', this.manageRevealedData(this.state.meta?.linkedin))}
                {this.state.meta?.snapchat && this.renderSocialLinkSection('Snapchat', this.manageRevealedData(this.state.meta?.snapchat))}
                {this.renderOtherLinks()}
            </div>
        </div>;
    }

    public renderSocialLinkSection(social: string, link: string) {
        return <div className={'display-flex flex-column mb-7'}>
            <span className={'text--small-grey'}>{social}</span>
            <a href={get(link)} target="_blank" className={'text--small hover-opacity'}>{get(link)}</a>
        </div>;
    }

    public renderOtherLinks() {
        const other = (this.state.meta?.other || []);

        if (!other?.length) {
            return null;
        }

        return <div className={'display-flex flex-column mb-7'}>
            <span className={'text--small-grey'}>{trans('account.data.other')}</span>
            {other.map((link: string, index) => <a key={index} href={get(link)} className={'text--small hover-opacity display-block'}>{get(link)}</a>)}
        </div>;
    }

    public render() {
        return <BoxWrapper
            shouldClose={this.state.shouldClose}
            content={<ContactInfoForm onCancel={() => this.setState({ shouldClose: !this.state.shouldClose })} user={this.state.user} meta={this.state.meta}/>}
            enableEdit={this.props.enableEdit}
            className={'h-100'}
            title={<h6 className={'fw-bold text--small'}>{trans('account.data.contact.infos')}</h6>}
        >
            {this.renderContact()}
            {this.props.shouldReveal && <Hr className={'mb-5'}/>}
            {this.renderSocial()}
        </BoxWrapper>;
    }
}
