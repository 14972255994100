import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { UserInterface } from '../share/interfaces/user.interface';
import { BehaviourTab } from './tabs/behaviour.tab';
import { LoveStyleTab } from './tabs/love-style.tab';
import { PersonalityTab } from './tabs/personality.tab';
import { StarSignTab } from './tabs/star-sign.tab';

export const analysisTabs = {
    behaviour: 'behaviour',
    personality: 'personality',
    loveStyle: 'love-style',
    starSign: 'star-sign',
};

export interface AnalysisTabsProps {
    fixedTab?: 'behaviour' | 'personality' | 'loveStyle' | 'starSign' | 'star-sign' | 'love-style';
    evaluatedData: any;
    user?: UserInterface;
}

export class AnalysisTabs extends React.Component<AnalysisTabsProps, any> {
    public state = { active: analysisTabs.behaviour };

    public componentDidMount() {
        if (this.props.fixedTab) {
            this.setState({ active: this.props.fixedTab });
        }
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.fixedTab !== this.props.fixedTab && this.props.fixedTab) {
            this.setState({ active: this.props.fixedTab });
        }
    }

    public renderSelector(tab: string, text: string, score: number) {
        const isActive = this.state.active === tab;
        const backgroundColour = isActive ? 'background-primary' : 'background-white border-primary';
        const textColour = isActive ? 'color--light' : 'color--primary';

        return <div
            className={`${backgroundColour} tab-selector px-4 hover-opacity mr-4 mb-3`}
            onClick={() => this.setState({ active: tab })}
        >
            <h6 className={`fw-bold text--small mb-0 fs-12 ${textColour}`}>{trans(text)}</h6>
            {!!score && <div className={'percentage-tag ml-5'}><span className={'fw-bold fs-11 py-px-2 px-px-4'}>{score}%</span></div>}
        </div>;
    }

    public renderTabSelectors() {
        return <div className={'row'}>
            <div className={'col-md-5 col-sm-12 col-24'}>
                {this.renderSelector(analysisTabs.behaviour, 'account.data.behaviour', this.props?.evaluatedData?.behaviourMatchScore || 0)}
            </div>
            <div className={'col-md-5 col-sm-12 col-24'}>
                {this.renderSelector(analysisTabs.personality, 'account.data.personality', this.props?.evaluatedData?.personalityMatchScore || 0)}
            </div>
            <div className={'col-md-7 col-sm-12 col-24'}>
                {this.renderSelector(analysisTabs.starSign, 'account.data.starSign', this.props?.evaluatedData?.starSignCompatibility || 0)}
            </div>
            <div className={'col-md-5 col-sm-12 col-24'}>
                {this.renderSelector(analysisTabs.loveStyle, 'account.data.loveStyle', 0)}
            </div>
        </div>;
    }

    public renderActiveTab() {
        switch (this.state.active) {
            case analysisTabs.behaviour:
                return <BehaviourTab {...this.props}/>;
            case analysisTabs.personality:
                return <PersonalityTab {...this.props}/>;
            case analysisTabs.loveStyle:
                return <LoveStyleTab {...this.props}/>;
            case analysisTabs.starSign:
                return <StarSignTab {...this.props}/>;
            default:
                return <BehaviourTab {...this.props}/>;
        }
    }

    public render() {
        if (this.props.fixedTab) {
            return this.renderActiveTab();
        }

        return <div className={'row'}>
            <div className={'pb-6 pt-4 col-24'}>
                {this.renderTabSelectors()}
            </div>
            <div className={'col-24'}>
                {this.renderActiveTab()}
            </div>
        </div>;
    }
}
