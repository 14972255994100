import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { generateLink } from '../../libs/generate-link';
import { Subscribable } from '../../libs/subscribable';
import { routes } from '../../routes';
import './not-found.scss';

export class NotFound extends Subscribable<any, any> {
    public render(): React.ReactNode {
        return <div className="NotFound container display-flex justify-content-center align-items-center flex-column py-16">
            <h1>404</h1>
            <h2>{trans('notfound.title')}</h2>
            <span>{trans('notfound.description')}</span>
            <Link className={'backToHome'} to={generateLink(routes.homeExtended)}>{trans('notfound.backtohome')}</Link>
        </div>;
    }
}
