import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import { filter, find, get, map } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import { handleError } from '../../share/helpers/util';
import { CHILDREN, COUNTRY_OPTIONS, EDUCATION, LANGUAGES, LIVING_STATUS, MONEY_MANAGEMENT_HABITS, PROFESSIONS, RELATIONSHIP_HISTORY, RELATIONSHIP_STATUS, RELIGION, UPBRINGING } from '../edit-profile/constants';
import { SingleLineSelect } from './extras/single-line.select';

export class BackgroundDataForm extends Subscribable<any, any> {

    public form = new Form({
        city: new Field({
            label: trans('account.data.city'),
            value: this.props.meta?.city || '',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        country: new Field({
            label: trans('account.data.country'),
            value: find(COUNTRY_OPTIONS, (option) => option.value === get(this.props, 'meta.country', '')),
            options: COUNTRY_OPTIONS,
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        state: new Field({
            label: trans('account.data.state'),
            value: this.props.meta?.state || '',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        livingStatus: new Field({
            label: trans('account.data.living.status'),
            value: find(LIVING_STATUS, (option) => option.value === get(this.props, 'meta.livingStatus')),
            multi: false,
            options: map(LIVING_STATUS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        relationshipHistory: new Field({
            label: trans('account.data.relationship.history'),
            value: find(RELATIONSHIP_HISTORY, (option) => option.value === get(this.props, 'meta.relationshipHistory')),
            multi: false,
            options: map(RELATIONSHIP_HISTORY, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        children: new Field({
            label: trans('account.data.children'),
            value: find(CHILDREN, (option) => option.value === get(this.props, 'meta.children')),
            multi: false,
            options: map(CHILDREN, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        religion: new Field({
            label: trans('account.data.religion'),
            value: find(RELIGION, (option) => option.value === get(this.props, 'meta.religion')),
            multi: false,
            options: map(RELIGION, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        educationLevel: new Field({
            label: trans('account.data.education.level'),
            value: find(EDUCATION, (option) => option.value === get(this.props, 'meta.educationLevel')),
            multi: false,
            options: map(EDUCATION, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        moneyManagementHabits: new Field({
            label: trans('account.data.money.management.habits'),
            value: find(MONEY_MANAGEMENT_HABITS, (option) => option.value === get(this.props, 'meta.moneyManagementHabits')),
            multi: false,
            options: map(MONEY_MANAGEMENT_HABITS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        upbringing: new Field({
            label: trans('account.data.upbringing'),
            value: filter(UPBRINGING, (option) => (this.props?.meta?.upbringing || []).includes(option.value)),
            multi: true,
            options: map(UPBRINGING, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        professionType: new Field({
            label: trans('account.data.profession.type'),
            value: filter(PROFESSIONS, (option) => (this.props?.meta?.professionType || []).includes(option.value)),
            multi: true,
            options: map(PROFESSIONS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        languages: new Field({
            label: trans('account.data.languages'),
            value: LANGUAGES.filter(language => get(this.props, 'meta.languages', '').includes(language.value, '')),
            multi: true,
            options: map(LANGUAGES, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
        relationshipStatus: new Field({
            label: trans('account.data.marital.status'),
            value: find(RELATIONSHIP_STATUS, (option) => option.value === get(this.props, 'meta.relationshipStatus', '')),
            multi: false,
            options: map(RELATIONSHIP_STATUS, (option) => ({ title: trans(option.title), value: option.value })),
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
    });

    public setForm() {
        map(this.form.fields, (field, key) => {
            return field.setValue(get(this.props, `meta[${key}]`));
        });
    }

    public parseData() {
        const data = this.form.toJSON();
        const payload = {};

        for (const prop of Object.keys(data)) {
            switch (prop) {
                case 'upbringing':
                    payload[prop] = (data[prop] || []).map(option => option?.value);
                    break;
                case 'languages':
                    payload[prop] = (data[prop] || []).map(option => option?.value);
                    break;
                case 'professionType':
                    payload[prop] = (data[prop] || []).map(option => option?.value);
                    break;
                default:
                    payload[prop] = data[prop]?.value || data[prop];
            }
        }

        return payload;
    }

    public async handleSubmit() {
        try {
            const data = this.parseData();

            const response = await Repository.put('/user', data);

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }

    public render() {
        return <div className={'p-box'}>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <h2 className={'mb-8'}>{trans('account.edit.title')}</h2>
                <div className="row">
                    <div className="w-100 col-24 px-7">
                        <div className="row">
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('country')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('state')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Input
                                    field={this.form.field('city')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    type="text"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('religion')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('educationLevel')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('children')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('livingStatus')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('relationshipHistory')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <SingleLineSelect
                                    field={this.form.field('moneyManagementHabits')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>

                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    field={this.form.field('languages')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    field={this.form.field('upbringing')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    field={this.form.field('professionType')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <Select
                                    field={this.form.field('relationshipStatus')}
                                    labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                                    className="mb-box variant-primary size-medium"
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-100 col-24 display-flex justify-content-end">
                                <Button
                                    type={'button'}
                                    onClick={() => this.handleCancel()}
                                    className="variant-link size-medium"
                                    title={trans('global.buttons.cancel')}
                                />
                                <Button type={'submit'} className="variant-primary size-medium">
                                    <span className="material-icons mr-4">check</span>
                                    <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <div className="col-24 pt-6">
                            <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                        </div>
                    </div>
                </div>}
            </AbstractForm>
        </div>;
    }

    public handleCancel() {
        this.setForm();
        this.props.onCancel();
    }
}
