export abstract class NavigationActions {
    public static readonly TOGGLE_NAVIGATION = 'NavigationActions(TOGGLE_NAVIGATION)';
    public static readonly SET_NAVIGATION = 'NavigationActions(SET_NAVIGATION)';

    public static open() {
        return {
            type: NavigationActions.SET_NAVIGATION,
            payload: true
        };
    }

    public static close() {
        return {
            type: NavigationActions.SET_NAVIGATION,
            payload: false
        };
    }

    public static toggle() {
        return {
            type: NavigationActions.TOGGLE_NAVIGATION
        };
    }
}
