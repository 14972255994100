import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { GaugeChart } from './gauge-chart';

export interface GaugeWrapperProps {
    blur?: boolean;
    isAnimated?: boolean;
    value: number;
    width: number;
    height: number;
    id?: string;
}

export class GaugeWrapper extends Subscribable<GaugeWrapperProps, any> {
    public render() {
        return <div id={this.props.id} className={`w-px-${this.props.width} h-px-${this.props.height} GaugeChartWrapper`}>
            <GaugeChart
                noResize={true}
                isAnimated={!!this.props.isAnimated}
                value={this.props.value}
            />
        </div>;
    }
}
