import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { Subscribable } from '../../../../libs/subscribable';
import { imperialLengthToMetric, imperialWeightToMetric, metricLengthToImperial, metricWeightToImperial } from '../../../share/helpers/util';
import './measurement-input.scss';

export interface MeasurementInputProps {
    field: Field;
    label?: string;
    imperial?: string;
    metric?: string;
    reset?: boolean;
    placeholder?: string;
    noErrors?: boolean;
}

export class MeasurementInput extends Subscribable<MeasurementInputProps, any> {
    public state = { value: '', convertedVal: '', actualValue: '', active: this.props.metric, isDirty: false };

    public componentDidMount() {
        this.props.field.dirty$.subscribe(dirty => {
            const value = this.props.field.getValue();

            this.setState({ isDirty: !value && dirty });
        });
        this.props.field.value$.subscribe(value => this.state.isDirty && this.setState({ isDirty: !value }));

        const { convertedVal } = this.getConvertedVal(this.props.field.getValue());
        this.setState({ convertedVal, value: this.props.field.getValue() });
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevState.actualValue !== this.state.actualValue) {
            this.props.field.setValue(this.state.actualValue);
        }
        if (prevProps.reset !== this.props.reset) {
            this.setState({ convertedVal: '', value: '' });
        }
    }

    public getConvertedVal(value: number) {
        switch (this.props.label) {
            case 'account.data.weight':
                return {
                    convertedVal: this.state.active === this.props.metric ? metricWeightToImperial(value) : imperialWeightToMetric(value),
                    actualValue: this.state.active === this.props.metric ? value : imperialWeightToMetric(value),
                };
            default:
                return {
                    convertedVal: this.state.active === this.props.metric ? metricLengthToImperial(value) : imperialLengthToMetric(value),
                    actualValue: this.state.active === this.props.metric ? value : imperialLengthToMetric(value),
                };
        }
    }

    public handleOnChange(e: any) {
        const { convertedVal, actualValue } = this.getConvertedVal(e.target?.value);
        this.setState({ convertedVal, actualValue, value: e.target?.value });
    }

    public handleSelector(unit?: string) {
        this.setState({ active: unit, convertedVal: this.state.value, value: this.state.convertedVal });
    }

    public renderSelector() {
        const { metric, imperial } = this.props;
        const { active } = this.state;

        return <div className={'position-center max-width-61'}>
            <div
                onClick={() => this.handleSelector(metric)}
                className={`${metric === active && 'unit-selected'} unit-selector position-center hover-opacity`}
            >
                <span className={'input-label fs-small fw-bold palette--c-neutral-5 text-uppercase'}>{metric}</span>
            </div>
            <div
                onClick={() => this.handleSelector(imperial)}
                className={`${imperial === active && 'unit-selected'} unit-selector position-center hover-opacity`}
            >
                <span className={'input-label fs-small fw-bold palette--c-neutral-5 text-uppercase'}>{imperial}</span>
            </div>
        </div>;
    }

    public renderTopSection() {
        return <div className={'w-100 display-flex flex-wrap'}>
            <div className={'col-12 display-flex justify-content-start p-0 pb-1'}>
                <span className={'input-label fs-small fw-bold palette--c-neutral-5 text-uppercase'}>{trans(this.props?.label || '')}</span>
            </div>
            <div className={'col-12 display-flex justify-content-end p-0 pb-1'}>
                {this.renderSelector()}
            </div>
        </div>;
    }

    public renderMainInput() {
        return <input
            className={'measurement-input w-100'}
            type={'text'}
            placeholder={this.props.placeholder || ''}
            value={this.state.value}
            onChange={(e) => this.handleOnChange(e)}
        />;
    }

    public renderAlternateData() {
        return <input
            className={'measurement-input w-100'}
            type={'text'}
            value={this.state.convertedVal}
            disabled={true}
        />;
    }

    public render() {
        return <div className={'display-flex flex-column'}>
            <div>{this.renderTopSection()}</div>
            <div className={'measurement-wrapper w-100 display-flex flex-wrap'}>
                <div className={'col-12 p-0'}>{this.renderMainInput()}</div>
                <div className={'col-12 p-0'}>{this.renderAlternateData()}</div>
            </div>
            <div>
                {this.state.isDirty && !this.props.noErrors && <span className={'input-validation palette--c-red-2 fs-13'}>
                    {trans('global.input.required')}
                </span>}
            </div>
        </div>;
    }
}
