import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import '@codebuild/cookie-jar/polyfills';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import './index.scss';
import { LoginLayout } from './layouts/login.layout';
import { SimpleLayout } from './layouts/simple/simple.layout';
import { AuthorizedRoute } from './libs/authorized.route';
import { getLanguage, parseLanguage } from './libs/get-language';
import { UnauthorizedRoute } from './libs/unauthorized.route';
import { reducers } from './reducers';
import { routes } from './routes';
import { Contact } from './screens/contact/contact';
import { CouplesAssessmentScreen } from './screens/couples-assessment/couples-assessment.screen';
import { EmailVerificationScreen } from './screens/email-verification/email-verification.screen';
import { FaqScreen } from './screens/faq/faq.screen';
import { ForgotPasswordRequestScreen } from './screens/forgot-password-request/forgot-password-request.screen';
import { HomeScreen } from './screens/home/home.screen';
import { InviteFriendScreen } from './screens/invite-friend/invite-friend.screen';
import { PrivacyScreen } from './screens/legal-pages/privacy.screen';
import { TermsOfUseScreen } from './screens/legal-pages/terms-of-use.screen';
import { LoginScreen } from './screens/login/login.screen';
import { MatchmakingResultsScreen } from './screens/matchmaking-results/matchmaking-results.screen';
import { MeProfileScreen } from './screens/me-profile/me-profile.screen';
import { NotFound } from './screens/not-found/not-found';
import { PaymentScreen } from './screens/payment/payment.screen';
import { PricingScreen } from './screens/pricing/pricing.screen';
import { Questionnaires } from './screens/questionnaires/questionnaires';
import { RegistrationScreen } from './screens/registration/registration.screen';
import { ResetPasswordScreen } from './screens/reset-password/reset-password.screen';
import { StartQuestionnairesScreen } from './screens/start-questionnaires/start-questionnaires.screen';
import { StartSignCompatibilityTestScreen } from './screens/start-sign-compatibility-test/start-sign-compatibility-test.screen';
import { TopUpScreen } from './screens/topup/top-up.screen';
import { UserProfileScreen } from './screens/user-profile/user-profile.screen';

import './scss/index.scss';

const root$ = document.getElementById('application');
const history = createBrowserHistory();
const store = createStore(
    combineReducers({
        ...reducers,
        router: connectRouter(history)
    }),
    compose(
        applyMiddleware(routerMiddleware(history)),
        // applyMiddleware(reduxLogger),
        applyMiddleware(reduxThunk),
    )
);
const renderApp = () => {
    ReactDOM.render(<Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                {routes.login.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.login}
                    key={`${lr.lang}-${lr.path}`}
                    layout={LoginLayout}
                    exact={true}
                    path={lr.path}
                    component={LoginScreen}
                    checkLoggedIn={true}
                    checkLoggedInTarget={lr.checkLoggedInTarget}
                />)}

                {routes.forgotPasswordRequest.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.forgotPasswordRequest}
                    key={`${lr.lang}-${lr.path}`}
                    layout={LoginLayout}
                    exact={true}
                    path={lr.path}
                    component={ForgotPasswordRequestScreen}
                    checkLoggedIn={false}
                    checkLoggedInTarget={lr.checkLoggedInTarget}
                />)}

                {routes.registration.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.registration}
                    key={`${lr.lang}-${lr.path}`}
                    layout={LoginLayout}
                    exact={true}
                    path={lr.path}
                    component={RegistrationScreen}
                    checkLoggedIn={false}
                    checkLoggedInTarget={lr.checkLoggedInTarget}
                />)}

                {routes.faq.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.faq}
                    key={`${lr.lang}-${lr.path}`}
                    layout={SimpleLayout}
                    exact={true}
                    path={lr.path}
                    component={FaqScreen}
                    checkLoggedIn={false}
                />)}

                {routes.home.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.home}
                    layout={SimpleLayout.noBackground()}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={HomeScreen}
                />)}

                {routes.homeExtended.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.homeExtended}
                    layout={SimpleLayout.noBackground()}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={HomeScreen}
                />)}

                {routes.inviteFriend.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.inviteFriend}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={InviteFriendScreen}
                />)}

                {routes.coupleAssessments.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.coupleAssessments}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={CouplesAssessmentScreen}
                />)}

                {routes.pricing.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.pricing}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={PricingScreen}
                />)}

                {routes.compatibilityTest.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.compatibilityTest}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={StartSignCompatibilityTestScreen}
                />)}

                {routes.profile.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.profile}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={MeProfileScreen}
                />)}

                {routes.topUp.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.topUp}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={TopUpScreen}
                />)}

                {routes.profiles.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.profiles}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={false}
                    path={lr.path}
                    component={UserProfileScreen}
                />)}

                {routes.matchmakingResults.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.matchmakingResults}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={MatchmakingResultsScreen}
                />)}

                {routes.startQuestionnaire.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.startQuestionnaire}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={StartQuestionnairesScreen}
                />)}

                {routes.questionnaires.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.questionnaires}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={Questionnaires}
                />)}

                {routes.payment.languages.map((lr) => <AuthorizedRoute
                    routeSource={routes.payment}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={PaymentScreen}
                />)}

                {routes.forgotPasswordValidate.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.forgotPasswordValidate}
                    layout={SimpleLayout.noBackground()}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={ResetPasswordScreen}
                />)}

                {routes.verificationValidate.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.verificationValidate}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={EmailVerificationScreen}
                />)}

                {routes.verificationValidate.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.verificationValidate}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={EmailVerificationScreen}
                />)}

                {routes.terms.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.terms}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={(props) => <Redirect to={lr.redirect}/>}
                />)}

                {routes.termsOfUse.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.termsOfUse}
                    layout={SimpleLayout.noBackground()}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={TermsOfUseScreen}
                />)}

                {routes.privacy.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.privacy}
                    layout={SimpleLayout.noBackground()}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={PrivacyScreen}
                />)}

                {routes.contact.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.contact}
                    layout={SimpleLayout.noBackground()}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={Contact}
                />)}

                {routes.notFound.languages.map((lr) => <UnauthorizedRoute
                    routeSource={routes.notFound}
                    layout={SimpleLayout}
                    key={`${lr.lang}-${lr.path}`}
                    exact={true}
                    path={lr.path}
                    component={NotFound}
                />)}

                <Route component={(props) => <Redirect to={`/${getLanguage()}${props.location.pathname}${window.location.search}`}/>}/>

            </Switch>
        </ConnectedRouter>

        <ToastContainer/>
        {/*<QueryDebugger/>*/}
    </Provider>, root$);
};

// TranslationLib.setLang(getLanguage());

TranslationLib
    .addLangData('hu', require('./translation/hu.tsx'), false)
    .addLangData('en', require('./translation/en.tsx'), false)
    .addLangData('ro', require('./translation/ro.tsx'), false)
    .initialize(false);

TranslationLib.setLang(parseLanguage());
moment.locale(parseLanguage());

Environment
    .download()
    .then(() => renderApp())
    .catch(() => alert('Végzetes hiba történt!'));
