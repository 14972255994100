import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import * as React from 'react';
import { BehaviouralAnalysis } from '../../components/profile/analysis/behavioural-analysis';
import { LoveStyleAnalysis } from '../../components/profile/analysis/love-style-analysis';
import { PersonalityAnalysis } from '../../components/profile/analysis/personality-analysis';
import { StarSignAnalysis } from '../../components/profile/analysis/star-sign-analysis';
import { BackgroundData } from '../../components/profile/background-data/background-data';
import { ContactInfos } from '../../components/profile/contact-infos/contact-infos';
import { ProfileCompletionMeter } from '../../components/profile/notifications/profile-completion-meter';
import { ProfileVisibility } from '../../components/profile/notifications/profile-visibility';
import { PersonalAttributes } from '../../components/profile/personal-attributes/personal-attributes';
import { PersonalPreference } from '../../components/profile/personal-preference/personal-preference';
import { DatePickerModal } from '../../components/profile/profile-box/date-picker.modal';
import { ProfileBox } from '../../components/profile/profile-box/profile-box';
import { StorageHelper } from '../../components/share/helpers/storage.helper';
import { Subscribable } from '../../libs/subscribable';
import './/profile.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class MeProfileScreen extends Subscribable<any, any> {
    public state = { isLoading: false, questionnaires: [] };
    public storage = new StorageHelper('questionnaires');

    public async componentDidMount() {
        if (!this.isQuestionnairesInStore()) {
            await this.getQuestionnaires();
        }
    }

    public isQuestionnairesInStore(): boolean {
        const questionnaires = this.storage.get();

        return !!questionnaires;
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            if (!this.isQuestionnairesInStore()) {
                await this.getQuestionnaires();
            }
        }
    }

    public async getQuestionnaires() {
        try {
            this.setState({ isLoading: true });
            const response = await Repository.get(`/questionnaires/${this.props.user._id}`);

            if (!response) {
                throw new Error('something went wrong');
            }

            this.storage.set(response.questionnaires);

            this.setState({ questionnaires: response.questionnaires, isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <div className="container max-width-1138">
                <DatePickerModal user={this.props.user} birthDate={this.props.user?.meta?.birthDate}/>
                <div className={'row'}>
                    {!this.props.user.meta?.isProfileComplete && <div className={'col-24 pb-6'}>
                        <ProfileCompletionMeter user={this.props.user}/>
                    </div>}
                    <div className={'col-24 pb-6'}>
                        <ProfileVisibility user={this.props.user}/>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-24 col-md-6 pb-6 pb-md-0'}>
                        <ProfileBox enableEdit={true} user={this.props.user}/>
                    </div>
                    <div className={'col-24 col-md-10 pb-6 pb-md-0'}>
                        <PersonalAttributes enableEdit={true} user={this.props.user}/>
                    </div>
                    <div className={'col-24 col-md-8'}>
                        <ContactInfos shouldReveal={true} enableEdit={true} user={this.props.user} isMainUser={true}/>
                    </div>
                </div>
                <div className={'row pt-7'}>
                    <div className={'col-24 col-md-8'}>
                        <div className={'row'}>
                            <div className={'col-24 pb-6'}>
                                <BackgroundData enableEdit={true} user={this.props.user}/>
                            </div>
                            <div className={'col-24 pb-6'}>
                                <PersonalPreference enableEdit={true} user={this.props.user}/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-24 pb-6 col-md-16'}>
                        <div className={'row'}>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <BehaviouralAnalysis user={this.props.user} analysisType={'behaviouralQuestionnaire'}/>
                            </div>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <PersonalityAnalysis user={this.props.user}/>
                            </div>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <StarSignAnalysis user={this.props.user}/>
                            </div>
                            <div className={'col-24 pb-6 col-md-12'}>
                                <LoveStyleAnalysis user={this.props.user}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
