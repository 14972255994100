import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { GaugeWrapper } from '../gauge-chart/gauge-wrapper';
import { handleNotEnoughBalance } from '../share/helpers/util';
import { Hr } from '../share/ui-components/hr';

export interface MatchedUser {
    _id: string;
    userId: string;
    personalityTest: number;
    behaviouralTest: number;
    starSignCompatibility: number;
    finalResult: number;
    firstName: string;
    lastName: string;
    photo: string;
}

export interface ProfileCardProps {
    profile: MatchedUser;
}

export class CompatibilityProfileCard extends Subscribable<ProfileCardProps, any> {
    public state: any = {
        isPurchased: false,
    };

    public async componentDidMount() {
        await this.isPurchased();
    }

    public async isPurchased() {
        try {
            this.setState({ loading: true });

            const response = await Repository.get('/analysis-purchase/purchase-profile-infos');

            if (!response?.purchases) {
                return;
            }

            const userId = this.props.profile.userId;

            const isPurchased = !!response.purchases.matchMaking.find(usId => usId === userId);

            this.setState({ isPurchased });
        } catch (e) {
            handleNotEnoughBalance(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    public async purchase() {
        try {
            this.setState({ loading: true });

            const profileId = this.props.profile.userId;

            const response = await Repository.put('/analysis-purchase/purchase-profile-infos', { profileId, type: 'match-making' });

            if (!response?.savedPurchaseInfo) {
                throw new Error(trans('account.data.error.something.went.wrong'));
            }

            window.location.reload();
        } catch (e) {
            handleNotEnoughBalance(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    public renderTopPart({ photo, firstName }: MatchedUser) {
        const fontSize = firstName?.length > 20 ? 15 : 17;

        return <div className={'display-flex flex-column align-items-center justify-content-center'}>
            <div
                className={'img-wrapper img-wrapper--round-small'}
                style={{ backgroundImage: `url(${photo})` }}
            />
            <h4 className={`text--h3 mt-4 fs-${fontSize}`}>{firstName}</h4>
        </div>;
    }

    public renderCreditButtonContent(title: string) {
        return <div className={'display-flex'}>
            <span className={'fs-14'}>{trans(title)} <span className={'text-italic fs-15'}>({5} {trans('global.label.credits')})</span></span>
        </div>;
    }

    public renderButtons() {
        if (!this.state.isPurchased) {
            return <Button
                onClick={() => this.purchase()}
                className={'variant-primary size-large hover-opacity'}
                title={this.renderCreditButtonContent('account.data.view.profile')}
            />;
        }

        const lang = TranslationLib.getLang() || 'en';
        const { profile } = this.props;

        return <a className={'text-decoration-none'} href={`/${lang}/users/${profile.userId}`}>
            <Button
                className={'variant-primary size-large hover-opacity'}
                title={trans('account.data.view.profile')}
            />
        </a>;
    }

    public render() {
        return <div className={'border-radius-px-8 p-7 background-white mb-5 hover-scale'}>
            {this.renderTopPart(this.props.profile)}
            <Hr className={'mb-7'}/>
            <div className={'row'}>
                <div className={'col-24 w-100 position-center pb-7'}>
                    <GaugeWrapper
                        width={120}
                        height={120}
                        isAnimated={false}
                        value={Math.round(this.props.profile?.finalResult) / 100}
                    />
                </div>
                <div className={'col-24 position-center'}>
                    {this.renderButtons()}
                </div>
            </div>
        </div>;
    }
}
