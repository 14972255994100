import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './legal-page.scss';
import { TERMS_OF_USE } from './terms';

export class TermsOfUseScreen extends Subscribable<any, any> {

    public render(): React.ReactNode {
        return <div className={'FaqScreen p-box'}>
            <div className="container">
                <div className={'p-box'}>
                    <div className="row justify-content-center">
                        <div className="col-lg-16 col-24">
                            <div dangerouslySetInnerHTML={{ __html: TERMS_OF_USE }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
