import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { CategoryDescription } from '../../share/category-description';
import { DoubleChartsWithDescription } from './extras/double-charts-with-description';

export class LoveStyleTab extends Subscribable<any, any> {
    public getDiffDataSets() {
        const user1 = this.props.evaluatedData?.loveStyleTestScoreResults?.user1 || {};
        const user2 = this.props.evaluatedData?.loveStyleTestScoreResults?.user2 || {};
        const datasets = {};

        for (const key of Object.keys(user1)) {
            datasets[key] = user1[key] - user2[key];
        }

        return datasets;
    }

    public renderTopSection() {
        const datasets = this.getDiffDataSets();

        return <div>
            <DoubleChartsWithDescription
                differenceTitle={trans('variance.between.us')}
                comparisonTitle={trans('love-style-dimension')}
                evaluatedData={this.props.evaluatedData}
                differenceDataset={datasets}
                user1Dataset={(this.props.evaluatedData?.loveStyleTestScoreResults?.user1 || {}) as any}
                user2Dataset={(this.props.evaluatedData?.loveStyleTestScoreResults?.user2 || {}) as any}
                description={trans('couples.assessment.love-style.description')}
                user={this.props.user}
                chartName={'Bar'}
                fullSized={true}
                doNotDisplayLegend={true}
                customColors={{ borderColor: ['rgb(185,198,213)'], backgroundColor: ['rgb(185,198,213)'] }}
            />
        </div>;
    }

    public renderBottomSection() {
        return <CategoryDescription
            customTitle={'account.data.my.love-style.comparative.analysis'}
            oppositeGender={this.props.evaluatedData?.rightGender}
            labelToBeOppositeGender={true}
            questionnaire={'love-style'}
            data={(this.props.evaluatedData?.loveStyleTestScoreResults?.user1 || {}) as any}
            data2={(this.props.evaluatedData?.loveStyleTestScoreResults?.user2 || {}) as any}
        />;
    }

    public render() {
        return <div className={'row'}>
            <div className={'col-24'}>
                {this.renderTopSection()}
            </div>
            <div className={'col-24 pt-7'}>
                {this.renderBottomSection()}
            </div>
        </div>;
    }
}
