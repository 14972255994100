/**
 * eases up to use the session and the local storage in code.
 * abstracts away the JSON usage
 */

export interface StorageHelperInterface {
    name: string;
    storage: Storage;
    has: () => void;
    set: (value: any) => void;
    getItem: (prop: string) => any;
    get: () => any;
    remove: () => void;
}

export class StorageHelper implements StorageHelperInterface {
    public name: string;
    public storage = window.localStorage;

    public constructor(name: string, type: 'local' | 'session' = 'local') {
        this.name = name;
        this.storage = type === 'local' ? window.localStorage : window.sessionStorage;
    }

    public has() {
        return !!this.storage.getItem(this.name);
    }

    public set(value: any) {
        this.storage.setItem(this.name, JSON.stringify(value, null));
    }

    public get(): any {
        const val = this.storage.getItem(this.name);

        return !!val ? JSON.parse(val) : false;
    }

    public getItem(prop: string): any {
        const val = this.storage.getItem(this.name);

        if (!!val) {
            const parsedVal = JSON.parse(val);

            return !!parsedVal[prop] ? parsedVal[prop] : false;
        }

        return false;
    }

    public remove() {
        this.storage.removeItem(this.name);
    }
}
