import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { CONSTANTS } from '../../../constants';
import { PersonalityChartCategoryOrganizer } from '../../questionnaires/libs/personality-chart-category-organizer';
import { CategoryDescription } from '../../share/category-description';
import { DimensionTabs } from '../../share/dimension-tabs';
import { Hr } from '../../share/ui-components/hr';
import { ChartsWithDescription } from './extras/charts-with-description';
import { DoubleChartsWithDescription, GENDER_COLOURS } from './extras/double-charts-with-description';

export class PersonalityTab extends DimensionTabs<any, any> {
    public state = {
        ...this.state,
        user1: null,
        user2: null,
        difference: null
    };

    public componentDidMount() {
        const user1Category = this.props.evaluatedData?.personalityCategoryTestResultsByUser?.user1 || {};
        const user1SubCategory = this.props.evaluatedData?.personalitySubCategoryTestResultsByUser?.user1 || {};
        const user2Category = this.props.evaluatedData?.personalityCategoryTestResultsByUser?.user2 || {};
        const user2SubCategory = this.props.evaluatedData?.personalitySubCategoryTestResultsByUser?.user2 || {};
        const subCategories = this.props.evaluatedData?.personalitySubCategoryTestResults || {};
        const mainCategories = this.props.evaluatedData?.personalityCategoryTestResults || {};
        const { subCategoryDataset, categoryDataset } = new PersonalityChartCategoryOrganizer(subCategories, mainCategories);

        this.setState({
            user1: new PersonalityChartCategoryOrganizer(user1SubCategory, user1Category),
            user2: new PersonalityChartCategoryOrganizer(user2SubCategory, user2Category),
            difference: { subCategoryDataset, categoryDataset }
        });
    }

    public getChartBarColours(rawData: number[]): { borderColor: string[], backgroundColor: string[] } {

        if (!rawData) {
            return {} as any;
        }

        const colors = { green: 'rgb(35,220,78)', grey: 'rgb(185,198,213)', red: 'rgb(241,78,57)' };

        const colourSet = Object.keys(rawData).map(key => {
            const absoluteValue = Math.abs(rawData[key]);

            return CONSTANTS.sameCategories.includes(key)
                ? absoluteValue > 2.49 ? colors.red : (absoluteValue < 1.1 ? colors.green : colors.grey)
                : absoluteValue > 2.49 ? colors.green : (absoluteValue < 1.1 ? colors.red : colors.grey);
        });

        return {
            borderColor: colourSet,
            backgroundColor: colourSet
        };
    }

    public renderMainContent() {
        const datasets = this.state.difference?.categoryDataset || {};
        const user1 = this.state.user1?.categoryDataset || {};
        const user2 = this.state.user2?.categoryDataset || {};

        return <div className={'row'}>
            <div className={'col-24'}>
                <DoubleChartsWithDescription
                    differenceTitle={trans('personality.differences')}
                    comparisonTitle={trans('main-dimension')}
                    evaluatedData={this.props.evaluatedData}
                    differenceDataset={datasets as any}
                    user1Dataset={user1 as any}
                    user2Dataset={user2 as any}
                    description={trans('couples.assessment.personality.dimensions.description')}
                    user={this.props.user}
                    chartName={'Bar'}
                    fullSized={true}
                    doNotDisplayLegend={true}
                    customColors={{ borderColor: ['rgb(185,198,213)'], backgroundColor: ['rgb(185,198,213)'] }}
                />
            </div>

            <div className={'col-24 pt-7'}>
                <CategoryDescription
                    customTitle={'account.data.my.personality.comparative.description'}
                    size={'lg'}
                    oppositeGender={this.props.evaluatedData?.rightGender}
                    labelToBeOppositeGender={true}
                    genderLabel={this.props.genderLabel}
                    data={user1}
                    data2={user2}
                    questionnaire={'personality'}
                />
            </div>
        </div>;
    }

    public getColourByGender() {
        switch (this.props.user?.meta?.gender) {
            case 'account.edit.gender.female':
                return GENDER_COLOURS.female;
            case 'account.edit.gender.male':
                return GENDER_COLOURS.male;
            default:
                return GENDER_COLOURS.female;
        }
    }

    public getColourIndexByGender() {
        switch (this.props.evaluatedData?.rightGender) {
            case 'account.edit.gender.female':
                return 0;
            case 'account.edit.gender.male':
                return 1;
            default:
                return 0;
        }
    }

    public renderSubContent() {
        const subCategoryDataset = this.state.difference?.subCategoryDataset || {};
        const user1 = this.state.user1?.subCategoryDataset || {};
        const user2 = this.state.user2?.subCategoryDataset || {};
        const isMobile = window.innerWidth < 900;

        return <div className={'row'}>
            <div className={'col-24'}>
                <ChartsWithDescription
                    hideVertical={true}
                    title={trans('our-sub-dimensions')}
                    datasets={this.state.user1.subCategory}
                    datasets2={this.state.user2.subCategory}
                    description={trans('couples.assessment.personality.traits.description')}
                    user={this.props.user}
                    chartName={'Bar'}
                    fullSized={true}
                    doNotDisplayLegend={true}
                    customColors={this.getColourByGender()}
                    datasetsColor={this.props.datasetsColor}
                    datasetsColor2={this.getColourIndexByGender()}
                    labels={this.state.user2.subCategoryLabels}
                />
            </div>
            <div className={'col-24'}>
                <ChartsWithDescription
                    hideVertical={true}
                    barThickness={isMobile ? 6 : 30}
                    title={trans('account.data.difference.between.us')}
                    datasets={subCategoryDataset as any}
                    user={this.props.user}
                    chartName={'Bar'}
                    fullSized={true}
                    doNotDisplayLegend={true}
                    customColors={this.getChartBarColours(subCategoryDataset as any)}
                />
            </div>
            <div className={'col-24 pt-7'}>
                <CategoryDescription
                    customTitle={'account.data.my.personality.comparative.description'}
                    size={'md'}
                    labelToBeOppositeGender={true}
                    oppositeGender={this.props.evaluatedData?.rightGender}
                    genderLabel={this.props.genderLabel}
                    data={user1}
                    data2={user2}
                    questionnaire={'personality'}
                />
            </div>
        </div>;
    }

    public renderActiveTab() {
        switch (this.state.active) {
            case 'main-dimension.selector':
                return this.renderMainContent();
            case 'sub-dimension':
                return this.renderSubContent();
            default:
                return null;
        }
    }

    public render() {
        return <div>
            <Hr type={'light'}/>
            {this.renderSelectors()}
            {this.renderActiveTab()}
        </div>;
    }
}
