import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { CategoryDescription } from './category-description';
import { UserMetaInterface } from './interfaces/user.interface';

export interface DimensionTabsProps {
    category: any;
    category2?: any;
    subCategory: any;
    subCategory2?: any;
    oppositeGender?: UserMetaInterface['gender'];
    genderLabel?: boolean;
    labelToBeOppositeGender?: boolean;
}

export class DimensionTabs<TProps extends DimensionTabsProps, TState> extends React.Component<TProps | DimensionTabsProps, TState | any> {
    public state = {
        active: 'main-dimension.selector'
    };

    public renderSelector(tab: 'main-dimension.selector' | 'sub-dimension') {
        const isActive = this.state.active === tab;
        const backgroundColour = isActive ? 'background-primary' : 'background-white border-primary';
        const textColour = isActive ? 'color--light' : 'color--primary';

        return <div
            className={`${backgroundColour} tab-selector px-7 hover-opacity mr-4`}
            onClick={() => this.setState({ active: tab })}
        >
            <h6 className={`fw-bold text--small mb-0 fs-md-14 fs-12 ${textColour}`}>{trans(tab)}</h6>
        </div>;
    }

    public renderSelectors() {
        return <div className={'row py-4'}>
            <div className={'col-24 col-md-16'}>
                <div className={'row'}>
                    <div className={'col-24 col-sm-10 py-4'}>
                        {this.renderSelector('main-dimension.selector')}
                    </div>
                    <div className={'col-24 col-sm-10 py-4'}>
                        {this.renderSelector('sub-dimension')}
                    </div>
                </div>
            </div>
        </div>;
    }

    public renderActiveTab() {
        const selectors = this.renderSelectors();

        switch (this.state.active) {
            case 'main-dimension.selector':
                return <CategoryDescription
                    size={'lg'}
                    oppositeGender={this.props.oppositeGender}
                    labelToBeOppositeGender={this.props.labelToBeOppositeGender}
                    genderLabel={this.props.genderLabel}
                    data={this.props.category}
                    data2={this.props.category2}
                    questionnaire={'personality'}
                    extraContent={selectors}
                />;
            case 'sub-dimension':
                return <CategoryDescription
                    size={'md'}
                    labelToBeOppositeGender={this.props.labelToBeOppositeGender}
                    oppositeGender={this.props.oppositeGender}
                    genderLabel={this.props.genderLabel}
                    data={this.props.subCategory}
                    data2={this.props.subCategory2}
                    questionnaire={'personality'}
                    extraContent={selectors}
                />;
            default:
                return null;
        }
    }

    public render() {
        return <div>
            {this.renderActiveTab()}
        </div>;
    }
}
