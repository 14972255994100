import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { HandleToasts } from '../../../handle.toasts';
import { Subscribable } from '../../../libs/subscribable';
import { GaugeChart } from '../../gauge-chart/gauge-chart';
import { Spinner } from '../../spinner';
import { CompatibilityDetails } from './compatibility-details';
import './compatibility-results.scss';
import { EditResult } from './edit-result';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class CompatibilityResults extends Subscribable<any, any> {
    public deleteModalRef$: Modal | null;

    public state: any = {
        onDelete: null,
        results: [],
        currentSort: { type: 'createdAt', direction: -1 },
        loading: false
    };

    public async componentDidMount(): Promise<void> {
        await this.fetchResults(this.state.currentSort);
    }

    public render(): React.ReactNode {
        return <div className={'CompatibilityResults w-100 border-radius-2 palette--bgc-neutral-1 elevation-1 p-box'}>
            <div>
                <h6 className={'text-uppercase mb-6'}>{trans('starsign.results.title')}</h6>
            </div>

            <div className={''}>
                {!get(this.state, 'loading') && !!get(this.state, 'results', []).length &&
                    <div className={''}>
                        <div className={'row  display-none display-md-flex px-7'}>
                            <div className={'col-4 col-lg-4 col-md-6'}>
                                <h6
                                    className={'fs-medium fw-bold palette--c-neutral-5 text-uppercase display-flex align-items-center cursor-pointer'}
                                    onClick={() => this.sortResult('starSign.percentage')}
                                >
                                    {trans('starsign.results.result')}
                                    {this.state.currentSort.type === 'starSign.percentage' && <span className={'material-icons ml-2'}>{this.state.currentSort.direction === 1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>}
                                </h6>
                            </div>
                            <div className={'col-8 col-lg-8 col-md-10'}>
                                <h6
                                    className={'fs-medium fw-bold palette--c-neutral-5 text-uppercase display-flex align-items-center cursor-pointer'}
                                    onClick={() => this.sortResult('starSign.name')}
                                >
                                    {trans('starsign.results.name')}
                                    {this.state.currentSort.type === 'starSign.name' && <span className={'material-icons ml-2'}>{this.state.currentSort.direction === 1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>}
                                </h6>
                            </div>
                            {/*<div className={'col-lg-7 col-md-7 col-24'}>*/}
                            {/*    <h6*/}
                            {/*        className={'fs-medium fw-bold palette--c-neutral-5 text-uppercase display-flex align-items-center cursor-pointer'}*/}
                            {/*        onClick={() => this.sortResult('createdAt')}*/}
                            {/*    >*/}
                            {/*        {trans('starsign.results.create')}*/}
                            {/*        {this.state.currentSort.type === 'createdAt' && <span className={'material-icons ml-2'}>{this.state.currentSort.direction === 1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>}*/}
                            {/*    </h6>*/}
                            {/*</div>*/}
                        </div>
                        <div className={'ResultsList'}>
                            {(get(this.state, 'results', []) || []).map((result, index) => this.renderResult(result, index))}
                        </div>
                    </div>}

                {!get(this.state, 'loading') && (!get(this.state, 'results', []).length) && this.renderEmpty()}
                {get(this.state, 'loading') && this.renderLoading()}
            </div>
            <Modal
                hideClose={true}
                ref={(ref) => this.deleteModalRef$ = ref}
                content={() => this.renderDeleteModalContent()}
                wrapperClasses="DeleteModalWrapper border-left-3 palette--bc-red-2 palette--bgc-neutral-1 elevation-2 border-radius-1"
                footer={() => null}
            />
        </div>;
    }

    public renderDeleteModalContent() {
        return <div className={'py-6'}>
            <div className={' mb-6  display-flex align-items-start justify-content-space-between'}>
                <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                    <span className={'material-icons palette--c-red-2 mr-4'}>info</span>
                    <div>
                        <h2>{trans('starsign.result.delete.title')}</h2>
                        <span>{trans('starsign.result.delete.desc')}</span>
                    </div>
                </div>

            </div>
            <div>
                <div className={'display-flex justify-content-end align-items-center'}>
                    <Button className={'variant-outline size-medium mr-3'} onClick={() => this.cancelDelete()}>{trans('global.buttons.cancel')}</Button>
                    <Button className={'variant-error size-medium'} onClick={() => this.deleteResult()}><span className="material-icons mr-3">delete</span>{trans('global.buttons.delete')}</Button>
                </div>
            </div>
        </div>;
    }

    public cancelDelete() {
        this.setState({ onDelete: null });
        this.deleteModalRef$?.close();
    }

    public reload() {
        this.fetchResults(this.state.currentSort);
    }

    public sortResult(property: string) {
        if (this.state.currentSort.type === property) {
            this.setState({ currentSort: { type: property, direction: (-this.state.currentSort.direction) } }, () => this.fetchResults(this.state.currentSort));
        } else {
            this.setState({ currentSort: { type: property, direction: 1 } }, () => this.fetchResults(this.state.currentSort));
        }
    }

    public renderLoading() {
        return <div className={'EmptyResult'}>
            <Spinner size="large" theme="dark" className="my-6"/>
            <span>{trans('starsign.loading')}</span>
        </div>;
    }

    public renderEmpty() {
        return <div className={'EmptyResult'}>
            <img src={'/assets/images/compatibility-result-empty-image.webp'} alt="compatibility results logo"/>
            <span>{trans('starsign.empty')}</span>
        </div>;
    }

    public async fetchResults(sort?: any) {
        try {
            const query: any = {
                limit: 100000
            };

            if (sort) {
                query[`sort[${sort.type}]`] = sort.direction;
            }

            this.setState({
                loading: true
            });

            const results = await Repository.get('/competition', query);

            if (!results) {
                throw new Error('no results');
            }

            const filtered = (results.items || []).filter((item) => !!get(item, 'publicResults.percentage'));

            this.setState({
                results: filtered,
                loading: false
            });
        } catch (err) {
            this.setState({
                loading: true
            });

            HandleToasts.error(err);
        }
    }

    public renderResult(result: any, index: number) {
        return <div key={result._id || index} className={'p-box border-bottom-1 palette--bc-neutral-3 ResultListItem'}>
            <div className="row display-flex flex-md-row flex-column justify-content-center align-items-center">
                <div className="col-lg-4 col-md-4 col-10 mb-md-0 mb-7">
                    <div className={'pr-md-7 pr-0'}>
                        <GaugeChart isAnimated={false} value={this.getCompetitionResult(result)}/>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-24">
                    <div className="display-flex h-100 flex-column justify-content-center align-items-md-start align-items-center">
                        <div className={' display-flex align-items-center justify-content-sm-center justify-content-space-between w-100 w-px-sm-1'}>
                            <h1 className={'mb-0'}>{get(result, 'meta.name', ' ') || ' '}</h1>
                            <EditResult onSubmit={() => this.reload()} result={result}/>
                        </div>
                        <div className={'my-4 my-sm-0 display-flex flex-row justify-content-center justify-content-md-start'}>
                            <span className={'fw-normal palette--c-neutral-5'}>{trans('starsign.results.born')}: {moment(get(result, 'answer.birthDate'), 'YYYY-MM-DD').format('YYYY.MM.DD')}</span>
                        </div>
                    </div>
                </div>
                {/*<div className="col-lg-7 col-md-7 col-24">*/}
                {/*    <div className="my-4 my-sm-0 display-flex h-100 flex-column justify-content-center  align-items-md-start align-items-center">*/}
                {/*        <div className={'display-flex flex-row justify-content-center justify-content-md-space-between w-100'}>*/}
                {/*            <span className={'fw-normal palette--c-neutral-5'}>{trans('starsign.results.created')}: {moment(get(result, 'createdAt')).format('YYYY.MM.DD HH:mm')}</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="my-4 my-sm-0 col-lg-8 col-md-8 col-24 display-flex align-items-center justify-content-sm-center justify-content-space-between">
                    <CompatibilityDetails result={result}/>
                    <div className={'ml-3 cursor-pointer'} onClick={() => this.onDelete(result)}>
                        <span className={'material-icons palette--c-neutral-5'}>delete</span>
                    </div>
                </div>
            </div>

        </div>;

    }

    public onDelete(result) {
        this.setState({ onDelete: result._id });
        this.deleteModalRef$?.open();
    }

    public async deleteResult() {
        try {
            await Repository.delete(`/competition/${get(this.state, 'onDelete')}`);
            this.deleteModalRef$?.close();
            this.reload();
        } catch (err) {
            this.setState({ error: err });
        }
    }

    public getCompetitionResult(result) {
        if (!result) {
            return 0;
        }
        const publicResult = get(result, 'publicResults', '');
        const percentage = get(publicResult, 'percentage', '0').replace('%', '');
        return (percentage / 100);
    }
}
