export const CONSTANTS = {
    oppositeCategories: [
        'Anxiety',
        'Dependence',
        'Fearfulness',
        'Flexibility',
        'Forgiveness',
        'Gentleness',
        'Patience',
        'Sentimentality',
        'Prudence',
        'Perfectionism',
    ],
    sameCategories: [
        'Social-Esteem',
        'Social-Boldness',
        'Corrupt-Avoidance',
        'Creativity',
        'Cultured',
        'Diligence',
        'Greed-Avoidance',
        'Inquisitiveness',
        'Liveliness',
        'Modesty',
        'Organization',
        'Own-Interest-Avoidance',
        'Sociability',
        'Unconventionality'
    ]
};
