import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { get } from 'lodash';
import * as React from 'react';
import { MessageBox } from '../../components/message-box/message-box';
import { Subscribable } from '../../libs/subscribable';
import './contact.scss';

export class Contact extends Subscribable<any, any> {
    public state: any = {
        submitSuccess: false,
        error: null
    };
    public form = new Form({
        firstName: new Field({
            label: trans('contact.firstName'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        lastName: new Field({
            label: trans('contact.lastName'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        email: new Field({
            label: trans('contact.email'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        message: new Field({
            label: trans('contact.message'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        })
    });

    public render(): React.ReactNode {
        return <div className="Contact flex-fill py-8">
            <div className="backgroundGradient"/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-24 display-flex flex-column align-items-md-start justify-content-center align-items-center">
                        <h2 className={'palette--c-neutral-1 mb-8'}>{trans('contact.title')}</h2>
                        {this.renderForm()}
                    </div>
                    <div className="col-12 display-none display-md-flex justify-content-center align-items-center">
                        <img src="/assets/images/hello.webp" alt="hello image"/>
                    </div>
                </div>
            </div>
        </div>;
    }

    public renderForm() {
        if (this.state.submitSuccess) {
            return <div className="palette--bgc-neutral-1 border-radius-2 elevation-1 ContactForm p-box">
                <div className="row display-flex flex-column flex-fill align-items-center">
                    <span className={'CheckIcon material-icons'}>check_circle</span>
                    <h1 className={'mb-8'}>{trans('contact.successTitle')}</h1>
                    <span className={'fs-large'}>{trans('contact.successDescription')}</span>
                </div>
            </div>;
        }

        return <div className="palette--bgc-neutral-1 border-radius-2 elevation-1 ContactForm p-box">
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <div className="row">
                    <div className="w-100 col-24 p-box">
                        <Input field={this.form.field('firstName')} labelClassName={'fs-small fw-bold palette--c-neutral-5 '} className="mb-box variant-primary size-medium" disabled={false}/>
                        <Input field={this.form.field('lastName')} labelClassName={'fs-small fw-bold palette--c-neutral-5 '} className="mb-box variant-primary size-medium" disabled={false}/>
                        <Input field={this.form.field('email')} labelClassName={'fs-small fw-bold palette--c-neutral-5 '} className="mb-box variant-primary size-medium" type={'email'} disabled={false}/>
                        <Input
                            field={this.form.field('message')}
                            element={'textarea'}
                            rows={6}
                            labelClassName={'fs-small fw-bold palette--c-neutral-5'}
                            className="mb-box variant-primary size-medium"
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 col-24 display-flex justify-content-start px-box pb-box">

                        <Button className="variant-primary size-medium">
                            <span className={'palette--c-neutral-1 display-flex align-items-center'}>
                                <span className={'material-icons palette--c-neutral-1 mr-2'}>send</span>
                                {trans('contact.send')}
                            </span>
                        </Button>

                    </div>
                </div>

                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <MessageBox type={'error'} message={trans(get(this.state, 'error.message'))}/>
                    </div>
                </div>}
            </AbstractForm>
        </div>;
    }

    public async handleSubmit() {
        try {
            await Repository.post('/contact', this.form.toJSON());

            this.setState({ submitSuccess: true });
        } catch (err) {
            this.setState({ error: err });
        }
    }
}
