import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import moment from 'moment';
import { getLanguage } from '../../../libs/get-language';
import { PurchaseDocument } from '../manage-access';

export interface PriceFormatOptions {
    currency: string;
    price: number;
    locale: string;
}

export const priceFormat = ({ currency, price, locale }: PriceFormatOptions) => {
    if (!!price) {
        return Intl
            .NumberFormat(locale, {
                currency,
                style: 'currency',
            })
            .format(price);
        // .replace(/\D00(?=\D*$)/, '')
        // .replace(/hun/i, 'Ft');
    }

    return '';
};

export const formatNumber = (amount: number) => {
    if (isNaN(amount)) {
        return 0;
    }

    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(amount).replace(',', ' ');
};

/**
 * creates a dummy number array for testing purposes.
 *
 */
export const numArray = (number: number, value: any = false) => {
    if (!value) {
        return Array.from({ length: number }, (i, index) => (index + 1));
    }
    return Array.from({ length: number }, (i, index) => value || index);
};

export const manageHiddenData = (data: string, test: boolean) => {
    return test ? data : numArray(data.length, '#').join('');
};

/**
 * in case the given value is valid it applies the replace method. otherwise it returns the input value.
 */
export const checkAndReplace = (value: string, toBeReplaced: string | RegExp, toBeReplacedWith: string) => {
    return !!value ? value.replace(toBeReplaced, toBeReplacedWith) : '';
};

export const getUniqueId = () => {
    return (Math.random() * 1000).toString(16).replace('.', '');
};

/**
 * turns an object into an array keeping the property name as id
 */
export const getArrayFromObject = (object: any) => {
    let result: any = [];
    for (const prop in object) {
        if (object.hasOwnProperty(prop)) {
            result = [...result, { ...object[prop], id: prop }];
        }
    }

    return result;
};

/**
 * make the datepicker value compatible with safari and firefox
 * @param dateString
 */
export const formatLongText = (text: string, maxLength: number) => {
    if (!text) {
        return '';
    }
    if (text.length <= maxLength) {
        return text;
    }

    return `${text.slice(0, maxLength)}...`;
};

/**
 *
 * @param arr
 * @param order asc || desc order...
 */
export const sort = (arr: any[], order = 'asc', prop = '') => {
    if (!prop) {

        return order === 'asc'
            ? arr.sort((currentItem, nextItem) => currentItem - nextItem)
            : arr.sort((currentItem, nextItem) => nextItem - currentItem);
    }

    return order === 'asc'
        ? arr.sort((currentItem, nextItem) => currentItem[prop] - nextItem[prop])
        : arr.sort((currentItem, nextItem) => nextItem[prop] - currentItem[prop]);
};

export const parseDate = (date: any, format: string = 'YYYY.MM.DD.', defaultVal: any = ' - ') => {
    if (!date) {
        return defaultVal;
    }

    return moment(date).format(format);
};

export const round = (number: number, decimal: number = 100) => {
    if (!number) {
        return number;
    }

    return Math.round(number * decimal) / decimal;
};

export const get = (val: any, defaultVal = ' - '): string => {
    return !val ? defaultVal : val;
};
const getGender = (gender: string) => {
    switch (gender?.toLowerCase() || '') {
        case 'female':
            return 'female';
        case 'male':
            return 'male';
        default:
            return trans(gender);
    }
};

const getGenderProfilePic = (gender: string) => {
    const avatar = !!gender ? getGender(gender) : 'male';

    return `/assets/images/profile/Empty_instructor_${(avatar || '').toLowerCase()}.webp`;
};

export const getImageSrc = (photo: string | undefined | null, gender: string) => {
    return !!photo ? photo : getGenderProfilePic(gender);
};

export const calculateAge = (birthDate): number | string => {
    if (!birthDate) {
        return '-';
    }

    return Math.floor((moment.duration(moment().diff(birthDate))).asYears());
};

export const metricWeightToImperial = (value: number) => {
    const lbs = 0.45359237;

    return round(value / lbs, 1);
};

export const imperialWeightToMetric = (value: number) => {
    const lbs = 0.45359237;

    return round(value * lbs, 100);
};

export const metricLengthToImperial = (value: number) => {
    const units = { feet: 30.48, inch: 2.54 };
    const feet = (value / units.feet).toString();
    const inches = round(Number(`0.${`${Number(feet.slice(2)) * units.feet / units.inch}`.replace('.', '')}`) * 10, 10).toString().replace('.', '');

    return `${feet[0]}'${inches.length === 1 ? +inches * 10 : inches}`;
};

export const imperialLengthToMetric = (value: number) => {
    const units = { feet: 30.48, inch: 2.54 };

    return round(Number(value.toString().charAt(0)) * units.feet + round(Number(value.toString().slice(2)) * units.inch, 10), 1);
};

export type PurchasedInfoTypes = 'behavioural-chart'
    | 'behavioural-compatibility'
    | 'personality-compatibility'
    | 'personality-chart'
    | 'love-style-chart'
    | 'star-sign-description'
    | 'contact-info'
    | 'match-making';

export const checkShouldReveal = (purchases: PurchaseDocument | null, userId: string, type: PurchasedInfoTypes) => {
    const result = (purchases?.purchasedContacts || []).find(purchase => purchase === userId);

    return !!result;
};

export const handleError = (error: any) => {
    if (!Environment || !Environment?.get('stage')) {
        return;
    }

    switch (Environment.get('stage')) {
        case 'development':
            console.log({ error });
            break;
        case 'production':
            return;
        default:
            return;
    }
};

export const handleNotEnoughBalance = (error: any) => {
    const message = error?.response?.data?.message;
    const lang = getLanguage();

    if (message === 'not.enough.balance') {
        window.location.href = `/${lang}/topup`;
    }
};
