import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { ProfileCompletionMeter } from '../profile/notifications/profile-completion-meter';
import { ProfileVisibility } from '../profile/notifications/profile-visibility';
import { formatNumber } from '../share/helpers/util';
import { UserInterface, UserMetaInterface } from '../share/interfaces/user.interface';
import { OverlaySpinner } from '../share/ui-components/overlay-spinner';
import { Paginator } from './paginator';
import { Profile, ProfileCard } from './profile.card';

export interface ProfileCardsProps {
    type?: 'like-list' | 'normal-list';
    user: UserInterface;
    filters: any;
    disabled?: boolean;
    disablePaginator?: boolean;
    defaultFilter?: any;
}

// TODO-> When filtering we should jump to the first page.

export class ProfileCards extends Subscribable<ProfileCardsProps, any> {
    public user: UserInterface = this.props.user;
    public meta: UserMetaInterface = this.props.user?.meta || {};
    public state = {
        profiles: [],
        total: 0,
        totalProfiles: 0,
        currentPage: 1,
        fetchPage: 0,
        isLoading: false
    };

    public async componentDidMount() {
        await this.getProfiles(this.state.currentPage - 1, this.props.defaultFilter);
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevState.currentPage !== this.state.currentPage) {
            await this.getProfiles(this.state.currentPage - 1);
        }
        if (prevProps.filters !== this.props.filters) {
            await this.getProfiles(0);
        }
    }

    public formatFilters(filters = {}) {
        const multiOptions = ['languages', 'relationshipInterests', 'professionType'];
        const formattedFilters = {};

        for (const prop of Object.keys(filters)) {
            if (multiOptions.includes(prop)) {
                formattedFilters[prop] = (filters[prop] || []).map(language => (language?.value || ''));
                continue;
            }

            formattedFilters[prop] = typeof filters[prop] === 'object' ? filters[prop]?.value : filters[prop];
        }

        return formattedFilters;
    }

    public getPath() {
        switch (this.props.type) {
            case 'like-list':
                return '/like/by-me';
            case 'normal-list':
                return '/user';
            default:
                return '/user';
        }
    }

    public getCardSizes() {
        switch (this.props.type) {
            case 'like-list':
                return 'col-24 col-xms-12 col-md-6 col-lg-4';
            case 'normal-list':
                return 'col-24 col-xms-12 col-md-8 col-lg-6';
            default:
                return 'col-24 col-xms-12 col-md-8 col-lg-6';
        }
    }

    public async getProfiles(page: number, defaultFilter = {}) {
        try {
            if (this.props.disabled) {
                return;
            }

            this.setState({ isLoading: true });
            const filters = this.formatFilters({ ...defaultFilter, ...(this.props?.filters || {}) });

            const response = await Repository.get(this.getPath(), { ...filters, page: page });

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({
                profiles: response.users,
                total: response.total,
                totalProfiles: response.totalProfiles,
                isLoading: false
            });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public renderNoMatchWereFound() {
        return !this.state.isLoading && <div className={'w-100 position-center py-7 my-7'}><span className={'text--h3'}>{trans('account.data.noMatches')}</span></div>;
    }

    public renderStatusStrips() {
        if (!this.props.disabled) {
            return null;
        }

        return <div className={'row'}>
            {!this.props?.user?.meta?.isProfilePublic &&
                <div className={'col-24 pb-6'}>
                    <ProfileVisibility user={this.props.user}/>
                </div>}
            {!this.props.user.meta?.isProfileComplete && <div className={'col-24 pb-6'}>
                <ProfileCompletionMeter user={this.props.user}/>
            </div>}
        </div>;
    }

    public renderProfileCards() {
        if (!this.state.profiles?.length) {
            return this.renderNoMatchWereFound();
        }

        return <div className={'row w-100'}>
            {this.state.profiles.map((profile: Profile, index) => {
                return <div
                    key={profile.id}
                    className={`appear-smoothly-${index + 1} ${this.getCardSizes()} min-width-180`}
                >
                    <ProfileCard key={index} profile={profile}/>
                </div>;
            })}
        </div>;
    }

    public renderPaginator() {
        if (this.props.disablePaginator) {
            return null;
        }

        return <div className={'row'}>
            <div className={'col-24 position-center'}>
                <Paginator
                    total={this.state.total}
                    currentPage={this.state.currentPage}
                    fetchPage={(currentPage: number) => this.setState({ currentPage })}
                />
            </div>
        </div>;
    }

    public render() {
        return <div>
            <div className={'row mb-7 w-100'}>
                <div className={'col-md-16'}>
                    <span className={'text--h3-semi-bold'}>{trans('account.data.available.profiles')}</span>
                </div>
                <div className={'col-md-8 display-flex justify-content-end align-items-end'}>
                    <span className={'text--h5'}>{formatNumber(this.state.totalProfiles)} {trans('account.data.profiles')}</span>
                </div>
            </div>
            <OverlaySpinner isLoading={this.state.isLoading}/>
            {this.renderStatusStrips()}
            {this.renderProfileCards()}
            {this.renderPaginator()}
        </div>;
    }
}
