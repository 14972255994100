import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import * as React from 'react';
import { InviteFriendForm } from '../../components/invite-friend/invite-friend.form';
import { Subscribable } from '../../libs/subscribable';
import './invite-friend.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class InviteFriendScreen extends Subscribable<any, any> {

    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <div className="container p-md-16 p-0 max-width-1200">
                <h3 className={'pb-5 fw-bold'}>{trans('account.data.friend.invitations')}</h3>
                <InviteFriendForm/>
            </div>
        </div>;
    }
}
