import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Dropdown } from '@codebuild/cookie-jar/uikit/components/dropdown/dropdown';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import { get, keys } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import { MessageBox } from '../../message-box/message-box';
import './personality-details.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class PersonalityDetails extends Subscribable<any, any> {
    public languageDropdown$: any;
    public descriptionDetailsModal$: any;

    public state: any = {
        result: this.props.result,
        error: null,
        currentLanguage: TranslationLib.getLang()
    };

    public render(): React.ReactNode {
        return <div className={'CompatibilityDetails'}>
            {this.renderResultActions(get(this.state, 'result'))}
            <Modal
                hideClose={true}
                ref={(ref) => this.descriptionDetailsModal$ = ref}
                content={() => this.renderDetailsModalContent()}
                wrapperClasses="DescriptionPreviewModal palette--bgc-neutral-1 elevation-2 border-radius-2"
                footer={() => null}
            />
        </div>;
    }

    public openDetailsModal(result) {
        this.setState({ resultDetails: result }, () => this.descriptionDetailsModal$.open());
    }

    public closeDetailsModal() {
        this.setState({ resultDetails: null }, () => this.descriptionDetailsModal$.close());
    }

    public renderDetailsModalContent() {
        return <div className={'p-box'}>
            <div className={'DetailsLangSelector mb-8'}>
                <Dropdown
                    className="DropdownButton"
                    backdropClasses=""
                    ref={(ref: Dropdown) => (this.languageDropdown$ = ref)}
                    direction="left"
                    renderDropdown={(ctx: Dropdown) => this.renderDropdownBox()}
                    renderContent={() => this.dropdownContent()}
                />
            </div>

            <div className={'mb-8'}>
                {this.getDetailsToLang()}
            </div>

            {(this.state.error) && <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>}

            <div className={'display-flex justify-content-end'}>
                <Button className={'variant-link size-medium'} onClick={() => this.closeDetailsModal()}>{trans('global.buttons.close')}</Button>
            </div>
        </div>;
    }

    public getDetailsToLang() {
        const lang = get(this.state, 'currentLanguage').toString().toLowerCase();

        if (this.hasDescription(this.state.result, lang.toString().toLowerCase())) {
            const chunks = get(this.state, `result.${lang}`, '').split('\n');

            return chunks.map((item, index) => <p className="pb-4" key={index} dangerouslySetInnerHTML={{ __html: item }}/>);
        }

        return <div>
            <div className={'py-6 mb-6 border-top-1 border-bottom-1 palette--bc-neutral-4 display-flex align-items-start justify-content-space-between'}>
                <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                    <span className={'material-icons palette--c-blue-2 mr-4'}>info</span>
                    <div>
                        <p className={'fs-large'}>{trans('starsign.noresult.title')}</p>
                        <span>{trans('starsign.noresult.desc')}</span>
                    </div>
                </div>
            </div>
        </div>;
    }

    public dropdownContent() {
        return <div onClick={() => this.languageDropdown$.toggle()}>
            <div className={'LanguageDropdown palette--bc-neutral-3 border-1 py-4 px-6 border-radius-1 display-inline-flex justify-content-center align-items-center cursor-pointer'}>
                {this.getCurrentLang()}
                <span className={'ml-8 material-icons'}>keyboard_arrow_down</span>
            </div>
        </div>;
    }

    public getCurrentLang() {
        const lang = (get(this.state, 'currentLanguage') as string).toLowerCase() || 'en';
        switch (lang) {
            case 'hu':
                return trans('global.language.hu');
            case 'en':
                return trans('global.language.en');
            case 'ro':
                return trans('global.language.ro');
            default:
                return trans('global.language.en');
        }
    }

    public renderDropdownBox() {
        return <div className={'p-box DropdownBox'}>
            <div className={'mb-4 display-flex justify-content-space-between'} onClick={() => this.setState({ currentLanguage: 'hu' }, () => this.languageDropdown$.close())}>
                <span className={'fs-large flex-fill mr-8'}>{trans('global.language.hu')}</span>

                {!this.hasDescription(this.state.result, 'hu') &&
                    <div className={'palette--bgc-neutral-3 border-radius-1 py-2 px-3 palette--c-neutral-5'}>
                        {trans('starsign.result.comingsoon')}
                    </div>}
            </div>
            <div className={'mb-4 display-flex justify-content-space-between'} onClick={() => this.setState({ currentLanguage: 'en' }, () => this.languageDropdown$.close())}>
                <span className={'fs-large flex-fill mr-8'}>{trans('global.language.en')}</span>
                {!this.hasDescription(this.state.result, 'en') &&
                    <div
                        className={'palette--bgc-neutral-3 border-radius-1 py-2 px-3 palette--c-neutral-5'}
                    >
                        {trans('starsign.result.comingsoon')}
                    </div>
                }
            </div>
            <div className={'mb-4 display-flex justify-content-space-between'} onClick={() => this.setState({ currentLanguage: 'ro' }, () => this.languageDropdown$.close())}>
                <span className={'fs-large flex-fill mr-8'}>{trans('global.language.ro')}</span>
                {!this.hasDescription(this.state.result, 'ro') &&
                    <div
                        className={'palette--bgc-neutral-3 border-radius-1 py-2 px-3 palette--c-neutral-5'}
                    >
                        {trans('starsign.result.comingsoon')}
                    </div>
                }
            </div>
        </div>;
    }

    public hasDescription(result, lang?: string) {
        if (!!lang) {
            return keys(get(this.state, 'result', null) || []).includes(lang);
        }
        return keys(get(this.state, 'result', null)).length;

    }

    public getResultPreview(result) {
        const defaultLang = TranslationLib.getLang();

        if (!get(this.state, 'result', null)) {
            return;
        }
        if (get(this.state, `result[${defaultLang}]`, '').length) {
            return <span dangerouslySetInnerHTML={{ __html: `${(get(this.state, `result[${defaultLang}]`, '')).slice(0, 250)}...` }}/>;
        }
        const k = keys(get(this.state, 'result', null));
        return k ? <span dangerouslySetInnerHTML={{ __html: `${(get(this.state, `result[${k[0]}]`, '')).slice(0, 250)}...` }}/> : null;

    }

    public renderResultActions(result) {
        return <div>
            {!this.props.hidePreview && <div>
                {this.getResultPreview(result)}
            </div>}
            {(!!this.hasDescription(result)) &&
                <Button onClick={() => this.openDetailsModal(result)} className={arrayToClass([this.props.readMoreClasses || 'variant-outline size-medium'])}>{this.props.readMoreTitle || trans('starsign.result.readmore')}</Button>}
            {!this.props.hideCommingSoon && !this.hasDescription(result) && <Button className={arrayToClass([this.props.comingSoonClasses || 'variant-disabled size-medium ComingSoonButton'])}>{trans('starsign.result.comingsoon')}</Button>}
        </div>;
    }

    /* private capitalize(string: string) {
         return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
     }*/

}
