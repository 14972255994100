import { authenticationReducer } from './reducers/authentication.reducer';
import { headerReducer } from './reducers/header.reducer';
import { navigationReducer } from './reducers/navigation.reducer';
import { urlReducer } from './reducers/url.reducer';

export const reducers = {
    url: urlReducer,
    authentication: authenticationReducer,
    navigation: navigationReducer,
    header: headerReducer
};
