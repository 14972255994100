import * as React from 'react';
import RedirectHandler from '../../components/redirect-handler';
import { Subscribable } from '../../libs/subscribable';
import './home.screen.scss';

export class HomeScreen extends Subscribable<any, any> {
    public render(): React.ReactNode {
        return <RedirectHandler/>;
    }
}
