import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { arrayToClass } from '@codebuild/cookie-jar/uikit/libs/array-to-class';
import moment from 'moment';
import * as queryString from 'querystring';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { LanguageSelector } from '../../components/language-selector/language-selector';
import { HandleToasts } from '../../handle.toasts';
import { generateLink } from '../../libs/generate-link';
import { Subscribable } from '../../libs/subscribable';
import { HOME_PAGE, routes } from '../../routes';
import './reset-password.screen.scss';

const logo = require('../../assets/images/selectivita-logo-symbol.svg');

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class ResetPasswordScreen extends Subscribable<any, any> {
    public state: any = {
        isSuccess: false,
        isLoading: false,
        isError: false
    };
    public form = new Form({
        new: new Field({
            label: trans('account.edit.changepsw.new'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required'))
            ]
        }),
        repassword: new Field({
            label: trans('account.edit.changepsw.newagain'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('global.input.required')),
            ]
        }),
    });

    public isDisabled(): boolean {
        return false;
    }

    public async handleSubmit() {
        try {
            const query = queryString.parse(this.props.location.search);
            const token = query['?token'];
            const user = query.user;
            const password = this.form.toJSON().new;

            await Repository.post('/auth/forgot-password/validate', { user, token, password });

            this.setState({
                isLoading: false,
                isSuccess: true,
                isError: false
            });
        } catch (err) {
            HandleToasts.error(err, trans(err?.response?.data?.message || err?.message || 'account.data.error.something.went.wrong'));

            this.setState({
                isLoading: false,
                isError: true,
                isSuccess: false
            });
        }
    }

    public render(): React.ReactNode {
        return <div className={'ResetPasswordScreen p-box'}>
            <div className="row flex-fill">
                <div className="col-xl-8 col-lg-10 col-md-14 col-24">
                    {this.renderResetPasswordBox()}
                </div>
            </div>
        </div>;
    }

    public renderResetPasswordBox() {
        const loginContainer = arrayToClass([
            ' palette--bgc-neutral-2 display-flex flex-column p-box',
            'ResetPasswordContainer'
        ]);
        return <div className={loginContainer}>
            {this.renderResetPasswordHeadline()}
            {this.renderForm()}
            {this.renderFooter()}
        </div>;
    }

    public renderFooter() {
        return <div className={'display-flex flex-column justify-content-center align-items-center'}>
            <div className={'mb-6'}>
                <LanguageSelector/>
            </div>
            <div className={''}>
                <span className={'text-center'}>{this.getCopyRightText()}</span>
            </div>
        </div>;
    }

    public getCopyRightText() {
        const start = trans('global.footermenu.copyright.start');
        const end = trans('global.footermenu.copyright.end');
        const currentYear = moment().format('y').toString();

        return `${start}${currentYear} ${end}`;
    }

    public renderResetPasswordHeadline() {

        return <div className={'row mb-7'}>
            <a href={HOME_PAGE} className={'col-sm-10 col-24 text-decoration-none mb-sm-0 mb-7'}>
                <div className={'justify-content-start align-items-center display-flex flex-row'}>
                    <img src={logo} className={'Logo'} alt={'logo'}/>
                    <h2 className={'mb-0 ml-7'}>Selectivita</h2>
                </div>
            </a>
        </div>;
    }

    private renderForm(): React.ReactNode {
        if (this.state.isSuccess) {
            return <div className="row display-flex flex-column flex-fill align-items-center">
                <span className={'CheckIcon material-icons'}>check_circle</span>
                <h1 className={'mb-8 text-center'}>{trans('change.password')}</h1>
                <span className={'fs-large'}>{trans('change.login..password')}</span>
                <Link to={generateLink(routes.login)} className={'fs-large palette--c-blue-2 fw-bold text-decoration-none'}>{trans('signup.login.button')}</Link>
            </div>;
        }

        return <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
            <div className="row justify-content-start">
                <div className="w-100 pt-box px-box">
                    <h1>Create new password</h1>
                    <span className={'palette--c-neutral-5 fs-large'}>{trans('setting.password')}</span>
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 p-box">
                    <Input
                        field={this.form.field('new')}
                        labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                        className="mb-box variant-primary size-medium"
                        type="password"
                        disabled={this.isDisabled()}
                    />
                    <Input
                        field={this.form.field('repassword')}
                        labelClassName={'fs-small fw-bold palette--c-neutral-5 text-uppercase'}
                        className="mb-box variant-primary size-medium"
                        type="password"
                        disabled={this.isDisabled()}
                    />
                </div>
            </div>
            <div className="row">
                <div className="w-100 col-24 display-flex justify-content-end px-box pb-box">

                    <Button type={'submit'} className="variant-primary size-medium" title={''}>
                        <span className="material-icons mr-4">check</span>
                        <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                    </Button>

                </div>
            </div>
        </AbstractForm>;
    }
}
