import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import moment from 'moment';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import { SeeMorePaginator } from '../matchmaking/see-more-paginator';
import { UserInterface, UserMetaInterface } from '../share/interfaces/user.interface';
import { ErrorModal } from '../share/ui-components/error.modal';
import { Hr } from '../share/ui-components/hr';
import { Spinner } from '../spinner';

export interface InvitedUser {
    createdAt: string;
    inviteeEmail: string;
    isFulfilled: boolean;
    token: string;
    updatedAt: string;
    userId: string;
    registeredAt: Date;
    name: string;
    _id: string;
}

export class InviteFriendForm extends Subscribable<any, any> {
    public user: UserInterface = this.props.user;
    public meta: UserMetaInterface = this.props.user?.meta || {};
    public $errorModal: ErrorModal;

    public state = {
        profiles: [],
        total: 0,
        totalProfiles: 0,
        currentPage: 1,
        fetchPage: 0,
        isLoading: false,
        isInvitationLoading: false,
        error: '',
    };

    public form = new Form({
        email: new Field({
            value: '',
            placeholder: 'name@email.com',
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        })
    });

    public async componentDidMount() {
        await this.getProfiles(0);
    }

    public async getProfiles(nextPage: number) {
        try {
            this.setState({ isLoading: true });

            const response = await Repository.get('/friend-invitation/get-all-invitation', {});

            if (!response) {
                throw new Error('something went wrong');
            }

            this.setState({
                profiles: response.invitedUsers || [],
                total: response?.total || 0,
                totalProfiles: response?.totalProfiles || 0,
                isLoading: false
            });
        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    public renderButtonContent(icon: string, title: string) {
        return <div className={'display-flex'}>
            <span className={'material-icons icon-positioning'}>{icon}</span>
            {this.state.isInvitationLoading ? <div className={'pl-4'}><Spinner/></div> : <span className={'responsive-text--btn'}>{title}</span>}
        </div>;
    }

    public async handleInvitation(email: string) {
        try {
            this.setState({ isInvitationLoading: true });
            const response = await Repository.post('/friend-invitation/invite-by-email', { friendEmail: email });

            if (!response) {
                throw new Error(trans('account.data.error.something.went.wrong'));
            }

            this.setState({ isInvitationLoading: false });

            await this.reloadList();
        } catch (error) {
            this.setState({ isInvitationLoading: false, error: this.parseError(error?.response?.data?.message || error?.message || error) });
            this.$errorModal.open();
        }
    }

    public parseError(errorMessage: string) {
        switch (errorMessage) {
            case 'NoEmailHasBeenProvided':
                return trans('account.data.error.no.email.has.been.provided');
            case 'UserNotFound':
                return trans('account.data.error.user.not.found');
            case 'EmailAlreadyInUse':
                return trans('account.data.error.email.already.in.use');
            default:
                return trans('account.data.error.something.went.wrong');
        }
    }

    public renderEmailSearchOld() {
        return <div className={'w-100 position-center h-px-170 search-bar flex-md-row flex-column'}>
            <div className={'px-7'}>
                <Input field={this.form.field('email')} labelClassName={'fs-small fw-bold palette--c-neutral-5 '} className="mb-box variant-primary size-medium" disabled={false}/>
            </div>
            <div className={'px-7 pt-7 pt-md-0'}>
                <Button
                    onClick={() => this.handleInvitation(this.form.toJSON().email)}
                    title={this.renderButtonContent('mail', trans('contact.invite'))}
                    className={'variant-primary size-large'}
                />
            </div>
        </div>;
    }

    public renderEmailSearch() {
        return <div className={'w-100 position-center p-md-7'}>
            <div className={'refer-a-friend-banner row border-radius-px-4 px-4 py-2'}>
                <div className={'col-24 display-flex mt-7 py-6 justify-content-center'}>
                    <h4 className={'text--h3 responsive-text--h4 palette--c-neutral-1 letter-spacing-7 text-uppercase'}>{trans('account.data.invite.friend')}</h4>
                </div>
                <div className={'col-24 position-center py-6'}>
                    <div className={'row w-100'}>
                        <div className={'col-md-12 col-24 position-center'}>
                            <div>
                                <h4 className={'text--h3 responsive-text--h5 palette--c-neutral-1 text-center text-uppercase'}>{trans('spread.the.word')}</h4>
                                <h4 className={'text--h3 responsive-text--h5 palette--c-neutral-1 text-center'}>&</h4>
                                <h4 className={'text--h3 responsive-text--h5 palette--c-neutral-1 text-center text-uppercase'}>{trans('earn.free.credits')}</h4>
                            </div>
                        </div>
                        <div className={'col-md-12 col-24 display-flex justify-content-center justify-content-md-start py-8'}>
                            <img className={'w-px-296'} src={'/assets/images/refer-a-friend/graphic.webp'}/>
                        </div>
                    </div>
                </div>
                <div className={'col-24 p-3'}>
                    <div className={'row'}>
                        <div className={'col-md-11 col-24 display-flex align-items-start pb-md-0 pb-7'}>
                            <span className={'text--span fs-16 palette--c-neutral-1'}>{trans('free.credit.for.refering.a.friend')}</span>
                        </div>
                        <div className={'col-md-13 col-24'}>
                            <div className={'row'}>
                                <div className={'col-24 col-md-17'}>
                                    <Input
                                        field={this.form.field('email')}
                                        labelClassName={'fs-small fw-bold palette--c-neutral-5 '}
                                        className="mb-box variant-primary size-medium"
                                    />
                                </div>
                                <div className={'col-24 col-md-7 display-flex justify-content-flex-start pb-md-0 pb-7'}>
                                    <Button
                                        onClick={() => this.handleInvitation(this.form.toJSON().email)}
                                        title={this.renderButtonContent('mail', trans('contact.invite'))}
                                        className={'variant-primary size-large h-px-32'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    public async reloadList() {
        this.setState({ profiles: [] });
        await this.getProfiles(this.state.currentPage);
    }

    public renderHeader() {
        return <div className={'pl-6 row my-4'}>
            <div className={'col-4'}>
                <span className={'fw-bold text--small mb-0 fs-8 fs-md-12'}>{trans('account.data.name')}</span>
            </div>
            <div className={'col-5'}>
                <span className={'fw-bold text--small mb-0 fs-8 fs-md-12'}>{trans('account.data.header.email')}</span>
            </div>
            <div className={'col-5'}>
                <span className={'fw-bold text--small mb-0 fs-8 fs-md-12'}>{trans('account.data.invited.at')}</span>
            </div>
            <div className={'col-6'}>
                <span className={'fw-bold text--small mb-0 fs-8 fs-md-12'}>{trans('account.data.registered.at')}</span>
            </div>
            <div className={'col-4'}>
                <span className={'fw-bold text--small mb-0 fs-8 fs-md-12'}>{trans('account.data.is.registered')}</span>
            </div>
        </div>;
    }

    public manageRegistration(registeredAt: Date, email: string) {
        if (registeredAt) {
            return <span className={' fs-10 fs-md-13'}>{moment(registeredAt).format('YYYY.MM.DD.')}</span>;
        }

        return <div>
            <Button
                onClick={() => this.handleInvitation(email)}
                title={this.renderButtonContent('mail', trans('contact.resend.invitation'))}
                className={'variant-contact size-small fix-height-28 display-sm-flex display-none'}
            />
            <Button
                onClick={() => this.handleInvitation(email)}
                title={<span className={'material-icons'}>mail</span>}
                className={'variant-contact size-small display-sm-none position-center rounded h-px-30 w-px-30'}
            />
        </div>;
    }

    public renderInvitedUser(user: InvitedUser, index: number) {
        const isRegistered = user.isFulfilled;

        return <div key={user._id} className={'pl-6 row my-2'}>
            <div className={'col-4'}>
                <span className={'fs-md-13 fs-10'}>{user?.name || '-'}</span>
            </div>
            <div className={'col-5 overflow-hidden'}>
                <span className={'fs-md-13 fs-10 Ellipsis'}>{user.inviteeEmail}</span>
            </div>
            <div className={'col-5'}>
                <span className={'fs-md-13 fs-10'}>{moment(user.createdAt).format('YYYY.MM.DD.')}</span>
            </div>
            <div className={'col-6'}>
                {this.manageRegistration(user?.registeredAt, user.inviteeEmail)}
            </div>
            <div className={'col-4'}>
                {isRegistered
                    ? <span className={'fs-md-13 fs-10 badge--active'}>{trans('account.data.fulfilled')}</span>
                    : <span className={'fs-md-13 fs-10 badge--inactive'}>{trans('account.data.unfulfilled')}</span>}
            </div>
        </div>;
    }

    public render() {
        return <div className={'w-100 background-white tab-content p-7'}>
            {this.renderEmailSearch()}
            <Hr type={'light'} className={'col-24 mb-7'}/>
            <h6 className={'fw-bold text--small mb-0'}>{trans('sent.invites')}</h6>
            <div className={'pb-4 display-flex flex-column w-100'}>
                {this.state.isLoading && <div className={'position-center py-7 w-100'}><Spinner theme={'dark'} size={'medium'}/></div>}
                {this.renderHeader()}
                {this.state.profiles.length
                    ? this.state.profiles.map((user: InvitedUser, index) => this.renderInvitedUser(user, index))
                    : <div className={'position-center py-7 w-100'}><span className={'palette--c-neutral-5'}>{trans('account.data.invite.empty.list')}</span></div>}
            </div>
            <SeeMorePaginator
                loadMore={(nextPage) => this.getProfiles(nextPage)}
                totalProfiles={this.state.totalProfiles}
                profileListLength={(this.state.profiles || []).length}
                currentPage={this.state.currentPage}
            />
            <ErrorModal
                ref={(ref: any) => this.$errorModal = ref}
                errorText={this.state.error}
            />
        </div>;
    }
}
