import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Modal } from '@codebuild/cookie-jar/uikit/components/modal/modal';
import { get, map } from 'lodash';
import * as React from 'react';
import { UserActions } from '../../../actions/user.actions';
import { Subscribable } from '../../../libs/subscribable';
import { ChangePassword } from '../../authentication/change-password/change-password';
import { BirthDateInput } from '../../birth-date-picker/birth-date-input';
import { MessageBox } from '../../message-box/message-box';
import { ProfileImageUploader } from '../../profile-image-uploader/profile-image-uploader';
import { handleError } from '../../share/helpers/util';

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({
    logout: () => dispatch(UserActions.logout())
});

@connect(mapStateProps, mapDispatchProps)
export class ProfileBoxForm extends Subscribable<any, any> {
    public deleteModalRef$: any;
    public changePassword$: any;

    public state: any = {
        attachmentImage: this.props.meta?.photo || '',
        changePassword: false,
    };

    public form = new Form({
        birthDate: new Field({
            label: '',
            value: this.props.meta?.birthDate || '',
            isDate: true,
            validators: [Validator.REQUIRED(trans('global.input.required'))]
        }),
    });

    public renderDeleteModalContent() {
        return <div className={'py-6'}>
            <div className={' mb-6  display-flex align-items-start justify-content-space-between'}>
                <div className={'flex-fill display-flex align-items-start justify-content-start'}>
                    <span className={'material-icons palette--c-red-2 mr-4'}>info</span>
                    <div>
                        <h2>{trans('account.delete.title')}</h2>
                        <span>{trans('account.delete.desc')}</span>
                    </div>
                </div>

            </div>
            <div>
                <div className={'display-flex justify-content-end align-items-center'}>
                    <Button
                        className={'variant-outline size-medium mr-3'}
                        onClick={() => this.deleteModalRef$.close()}
                    >
                        {trans('global.buttons.cancel')}
                    </Button>
                    <Button
                        className={'variant-error size-medium'}
                        onClick={() => this.deleteProfile()}
                    >
                        <span className="material-icons mr-3">delete</span>
                        {trans('profile.buttons.delete')}
                    </Button>
                </div>
            </div>
        </div>;
    }

    public onDelete() {
        this.deleteModalRef$?.open();
    }

    public async deleteProfile() {
        try {
            await Repository.delete('/user/');
            this.deleteModalRef$?.close();
            this.props.logout();
            window.localStorage.clear();
            window.location.reload();
        } catch (err) {
            this.setState({ error: err });
        }
    }

    public setForm() {
        map(this.form.fields, (field, key) => {
            return field.setValue(get(this.props, `meta[${key}]`));
        });
    }

    public parseData() {
        const data = this.form.toJSON();
        data.photo = this.state.attachmentImage;

        return data;
    }

    public async handleSubmit() {
        try {
            const data = this.parseData();

            const response = await Repository.put('/user', data);

            if (!response) {
                throw new Error('something went wrong');
            }

            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }

    public async changePassword(data) {
        try {
            await Repository.put('/user/password', data);
        } catch (err) {
            this.setState({ error: err });
        } finally {
            this.setState({ changePassword: false });
        }
    }

    public render() {
        if (this.state.changePassword) {

            return <div className={'p-box w-100'}>
                <h2>{trans('account.edit.changepsw.title')}</h2>
                <ChangePassword
                    ref={(ref) => this.changePassword$ = ref}
                    onCancel={() => this.setState({ changePassword: false })}
                    onSubmit={(data: any) => this.changePassword(data)}
                />
            </div>;
        }

        return <div className={'p-box'}>
            <AbstractForm form={this.form} className={'w-100'} onSubmitSuccess={() => this.handleSubmit()}>
                <h2 className={'mb-8'}>{trans('account.edit.title')}</h2>
                <div className="row mb-7">
                    <div className="col-md-12 col-24">
                        <ProfileImageUploader
                            currentImage={this.state.attachmentImage}
                            onUploadSuccess={(data) => this.setState({ attachmentImage: get(data, 'variants[0].payload') })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 col-24">
                        <div className="row mb-7">
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <label className={'mb-2 fs-small fw-bold palette--c-neutral-5 text-uppercase'}>{trans('account.edit.dateOfBirth')}</label>
                                <BirthDateInput
                                    disabled={this.props.meta?.birthDate || ''}
                                    field={this.form.field('birthDate')}
                                />
                            </div>
                            <div className="col-md-12 col-24 mb-md-0 mb-7">
                                <div className="col-24 mt-7 display-flex align-items-end">
                                    <Button
                                        type={'button'}
                                        onClick={() => this.setState({ changePassword: true })}
                                        className="variant-outline size-medium"
                                    >
                                        <span className="material-icons mr-4">vpn_key</span>
                                        <span>{trans('account.edit.changepsw')}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-100 col-24 display-flex justify-content-end">
                                <div className={'flex-fill'}>
                                    <Button
                                        type={'button'}
                                        onClick={() => this.onDelete()}
                                        className="variant-error size-medium"
                                        title={trans('profile.buttons.delete')}
                                    />
                                </div>
                                <Button
                                    type={'button'}
                                    onClick={() => this.handleCancel()}
                                    className="variant-link size-medium"
                                    title={trans('global.buttons.cancel')}
                                />
                                <Button type={'submit'} className="variant-primary size-medium">
                                    <span className="material-icons mr-4">check</span>
                                    <span className="palette--c-neutral-1">{trans('global.buttons.save')}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        hideClose={true}
                        ref={(ref) => this.deleteModalRef$ = ref}
                        content={() => this.renderDeleteModalContent()}
                        wrapperClasses="DeleteModalWrapper border-left-3 palette--bc-red-2 palette--bgc-neutral-1 elevation-2 border-radius-1"
                        footer={() => null}
                    />
                </div>
                {get(this.state, 'error') && <div className="row">
                    <div className="col-24">
                        <div className="col-24 pt-6">
                            <MessageBox type={'error'} message={trans(get(this.state, 'error.response.data.message'))}/>
                        </div>
                    </div>
                </div>}
            </AbstractForm>
        </div>;
    }

    public handleCancel() {
        this.setForm();
        this.props.onCancel();
    }
}
