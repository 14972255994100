import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { get } from '../../share/helpers/util';
import { PersonalPreferencesForm } from '../forms/personal-preferences-form';
import { BoxWrapper } from '../shared/box-wrapper';
import { UserBase } from '../shared/user.base';

export class PersonalPreference extends UserBase {
    public getTrans(data: any) {
        return get(trans(data || ''));
    }

    public renderMultiOptions(options: string[], title: string) {
        return <div className={'display-flex flex-column mb-4'}>
            <span className={'text--small-grey'}>{trans(title)}</span>
            {options.length && (options || []).map((option, key) => <span key={key} className={'text--small'}>{this.getTrans(option)}</span>)}
        </div>;
    }

    public render() {
        return <BoxWrapper
            shouldClose={this.state.shouldClose}
            modalClass={'max-width-900'}
            content={<PersonalPreferencesForm
                onCancel={() => this.setState({ shouldClose: !this.state.shouldClose })}
                meta={this.state.meta}
            />}
            enableEdit={this.props.enableEdit}
            title={<h6 className={'fw-bold text--small'}>{trans('account.data.miscellaneous.filters')}</h6>}
        >

            <div className={'w-100'}>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.sexual.orientation')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.sexualOrientation)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.libido')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.libido)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.worship.frequency')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.worshipFrequency)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.is.smoker')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.isSmoker)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.alcohol.consumption')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.alcoholConsumption)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.drug.usage')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.drugUsage)}</span>
                </div>
                <div className={'display-flex flex-column mb-4'}>
                    <span className={'text--small-grey'}>{trans('account.data.tattoos')}</span>
                    <span className={'text--small'}>{this.getTrans(this.state.meta?.tattoos)}</span>
                </div>
                {this.renderMultiOptions([this.state.meta?.relationshipInterests].flat(), 'account.data.relationship.interests')}
            </div>
        </BoxWrapper>;
    }
}
