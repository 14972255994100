import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import * as React from 'react';
import { StorageHelper } from '../../components/share/helpers/storage.helper';
import { handleError } from '../../components/share/helpers/util';
import { QuestionnaireController } from '../../components/start-questionnaire/questionnaire.controller';
import { Subscribable } from '../../libs/subscribable';
import { QuestionnaireProps } from '../questionnaires/questionnaires';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class StartQuestionnairesScreen extends Subscribable<any, any> {
    public storage = new StorageHelper('questionnaires');
    public state = { questionnaire: null, isLoading: true };

    public async componentDidMount() {
        const questionnnaireData = this.storage.get();
        const questionnaire = this.formatQuestionnaire(questionnnaireData);

        this.setState({ questionnaire, isLoading: !!questionnaire });
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            const questionnaire = this.storage.get();

            if (!questionnaire || !questionnaire?.length) {
                await this.getQuestionnaires();
            }
        }

        if (prevState.questionnaire !== this.state.questionnaire) {
            this.setState({ isLoading: !this.state.questionnaire });
        }
    }

    public formatQuestionnaire(questionnaires: QuestionnaireProps[]): QuestionnaireProps | null {
        if (!questionnaires || !questionnaires?.length) {
            return null;
        }

        const currentQuestionnaire = questionnaires.filter((questionnaire: QuestionnaireProps) => questionnaire.meta.identifier === this.props.match.params.questionnaire)?.[0];

        if (!currentQuestionnaire) {
            return null;
        }

        return currentQuestionnaire;
    }

    public async getQuestionnaires() {
        try {
            const response = await Repository.get(`/questionnaires/${this.props.user._id}`);

            if (!response) {
                throw new Error('something went wrong');
            }

            this.storage.set(response.questionnaires);

            this.setState({ questionnaire: this.formatQuestionnaire(response.questionnaires) });
        } catch (err) {
            handleError(err);
        }
    }

    public render(): React.ReactNode {
        return <div className={'MeProfileScreen p-box'}>
            <div className="BackgroundWrapper"/>
            <div className="container max-width-1138">
                {this.state.questionnaire && <QuestionnaireController user={this.props.user} questionnaire={this.state.questionnaire}/>}
            </div>
        </div>;
    }
}
