import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { HandleToasts } from '../../../handle.toasts';
import { Subscribable } from '../../../libs/subscribable';
import { CompatibilityDetails } from '../../compatibility-test/compatibility-results/compatibility-details';
import { GaugeWrapper } from '../../gauge-chart/gauge-wrapper';
import { handleError, handleNotEnoughBalance } from '../../share/helpers/util';
import { BoxWrapper } from '../shared/box-wrapper';
import { CompatibilityBoxProps } from './behaviour-compatibility-box';
import { StarSignContent } from './extras/modal-content/star-sign-content';

export class StarSignCompatibilityBox extends Subscribable<CompatibilityBoxProps, any> {
    public state = {
        isPurchased: false,
        isAnimated: false,
        analysisData: null,
        result: null
    };

    public async componentDidMount() {
        this.checkIfPurchased();
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.checkIfPurchased();
        }
        if (prevProps.purchases !== this.props.purchases) {
            this.checkIfPurchased();
        }
        if (prevState.isPurchased !== this.state.isPurchased && this.state.isPurchased) {
            await this.getAnalysisData();
        }
    }

    public checkIfPurchased() {
        const isPurchased = !!this.props.purchases?.starSignCharts?.find((i: string) => i === this.props.user?._id);
        this.setState({ isPurchased });
    }

    public renderGauge() {
        const compatibility = ((this.state?.analysisData as any)?.compatibilityRate || 0) / 100;
        const isAnimated = this.state.isAnimated;

        if (isAnimated) {
            return <div className={'w-100 position-center'}>
                <GaugeWrapper
                    isAnimated={true}
                    value={compatibility}
                    width={150}
                    height={150}
                />
            </div>;
        }

        return <div className={'w-100 position-center'}>
            <GaugeWrapper
                isAnimated={false}
                value={compatibility}
                width={150}
                height={150}
            />
        </div>;
    }

    public async calculateCompatibility() {
        try {
            const profileId = this.props.user?._id;

            const response = await Repository.put('/analysis-purchase/purchase-profile-infos', { profileId, type: 'star-sign' });

            if (!response) {
                throw new Error(trans('account.data.error.something.went.wrong'));
            }

            this.setState({ isAnimated: true, isPurchased: true }, () => this.getAnalysisData());
        } catch (e) {
            HandleToasts.error(e, trans(e?.response?.data?.message || e?.message || 'account.data.error.something.went.wrong'));

            handleNotEnoughBalance(e);
        }
    }

    public async getAnalysisData(): Promise<any> {
        try {
            const profileId = this.props.user?._id;

            const analysisData = await Repository.get('/analysis-purchase/get-user-analysis', {
                id: profileId,
                type: 'starSignCompatibilityDetails'
            });

            if (!analysisData) {
                throw new Error('something went wrong');
            }

            this.setState({ analysisData });
        } catch (e) {
            handleError(e);
        }
    }

    public renderButton() {
        return <div>
            <CompatibilityDetails primaryButton={true} result={(this.state?.analysisData as any)?.data}/>
        </div>;
    }

    public renderContent() {
        return <StarSignContent user={this.props.user} userAnalysisData={{} as any}/>;
    }

    public renderButtonContent(btnText: string, credit: number) {
        const text = trans(btnText);
        const creditText = trans(credit > 1 ? 'global.label.credits' : 'global.label.credit');

        return <span className={'fs-13'}>{text} <span className={'text-italic fs-13'}>({credit} {creditText})</span></span>;
    }

    public renderCalculateButton() {
        if (this.state.isPurchased) {
            return null;
        }

        return <Button
            className={'ui-base uikit-button variant-primary size-medium'}
            onClick={() => this.calculateCompatibility()}
        >
            {this.renderButtonContent('account.data.calculate.compatibility', 1)}
        </Button>;
    }

    public render() {
        return <BoxWrapper
            questionnaireBox={true}
            renderExtraContent={this.renderButton()}
            content={this.renderContent()}
            className={'fix-height-310'}
            title={<h6 className={'fw-bold text--small'}>{trans('account.data.starSign')}</h6>}
        >
            {this.renderGauge()}
            <div className={'w-100 position-center'}>
                {this.renderCalculateButton()}
            </div>
        </BoxWrapper>;
    }
}
