import { connect } from '@codebuild/cookie-jar/libs/connect';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import * as React from 'react';
import { UserActions } from '../../actions/user.actions';
import { handleError } from '../../components/share/helpers/util';
import { Spinner } from '../../components/spinner';
import { Subscribable } from '../../libs/subscribable';
import './payment.screen.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user,
});

const mapDispatchProps = (dispatch: any) => ({
    setResultRequest: (requestData: any) => dispatch(UserActions.setResultRequest(requestData))

});

@connect(mapStateProps, mapDispatchProps)
export class PaymentScreen extends Subscribable<any, any> {
    public interval$: any = null;
    public state: any = {
        paymentId: this.props.match.params.id,
        paymentStatus: null
    };

    public componentDidMount(): void {
        this.setState({
            paymentId: this.props.match.params.id
        });

        this.interval$ = setInterval(() => this.fetchPayment(), 3000);
    }

    public render(): React.ReactNode {
        return <div className={'PaymentScreen'}>
            <div className="container">
                <div className="display-flex flex-column justify-content-center align-items-center p-box">
                    <h1 className={'mb-8'}>{trans('payment.status.title')}</h1>
                    <span className={'fs-large mb-16'}>{trans('payment.status.description')}</span>
                    {(this.state.paymentStatus === 'PENDING' || !this.state.paymentStatus) && <div className={'display-flex justify-content-center align-items-center flex-column'}>
                        <div className={'mb-16'}><Spinner size={'large'} theme={'dark'}/></div>
                        <Button className={'variant-outline size-medium'} title={trans('payment.status.button')} onClick={() => this.fetchPayment()}/>
                    </div>}
                    {this.state.paymentStatus === 'SUCCESS' && <div>
                        <div><span className={'CheckIcon material-icons'}>check_circle</span></div>
                    </div>}
                    {(this.state.paymentStatus === 'FAILED' || this.state.paymentStatus === 'UNKNOWN' || this.state.paymentStatus === 'TIMEOUT') && <div>
                        <div><span className={'ErrorIcon material-icons'}>error</span></div>
                    </div>}
                </div>
            </div>
        </div>;
    }

    public async fetchPayment() {
        try {
            const payment = await Repository.get(`/payment/${this.state.paymentId}`);
            if (payment.status === 'SUCCESS') {
                clearInterval(this.interval$);
            }
            this.setState({ paymentStatus: payment.status });
        } catch (err) {
            clearInterval(this.interval$);

            handleError(err);
        }
    }
}
