import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './pricing.screen.scss';
import { Pricing } from './pricing/pricing';

export class PricingScreen extends Subscribable<any, any> {

    public render(): React.ReactNode {
        return <div className={'PricingScreen p-box'}>
            <div className="container">
                <div className="container p-box">
                    <div className={'display-flex justify-content-center align-items-center p-box'}>
                        <h1>Our pricing</h1>
                    </div>
                </div>
                <Pricing/>
            </div>
        </div>;
    }

}
