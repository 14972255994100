import { connect } from '@codebuild/cookie-jar/libs/connect';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { get } from 'lodash';
import * as React from 'react';
import { Subscribable } from '../../../libs/subscribable';
import './credit-box.scss';

const mapStateProps = (store: any) => ({
    user: store.authentication.user
});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class CreditBox extends Subscribable<any, any> {
    public render(): React.ReactNode {
        return <div className={'p-box elevation-1 border-radius-2 palette--bgc-neutral-1 h-100 CreditBox'}>
            <div className={'row'}>
                <div className="col-24">
                    <h6 className={'text-uppercase'}>{trans('global.balance')}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-24 display-flex justify-content-center align-items-center py-16">
                    <div className={'display-flex justify-content-center align-items-center'}>
                        <h3 className={'CreditNumber mr-6'}>{get(this.props, 'user.meta.balance', 0)}</h3>
                    </div>
                </div>
            </div>
        </div>;
    }

}
