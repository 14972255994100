import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import React from 'react';
import { BehaviourContent } from '../compatibility-infos/extras/modal-content/behaviour-content';
import { EditModal } from '../shared/edit.modal';
import { DisplayBox } from './libs/display-box';

export class BehaviouralAnalysis extends DisplayBox {
    public title = trans('account.data.behavioural.questionnaire');
    public buttonTitle = trans('account.data.view.data');
    public image = '/assets/images/profile/behavioural.png';
    public modal$: EditModal | null;
    public questionnaire = 'behavioural';
    public isQuestionnaireFilled = !!this.props.user.meta[`${this.questionnaire}Questionnaire`];

    public renderDescription() {
        const questionnaire = this.props.user.meta?.[`${this.questionnaire}Questionnaire`];

        if (!questionnaire) {
            return null;
        }

        const userAnalysisData = {
            gender: this.props.user.meta?.gender,
            data: questionnaire
        };

        return <BehaviourContent user={this.props.user} userAnalysisData={userAnalysisData}/>;
    }

    public renderImage() {
        return <img
            className={'h-px-185 width-auto'}
            src={this.image}
            alt={this.alt || trans('account.data.alt.icon.text')}
        />;
    }

    public renderModal(): null | JSX.Element {
        return <EditModal
            className={'max-width-848'}
            content={this.renderDescription()}
            ref={ref => this.modal$ = ref}
        />;
    }

    public handleButtonClick(): void {
        this.modal$?.openModal();
    }
}
